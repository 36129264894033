import { Alert, Stack } from '@mui/material';
import { FieldValues, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  ProductionRun,
  ProductionRunCreate,
  ProductionRunLinesLite,
  ProductionRunLite,
  ProductionRunUpdate,
} from 'types/production';
import {
  HookFormInput,
  HookFormDatePicker,
  HookFormTeamAutocomplete,
} from 'ui-component/HookFormComponents';
import { useEffect, useState } from 'react';
import { useSelector } from 'store';
import {
  useCreateProductionRunMutation,
  useDuplicateProductionRunMutation,
  useUpdateProductionRunMutation,
  useUpdateProductionRunLineMutation,
  useCreateProductionRunLineMutation,
} from 'store/slices/apiV1/production';
import { convertDate, formatToUniversalDate } from 'utils/dates';
import {
  getDefaultShippingMethod,
  getShipmentCarrierAndService,
  handleErr,
} from 'utils/functions';
import HookFormStockLocationSelect from 'ui-component/HookFormComponents/HookFormStockLocationSelect';
import _ from 'lodash';
import { useGetOrgQuery } from 'store/slices/apiV1/org';
import { skipToken } from '@reduxjs/toolkit/dist/query/react';
import {
  formConstants,
  validationSchema,
} from 'views/production/components/CreateEditProductionRunDialog/constants';
import { getCopy } from 'views/production/components/CreateEditProductionRunDialog/copy';
import { useGetBomQuery } from 'store/slices/apiV1/bom';
import { ALL_APP_IDS, ALL_APPS } from 'constants/appConstants';
import { appPermissionAccessLevels } from 'types/apps';
import useSnackbar from 'hooks/useSnackbar';
import { StockLocationType } from 'types/inventory';
import { useAppAccessContext } from 'contexts/AppAccessContext';
import useCustomTeamTypeName from 'hooks/useCustomTeamTypeName';
import useGetCustomPropertyDefinitions from 'hooks/useGetCustomPropertyDefinitions';
import {
  CustomPropertyModel,
  CustomPropertyVisibility,
} from 'types/customProperty';
import { HookFormCustomPropertyInput } from 'ui-component/HookFormComponents/HookFormCustomPropertyInput';
import { ExtendedDialog } from 'ui-component/extended/ExtendedDialog';

type CreateUpdateProductionRunFormValues = {
  name: ProductionRunUpdate['name'];
  referenceNumber: ProductionRunUpdate['referenceNumber'];
  productionDate: ProductionRunUpdate['productionDate'];
  notes: ProductionRunCreate['notes'];
  productionLocation: ProductionRunUpdate['productionLocation'];
  defaultShippingMethod: string;
  quant: ProductionRunCreate['quant'];
  team: ProductionRunUpdate['team'];
  customProperties: ProductionRunCreate['customProperties'];
};

type CreateEditProductionRunDialogProps = {
  dialogOpen: boolean;
  onClose: (runId?: string) => void;
  productionRun?: ProductionRun | ProductionRunLite;
  duplicate?: boolean;
  batchForBom?: string | null;
  batchLine?: ProductionRunLinesLite;
  onCreateSuccess?: (productionRun: ProductionRun) => void;
};

const CreateEditProductionRunDialog = ({
  dialogOpen,
  onClose,
  productionRun,
  duplicate,
  batchForBom,
  batchLine,
  onCreateSuccess,
}: CreateEditProductionRunDialogProps) => {
  const { dispatchSuccessSnackbar } = useSnackbar();

  const [submitError, setSubmitError] = useState<string | null>(null);
  const { activeOrgId, activeOrg } = useSelector((state) => state.org);
  const { data: org } = useGetOrgQuery(activeOrgId ?? skipToken);
  const copy = getCopy();

  const { hasAppPermission } = useAppAccessContext();
  const propertiesAppEnabled = hasAppPermission(ALL_APP_IDS.PROPERTIES);
  const { handleReplaceWithCustomTeamName } = useCustomTeamTypeName();

  const { customProperties, getDefaultValuesForCustomProperties } =
    useGetCustomPropertyDefinitions({
      model: CustomPropertyModel.PRODUCTION_RUN,
      visibilityContext:
        productionRun && !duplicate
          ? CustomPropertyVisibility.EDIT_RECORD_DIALOG
          : CustomPropertyVisibility.NEW_RECORD_DIALOG,
    });

  const {
    data: bom,
    isLoading: isBomLoading,
    isSuccess: isBomSuccess,
  } = useGetBomQuery(batchForBom ?? skipToken);

  const [updateProductionRun, { isLoading: isLoadingUpdate }] =
    useUpdateProductionRunMutation();
  const [createProductionRun, { isLoading: isLoadingCreate }] =
    useCreateProductionRunMutation();
  const [duplicateProductionRun, { isLoading: isLoadingDuplicate }] =
    useDuplicateProductionRunMutation();
  const [updateProductionRunLine] = useUpdateProductionRunLineMutation();
  const [addBomToProductionRun, { isLoading: isAdding }] =
    useCreateProductionRunLineMutation();

  const initialFormValues = {
    [formConstants.name.id]: productionRun
      ? duplicate
        ? `${productionRun?.name} Copy`
        : productionRun?.name
      : '',
    [formConstants.referenceNumber.id]: productionRun?.referenceNumber ?? '',
    [formConstants.productionDate.id]: productionRun?.productionDate
      ? convertDate(productionRun?.productionDate)
      : null,
    [formConstants.quant.id]: batchLine?.quant ?? 1,
    [formConstants.productionLocation.id]:
      typeof productionRun?.productionLocation === 'string'
        ? productionRun?.productionLocation
        : productionRun?.productionLocation?.id ?? '',
    [formConstants.defaultShippingMethod.id]: productionRun
      ? getDefaultShippingMethod({
          productionRun,
        })
      : getDefaultShippingMethod({ org }),
    [formConstants.team.id]: productionRun?.team ?? bom?.team?.id ?? '',
    customProperties: getDefaultValuesForCustomProperties<
      ProductionRun | ProductionRunLite
    >(productionRun),
  };

  const {
    control,
    reset,
    formState: { errors },
    handleSubmit,
    setValue,
    watch,
  } = useForm<FieldValues>({
    defaultValues: initialFormValues,
    resolver: yupResolver(validationSchema),
  });

  const watchProductionDate = watch(formConstants.productionDate.id);
  const watchQuant = watch(formConstants.quant.id);
  const watchName = watch(formConstants.name.id);

  useEffect(() => {
    if (
      isBomSuccess &&
      batchForBom &&
      (_.endsWith(watchName, `x ${bom?.name}`) || !watchName)
    ) {
      setValue(
        formConstants.name.id,
        `${watchQuant?.toLocaleString()}x ${bom?.name}`
      );
    }
  }, [watchQuant, isBomLoading, bom, isBomSuccess]);

  useEffect(() => {
    if (dialogOpen) {
      reset(initialFormValues);
    }
  }, [dialogOpen, customProperties]);

  const handleClose = (runId?: string) => {
    onClose(runId);
    setSubmitError(null);
  };

  const constructPayload = (values: CreateUpdateProductionRunFormValues) => {
    const { defaultShippingCarrier, defaultShippingService } =
      getShipmentCarrierAndService(
        _.get(values, formConstants.defaultShippingMethod.id)
      );
    return {
      name: _.get(values, formConstants.name.id),
      referenceNumber: _.get(values, formConstants.referenceNumber.id),
      productionDate: _.get(values, formConstants.productionDate.id)
        ? formatToUniversalDate(
            new Date(_.get(values, formConstants.productionDate.id))
          )
        : null,
      notes: _.get(values, formConstants.notes.id, undefined),
      productionLocation: _.get(values, formConstants.productionLocation.id),
      defaultShippingCarrier: defaultShippingCarrier || undefined,
      defaultShippingService: defaultShippingService || undefined,
      bom: undefined,
      quant: undefined,
      team: _.get(values, formConstants.team.id, undefined),
      customProperties: values.customProperties || undefined,
    };
  };

  const handleCreateOrEditProductionRun = async (
    values: CreateUpdateProductionRunFormValues
  ) => {
    try {
      const payload = constructPayload(values);
      let productionRunId = productionRun ? productionRun.id : undefined;
      if (productionRun) {
        if (duplicate) {
          const duplicatedProductionRun = await duplicateProductionRun({
            productionRunId: productionRun.id,
            payload,
          }).unwrap();
          productionRunId = duplicatedProductionRun.id;
        } else {
          await updateProductionRun({
            payload: payload as ProductionRunUpdate,
            id: productionRun.id,
          }).unwrap();
        }
      } else {
        const createdProductionRun = await createProductionRun(
          payload as ProductionRunCreate
        ).unwrap();
        if (onCreateSuccess) onCreateSuccess(createdProductionRun);
        productionRunId = createdProductionRun.id;
      }
      if (productionRun && batchLine) {
        await updateProductionRunLine({
          productionRunId: productionRun.id,
          productionRunLineId: batchLine.id,
          payload: { quant: _.get(values, formConstants.quant.id) },
        }).unwrap();
      }
      if (batchForBom && productionRunId) {
        await addBomToProductionRun({
          productionRunId,
          payload: {
            bom: batchForBom,
            quant: _.get(values, formConstants.quant.id),
          },
        }).unwrap();
      }
      dispatchSuccessSnackbar(
        `Program successfully ${
          productionRun ? (duplicate ? 'duplicated' : 'edited') : 'created'
        }.`,
        productionRunId
          ? `${ALL_APPS.PRODUCTION.path}/${productionRunId}`
          : undefined
      );
      handleClose(productionRunId);
    } catch (err) {
      handleErr(err, setSubmitError);
    }
  };

  return (
    <ExtendedDialog
      onCloseDialog={handleClose}
      open={dialogOpen}
      fullWidth
      maxWidth="sm"
      title={`${
        productionRun ? (duplicate ? 'Duplicate' : 'Edit') : 'New'
      } Program`}
      isForm
      onSubmit={handleSubmit((values: FieldValues) =>
        handleCreateOrEditProductionRun(
          values as CreateUpdateProductionRunFormValues
        )
      )}
      formSubmitError={submitError}
      trackingNames={{
        cancel: copy.trackingName.cancel,
        save: copy.trackingName.save,
      }}
      isSubmitting={
        isLoadingCreate || isLoadingUpdate || isLoadingDuplicate || isAdding
      }
      permissionScope={{
        app: batchForBom ? ALL_APPS.BOMS.id : ALL_APPS.PRODUCTION.id,
        accessLevel: appPermissionAccessLevels.edit,
      }}
    >
      {batchForBom && !duplicate && (
        <HookFormInput
          errors={errors}
          name={formConstants.quant.id}
          label={formConstants.quant.label}
          control={control}
          type="number"
        />
      )}
      <HookFormInput
        errors={errors}
        name={formConstants.name.id}
        label={formConstants.name.label}
        control={control}
      />
      <HookFormInput
        errors={errors}
        name={formConstants.referenceNumber.id}
        label={`${activeOrg?.name} Number`}
        control={control}
      />
      {!batchForBom && (
        <HookFormDatePicker
          name={formConstants.productionDate.id}
          label={formConstants.productionDate.label}
          control={control}
          errors={errors}
          disablePast
        />
      )}
      {!batchForBom && !watchProductionDate && (
        <Alert severity="info" sx={{ mt: 2, mb: 2 }}>
          No production date is set for this program, so all dates will be
          calculated based on a work-forward model to execute production as soon
          as possible.
        </Alert>
      )}
      <HookFormStockLocationSelect
        contextualCreate
        limitCreateLocationTypeToFacility
        filters={[
          {
            field: 'locationType',
            operator: '=',
            value: StockLocationType.FACILITY,
          },
        ]}
        label={formConstants.productionLocation.label}
        name={formConstants.productionLocation.id}
        control={control}
        errors={errors}
        setValue={setValue}
      />
      {propertiesAppEnabled && (
        <HookFormTeamAutocomplete
          control={control}
          name={formConstants.team.id}
          label={handleReplaceWithCustomTeamName(formConstants.team.label)}
          errors={errors}
          setValue={setValue}
          defaultValue={initialFormValues[formConstants.team.id] as string}
          disabled={!!bom?.team?.id}
        />
      )}
      <Stack sx={{ mt: 2 }} spacing={2}>
        {_.map(customProperties, (property) => (
          <HookFormCustomPropertyInput
            control={control}
            errors={errors}
            key={property.key}
            customProperty={property}
            setValue={setValue}
          />
        ))}
      </Stack>
    </ExtendedDialog>
  );
};

export default CreateEditProductionRunDialog;
