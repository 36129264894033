import { useCallback } from 'react';
import { useDispatch, useSelector } from 'store';
import { setStoredOrg, handleOrgSwitch } from 'store/actions';
import { setActiveOrgId } from 'store/slices/apiV1/org';
import { getCustomizedParts } from 'store/slices/apiV1/partData';

export const useUpdateOrgData = () => {
  const dispatch = useDispatch();
  const customizedParts = useSelector(getCustomizedParts);
  const { blended } = useSelector((state) => state.org);

  const changeActiveOrg = useCallback(
    (orgId: string) => {
      dispatch(setStoredOrg(orgId));
      dispatch(
        setActiveOrgId({
          orgId,
          blended: false,
          skipResetState: true,
        })
      );
      dispatch(handleOrgSwitch({ customPartIds: customizedParts }));
    },
    [dispatch]
  );

  const toggleBlended = useCallback(
    ({ skipResetState }: { skipResetState: boolean }) => {
      if (blended) {
        dispatch(setActiveOrgId({ skipResetState, blended: false }));
      } else {
        dispatch(setActiveOrgId({ skipResetState, blended: true }));
        dispatch(handleOrgSwitch({ customPartIds: customizedParts }));
      }
      dispatch(handleOrgSwitch({ customPartIds: customizedParts }));
    },
    [dispatch]
  );

  const activateBlended = useCallback(() => {
    if (!blended) {
      dispatch(setActiveOrgId({ blended: true }));
      dispatch(handleOrgSwitch({ customPartIds: customizedParts }));
    }
  }, [dispatch, blended]);

  return {
    changeActiveOrg,
    toggleBlended,
    activateBlended,
  };
};
