import {
  DecoratedKitRequestLine,
  HandlingRequestDataLite,
  KitRequestLineDataAllocationLite,
  KitRequestLineDataLineLite,
  ShipmentLineDataLite,
} from 'hooks/useGetDecoratedKitRequestLines';
import { StockLotQuerySchemaLite, StockLotStatus } from 'types/inventory';
import { ChipColor } from 'ui-component/extended/Chip';
import _ from 'lodash';
import { StockLocationSchemaLite } from 'types/clientV2/stockLocations';

export enum KitRequestAllocationStatus {
  Unavailable = 'unavailable',
  Expected = 'expected',
  OnHand = 'on_hand',
  Quarantined = 'quarantined',
  Kitted = 'kitted',
  Shipped = 'shipped',
  Delivered = 'delivered',
}

export const kitRequestAllocationStatusTextMapper: {
  [key in KitRequestAllocationStatus]: string;
} = {
  [KitRequestAllocationStatus.Unavailable]: 'UNAVAILABLE',
  [KitRequestAllocationStatus.Expected]: 'EXPECTED',
  [KitRequestAllocationStatus.OnHand]: 'ON HAND',
  [KitRequestAllocationStatus.Quarantined]: 'QUARANTINED',
  [KitRequestAllocationStatus.Kitted]: 'KITTED',
  [KitRequestAllocationStatus.Shipped]: 'SHIPPED',
  [KitRequestAllocationStatus.Delivered]: 'DELIVERED',
};

export const kitRequestAllocationStatusColorMapper: {
  [key in KitRequestAllocationStatus]: ChipColor;
} = {
  [KitRequestAllocationStatus.Unavailable]: ChipColor.grey,
  [KitRequestAllocationStatus.Expected]: ChipColor.secondary,
  [KitRequestAllocationStatus.OnHand]: ChipColor.success,
  [KitRequestAllocationStatus.Quarantined]: ChipColor.error,
  [KitRequestAllocationStatus.Kitted]: ChipColor.primary,
  [KitRequestAllocationStatus.Shipped]: ChipColor.primary,
  [KitRequestAllocationStatus.Delivered]: ChipColor.success,
};

export type StatusCount = {
  status: KitRequestAllocationStatus;
  quant: number;
};

export type KitRequestLineStats = {
  id: string;
  targetQuantity: number | null;
  minimumQuantity: number | null;
  calculatedStatuses: KitRequestAllocationStatus[];
  statusCounts: StatusCount[];
};

type KitRequestStats = {
  totalLines: number;
  lineStatuses: KitRequestLineStats[];
};

export type CalculateKitRequestStatusReturn = {
  allocationStats: Record<string, KitRequestAllocationStatus>;
  lineStats: Record<string, KitRequestLineStats>;
  requestStats: KitRequestStats;
};

const calculateAllocationStatus = (
  allocation: KitRequestLineDataAllocationLite,
  kitRequestLine: DecoratedKitRequestLine
): KitRequestAllocationStatus => {
  // Case 1: Unavailable - no stock lot assigned
  if (!allocation.stockLot) {
    return KitRequestAllocationStatus.Unavailable;
  }

  const stockLot = allocation.stockLot;

  // Case 2: Expected - stock lot has status of 'expected'
  if (stockLot.status === StockLotStatus.EXPECTED) {
    return KitRequestAllocationStatus.Expected;
  }

  // Case 4: Check for shipment line
  // Shipment has to be shipped at too
  const matchingShipmentLine = kitRequestLine.shipmentLines.find(
    (shipmentLine) => shipmentLine.stockLot === stockLot.id
  );

  // Case 5: Shipped - shipment line exists and is not ingested
  if (matchingShipmentLine && !matchingShipmentLine.ingestedAt) {
    return KitRequestAllocationStatus.Shipped;
  }
  // Case 6: Delivered - shipment line exists and has been ingested
  if (matchingShipmentLine?.ingestedAt) {
    return KitRequestAllocationStatus.Delivered;
  }

  // Case 3: On Hand - stock lot has status of 'on hand'
  if (
    stockLot.status === StockLotStatus.ON_HAND ||
    stockLot.status === StockLotStatus.SMT_NO_PNP
  ) {
    return KitRequestAllocationStatus.OnHand;
  }

  // Case 7: Quarantined - stock lot has status of 'quarantined'
  if (stockLot.status === StockLotStatus.QUARANTINED) {
    return KitRequestAllocationStatus.Quarantined;
  }

  return KitRequestAllocationStatus.Unavailable;
};

export const calculateKitRequestStatus = (
  decoratedLines: DecoratedKitRequestLine[]
): CalculateKitRequestStatusReturn => {
  // Calculate allocation stats
  const allocationStats: Record<string, KitRequestAllocationStatus> = {};

  // Calculate line stats
  const lineStats: Record<string, KitRequestLineStats> = {};

  decoratedLines.forEach((line) => {
    const lineAllocations = line.allocations.map((allocation) => {
      const status = calculateAllocationStatus(allocation, line);
      allocationStats[allocation.id] = status;
      return {
        status,
        quantity: allocation.quant,
      };
    });

    const shippedLines = _.filter(
      line.shipmentLines,
      (shipmentLine) => !!shipmentLine.shipment.shippedAt
    );

    const calculatedStatuses = lineAllocations.map(
      (allocation) => allocation.status
    );

    // Ensure 'Shipped' status is included if there are shipped lines
    if (
      shippedLines.length > 0 &&
      !calculatedStatuses.includes(KitRequestAllocationStatus.Shipped)
    ) {
      calculatedStatuses.push(KitRequestAllocationStatus.Shipped);
    }

    lineStats[line.id] = {
      id: line.id,
      targetQuantity: line.targetQuantity,
      minimumQuantity: line.minimumQuantity,
      calculatedStatuses,
      statusCounts: [
        ..._.chain(lineAllocations)
          .map('status')
          .uniq()
          .map((s) => ({
            status: s,
            quant: _.chain(lineAllocations)
              .filter({ status: s })
              .sumBy('quantity')
              .value(),
          }))
          .filter((s) => s.quant > 0)
          .value(),
        ...(shippedLines.length > 0
          ? [
              {
                status: KitRequestAllocationStatus.Shipped,
                quant: _.sumBy(shippedLines, 'shippedQuant'),
              },
            ]
          : []),
      ],
    };
  });

  const requestStats: KitRequestStats = {
    totalLines: decoratedLines.length,
    lineStatuses: Object.values(lineStats),
  };

  return {
    allocationStats,
    lineStats,
    requestStats,
  };
};

export const createDecoratedKitRequestLines = ({
  kitRequestLines,
  allocations,
  shipmentLines,
  handlingRequests,
  stockLots,
}: {
  kitRequestLines?: KitRequestLineDataLineLite[];
  allocations?: KitRequestLineDataAllocationLite[];
  shipmentLines?: ShipmentLineDataLite[];
  handlingRequests?: HandlingRequestDataLite[];
  stockLots?: StockLotQuerySchemaLite[];
}) =>
  kitRequestLines
    ? (kitRequestLines.map((line) => ({
        ...line,
        allocations:
          allocations?.filter(
            (allocation) => allocation.kitRequestLine === line.id
          ) ?? [],
        shipmentLines:
          shipmentLines?.filter(
            (shipmentLine) => shipmentLine.kitRequestLine === line.id
          ) ?? [],
        handlingRequests:
          handlingRequests?.filter(
            (handlingRequest) => handlingRequest.kitRequestLine === line.id
          ) ?? [],
        stockLots:
          stockLots?.filter((stockLot) =>
            _.chain(allocations ?? [])
              .map('stockLot.id')
              .includes(stockLot.id)
              .value()
          ) ?? [],
      })) as DecoratedKitRequestLine[])
    : undefined;

export const combineDecoratedKitRequestLinesWithStatuses = ({
  decoratedKitRequestLines,
  kitRequestStatuses,
}: {
  decoratedKitRequestLines?: DecoratedKitRequestLine[];
  kitRequestStatuses?: CalculateKitRequestStatusReturn;
}) => {
  if (!decoratedKitRequestLines || !kitRequestStatuses)
    return decoratedKitRequestLines;

  return decoratedKitRequestLines.map((line) => ({
    ...line,
    stats: kitRequestStatuses.lineStats[line.id],
    allocations: line.allocations.map((allocation) => ({
      ...allocation,
      calculatedStatus: kitRequestStatuses.allocationStats[allocation.id],
    })),
  }));
};

export type EstimatedHandlingRequestData = {
  numberOfReels: number;
  numberOfShipmentLines: number;
  numberOfMerges: number;
  numberOfSplits: number;
};

// Estimate the number of handling requests to be generated
export const estimateHandlingRequestData = ({
  decoratedKitRequestLines,
  shipToLocation,
}: {
  decoratedKitRequestLines?: DecoratedKitRequestLine[];
  shipToLocation?: StockLocationSchemaLite;
}): EstimatedHandlingRequestData => {
  // Calculate number of reels based on kit request line count if location requires reels
  const numberOfReels = shipToLocation?.requiresReels
    ? decoratedKitRequestLines?.length ?? 0
    : 0;

  const numberOfShipmentLines = decoratedKitRequestLines?.length ?? 0;

  // Count merges for lines with multiple allocations if merges are required
  const numberOfMerges = shipToLocation?.requiresMerges
    ? decoratedKitRequestLines?.filter((line) => line.allocations.length > 1)
        .length
    : 0;

  // Count splits by checking if any allocation quantity is less than its stock lot quantity
  const numberOfSplits = shipToLocation?.requiresSplits
    ? decoratedKitRequestLines?.filter((line) =>
        line.allocations.some(
          (allocation) => allocation.quant < allocation.stockLot.quant
        )
      ).length
    : 0;

  const estimatedHandlingRequestData = {
    numberOfReels,
    numberOfShipmentLines,
    numberOfMerges: numberOfMerges ?? 0,
    numberOfSplits: numberOfSplits ?? 0,
  };

  return estimatedHandlingRequestData;
};
