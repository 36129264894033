import _ from 'lodash';
import { statusColorCallback } from 'ui-component/DataGrid/GridColTypeDef';
import {
  IconCircleCheck,
  IconHelpCircle,
  IconInfoCircle,
} from '@tabler/icons-react';
import Chip from 'ui-component/extended/Chip';
import { useTheme } from '@mui/material/styles';

export function RenderPartAlt(value: string) {
  const theme = useTheme();
  return (
    <Chip
      title={
        _.get(
          {
            'Primary Part': 'This is the primary part for this request',
            'Not Approved':
              'This part is not approved and will be ignored in purchasing, kitting, and other operations within the Cofactr platform',
            Approved:
              'This part is approved and will be used in purchasing, kitting, and other operations within the Cofactr platform',
            'Used in BOM':
              'This is a suggestion because this part is used in BOMs in your account. It is not approved and will be ignored in purchasing, kitting, and other operations within the Cofactr platform.',
            'From Supplier':
              'This is a suggestion from a distributor or manufacturer. It is not approved and will be ignored in purchasing, kitting, and other operations within the Cofactr platform.',
            'MPN Search':
              'This is a suggestion because this part has a similar MPN to the primary part. It is not approved and will be ignored in purchasing, kitting, and other operations within the Cofactr platform.',
            'Parametric Search':
              'This is a suggestion because this part has similar specs to the primary part. It is not approved and will be ignored in purchasing, kitting, and other operations within the Cofactr platform.',
            Community:
              'This is a suggestion because other Cofactr users have used this part as an alternative. It is not approved and will be ignored in purchasing, kitting, and other operations within the Cofactr platform.',
            'Your Search': 'This is a result from your search.',
            AltAI:
              'This suggestion was automatically generated by Cofactr AltAI based on the specifications of the part. It is not approved and will be ignored in purchasing, kitting, and other operations within the Cofactr platform.',
            'Same Custom PN':
              'This part has the same custom part number as the primary part. It is not approved and will be ignored in purchasing, kitting, and other operations within the Cofactr platform.',
          },
          value
        ) || ''
      }
      label={value}
      chipcolor={statusColorCallback(value)}
      icon={
        _.includes(['Primary Part', 'Approved'], value) ? (
          <IconCircleCheck color={theme.palette.success.main} />
        ) : value === 'Not Approved' ? (
          <IconHelpCircle color={theme.palette.secondary.main} />
        ) : value === 'Your Search' ? (
          <IconHelpCircle color={theme.palette.warning.dark} />
        ) : (
          <IconInfoCircle
            color={
              ['AltAI', 'Same Custom PN'].includes(value)
                ? theme.palette.secondary.main
                : theme.palette.warning.dark
            }
          />
        )
      }
    />
  );
}
