import { AvatarGroup, Box, Stack, Tooltip, useTheme } from '@mui/material';
import UserAvatar from 'ui-component/extended/UserAvatar';
import { User, UserLite } from 'types/user';
import { FC } from 'react';
import _ from 'lodash';
import { OrgUserFull } from 'types/org';
import UserInfoSection from 'ui-component/UserInfoSection';
import { useIsCofactr } from 'hooks/useIsCofactr';

interface FacepileInfoSectionProps {
  users: (User | UserLite | OrgUserFull)[] | null | undefined;
  size?: 'sm' | 'md';
}

const FacepileInfoSection: FC<FacepileInfoSectionProps> = ({ users, size }) => {
  const theme = useTheme();
  const { isCofactr } = useIsCofactr();

  const usersValue = _.chain(users || [])
    .compact()
    .uniqBy('id')
    .map((user) => ({
      user,
      obscureCofactrUser: user.isStaff && !isCofactr,
    }))
    .value();

  return (
    <Stack
      direction="row"
      sx={{
        justifyContent: 'flex-start',
        alignItems: 'center',
      }}
    >
      <AvatarGroup max={8} spacing="small">
        {usersValue.map((user) => (
          <Tooltip
            title={<UserInfoSection user={user.user} automation showEmail />}
            key={user.user.id}
            componentsProps={{
              tooltip: {
                sx: {
                  bgcolor: 'white',
                  border: '1px solid',
                  borderColor: theme.palette.grey[300],
                  borderRadius: '8px',
                },
              },
            }}
          >
            <Box>
              <UserAvatar
                user={user.user}
                sx={{
                  ...theme.typography.mediumAvatar,
                }}
                size="md"
                obscureCofactrUser={user.obscureCofactrUser}
                automation
              />
            </Box>
          </Tooltip>
        ))}
      </AvatarGroup>
    </Stack>
  );
};

export default FacepileInfoSection;
