import { createContext, ReactNode, useContext } from 'react';
import Pusher from 'pusher-js';
import * as PusherTypes from 'pusher-js';

type PusherContextType = {
  pusherChannel: PusherTypes.Channel;
  jobsChannel: PusherTypes.Channel;
};

// @ts-ignore -- Stub out channels in local env w/o Pusher key
const channelStub = {
  bind: () => {},
  bind_global: () => {},
  unbind: () => {},
  unbind_global: () => {},
  unbind_all: () => {},
} as PusherTypes.Channel;

export const PusherContext = createContext({} as PusherContextType);

export const usePusherContext = () => useContext(PusherContext);

const PusherProvider = ({ children }: { children: ReactNode }) => {
  const pusher = process.env.PUSHER_APP_KEY
    ? new Pusher(process.env.PUSHER_APP_KEY || '', {
        cluster: process.env.PUSHER_APP_CLUSTER || '',
      })
    : null;
  const pusherChannel = pusher?.subscribe('parts') ?? channelStub;
  const jobsChannel = pusher?.subscribe('jobs') ?? channelStub;

  return (
    <PusherContext.Provider value={{ pusherChannel, jobsChannel }}>
      {children}
    </PusherContext.Provider>
  );
};

export default PusherProvider;
