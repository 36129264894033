import { pluralizeNoun } from 'utils/functions';
import { IconInfoCircle } from '@tabler/icons-react';
import { Alert, AlertTitle } from '@mui/material';
import { gridSpacing } from 'constants/themeConstants';

export const AltPartsAlert = ({
  approvedPartsCount,
}: {
  approvedPartsCount: number;
}) => (
  <Alert
    variant="outlined"
    severity="info"
    icon={<IconInfoCircle />}
    sx={{ mb: gridSpacing }}
  >
    <AlertTitle>Managing Alts</AlertTitle>
    {`This line has ${approvedPartsCount.toLocaleString()} approved alternative ${pluralizeNoun(
      'part',
      approvedPartsCount
    )}. These parts will be used in purchasing, kitting, and other operations within the Cofactr platform and treated as interchangeable with the primary part. Other parts that are Not Approved or are suggested alternatives are shown but will be ignored in purchasing, kitting, and other operations within the Cofactr platform.`}
  </Alert>
);
