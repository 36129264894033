import {
  MutableRefObject,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  DataGridPremium,
  DataGridPremiumProps,
  GRID_CHECKBOX_SELECTION_COL_DEF,
  GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
  GridActionsColDef,
  GridFilterInitialState,
  GridRowId,
  GridRowParams,
  GridSelectionModel,
  GridSortingInitialState,
  useGridApiRef,
  ValueOptions,
} from '@mui/x-data-grid-premium';
import { defaultPageSize, defaultPageSizeOptions } from 'constants/datagrid';
import useSavedDatagridState from 'hooks/useSavedDatagridState';
import { Box, LinearProgress } from '@mui/material';
import _ from 'lodash';
import {
  Icon as TablerIcon,
  IconColumns,
  IconDownload,
  IconFilter,
  IconMinus,
  IconPlus,
} from '@tabler/icons-react';
import {
  DatagridNames,
  ExtendedGridColDef,
  RowAction,
  DatagridRecordButtonProps,
  VisibleColumnGroup,
} from 'types/datagrid';
import { appPermissionStates } from 'types/apps';
import { getApplyFilterFnCaseInsensitive } from 'ui-component/DataGrid/FilterOperators';
import { useAppAccessContext } from 'contexts/AppAccessContext';
import VerboseLoader, {
  VerboseLoaderProps,
} from 'ui-component/extended/VerboseLoader';
import { Theme, useTheme } from '@mui/material/styles';
import { GenerateReportDataType } from 'types/generateReports';
import { COLOR_OPTIONS } from 'types';
import { SxProps } from '@mui/system';
import { GridApiPremium } from '@mui/x-data-grid-premium/models/gridApiPremium';
import { FieldValues } from 'react-hook-form';
import { renderMutationCellWithInput } from 'ui-component/DataGrid/Render';
import renderCellWithEditWrapper from 'ui-component/DataGrid/Render/RenderCellWithEditWrapper';
import { useDeviceContext } from 'hooks/useDeviceContext';
import ExtendedGridActionsCellItem from 'ui-component/DataGrid/ExtendedGridActionsCellItem';
import { gridClasses } from '@mui/x-data-grid';
import DataGridChartsContainer from 'ui-component/DataGrid/DataGridChartsContainer';
import NoRowsOverlayComponent from 'ui-component/DataGrid/NoRowsOverlayComponent';
import ExtendedGridToolbar from 'ui-component/DataGrid/ExtendedGridToolbar';

type DynamicFilter = {
  field: string;
  options: ValueOptions[];
};

export interface ExtendedDatagridProps<T> extends DataGridPremiumProps {
  children?: ReactNode;
  editable?: boolean;
  editLocked?: boolean;
  toolbarExport?: boolean;
  toolbarColumns?: boolean;
  toolbarFilters?: boolean;
  exportFileNamePrefix?: string | null;
  noRows?: string | ReactNode;
  initialSorting?: GridSortingInitialState | null;
  initialFilter?: GridFilterInitialState | null;
  initialVisibleColumns?: string[] | null;
  visibleColumnGroups?: VisibleColumnGroup[] | null;
  initialPinned?: string[] | null;
  pinnedActions?: boolean;
  gridName: DatagridNames;
  detailPanel?: boolean;
  detailExclusiveOpen?: boolean;
  hideToolbar?: boolean;
  noRowHeightAuto?: boolean;
  initialSx?: SxProps<Theme>;
  dynamicFilters?: DynamicFilter[];
  onPageSizeChange?: (pageSize: number) => void;
  columns: ExtendedGridColDef[];
  exportCsv?: () => void;
  isExportLoading?: boolean;
  apiRefServerSide?: MutableRefObject<GridApiPremium>;
  verboseLoader?: VerboseLoaderProps;
  generateReportData?: GenerateReportDataType;
  pageSizeOptions?: number[];
  showCharts?: boolean;
  loadingCharts?: boolean;
  overridePageSize?: number;
  shouldSaveDatagridState?: boolean;
  newRecordButton?: DatagridRecordButtonProps;
  uploadRecordButton?: DatagridRecordButtonProps;
  rowActions?: RowAction<T>[];
  fullCard?: boolean;
}

const defaultInitialSx = {};
const defaultOnPageSizeChange = () => {};

const ExtendedDatagrid = <T,>({
  children,
  loading = false,
  pagination = true,
  editable = false,
  editLocked = false,
  toolbarExport = true,
  toolbarColumns = true,
  toolbarFilters = true,
  exportFileNamePrefix = null,
  noRows = 'No records found',
  initialSorting = null,
  initialFilter = null,
  initialVisibleColumns = null,
  visibleColumnGroups = null,
  initialPinned = null,
  pinnedActions = true,
  gridName,
  detailPanel = false,
  detailExclusiveOpen = false,
  getDetailPanelContent,
  onRowClick,
  checkboxSelection = false,
  columns,
  rows,
  hideToolbar = false,
  noRowHeightAuto = false,
  initialSx = defaultInitialSx,
  dynamicFilters,
  onPageSizeChange = defaultOnPageSizeChange,
  exportCsv,
  isExportLoading,
  apiRefServerSide,
  verboseLoader,
  generateReportData,
  pageSizeOptions = defaultPageSizeOptions,
  showCharts = false,
  loadingCharts = false,
  overridePageSize,
  shouldSaveDatagridState = true,
  newRecordButton = {
    label: 'Add',
    disabled: false,
    onClick: undefined,
  },
  uploadRecordButton = {
    label: 'Upload',
    disabled: false,
    onClick: undefined,
  },
  aggregationModel,
  rowActions = [],
  onSelectionModelChange,
  fullCard = false,
  ...others
}: ExtendedDatagridProps<T>) => {
  const theme = useTheme();
  const { narrowWindow } = useDeviceContext();

  const {
    appPermissions,
    appsWithPermissions,
    isLoading: isLoadingAppAccess,
    isPermissionDisabled,
  } = useAppAccessContext();
  const apiRefClientSide = useGridApiRef();
  const apiRef = apiRefServerSide || apiRefClientSide;

  const [apiRefReady, setApiRefReady] = useState(false);

  useEffect(() => {
    if (apiRef?.current && !apiRefReady) {
      setApiRefReady(true);
    }
  }, [apiRef, apiRefReady]);

  const { savedDatagridState } = useSavedDatagridState({
    gridName,
    apiRef,
    shouldSaveDatagridState,
  });
  const [pageSize, setPageSize] = useState<number>(
    overridePageSize ??
      savedDatagridState?.pagination?.pageSize ??
      defaultPageSize
  );

  useEffect(() => onPageSizeChange(pageSize), []);

  // handle page size change
  const handlePageSizeChangeCallback = useCallback(
    (newPageSize: number) => {
      setPageSize(newPageSize);
      onPageSizeChange(newPageSize);
    },
    [onPageSizeChange]
  );

  const [detailPanelExpandedRowIds, setDetailPanelExpandedRowIds] = useState<
    GridRowId[]
  >([]);
  const handleDetailPanelExpandedRowIdsChange = useCallback(
    (ids: GridRowId[]) => {
      setDetailPanelExpandedRowIds(ids);
    },
    []
  );

  // handle checkbox selection
  const calculatedCheckboxSelection =
    checkboxSelection ||
    _.filter(rowActions, (action) => !!action.onBatchClick).length > 0;

  const [selectedRowIds, setSelectedRowIds] = useState<GridSelectionModel>([]);
  const selectedRowData = useMemo(
    () => rows.filter((row) => selectedRowIds.includes(row?.id?.toString())),
    [rows, selectedRowIds]
  );
  const handleSelect = (selectionModel: GridSelectionModel) => {
    setSelectedRowIds(selectionModel);
  };

  // generate action getter based on rowActions prop
  const actionGetter = useCallback(
    ({ row }: GridRowParams) =>
      _.chain(rowActions)
        .filter((action) => !!action.onRowClick && !action.hideRowAction?.(row))
        .map((action) => {
          const ActionIcon: TablerIcon = action.getRowIcon
            ? action.getRowIcon(row)
            : action.Icon;
          return (
            <ExtendedGridActionsCellItem
              onClick={() => {
                if (action.onRowClick) {
                  action.onRowClick(row);
                }
              }}
              color={action?.color ?? action.getColor?.(row)}
              icon={
                <ActionIcon
                  color={
                    action.disabled || action.isRowDisabled?.(row)
                      ? theme.palette.grey[500]
                      : _.get(theme.palette, [
                          action.color ||
                            action.getColor?.(row) ||
                            COLOR_OPTIONS.primary,
                          'main',
                        ])
                  }
                />
              }
              label={action.getLabel?.(row)}
              disabled={action.disabled || action.isRowDisabled?.(row)}
              showInMenu={action.showInRowMenu}
              permissionScope={action.permissionScope}
              tooltip={action.getRowTooltip?.(row)}
            />
          );
        })
        .value(),
    [rowActions]
  );

  // construct sx based on props
  const hasPartColumn = useMemo(
    () =>
      _.chain(columns)
        .map('field')
        .some((c) => _.includes(c, 'part'))
        .value(),
    [columns.length]
  );
  const sx: { [key: string]: object } = useMemo(() => {
    const sxObject: { [key: string]: object } = {
      '& ::-webkit-scrollbar': {
        width: '6px',
        height: '6px',
      },
      '& ::-webkit-scrollbar-track': {
        backgroundColor: '#f5f5f5',
      },
      '& ::-webkit-scrollbar-thumb': {
        borderRadius: '10px',
        backgroundColor: '#c4c4c4',
      },
      '.MuiDataGrid-cell--withRenderer': {
        overflow: 'hidden',
        width: '100%',
      },
      [`.${gridClasses.cell}--editing:last-child`]: {
        display: 'flex',
        flexDirection: 'row-reverse',
      },
      '.MuiDataGrid-detailPanel': {
        backgroundColor: theme.palette.grey[100],
      },
      '.MuiDataGrid-row--detailPanelExpanded': {
        backgroundColor: theme.palette.grey[100],
      },
      '& .MuiDataGrid-pinnedRows': {
        boxShadow: 'none',
      },
      '& .MuiDataGrid-cell': {
        px: '3px',
      },
      '& .MuiDataGrid-footerContainer': {
        justifyContent: 'start',
      },
      ...(fullCard && {
        '& .MuiDataGrid-columnHeader:first-of-type, & .MuiDataGrid-cell:first-of-type':
          {
            paddingLeft: 2,
          },
      }),
    };

    if (!hasPartColumn) {
      sxObject['&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell'] = {
        py: '8px',
      };
      sxObject['&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell'] = {
        py: '15px',
      };
      sxObject['&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell'] = {
        py: '22px',
      };
    }
    if (onRowClick || editable) {
      sxObject['&:hover'] = {
        '.MuiDataGrid-row': {
          cursor: 'pointer',
        },
      };
    } else {
      sxObject['.MuiDataGrid-row.Mui-hovered'] = {
        background: 'white',
      };
      sxObject['& .MuiDataGrid-row:hover'] = {
        background: 'white',
      };
    }
    return sxObject;
  }, [hasPartColumn, !!onRowClick, editable, fullCard]);

  // construct visible column groups
  const memoizedVisibleGroups = useMemo(() => {
    if (!visibleColumnGroups) {
      return null;
    }
    const groups = visibleColumnGroups;
    // add action columns to visibleColumnGroups
    groups.forEach((g) => {
      g.visibleColumns.push('actions');
    });

    // filter visibleColumnGroups based on appPermissions
    return groups.filter(
      (g) =>
        !g?.permissionScope?.app ||
        _.get(
          appPermissions,
          [g?.permissionScope.app, 'state'],
          appPermissionStates.disabled
        ) === appPermissionStates.enabled
    );
  }, [appPermissions]);

  // construct memoized columnVisibilityModel
  const memoizedColumnVisibilityModel: { [key: string]: boolean } =
    useMemo(() => {
      const columnVisibilityModel: { [key: string]: boolean } = {};

      if (initialVisibleColumns) {
        initialVisibleColumns.push('actions');
        _.map(columns, 'field').forEach((col) => {
          columnVisibilityModel[col] = initialVisibleColumns.includes(col);
        });
      }

      return columnVisibilityModel;
    }, [initialVisibleColumns]);

  // construct combinedInitialState
  const combinedInitialState = useMemo(() => {
    const initialStateObject: { [key: string]: object } = {};
    if (initialSorting) {
      initialStateObject.sorting = initialSorting;
    }
    if (initialFilter) {
      initialStateObject.filter = initialFilter;
    }
    if (pinnedActions && !initialPinned) {
      initialStateObject.pinnedColumns = {
        right: ['actions'],
      };
    } else if (!pinnedActions && initialPinned) {
      initialStateObject.pinnedColumns = {
        left: initialPinned,
      };
    } else if (pinnedActions && initialPinned) {
      initialStateObject.pinnedColumns = {
        right: ['actions'],
        left: initialPinned,
      };
    }
    if (memoizedColumnVisibilityModel) {
      initialStateObject.columns = {
        columnVisibilityModel: memoizedColumnVisibilityModel,
        all: _.map(columns, 'field'),
      };
    } else {
      initialStateObject.columns = { all: _.map(columns, 'field') };
    }
    return initialStateObject;
  }, [
    initialSorting,
    initialFilter,
    pinnedActions,
    initialPinned,
    memoizedColumnVisibilityModel,
  ]);

  // propagate new columns to saved datagrid state that predates those columns being added to code
  const newSavedColumnVisibilityModel: { [key: string]: boolean } =
    useMemo(() => {
      const savedColumnVisibilityModel =
        savedDatagridState?.columns?.columnVisibilityModel ||
        memoizedColumnVisibilityModel;
      const newSavedColumnVisibilityModelObject: { [key: string]: boolean } =
        {};
      _.forOwn(memoizedColumnVisibilityModel, (value, key) => {
        newSavedColumnVisibilityModelObject[key] = _.get(
          savedColumnVisibilityModel,
          key,
          value
        );
      });
      return newSavedColumnVisibilityModelObject;
    }, [
      memoizedColumnVisibilityModel,
      savedDatagridState?.columns?.columnVisibilityModel,
    ]);

  const getUpdatedSavedDatagridState = useCallback(() => {
    if (savedDatagridState) {
      const updatedSavedDatagridState = structuredClone(savedDatagridState);
      _.set(
        updatedSavedDatagridState,
        ['columns', 'columnVisibilityModel'],
        newSavedColumnVisibilityModel
      );
      return updatedSavedDatagridState;
    }
    return combinedInitialState;
  }, [savedDatagridState]);

  // construct detailPanel attributes based on props
  const detailPanelAttributes: { [key: string]: unknown } = useMemo(() => {
    const getDetailPanelHeight = () => 'auto';
    const getDetailPanelContentCallback = (params: GridRowParams) =>
      getDetailPanelContent && getDetailPanelContent(params);
    const detailPanelAttributesObject: { [key: string]: unknown } = {};
    if (detailPanel) {
      detailPanelAttributesObject.getDetailPanelHeight = getDetailPanelHeight;
      detailPanelAttributesObject.getDetailPanelContent = (
        params: GridRowParams
      ) => getDetailPanelContentCallback(params);
      detailPanelAttributesObject.onDetailPanelExpandedRowIdsChange =
        handleDetailPanelExpandedRowIdsChange;
    }
    if (detailExclusiveOpen) {
      detailPanelAttributesObject.detailPanelExpandedRowIds =
        detailPanelExpandedRowIds;
    }
    return detailPanelAttributesObject;
  }, [
    detailPanel,
    detailExclusiveOpen,
    getDetailPanelContent,
    detailPanelExpandedRowIds.length,
    handleDetailPanelExpandedRowIdsChange,
  ]);

  // inject saved datagrid state for flex and editLocked state into columns
  const columnsWithSavedState = useMemo(() => {
    let columnsWithSavedStateObject = _.chain(columns)
      .cloneDeep()
      .filter((v) => !!v)
      .value();
    columnsWithSavedStateObject.forEach((c) => {
      if ('flex' in c) {
        c.flex = _.get(
          savedDatagridState,
          ['columns', 'dimensions', c.field, 'flex'],
          c.flex
        );
      }
      if ('editable' in c && !_.get(c, 'allowEditWhileLocked', false)) {
        if (editLocked) {
          c.editable = false;
        }
        if (isPermissionDisabled(c?.editPermissionScope)) {
          c.editable = false;
        }
      }
    });

    // add detail panel toggle column
    if (detailPanel) {
      columnsWithSavedStateObject = [
        {
          ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
          type: 'string',
          hideable: false,
          headerName: 'Expand Toggle',
        },
        ...columnsWithSavedStateObject,
      ];
    }

    // add checkbox selection column
    if (calculatedCheckboxSelection) {
      columnsWithSavedStateObject = [
        {
          ...GRID_CHECKBOX_SELECTION_COL_DEF,
          type: 'string',
          hideable: false,
          headerName: 'Checkbox',
        },
        ...columnsWithSavedStateObject,
      ];
    }

    // inject case insensitive quick filter
    columnsWithSavedStateObject.forEach((c) => {
      if (!('getApplyQuickFilterFn' in c)) {
        c.getApplyQuickFilterFn = getApplyFilterFnCaseInsensitive;
      }
    });

    // add actions column if there are row actions
    if (_.filter(rowActions, (action) => !!action.onRowClick).length > 0) {
      columnsWithSavedStateObject = [
        ...columnsWithSavedStateObject,
        {
          field: 'actions',
          headerName: 'Actions',
          type: 'actions',
          hideable: false,
          minWidth:
            34 *
              (_.filter(rowActions, (a) => !!a.onRowClick && !a.showInRowMenu)
                .length +
                (_.some(rowActions, (a) => a.showInRowMenu) ? 1 : 0)) +
            20, // takes into account padding included in column width
          getActions: actionGetter,
        },
      ] as (ExtendedGridColDef | GridActionsColDef)[];
    }

    return columnsWithSavedStateObject;
  }, [
    columns,
    savedDatagridState,
    editLocked,
    detailPanel,
    calculatedCheckboxSelection,
    actionGetter,
    rowActions,
  ]);

  // handle state resetting
  const handleResetState = useCallback(() => {
    setPageSize(defaultPageSize);
    const stateToRestore = structuredClone(combinedInitialState);
    _.set(stateToRestore, 'columns.orderedFields', [
      calculatedCheckboxSelection && '__check__',
      detailPanel && '__detail_panel_toggle__',
      ..._.map(columns, 'field'),
    ]);
    // if no initialFilter set, default to no filters
    !Object.keys(stateToRestore).includes('filter') &&
      _.set(stateToRestore, 'filter', { filterModel: { items: [] } });
    apiRef?.current.restoreState(stateToRestore);
  }, [
    defaultPageSize,
    combinedInitialState,
    columns,
    calculatedCheckboxSelection,
    detailPanel,
  ]);

  const handleSetColumnGroup = useCallback(
    (name: string) => {
      const matchingColumnGroup = _.find(memoizedVisibleGroups || [], {
        name,
      });
      if (matchingColumnGroup) {
        const groupState: { [key: string]: object } = {};
        const groupColumnVisibilityModel: { [key: string]: boolean } = {};

        _.map(columns, 'field').forEach((col) => {
          groupColumnVisibilityModel[col] =
            matchingColumnGroup.visibleColumns.includes(col);
        });
        groupState.columns = {
          columnVisibilityModel: groupColumnVisibilityModel,
          all: _.map(columns, 'field'),
          orderedFields: [
            calculatedCheckboxSelection && '__check__',
            detailPanel && '__detail_panel_toggle__',
            ..._.map(columns, 'field'),
          ],
        };
        apiRef?.current.restoreState(groupState);
      }
    },
    [memoizedVisibleGroups, columns, calculatedCheckboxSelection, detailPanel]
  );

  // calculate fields to export
  const exportFields = useCallback(() => {
    const sortedVisibleFields = _.filter(
      apiRef?.current?.state?.columns?.all,
      (c) =>
        _.get(apiRef?.current?.state?.columns?.columnVisibilityModel, c) &&
        c !== 'actions'
    );
    const fieldsToExport: string[] = [];
    sortedVisibleFields.forEach((f) => {
      if (
        _.includes(f, 'part') &&
        _.includes(apiRef?.current?.state?.columns?.all, 'manufacturer') &&
        !_.includes(sortedVisibleFields, 'manufacturer')
      ) {
        fieldsToExport.push(`manufacturer`);
      }
      if (
        _.includes(f, 'part') &&
        _.includes(apiRef?.current?.state?.columns?.all, 'description') &&
        !_.includes(sortedVisibleFields, 'description')
      ) {
        fieldsToExport.push('description');
      }
      fieldsToExport.push(f);
    });
    return fieldsToExport;
  }, [apiRef?.current?.state?.columns?.all]);

  // filter columns by app access && allowInBlended
  const memoizedColumns = useMemo(() => {
    const filteredColumns = columnsWithSavedState.filter((c) => {
      const columnAppPermissions = c.permissionScope?.app
        ? appsWithPermissions[c.permissionScope?.app]
        : undefined;
      const allowColumnInBlended = Boolean(
        !!c.allowInBlended &&
          columnAppPermissions?.permissions?.state ===
            appPermissionStates.noBlended
      );
      return !isPermissionDisabled(c?.permissionScope) || allowColumnInBlended;
    });

    const columnsWithEditableParams = filteredColumns.map((column) => {
      if (column.editable && column.inlineEditRenderCell) {
        const getMutationArgs = column.getMutationArgs;
        const mutationPreSubmit = column.mutationPreSubmit;
        const originalRenderCell = column.renderCell;
        const getIsDisabled = column?.mutationGetIsDisabled;
        const getDefaultValue = column?.getMutationDefaultValue;
        column.renderEditCell = (params) =>
          renderMutationCellWithInput({
            params,
            apiRef,
            InputElement: column.mutationInputElement ?? undefined,
            validation: column?.mutationValidation,
            permissionScope: column?.editPermissionScope,
            useMutation: column.useMutation,
            getMutationArgs: getMutationArgs
              ? (data: FieldValues) => getMutationArgs(data, params)
              : undefined,
            preSubmit: mutationPreSubmit,
            defaultValue: getDefaultValue ? getDefaultValue(params) : undefined,
            additionalInputElementProps:
              column?.additionalInputElementProps ?? undefined,
            clientV2: column?.mutationAsClientV2 ?? false,
          });
        column.renderCell = (params) =>
          renderCellWithEditWrapper({
            params,
            apiRef,
            renderMethod: originalRenderCell
              ? () => originalRenderCell(params)
              : null,
            permissionScope: column?.editPermissionScope,
            disabled: editLocked,
            getIsDisabled,
          });
      }
      return column;
    });

    if (dynamicFilters) {
      dynamicFilters.forEach((filter) => {
        const columnIndex = _.findIndex(columnsWithEditableParams, [
          'field',
          filter.field,
        ]);

        columnsWithEditableParams[columnIndex] = {
          ...columnsWithEditableParams[columnIndex],
          valueOptions: filter.options,
        };
      });
    }

    return columnsWithEditableParams;
  }, [dynamicFilters, columns]);

  const [datagridColumnVisibilityModel, setDatagridColumnVisibilityModel] =
    useState(newSavedColumnVisibilityModel);

  useEffect(() => {
    if (
      !_.isEqual(datagridColumnVisibilityModel, newSavedColumnVisibilityModel)
    ) {
      setDatagridColumnVisibilityModel(newSavedColumnVisibilityModel);
    }
  }, [columns, newSavedColumnVisibilityModel]);

  return (
    <Box
      sx={{
        width: '100%',
        position: 'relative',
        height: fullCard ? '100%' : undefined,
      }}
    >
      {loading && verboseLoader && <VerboseLoader {...verboseLoader} />}
      {showCharts && (
        <DataGridChartsContainer
          apiRef={apiRef}
          datagridColumnVisibilityModel={datagridColumnVisibilityModel}
          loading={loading}
          loadingCharts={loadingCharts}
          memoizedColumns={memoizedColumns}
          rows={rows}
        />
      )}
      <DataGridPremium
        {...others}
        localeText={{
          toolbarColumns: narrowWindow ? '' : 'Columns',
          toolbarFilters: narrowWindow ? '' : 'Filters',
          toolbarExport: narrowWindow ? '' : 'Export',
        }}
        disableRowGrouping
        experimentalFeatures={{
          ...(editable ? { newEditingApi: true } : {}),
          ...(aggregationModel ? { aggregation: true } : {}),
        }}
        columns={memoizedColumns}
        rows={rows}
        throttleRowsMs={100}
        getRowHeight={noRowHeightAuto ? undefined : () => 'auto'}
        autoHeight={!fullCard}
        loading={(loading && !verboseLoader) || isLoadingAppAccess}
        pagination={pagination}
        apiRef={apiRef}
        pageSize={pageSize}
        onPageSizeChange={handlePageSizeChangeCallback}
        rowsPerPageOptions={pageSizeOptions}
        onRowClick={onRowClick}
        sx={{
          ...sx,
          ...initialSx,
        }}
        disableSelectionOnClick
        checkboxSelection={calculatedCheckboxSelection}
        disableColumnReorder={false}
        components={{
          DetailPanelExpandIcon: IconPlus,
          DetailPanelCollapseIcon: IconMinus,
          ExportIcon: IconDownload,
          OpenFilterButtonIcon: IconFilter,
          ColumnSelectorIcon: IconColumns,
          LoadingOverlay: LinearProgress,
          NoRowsOverlay: NoRowsOverlayComponent,
          Toolbar: ExtendedGridToolbar,
        }}
        componentsProps={{
          noRowsOverlay: { noRows },
          toolbar: {
            apiRef,
            exportCsv,
            fullCard,
            columns,
            handleResetState,
            handleSetColumnGroup,
            hideToolbar,
            isExportLoading,
            memoizedVisibleGroups,
            toolbarColumns,
            toolbarExport,
            toolbarFilters,
            children,
            exportFields,
            exportFileNamePrefix,
            gridName,
            generateReportData,
            newRecordButton,
            uploadRecordButton,
            rowActions,
            selectedRowData,
            selectedRowIds,
          },
        }}
        initialState={getUpdatedSavedDatagridState()}
        columnVisibilityModel={datagridColumnVisibilityModel}
        onColumnVisibilityModelChange={(newModel) =>
          setDatagridColumnVisibilityModel(newModel)
        }
        aggregationModel={aggregationModel}
        onSelectionModelChange={onSelectionModelChange || handleSelect}
        {...detailPanelAttributes}
      />
    </Box>
  );
};

export default ExtendedDatagrid;
