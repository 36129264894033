import { FieldValues, useForm } from 'react-hook-form';
import { useState } from 'react';
import { ExtendedDialog } from 'ui-component/extended/ExtendedDialog';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import * as yup from 'yup';
import _ from 'lodash';
import { HookFormInput, HookFormSelect } from 'ui-component/HookFormComponents';

import { Box, MenuItem } from '@mui/material';
import ExtendedTabs from 'ui-component/extended/ExtendedTabs';
import { IconBandage, IconBug } from '@tabler/icons-react';
import {
  useCreateBugReportMutation,
  useCreatePainPointMutation,
} from 'store/slices/apiV1/utility';
import { handleErr } from 'utils/functions';
import { CreateBugReport, CreatePainPoint, LinearTicket } from 'types/utility';
import { IS_PROD, IS_QA } from 'constants/envConstants';
import { openReplayTracker } from 'utils/route-guard/AuthGuard';
import { useFeature } from '@growthbook/growthbook-react';
import { FeatureFlags } from 'types';
import useSnackbar from 'hooks/useSnackbar';

enum TAB_NAMES {
  BUG = 'bug',
  PAIN_POINT = 'painPoint',
}

type BugReportDialogProps = {
  open: boolean;
  onClose: () => void;
};

const BugReportDialog = ({ open, onClose }: BugReportDialogProps) => {
  const [error, setError] = useState<string | null>(null);
  const [currentView, setCurrentView] = useState<TAB_NAMES>(TAB_NAMES.BUG);
  const openReplayFeature = useFeature(FeatureFlags.tempOpenReplay).on;
  const { dispatchSuccessSnackbar } = useSnackbar();

  const [createBugReport, { isLoading: isLoadingCreateBugReport }] =
    useCreateBugReportMutation();

  const [createPainPoint, { isLoading: isLoadingCreatePainPoint }] =
    useCreatePainPointMutation();

  const tabOptions = {
    bug: {
      name: TAB_NAMES.BUG,
      label: 'Bug Report',
      icon: <IconBug />,
    },
    painPoint: {
      name: TAB_NAMES.PAIN_POINT,
      label: 'Pain Point',
      icon: <IconBandage />,
    },
  };

  const bugFormConstants = {
    summary: {
      name: 'summary',
      label: 'Summary',
    },
    severity: { name: 'severity', label: 'Severity' },
    stepsToReproduce: { name: 'stepsToReproduce', label: 'Steps to Reproduce' },
    expectedResult: {
      name: 'expectedResult',
      label: 'Expected Results vs Actual Results',
    },
    workAround: {
      name: 'workAround',
      label: 'If possible, what have you done to work around this issue?',
    },
    additionalInformation: {
      name: 'additionalInformation',
      label: 'Additional Info/Supporting Docs',
    },
  };

  const painPointFormConstants = {
    summary: {
      name: 'summary',
      label: 'Summary',
    },
    impact: {
      name: 'impact',
      label: 'Impact',
    },
    suggestion: { name: 'suggestion', label: 'Problem and Suggestion' },
    additionalInformation: {
      name: 'additionalInformation',
      label: 'Additional Info/Supporting Docs',
    },
  };

  const severityOptions = [
    { name: 1, impactLabel: 'Extensive', severityLabel: 'Critical' },
    { name: 2, impactLabel: 'Significant', severityLabel: 'High' },
    { name: 3, impactLabel: 'Moderate', severityLabel: 'Medium' },
    { name: 4, impactLabel: 'Minor', severityLabel: 'Low' },
  ];

  const bugDefaultValues = {
    [bugFormConstants.summary.name]: '',
    [bugFormConstants.severity.name]: '',
    [bugFormConstants.stepsToReproduce.name]: '',
    [bugFormConstants.expectedResult.name]: '',
    [bugFormConstants.workAround.name]: '',
    [bugFormConstants.additionalInformation.name]: '',
  };

  const painPointDefaultValues = {
    [painPointFormConstants.summary.name]: '',
    [painPointFormConstants.impact.name]: '',
    [painPointFormConstants.suggestion.name]: '',
    [painPointFormConstants.additionalInformation.name]: '',
  };

  const bugValidationSchema = yup.object().shape({
    [bugFormConstants.summary.name]: yup
      .string()
      .max(
        96,
        `${bugFormConstants.summary.label} must be 96 characters or fewer`
      )
      .required(`${bugFormConstants.summary.label} is a required field.`),
    [bugFormConstants.severity.name]: yup
      .mixed()
      .oneOf(
        _.map(Object.values(severityOptions), 'name'),
        `${bugFormConstants.severity.label} must be one of: ${_.map(
          severityOptions,
          'severityLabel'
        ).join(', ')}`
      )
      .required(`${bugFormConstants.severity.label} is a required field.`),
    [bugFormConstants.stepsToReproduce.name]: yup.string(),
    [bugFormConstants.expectedResult.name]: yup.string(),
    [bugFormConstants.workAround.name]: yup.string(),
    [bugFormConstants.additionalInformation.name]: yup.string(),
  });

  const painPointValidationSchema = yup.object().shape({
    [painPointFormConstants.summary.name]: yup
      .string()
      .max(
        96,
        `${painPointFormConstants.summary.label} must be 96 characters or fewer`
      )
      .required(`${painPointFormConstants.summary.label} is a required field.`),
    [painPointFormConstants.impact.name]: yup
      .mixed()
      .oneOf(
        _.map(Object.values(severityOptions), 'name'),
        `${painPointFormConstants.impact.label} must be one of: ${_.map(
          severityOptions,
          'impactLabel'
        ).join(', ')}`
      )
      .required(`${painPointFormConstants.impact.label} is a required field.`),
    [painPointFormConstants.suggestion.name]: yup.string(),
    [painPointFormConstants.additionalInformation.name]: yup.string(),
  });

  const {
    handleSubmit,
    reset,
    control,
    formState: { errors: formErrors },
  } = useForm<FieldValues>({
    defaultValues:
      currentView === tabOptions.bug.name
        ? bugDefaultValues
        : painPointDefaultValues,
    resolver: yupResolver(
      currentView === tabOptions.bug.name
        ? bugValidationSchema
        : painPointValidationSchema
    ),
    mode: 'all',
  });

  const onSubmit = async (data: FieldValues) => {
    const currentViewKeys = Object.keys(
      currentView === tabOptions.bug.name
        ? bugFormConstants
        : painPointFormConstants
    );
    const payload = _.chain({
      ...data,
      sessionUrl:
        openReplayFeature && (IS_PROD || IS_QA)
          ? openReplayTracker.getSessionURL({ withCurrentTime: true })
          : null,
      url: window.location.href,
    })
      .omitBy(
        (val, key) =>
          ![...currentViewKeys, 'sessionUrl', 'url'].includes(key) || val === ''
      )
      .omitBy(_.isNil)
      .value();
    try {
      let ticket: undefined | LinearTicket;
      if (currentView === TAB_NAMES.BUG) {
        ticket = await createBugReport(payload as CreateBugReport).unwrap();
      }
      if (currentView === TAB_NAMES.PAIN_POINT) {
        ticket = await createPainPoint(payload as CreatePainPoint).unwrap();
      }

      dispatchSuccessSnackbar(
        `${
          currentView === TAB_NAMES.BUG ? 'Bug Report' : 'Pain Point'
        } submitted`,
        undefined,
        ticket?.url
          ? {
              onClick: () => {
                window.open(ticket?.url, '_blank', 'noopener,noreferrer');
              },
              name: 'View Ticket in Linear',
            }
          : undefined
      );
      handleClose();
    } catch (err) {
      handleErr(err, (errMessage: string) => setError(errMessage));
    }
  };

  const handleClose = () => {
    reset();
    setCurrentView(TAB_NAMES.BUG);
    onClose();
    setError(null);
  };

  return (
    <ExtendedDialog
      title="Submit Bug Report"
      onCloseDialog={handleClose}
      open={open}
      onSubmit={handleSubmit(onSubmit)}
      isForm
      formSubmitError={error}
      isSubmitting={isLoadingCreateBugReport || isLoadingCreatePainPoint}
    >
      <ExtendedTabs
        value={currentView}
        onClick={(tabName) => {
          setCurrentView(tabName as TAB_NAMES);
          reset();
        }}
        tabOptions={[
          {
            label: tabOptions.bug.label,
            icon: tabOptions.bug.icon,
            name: tabOptions.bug.name,
            contents: (
              <Box>
                <HookFormInput
                  control={control}
                  name={bugFormConstants.summary.name}
                  label={bugFormConstants.summary.label}
                  errors={formErrors}
                />
                <HookFormSelect
                  sx={{ mb: 2 }}
                  name={bugFormConstants.severity.name}
                  label={bugFormConstants.severity.label}
                  control={control}
                  errors={formErrors}
                  mt={0}
                >
                  {severityOptions.map((option) => (
                    <MenuItem key={option.name} value={option.name}>
                      {option.severityLabel}
                    </MenuItem>
                  ))}
                </HookFormSelect>
                <HookFormInput
                  control={control}
                  name={bugFormConstants.stepsToReproduce.name}
                  label={bugFormConstants.stepsToReproduce.label}
                  errors={formErrors}
                  multiline
                  resize
                  rows={2}
                />
                <HookFormInput
                  control={control}
                  name={bugFormConstants.expectedResult.name}
                  label={bugFormConstants.expectedResult.label}
                  errors={formErrors}
                  multiline
                  resize
                  rows={2}
                />
                <HookFormInput
                  control={control}
                  name={bugFormConstants.workAround.name}
                  label={bugFormConstants.workAround.label}
                  errors={formErrors}
                  multiline
                  resize
                  rows={2}
                />
                <HookFormInput
                  control={control}
                  name={bugFormConstants.additionalInformation.name}
                  label={bugFormConstants.additionalInformation.label}
                  errors={formErrors}
                  multiline
                  resize
                  rows={2}
                />
              </Box>
            ),
          },
          {
            label: tabOptions.painPoint.label,
            icon: tabOptions.painPoint.icon,
            name: tabOptions.painPoint.name,
            contents: (
              <Box>
                <HookFormInput
                  control={control}
                  name={painPointFormConstants.summary.name}
                  label={painPointFormConstants.summary.label}
                  errors={formErrors}
                />
                <HookFormSelect
                  sx={{ mb: 2 }}
                  name={painPointFormConstants.impact.name}
                  label={painPointFormConstants.impact.label}
                  control={control}
                  errors={formErrors}
                  mt={0}
                >
                  {severityOptions.map((option) => (
                    <MenuItem key={option.name} value={option.name}>
                      {option.impactLabel}
                    </MenuItem>
                  ))}
                </HookFormSelect>
                <HookFormInput
                  control={control}
                  name={painPointFormConstants.suggestion.name}
                  label={painPointFormConstants.suggestion.label}
                  errors={formErrors}
                  multiline
                  resize
                  rows={2}
                />
                <HookFormInput
                  control={control}
                  name={painPointFormConstants.additionalInformation.name}
                  label={painPointFormConstants.additionalInformation.label}
                  errors={formErrors}
                  multiline
                  resize
                  rows={2}
                />
              </Box>
            ),
          },
        ]}
      />
    </ExtendedDialog>
  );
};

export default BugReportDialog;
