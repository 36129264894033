import { Card, CardContent } from '@mui/material';
import ExtendedTabs from 'ui-component/extended/ExtendedTabs';
import { IconListCheck, IconPackage, IconReplace } from '@tabler/icons-react';
import { ALL_APPS } from 'constants/appConstants';
import { DecoratedKitRequestLine } from 'hooks/useGetDecoratedKitRequestLines';
import KitRequestAllocatedStockLotsTable from 'views/kitting/Kit/components/KitRequestLineDetailPanel/KitRequestAllocatedStockLotsTable';
import KitRequestsHandlingRequestsTable from 'views/kitting/Kit/components/KitRequestLineDetailPanel/KitRequestsHandlingRequestsTable';
import KitRequestLineAltsTable from 'views/kitting/Kit/components/KitRequestLineDetailPanel/KitRequestLineAltsTable';

const KitRequestLineDetailPanel = ({
  kitRequestLine,
}: {
  kitRequestLine: DecoratedKitRequestLine;
}) => (
  <>
    <Card sx={{ m: 2 }}>
      <CardContent>
        <ExtendedTabs
          tabOptions={[
            {
              name: 'stockLots',
              label: 'Stock Lots',
              icon: <IconPackage />,
              contents: (
                <KitRequestAllocatedStockLotsTable
                  kitRequestLineId={kitRequestLine.id}
                />
              ),
              permissionScope: { app: ALL_APPS.KITTING.id },
            },
            {
              name: 'handlingTasks',
              label: 'Handling Tasks',
              icon: <IconListCheck />,
              contents: (
                <KitRequestsHandlingRequestsTable
                  handlingRequestsForKitRequestLine={
                    kitRequestLine?.handlingRequests ?? []
                  }
                />
              ),
              permissionScope: { app: ALL_APPS.MANAGED_INVENTORY.id },
            },
            {
              name: 'alts',
              label: 'Alternatives',
              icon: <IconReplace />,
              contents: (
                <KitRequestLineAltsTable kitRequestLine={kitRequestLine} />
              ),
              permissionScope: { app: ALL_APPS.KITTING.id },
            },
          ]}
        />
      </CardContent>
    </Card>
  </>
);

export default KitRequestLineDetailPanel;
