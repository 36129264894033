import { RowAction } from 'types/datagrid';
import { useTheme } from '@mui/material/styles';
import _ from 'lodash';
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state';
import {
  Button,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Tooltip,
} from '@mui/material';
import { pluralizeNoun } from 'utils/functions';
import { Icon as TablerIcon, IconDotsVertical } from '@tabler/icons-react';
import { useAppAccessContext } from 'contexts/AppAccessContext';

export const MoreActionsButton = <T,>({
  rowActions,
  selectedRowData,
  narrowWindow,
}: {
  rowActions: RowAction<T>[];
  selectedRowData: any[];
  narrowWindow: boolean;
}) => {
  const theme = useTheme();
  const { isPermissionDisabled, getDisabledReason } = useAppAccessContext();

  const moreActionsLength = _.filter(
    rowActions,
    (action) => !!action.showInBatchMenu && !!action.onBatchClick
  ).length;

  if (!moreActionsLength) return <></>;
  return (
    <PopupState variant="popover" popupId="moreactions-popup-menu">
      {(popupState) => (
        <>
          {narrowWindow ? (
            <Tooltip
              title={
                !selectedRowData.length
                  ? 'Select at least one row checkbox to enable actions'
                  : `View ${moreActionsLength} more ${pluralizeNoun(
                      'action',
                      moreActionsLength
                    )}`
              }
            >
              <span>
                <IconButton
                  size="small"
                  {...bindTrigger(popupState)}
                  color="primary"
                  disabled={!selectedRowData.length}
                >
                  <IconDotsVertical />
                </IconButton>
              </span>
            </Tooltip>
          ) : (
            <Tooltip
              title={
                !selectedRowData.length
                  ? 'Select at least one row checkbox to enable actions'
                  : `View ${moreActionsLength} more ${pluralizeNoun(
                      'action',
                      moreActionsLength
                    )}`
              }
            >
              <span>
                <Button
                  variant="outlined"
                  size="small"
                  startIcon={<IconDotsVertical />}
                  {...bindTrigger(popupState)}
                  color="primary"
                  disabled={!selectedRowData.length}
                >
                  {`${moreActionsLength} More`}
                </Button>
              </span>
            </Tooltip>
          )}
          <Menu {...bindMenu(popupState)}>
            {_.filter(rowActions, (action) => !!action.showInBatchMenu).map(
              (action) => {
                const ActionIcon: TablerIcon = action.getRowIcon
                  ? action.getRowIcon(selectedRowData[0])
                  : action.Icon;
                return (
                  <MenuItem
                    onClick={() => {
                      if (action.onBatchClick) {
                        action.onBatchClick(selectedRowData);
                        popupState.close();
                      }
                    }}
                    disabled={
                      action.disabled ||
                      (!!action.isRowDisabled &&
                        _.some(selectedRowData || [], (r) =>
                          action.isRowDisabled?.(r)
                        )) ||
                      isPermissionDisabled(action.permissionScope)
                    }
                    key={action.getLabel(selectedRowData[0])}
                    color={action.color}
                  >
                    <ListItemIcon>
                      <ActionIcon
                        color={
                          action.disabled
                            ? theme.palette.grey[500]
                            : _.get(theme.palette, [
                                action.color || 'primary',
                                'main',
                              ])
                        }
                      />
                    </ListItemIcon>
                    <ListItemText
                      color={
                        action.disabled
                          ? theme.palette.grey[500]
                          : _.get(theme.palette, [
                              action.color || 'primary',
                              'main',
                            ])
                      }
                    >
                      {action.getLabel(selectedRowData[0])}
                    </ListItemText>
                  </MenuItem>
                );
              }
            )}
          </Menu>
        </>
      )}
    </PopupState>
  );
};
