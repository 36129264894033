import { useState, ReactElement, useEffect } from 'react';
import { useSelector } from 'store';
import { useTheme } from '@mui/material/styles';
import {
  Card,
  CardHeader,
  Tooltip,
  CardContent,
  IconButton,
  Menu,
  MenuItem,
  Typography,
  Stack,
  Collapse,
  CardActionArea,
} from '@mui/material';
import {
  IconEdit,
  IconDotsVertical,
  IconCut,
  IconCopy,
  IconBuildingWarehouse,
  IconHandStop,
  IconArrowsJoin2,
  IconDisc,
  IconHandOff,
  IconPackage,
  IconTag,
} from '@tabler/icons-react';
import type { StockLocation, StockLocationLite } from 'types/inventory';
import CreateUpdateFacilityDialog from 'views/inventory/components/CreateUpdateFacilityDialog';
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state';
import usePartRuleSets from 'hooks/usePartRuleSets';
import WithSkeleton from 'ui-component/extended/WithSkeleton';
import { ALL_APP_IDS } from 'constants/appConstants';
import {
  manufacturingConstantKeys,
  manufacturingConstDescription,
} from 'constants/toggleConstants';
import CopyClipboard from 'ui-component/extended/CopyClipboard';
import { skipToken } from '@reduxjs/toolkit/dist/query/react';
import { useGetOrgQuery } from 'store/slices/apiV1/org';
import { useGetPriceModelQuery } from 'store/slices/apiV1/purchasing';
import { actionButtonSpacing } from 'constants/themeConstants';
import _ from 'lodash';
import { useAppAccessContext } from 'contexts/AppAccessContext';
import { useLazyGetTeamQuery } from 'store/slices/apiV1/properties';
import { Team, TeamLite } from 'types/properties';
import { copyClipboard } from 'utils/functions';
import { StockLocationSchemaLite } from 'types/clientV2/stockLocations';

export interface AddressCardProps {
  location: StockLocation | StockLocationLite | StockLocationSchemaLite;
  EditButton?: ReactElement;
  startCollapsed?: boolean;
  clickable?: boolean;
}

export default function AddressCard({
  location,
  EditButton,
  startCollapsed,
  clickable = true,
}: AddressCardProps) {
  const theme = useTheme();

  const { activeOrgId } = useSelector((state) => state.org);
  const { data: org } = useGetOrgQuery(activeOrgId ?? skipToken);

  const { data: priceModel } = useGetPriceModelQuery(
    org?.priceModel.id || skipToken
  );

  const [editStockLocationDialogOpen, setEditStockLocationDialogOpen] =
    useState(false);
  const [collapsed, setCollapsed] = useState(!!startCollapsed);
  const [team, setTeam] = useState<Team | TeamLite | null>(null);

  const { isFetching, partRuleSet } = usePartRuleSets({
    partRuleSetId: location.partRuleSet ?? undefined,
  });

  const facilityName =
    location.externalName || location.name || 'this facility';

  const { hasAppPermission } = useAppAccessContext();
  const propertiesAppEnabled = hasAppPermission(ALL_APP_IDS.PROPERTIES);

  const [getTeam] = useLazyGetTeamQuery();

  const handleFetchTeam = async () => {
    if (!team && location?.team === 'string') {
      const fetchedTeam = await getTeam(location.team).unwrap();
      setTeam(fetchedTeam);
    }
  };

  useEffect(() => {
    if (location.team && typeof location?.team !== 'string') {
      setTeam(location.team);
    }
    if (!team && typeof location?.team === 'string') {
      handleFetchTeam();
    }
  }, [location?.team]);

  const CardComponent = () => (
    <Card variant="outlined">
      {/* Must be div because the menu is a button, and buttons can't be nested */}
      <CardHeader
        sx={{ p: 1 }}
        action={
          <>
            <PopupState variant="popover" popupId="address-card-menu">
              {(popupState) => (
                <>
                  <Tooltip title="Address Actions">
                    <IconButton
                      {...bindTrigger(popupState)}
                      onClick={(e) => {
                        // To prevent clickthrough on the collapse action
                        e.stopPropagation();
                        bindTrigger(popupState).onClick(e);
                      }}
                    >
                      <IconDotsVertical />
                    </IconButton>
                  </Tooltip>
                  <Menu
                    {...bindMenu(popupState)}
                    onClose={(e, reason) => {
                      // Necessary because e may not be an HTML event
                      if (reason === 'backdropClick') {
                        if (
                          'stopPropagation' in e &&
                          e.stopPropagation &&
                          typeof e.stopPropagation === 'function'
                        ) {
                          bindMenu(popupState).onClose();
                          e.stopPropagation();
                        }
                      }
                    }}
                  >
                    <MenuItem
                      onClick={() => setEditStockLocationDialogOpen(true)}
                      disabled={_.get(location, 'locked', false)}
                    >
                      <IconEdit />
                      <Typography sx={{ ml: 1 }}>Edit Location</Typography>
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        copyClipboard(location.id);
                        bindMenu(popupState).onClose();
                      }}
                    >
                      <IconCopy />
                      <Typography sx={{ ml: 1 }}>Copy Address ID</Typography>
                    </MenuItem>
                  </Menu>
                </>
              )}
            </PopupState>
          </>
        }
        title={
          <Typography variant="h4">
            <Stack direction="row" sx={{ alignItems: 'center' }}>
              {location.externalName || location.name}
              <CopyClipboard
                label="location name"
                input={location.externalName || location.name}
              />
            </Stack>
            {EditButton && EditButton}
          </Typography>
        }
      />
      <Collapse in={!collapsed} timeout="auto" unmountOnExit>
        <CardContent
          sx={{
            borderTop: 1,
            p: 1,
            borderColor: theme.palette.grey[200],
          }}
        >
          {location.address && (
            <>
              <Stack direction="row" sx={{ alignItems: 'center' }}>
                <Typography variant="body2" color="text.primary">
                  {location.address.company}
                </Typography>
                {/* The newlines and tab spacing is retained, so need to format it in
              JSX exactly as we want it copied */}
                <CopyClipboard
                  label="address"
                  input={`
${location.address.company}
${location.address.name}
${location.address.line1}
${location.address.line2}
${location.address.city}, ${location.address.state}, ${location.address.postalCode} ${location.address.country}
`}
                />
              </Stack>
              {location.address.name && (
                <Typography variant="body2" color="text.primary">
                  Attn: {location.address.name}
                </Typography>
              )}
              <Typography variant="body2" color="text.primary">
                {location.address.line1}
              </Typography>
              {location.address.line2 && (
                <Typography variant="body2" color="text.primary">
                  {location.address.line2}
                </Typography>
              )}
              <Typography variant="body2" color="text.primary">
                {`${location.address.city}, `}
                {`${location.address.state}, `}
                {`${location.address.postalCode} `}
                {`${location.address.country}`}
              </Typography>
            </>
          )}
        </CardContent>
        {location?.partRuleSet && (
          <CardContent
            sx={{ borderTop: 1, borderColor: theme.palette.grey[200], p: 1 }}
          >
            <Typography variant="subtitle1" color="text.secondary">
              Default Overage Model
            </Typography>
            {location.partRuleSet && (
              <WithSkeleton isLoading={isFetching} sx={{ ml: 1, mr: 1 }}>
                <Typography variant="body2" color="text.primary">
                  {partRuleSet?.name}
                </Typography>
              </WithSkeleton>
            )}
          </CardContent>
        )}
        {propertiesAppEnabled && team?.name && (
          <CardContent
            sx={{ borderTop: 1, borderColor: theme.palette.grey[200], p: 1 }}
          >
            <Stack direction="row" alignItems="center" spacing={1}>
              <IconTag color={theme.palette.primary.main} />
              <Typography>{team.name}</Typography>
            </Stack>
          </CardContent>
        )}
        <CardContent
          sx={{
            borderTop: 1,
            borderColor: theme.palette.grey[200],
            p: 1,
            '&:last-child': { p: 1 },
          }}
        >
          <Stack
            spacing={actionButtonSpacing}
            direction="row"
            flexWrap="wrap"
            useFlexGap
          >
            {manufacturingConstantKeys.warehouseShippingEnabled in location &&
              location.warehouseShippingEnabled && (
                <Tooltip
                  title={manufacturingConstDescription(
                    priceModel,
                    facilityName
                  )[manufacturingConstantKeys.warehouseShippingEnabled](true)}
                >
                  <IconBuildingWarehouse
                    color={theme.palette.primary.main}
                    stroke={1.5}
                  />
                </Tooltip>
              )}
            {manufacturingConstantKeys.consumesStock in location &&
              !location.consumesStock && (
                <Tooltip
                  title={manufacturingConstDescription(
                    priceModel,
                    facilityName
                  )[manufacturingConstantKeys.consumesStock](false)}
                >
                  <IconPackage
                    color={theme.palette.primary.main}
                    stroke={1.5}
                  />
                </Tooltip>
              )}
            {manufacturingConstantKeys.requiresSplits in location &&
              location.requiresSplits && (
                <Tooltip
                  title={manufacturingConstDescription(
                    priceModel,
                    facilityName
                  )[manufacturingConstantKeys.requiresSplits](true)}
                >
                  <IconCut color={theme.palette.primary.main} stroke={1.5} />
                </Tooltip>
              )}
            {manufacturingConstantKeys.acceptsSmtNoPnp in location &&
              !location.acceptsSmtNoPnp && (
                <Tooltip
                  title={manufacturingConstDescription(
                    priceModel,
                    facilityName
                  )[manufacturingConstantKeys.acceptsSmtNoPnp](false)}
                >
                  <IconHandOff
                    color={theme.palette.primary.main}
                    stroke={1.5}
                  />
                </Tooltip>
              )}
            {manufacturingConstantKeys.acceptsSmtNoPnp in location &&
              location.acceptsSmtNoPnp && (
                <Tooltip
                  title={manufacturingConstDescription(
                    priceModel,
                    facilityName
                  )[manufacturingConstantKeys.acceptsSmtNoPnp](true)}
                >
                  <IconHandStop
                    color={theme.palette.primary.main}
                    stroke={1.5}
                  />
                </Tooltip>
              )}
            {manufacturingConstantKeys.requiresMerges in location &&
              manufacturingConstantKeys.requiresReels in location &&
              location.requiresMerges &&
              !location.requiresReels && (
                <Tooltip
                  title={manufacturingConstDescription(
                    priceModel,
                    facilityName
                  )[manufacturingConstantKeys.packaging]({
                    requiresMerges: true,
                    requiresReels: false,
                  })}
                >
                  <IconArrowsJoin2
                    color={theme.palette.primary.main}
                    stroke={1.5}
                  />
                </Tooltip>
              )}

            {manufacturingConstantKeys.requiresMerges in location &&
              manufacturingConstantKeys.requiresReels in location &&
              location.requiresMerges &&
              location.requiresReels && (
                <Tooltip
                  title={manufacturingConstDescription(
                    priceModel,
                    facilityName
                  )[manufacturingConstantKeys.packaging]({
                    requiresMerges: true,
                    requiresReels: true,
                  })}
                >
                  <IconDisc color={theme.palette.primary.main} stroke={1.5} />
                </Tooltip>
              )}
          </Stack>
        </CardContent>
      </Collapse>
    </Card>
  );

  return (
    <>
      <CreateUpdateFacilityDialog
        dialogOpen={editStockLocationDialogOpen}
        onClose={() => setEditStockLocationDialogOpen(false)}
        selectedLocation={location as StockLocation}
      />
      {clickable ? (
        <CardActionArea
          component="div"
          onClick={() => {
            setCollapsed(!collapsed);
          }}
        >
          <CardComponent />
        </CardActionArea>
      ) : (
        <CardComponent />
      )}
    </>
  );
}
