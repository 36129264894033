import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { DASHBOARD_ROUTE, MOBILE_INVENTORY_ROUTES } from 'routes/constants';
import {
  setViewAsStaff,
  useGetUserQuery,
  useSignoutUserMutation,
} from 'store/slices/apiV1/user';
import { apiSlice } from 'store/slices/api';
import { AppBar, Toolbar, Box, Alert } from '@mui/material';
import SegmentIconButton from 'ui-component/Segment/SegmentIconButton';
import {
  IconSettings,
  IconLogout,
  IconDeviceDesktop,
  IconBug,
  IconUserShield,
} from '@tabler/icons-react';
import { useDeviceContext } from 'hooks/useDeviceContext';
import UserAvatar from 'ui-component/extended/UserAvatar';
import { logout } from 'store/actions';
import { useDispatch, useSelector } from 'store';
import { skipToken } from '@reduxjs/toolkit/query/react';
import useLocalStorage from 'hooks/useLocalStorage';
import {
  mobileInventoryDefaults,
  mobileInventoryStorageName,
} from 'types/mobileInventorySettings';
import { ALL_APPS, cofactrName } from 'constants/appConstants';
import { handleErr } from 'utils/functions';
import { useEffect, useState } from 'react';
import BugReportDialog from 'ui-component/BugReportDialog';
import { isCofactrUser } from 'utils/userPermissions';
import { NewVersionBanner } from 'ui-component/NewVersionBanner';
import { useNewVersion } from 'contexts/NewVersionContext';
import { useUpdateOrgData } from 'hooks/useUpdateOrgData';
import _ from 'lodash';
import NoPlanBanner from 'ui-component/NoPlanBanner';
import { HelpHub } from 'ui-component/Commandbar/HelpHub';
import { init } from 'commandbar';

const TopAppBar = () => {
  const { activateBlended } = useUpdateOrgData();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { showNewVersion } = useNewVersion();
  const { isLoggedIn } = useSelector((state) => state.session);
  const { viewAsStaff } = useSelector((state) => state.user);
  const { showNoPlanBanner } = useSelector((state) => state.utility);

  const { data: user } = useGetUserQuery(isLoggedIn ? undefined : skipToken);
  const theme = useTheme();
  const { desktopScreen } = useDeviceContext();
  const isCofactr = isCofactrUser({ user, viewAsStaff });

  const [signoutUser] = useSignoutUserMutation();
  const [bugReportDialogOpen, setBugReportDialogOpen] = useState(false);

  const renderStaffUserToggle =
    _.find(user?.orgs, ['id', user?.org])?.name === cofactrName;

  const handleLogout = async () => {
    try {
      await signoutUser().unwrap();
    } catch (err) {
      handleErr(err, (__: string) => {});
    } finally {
      dispatch(logout());
      dispatch(apiSlice.util.resetApiState());
    }
  };

  useEffect(() => activateBlended(), []);

  const [settings] = useLocalStorage(
    mobileInventoryStorageName,
    mobileInventoryDefaults
  );

  const hasFacility = settings?.facility;
  const hasPrinter = settings?.printer3020;

  useEffect(() => {
    if (window) {
      if (process.env.COMMANDBAR_KEY && !window.CommandBar) {
        init(process.env.COMMANDBAR_KEY);
      }
      if (window.CommandBar) {
        window.CommandBar.addRouter(navigate);
        if (user) {
          window.CommandBar.boot(user?.id);
        }
      }
    }

    return undefined;
  }, [user, !!window, window.CommandBar]);

  return (
    <>
      {bugReportDialogOpen && (
        <BugReportDialog
          open={bugReportDialogOpen}
          onClose={() => setBugReportDialogOpen(false)}
        />
      )}
      {showNewVersion && <NewVersionBanner />}
      {showNoPlanBanner && <NoPlanBanner />}
      <AppBar position="sticky" color="primary" sx={{ top: 0, bottom: 'auto' }}>
        <Toolbar>
          <UserAvatar
            user={user}
            sx={{
              ...theme.typography.mediumAvatar,
            }}
          />
          <Box sx={{ flexGrow: 1 }} />
          <HelpHub variant />

          {isCofactr && (
            <SegmentIconButton
              size={desktopScreen ? 'large' : 'medium'}
              color="inherit"
              onClick={() => setBugReportDialogOpen(true)}
            >
              <IconBug size={desktopScreen ? 32 : 24} color="white" />
            </SegmentIconButton>
          )}
          {renderStaffUserToggle && (
            <SegmentIconButton
              size={desktopScreen ? 'large' : 'medium'}
              color="inherit"
              onClick={() => {
                dispatch(setViewAsStaff(!viewAsStaff));
              }}
            >
              <IconUserShield
                size={desktopScreen ? 32 : 24}
                color={viewAsStaff ? theme.palette.success.main : 'white'}
              />
            </SegmentIconButton>
          )}
          {desktopScreen && (
            <SegmentIconButton
              size="large"
              color="inherit"
              onClick={() => navigate(DASHBOARD_ROUTE)}
            >
              <IconDeviceDesktop size={32} color="white" />
            </SegmentIconButton>
          )}

          {(!hasFacility || !hasPrinter) && (
            <Alert
              severity="error"
              variant="filled"
              icon={<IconSettings size={desktopScreen ? 32 : 24} />}
              onClick={() => navigate(MOBILE_INVENTORY_ROUTES.SETTINGS)}
            >
              Select a {!hasFacility && 'Facility + '}{' '}
              {!hasPrinter && 'Printer'}
            </Alert>
          )}
          {settings?.facility && (
            <SegmentIconButton
              size={desktopScreen ? 'large' : 'medium'}
              color="inherit"
              onClick={() => navigate(MOBILE_INVENTORY_ROUTES.SETTINGS)}
              permissionScope={{ app: ALL_APPS.WAREHOUSE.id }}
            >
              <IconSettings size={desktopScreen ? 32 : 24} />
            </SegmentIconButton>
          )}
          <SegmentIconButton
            color="inherit"
            size={desktopScreen ? 'large' : 'medium'}
            onClick={handleLogout}
          >
            <IconLogout size={desktopScreen ? 32 : 24} />
          </SegmentIconButton>
        </Toolbar>
      </AppBar>
    </>
  );
};

export default TopAppBar;
