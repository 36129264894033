import { Button, Divider, MenuItem, Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import _ from 'lodash';
import {
  formConstants,
  packageTypes,
  validationSchema,
} from 'ui-component/CustomPartDialog/constants';
import { Box } from '@mui/system';
import { yupResolver } from '@hookform/resolvers/yup';

import { MslValueOptions } from 'types/inventory';
import {
  Classification,
  CreateCustomPartRequest,
  Manufacturer,
  Part,
  PartRaw,
  terminationTypeOptionsExpanded,
} from 'types/part';
import { handleErr, pluralizeNoun, pluralizeWordOption } from 'utils/functions';
import {
  useCreateCustomPartMutation,
  useLazyGetPartsQuery,
} from 'store/slices/apiV1/part';
import { useSelector } from 'store';
import { HookFormInput, HookFormSelect } from 'ui-component/HookFormComponents';
import { ExtendedDialog } from 'ui-component/extended/ExtendedDialog';
import ManufacturerAutocomplete from 'ui-component/CustomPartDialog/components/ManufacturerAutocomplete';
import ClassificationAutocomplete from 'ui-component/CustomPartDialog/components/ClassificationAutocomplete';
import PackageAutocomplete from 'ui-component/CustomPartDialog/components/PackageAutocomplete';
import { ALL_APPS, ALL_FEATURE_IDS } from 'constants/appConstants';
import { appPermissionAccessLevels } from 'types/apps';
import AlertWithAppIcon from 'ui-component/AlertWithAppIcon';
import PartInfoWidget from 'ui-component/PartInfoWidget';
import { actionButtonSpacing, gridSpacing } from 'constants/themeConstants';
import useSnackbar from 'hooks/useSnackbar';

type CustomPartDialogProps = {
  dialogOpen: boolean;
  onClose: () => void;
  onCreateSuccess?: (newParts: Part | Part[]) => void;
  initialMpn?: string;
  rawPart?: PartRaw;
};

const CustomPartDialog = ({
  dialogOpen,
  onClose,
  onCreateSuccess,
  initialMpn,
  rawPart,
}: CustomPartDialogProps) => {
  const [error, setError] = useState<string | null>(null);
  const [__, setSearchError] = useState('');
  const { activeOrg } = useSelector((state) => state.org);
  const { dispatchSuccessSnackbar } = useSnackbar();

  const [createCustomPart, { isLoading: isLoadingCreateCustomPart }] =
    useCreateCustomPartMutation();

  const [getParts, { data: parts = [] }] = useLazyGetPartsQuery();

  const formDefaultValues = {
    [formConstants.mpn.name]: initialMpn ?? rawPart?.mpn ?? '',
    [formConstants.customId.name]: '',
    [formConstants.mfg.name]: '',
    [formConstants.customMfg.name]: rawPart?.mfg ?? '',
    [formConstants.classification.name]: '',
    [formConstants.customClassification.name]: '',
    [formConstants.msl.name]: '',
    [formConstants.description.name]: rawPart?.description ?? '',
    [formConstants.package.name]: '',
    [formConstants.terminations.name]: '',
    [formConstants.terminationType.name]: '',
  };

  const {
    handleSubmit,
    reset,
    control,
    formState: { errors },
    setValue,
    trigger,
    watch,
  } = useForm<FieldValues>({
    defaultValues: formDefaultValues,
    resolver: yupResolver(validationSchema),
    mode: 'all',
  });

  const watchMpn = watch('mpn', '');

  useEffect(() => {
    initialMpn && reset(formDefaultValues);
    rawPart && reset(formDefaultValues);
  }, [initialMpn, rawPart]);

  // search anytime searchQuery is updated
  const handleSearch = async () => {
    if (watchMpn) {
      try {
        setSearchError('');
        await getParts({ query: watchMpn }, true).unwrap();
      } catch (err) {
        handleErr(err, setSearchError);
      }
    }
  };

  useEffect(() => {
    handleSearch();
  }, [watchMpn, dialogOpen]);

  const partsWithSameMpn = _.filter(parts, { mpn: watchMpn });

  const onSubmit = async (data: FieldValues) => {
    setError(null);
    try {
      const payload = _.omitBy(
        data,
        (val) => _.isNil(val) || val === ''
      ) as CreateCustomPartRequest;
      const newParts = await createCustomPart({ payload }).unwrap();
      handleClose();
      dispatchSuccessSnackbar('Part Created');
      onCreateSuccess && onCreateSuccess(newParts);
    } catch (err) {
      handleErr(err, setError);
    }
  };
  const handleClose = () => {
    setError(null);
    onClose();
    reset();
  };

  const setManufacturerValue = (option: Manufacturer | null) => {
    if (option?.id === 'custom') {
      setValue(formConstants.customMfg.name, option.label);
      setValue(formConstants.mfg.name, '');
    } else if (option?.id) {
      setValue(formConstants.mfg.name, option.id);
      setValue(formConstants.customMfg.name, '');
    } else {
      setValue(formConstants.mfg.name, '');
      setValue(formConstants.customMfg.name, '');
    }
    trigger([formConstants.mfg.name]);
  };

  const setClassificationValue = (option: Classification | null) => {
    if (option?.id === 'custom') {
      setValue(formConstants.customClassification.name, option.label);
      setValue(formConstants.classification.name, '');
    } else if (option?.id) {
      setValue(formConstants.classification.name, option.id);
      setValue(formConstants.customClassification.name, '');
    } else {
      setValue(formConstants.classification.name, '');
      setValue(formConstants.customClassification.name, '');
    }
    trigger([formConstants.classification.name]);
  };

  return (
    <ExtendedDialog
      onCloseDialog={handleClose}
      open={dialogOpen}
      fullWidth
      maxWidth="sm"
      title="Create New Part"
      isForm
      onSubmit={handleSubmit((data) => onSubmit(data))}
      submitButtonCopy="Create Part"
      formSubmitError={error}
      permissionScope={{
        app: ALL_APPS.PART_LIBRARY.id,
        accessLevel: appPermissionAccessLevels.edit,
        feature: ALL_FEATURE_IDS.CUSTOM_PARTS,
      }}
    >
      {partsWithSameMpn.length > 0 && (
        <AlertWithAppIcon
          severity="info"
          app={ALL_APPS.PART_SEARCH.id}
          sx={{ my: gridSpacing }}
          ignorePermissions
          title={`Existing ${pluralizeNoun('Part', partsWithSameMpn.length)}`}
        >
          {`${partsWithSameMpn.length.toLocaleString()} ${pluralizeNoun(
            'part',
            partsWithSameMpn.length
          )} with the MPN ${watchMpn} already ${pluralizeWordOption(
            'exists',
            'exist',
            partsWithSameMpn.length
          )}. Creating a new part can lead to data duplication.`}
          <Stack
            direction="column"
            justifyContent="flex-start"
            alignItems="stretch"
            sx={{ mt: gridSpacing }}
            spacing={actionButtonSpacing}
            divider={<Divider flexItem />}
          >
            {partsWithSameMpn.map((part) => (
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="flex-start"
                key={part.id}
              >
                <PartInfoWidget part={part} />
                {onCreateSuccess && (
                  <Button
                    size="small"
                    variant="outlined"
                    onClick={() => {
                      handleClose();
                      onCreateSuccess(part);
                    }}
                    color="secondary"
                  >
                    Select
                  </Button>
                )}
              </Stack>
            ))}
          </Stack>
        </AlertWithAppIcon>
      )}
      <Box sx={{ my: 1 }}>
        <Box sx={{ mb: -2 }}>
          <HookFormInput
            control={control}
            name={formConstants.mpn.name}
            label={formConstants.mpn.label}
            errors={errors}
          />
        </Box>
        <HookFormInput
          control={control}
          name={formConstants.customId.name}
          label={`${activeOrg?.name || 'Custom'} Part Number`}
          errors={errors}
          sx={{ mt: 2 }}
        />
        <ManufacturerAutocomplete
          handleSetValue={setManufacturerValue}
          name={formConstants.mfg.name}
          label={formConstants.mfg.label}
          errors={errors}
          initialCustomValue={rawPart?.mfg}
        />
        <ClassificationAutocomplete
          handleSetValue={setClassificationValue}
          name={formConstants.classification.name}
          label={formConstants.classification.label}
          errors={errors}
        />
        <HookFormSelect
          control={control}
          label={formConstants.msl.label}
          name={formConstants.msl.name}
          errors={errors}
          sx={{ mb: 2, mt: 0 }}
        >
          {MslValueOptions.map((mslVal) => (
            <MenuItem value={mslVal} key={mslVal}>
              {mslVal}
            </MenuItem>
          ))}
        </HookFormSelect>
        <HookFormInput
          control={control}
          name={formConstants.description.name}
          label={formConstants.description.label}
          errors={errors}
        />
        <PackageAutocomplete
          handleSetValue={(option: string | null) =>
            setValue(formConstants.package.name, option)
          }
          label={formConstants.package.label}
          name={formConstants.package.name}
          options={packageTypes}
          errors={errors}
        />
        <HookFormInput
          control={control}
          name={formConstants.terminations.name}
          label={formConstants.terminations.label}
          errors={errors}
          inputProps={{
            inputMode: 'numeric',
            pattern: '[0-9]*',
            max: 1000000000,
            min: 1,
          }}
          type="number"
        />
        <HookFormSelect
          control={control}
          name={formConstants.terminationType.name}
          label={formConstants.terminationType.label}
          errors={errors}
          sx={{ mb: 2, mt: 0 }}
        >
          {terminationTypeOptionsExpanded.map((packageType) => (
            <MenuItem value={packageType.value} key={packageType.value}>
              {packageType.label}
            </MenuItem>
          ))}
        </HookFormSelect>
      </Box>
    </ExtendedDialog>
  );
};

export default CustomPartDialog;
