import { URLS } from 'store/slices/constants/apiV1';
import { apiSlice } from 'store/slices/api';
import { AllocationCRUDSchema, ConsumeStockEvent } from 'types/inventory';
import {
  CreateProductionRunAvailabilitiesRequest,
  ProductionRun,
  ProductionRunConsumption,
  ProductionRunCreate,
  ProductionRunDates,
  ProductionRunLineCreate,
  ProductionRunLines,
  ProductionRunPartOverride,
  ProductionRunPartOverrideCreate,
  ProductionRunShipments,
  ProductionRunSnapshot,
  ProductionRunsRequest,
  ProductionRunUpdate,
  ProductionRunLineUpdate,
  ProductionRunPartsDescribeParts,
  ProductionRunAllocationForPartPayload,
  ProductionRunAllocationForPart,
  ConsumeStockForRunPayload,
  ProductionRunLinesLite,
  ProductionRunLite,
} from 'types/production';
import { Availabilities, ShipToUserKittingOptions } from 'types/purchasing';
import { NoPartInventory } from 'types/part';

export const extendedApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getProductionRuns: builder.query<
      ProductionRunLite[],
      ProductionRunsRequest
    >({
      query: ({ status, productionLocationId, bomId }: ProductionRunsRequest) =>
        URLS.PRODUCTION_RUNS({ status, productionLocationId, bomId }),
      providesTags: (result) =>
        result
          ? [
              ...result.map(
                ({ id }: ProductionRunLite) =>
                  ({ type: 'ProductionRuns', id } as const)
              ),
              { type: 'ProductionRuns', id: 'LIST' },
            ]
          : [{ type: 'ProductionRuns', id: 'LIST' }],
    }),
    deleteProductionRun: builder.mutation<string, ProductionRun['id']>({
      query: (productionRunId) => ({
        url: URLS.PRODUCTION_RUNS({ productionRunId }),
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, productionRunId) => [
        { type: 'ProductionRuns', id: productionRunId },
        { type: 'Allocation', id: 'LIST' },
        { type: 'Allocation', id: 'PARTIAL-LIST' },
        { type: 'Shipments', id: 'LIST' },
        { type: 'HandlingTaskRows', id: 'LIST' },
        { type: 'PartInventory', id: 'LIST' },
        { type: 'PartInventory', id: 'PARTIAL-LIST' },
      ],
    }),
    updateProductionRun: builder.mutation<
      ProductionRun,
      { id: ProductionRun['id']; payload: ProductionRunUpdate }
    >({
      query: ({ id, payload }) => ({
        url: URLS.PRODUCTION_RUNS({ productionRunId: id }),
        method: 'PATCH',
        body: payload,
      }),
      invalidatesTags: (result, error, { id }) => [
        {
          type: 'ProductionRuns',
          id,
        },
        { type: 'Availabilities', id },
        { type: 'Availabilities', id: 'LIST' },
        { type: 'ProductionRunDates', id: 'LIST' },
        { type: 'Shipments', id: 'LIST' },
        { type: 'Allocation', id: 'LIST' },
        { type: 'Allocation', id: 'PARTIAL-LIST' },
        { type: 'ProductionRunParts', id: 'LIST' },
      ],
    }),
    getProductionRun: builder.query<ProductionRun, ProductionRun['id']>({
      query: (productionRunId) => ({
        url: URLS.PRODUCTION_RUNS({ productionRunId }),
      }),
      providesTags: (result) =>
        result
          ? [
              { type: 'ProductionRuns', id: result.id },
              { type: 'ProductionRuns', id: 'LIST' },
            ]
          : [{ type: 'ProductionRuns', id: 'LIST' }],
    }),
    createProductionRun: builder.mutation<ProductionRun, ProductionRunCreate>({
      query: (payload) => ({
        url: URLS.PRODUCTION_RUNS({}),
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: [{ type: 'ProductionRuns', id: 'LIST' }],
    }),
    duplicateProductionRun: builder.mutation<
      ProductionRun,
      { productionRunId: ProductionRun['id']; payload: ProductionRunUpdate }
    >({
      query: ({ productionRunId, payload }) => ({
        url: URLS.PRODUCTION_RUN_DUPLICATE(productionRunId),
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: (result, error, { productionRunId }) => [
        { type: 'ProductionRuns', id: productionRunId },
        { type: 'ProductionRuns', id: 'LIST' },
      ],
    }),
    getProductionRunPartsCore: builder.query<
      ProductionRunPartsDescribeParts,
      { productionRunId: ProductionRun['id']; part?: string }
    >({
      query: ({ productionRunId, part }) => ({
        url: URLS.PRODUCTION_RUN_PARTS(productionRunId, true, part),
      }),
      providesTags: () => [{ type: 'ProductionRunParts', id: 'LIST' }],
    }),
    getProductionRunConsumeStockEvents: builder.query<
      ConsumeStockEvent[],
      ProductionRun['id']
    >({
      query: (productionRunId) => ({
        url: URLS.PRODUCTION_RUN_CONSUMED_STOCK(productionRunId),
      }),
      providesTags: () => [{ type: 'ConsumeStockEvents', id: 'LIST' }],
    }),
    createConsumeStockEventsForRun: builder.mutation<
      ConsumeStockEvent[],
      {
        id: ProductionRun['id'];
        payload: ConsumeStockForRunPayload[];
      }
    >({
      query: ({ id, payload }) => ({
        url: URLS.PRODUCTION_RUN_CONSUMED_STOCK(id),
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: [
        { type: 'ConsumeStockEvents', id: 'LIST' },
        { type: 'Allocation', id: 'PARTIAL-LIST' },
        { type: 'NoPartInventory', id: 'LIST' },
      ],
    }),
    getProductionRunPartOverrides: builder.query<
      ProductionRunPartOverride[],
      ProductionRun['id']
    >({
      query: (productionRunId) => ({
        url: URLS.PRODUCTION_RUN_OVERRIDES(productionRunId),
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.map(
                ({ id }: ProductionRunPartOverride) =>
                  ({ type: 'ProductionRunPartOverrides', id } as const)
              ),
              { type: 'ProductionRunPartOverrides', id: 'LIST' },
            ]
          : [{ type: 'ProductionRunPartOverrides', id: 'LIST' }],
    }),
    createProductionRunPartOverride: builder.mutation<
      ProductionRunPartOverride,
      {
        productionRunId: ProductionRun['id'];
        payload: ProductionRunPartOverrideCreate;
      }
    >({
      query: ({ productionRunId, payload }) => ({
        url: URLS.PRODUCTION_RUN_OVERRIDES(productionRunId),
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: [
        { type: 'ProductionRunPartOverrides', id: 'LIST' },
        { type: 'ProductionRunDates', id: 'LIST' },
      ],
    }),
    deleteProductionRunPartOverride: builder.mutation<
      string,
      ProductionRunPartOverride['id']
    >({
      query: (overrideId) => ({
        url: URLS.PRODUCTION_RUN_OVERRIDE(overrideId),
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, overrideId) => [
        {
          type: 'ProductionRunPartOverrides',
          id: overrideId,
        },
        { type: 'ProductionRunDates', id: 'LIST' },
      ],
    }),
    updateProductionRunPartOverride: builder.mutation<
      ProductionRunPartOverride,
      {
        overrideId: ProductionRunPartOverride['id'];
        payload: ProductionRunPartOverrideCreate;
      }
    >({
      query: ({ overrideId, payload }) => ({
        url: URLS.PRODUCTION_RUN_OVERRIDE(overrideId),
        method: 'PATCH',
        body: payload,
      }),
      invalidatesTags: (result, error, { overrideId }) => [
        {
          type: 'ProductionRunPartOverrides',
          id: overrideId,
        },
        { type: 'ProductionRunDates', id: 'LIST' },
      ],
    }),
    getProductionRunLines: builder.query<
      ProductionRunLinesLite[],
      { productionRunId: ProductionRun['id'] }
    >({
      query: (options) => ({
        url: URLS.PRODUCTION_RUN_LINES(options),
      }),
      providesTags: (result) =>
        result
          ? [
              ...result?.map(
                ({ id }: ProductionRunLinesLite) =>
                  ({ type: 'ProductionRunLines', id } as const)
              ),
              { type: 'ProductionRunLines', id: 'LIST' },
            ]
          : [{ type: 'ProductionRunLines', id: 'LIST' }],
    }),
    getProductionRunLine: builder.query<
      ProductionRunLines,
      {
        productionRunId: ProductionRun['id'];
        productionRunLineId?: ProductionRunLines['id'];
      }
    >({
      query: (options) => ({
        url: URLS.PRODUCTION_RUN_LINES(options),
      }),
      providesTags: (result) =>
        result
          ? [
              { type: 'ProductionRunLines', id: result.id },
              { type: 'ProductionRunLines', id: 'LIST' },
            ]
          : [{ type: 'ProductionRunLines', id: 'LIST' }],
    }),
    createProductionRunLine: builder.mutation<
      ProductionRunLines,
      {
        productionRunId: ProductionRun['id'];
        payload: ProductionRunLineCreate;
      }
    >({
      query: ({ productionRunId, payload }) => ({
        url: URLS.PRODUCTION_RUN_LINES({ productionRunId }),
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: (
        result,
        error,
        { productionRunId, payload: { bom } }
      ) => [
        { type: 'ProductionRuns', id: productionRunId },
        { type: 'ProductionRunAllocations', id: productionRunId },
        { type: 'ProductionRunLines', id: 'LIST' },
        { type: 'ProductionRunParts', id: 'LIST' },
        { type: 'ProductionRunDates', id: 'LIST' },
        { type: 'Boms', id: bom },
      ],
    }),
    updateProductionRunLine: builder.mutation<
      ProductionRunLines,
      {
        productionRunId: ProductionRun['id'];
        productionRunLineId: ProductionRunLines['id'];
        payload: ProductionRunLineUpdate;
      }
    >({
      query: ({ productionRunId, productionRunLineId, payload }) => ({
        url: URLS.PRODUCTION_RUN_LINES({
          productionRunId,
          productionRunLineId,
        }),
        method: 'PATCH',
        body: payload,
      }),
      invalidatesTags: (
        result,
        error,
        { productionRunId, productionRunLineId }
      ) => [
        { type: 'ProductionRunAllocations', id: productionRunId },
        { type: 'ProductionRunLines', id: productionRunLineId },
        { type: 'ProductionRunParts', id: 'LIST' },
        { type: 'ProductionRunDates', id: 'LIST' },
      ],
    }),
    deleteProductionRunLine: builder.mutation<
      string,
      {
        productionRunId: ProductionRun['id'];
        productionRunLineId: ProductionRunLines['id'];
      }
    >({
      query: ({ productionRunId, productionRunLineId }) => ({
        url: URLS.PRODUCTION_RUN_LINES({
          productionRunId,
          productionRunLineId,
        }),
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, { productionRunId }) => [
        { type: 'ProductionRuns', id: productionRunId },
        { type: 'ProductionRunAllocations', id: productionRunId },
        { type: 'ProductionRunLines', id: 'LIST' },
        { type: 'ProductionRunParts', id: 'LIST' },
        { type: 'ProductionRunDates', id: 'LIST' },
      ],
    }),
    getProductionRunAvailabilities: builder.query<
      Availabilities,
      ProductionRun['id']
    >({
      query: (productionRunId) =>
        URLS.PRODUCTION_RUN_AVAILABILITIES(productionRunId),
      providesTags: (result, error, productionRunId) => [
        { type: 'Availabilities', id: productionRunId },
      ],
    }),
    getProductionRunAvailabilitiesWithKitting: builder.mutation<
      Availabilities,
      {
        productionRunId: ProductionRun['id'];
        payload: ShipToUserKittingOptions;
      }
    >({
      query: ({ productionRunId, payload }) => ({
        url: URLS.PRODUCTION_RUN_AVAILABILITIES(productionRunId),
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: (result, error, { productionRunId }) => [
        { type: 'Availabilities', id: productionRunId },
      ],
    }),
    createProductionRunAvailabilitiesSnapshot: builder.mutation<
      ProductionRunSnapshot,
      {
        productionRunId: ProductionRun['id'];
        payload: CreateProductionRunAvailabilitiesRequest;
      }
    >({
      query: ({ productionRunId, payload }) => ({
        url: URLS.PRODUCTION_RUN_AVAILABILITIES_SNAPSHOT(productionRunId),
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: (result, error, { payload }) => [
        { type: 'AvailabilitiesSnapshot', id: payload.selectedLead },
        { type: 'Order', id: 'LIST' },
      ],
    }),
    getProductionRunAllocations: builder.query<
      AllocationCRUDSchema[],
      { productionRunId: ProductionRun['id']; expand?: boolean }
    >({
      query: ({ productionRunId, expand }) =>
        URLS.PRODUCTION_RUN_ALLOCATIONS({ productionRunId, expand }),
      providesTags: (result, error, { productionRunId }) => [
        { type: 'ProductionRunAllocations', id: productionRunId },
      ],
    }),
    deleteProductionRunAllocations: builder.mutation<
      AllocationCRUDSchema[],
      { productionRunId: ProductionRun['id']; expand?: boolean }
    >({
      query: ({ productionRunId, expand }) => ({
        url: URLS.PRODUCTION_RUN_ALLOCATIONS({ productionRunId, expand }),
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, { productionRunId }) => [
        { type: 'ProductionRunAllocations', id: productionRunId },
        { type: 'ProductionRunShipments', id: productionRunId },
        { type: 'ProductionRunShipments', id: 'LIST' },
        { type: 'Allocation', id: 'LIST' },
        { type: 'Allocation', id: 'PARTIAL-LIST' },
        { type: 'NoPartInventory', id: 'LIST' },
      ],
    }),
    updateProductionRunAllocations: builder.mutation<
      AllocationCRUDSchema[],
      { productionRunId: ProductionRun['id']; expand?: boolean }
    >({
      query: ({ productionRunId, expand }) => ({
        url: URLS.PRODUCTION_RUN_ALLOCATIONS({ productionRunId, expand }),
        method: 'PUT',
      }),
      invalidatesTags: (result, error, { productionRunId }) => [
        { type: 'ProductionRunAllocations', id: productionRunId },
        { type: 'ProductionRunShipments', id: productionRunId },
        { type: 'ProductionRunShipments', id: 'LIST' },
        { type: 'Shipments', id: 'LIST' },
        { type: 'Allocation', id: 'LIST' },
        { type: 'Allocation', id: 'PARTIAL-LIST' },
        { type: 'NoPartInventory', id: 'LIST' },
      ],
    }),
    createProductionRunAllocations: builder.mutation<
      AllocationCRUDSchema[],
      { productionRunId: ProductionRun['id']; expand?: boolean }
    >({
      query: ({ productionRunId, expand }) => ({
        url: URLS.PRODUCTION_RUN_ALLOCATIONS({ productionRunId, expand }),
        method: 'POST',
      }),
      invalidatesTags: (result, error, { productionRunId }) => [
        { type: 'ProductionRunAllocations', id: productionRunId },
        { type: 'ProductionRunShipments', id: productionRunId },
        { type: 'ProductionRunShipments', id: 'LIST' },
        { type: 'Allocation', id: 'LIST' },
        { type: 'Allocation', id: 'PARTIAL-LIST' },
        { type: 'NoPartInventory', id: 'LIST' },
      ],
    }),
    approveProductionRun: builder.mutation<ProductionRun, ProductionRun['id']>({
      query: (productionRunId) => ({
        url: URLS.PRODUCTION_RUN_APPROVE(productionRunId),
        method: 'POST',
      }),
      invalidatesTags: (result, error, productionRunId) => [
        { type: 'ProductionRuns', id: productionRunId },
        { type: 'ProductionRuns', id: 'LIST' },
      ],
    }),
    unapproveProductionRun: builder.mutation<
      ProductionRun,
      ProductionRun['id']
    >({
      query: (productionRunId) => ({
        url: URLS.PRODUCTION_RUN_UNAPPROVE(productionRunId),
        method: 'POST',
      }),
      invalidatesTags: (result, error, productionRunId) => [
        { type: 'ProductionRuns', id: productionRunId },
        { type: 'ProductionRuns', id: 'LIST' },
      ],
    }),
    getProductionRunDates: builder.query<
      ProductionRunDates,
      ProductionRun['id']
    >({
      query: (productionRunId) => ({
        url: URLS.PRODUCTION_RUN_DATES(productionRunId),
      }),
      providesTags: (result, error, productionRunId) =>
        result
          ? [
              { type: 'ProductionRunDates', id: productionRunId },
              { type: 'ProductionRunDates', id: 'LIST' },
            ]
          : [{ type: 'ProductionRunDates', id: 'LIST' }],
    }),
    getTheoreticalProductionRunShipments: builder.query<
      ProductionRunShipments,
      ProductionRun['id']
    >({
      query: (productionRunId) => ({
        url: URLS.PRODUCTION_RUN_SHIPMENTS(productionRunId),
      }),
      providesTags: (result, error, productionRunId) =>
        result
          ? [
              { type: 'ProductionRunShipments', id: productionRunId },
              { type: 'ProductionRunShipments', id: 'LIST' },
            ]
          : [{ type: 'ProductionRunShipments', id: 'LIST' }],
    }),
    createProductionRunShipments: builder.mutation<
      ProductionRunShipments,
      ProductionRun['id']
    >({
      query: (productionRunId) => ({
        url: URLS.PRODUCTION_RUN_SHIPMENTS(productionRunId),
        method: 'PUT',
      }),
      invalidatesTags: (result, error, productionRunId) =>
        result
          ? [
              { type: 'ProductionRunShipments', id: productionRunId },
              { type: 'ProductionRunShipments', id: 'LIST' },
              { type: 'Shipments', id: 'LIST' },
            ]
          : [{ type: 'ProductionRunShipments', id: 'LIST' }],
    }),
    deleteProductionRunDraftShipments: builder.mutation({
      query: (productionRunId) => ({
        url: URLS.PRODUCTION_RUN_SHIPMENTS(productionRunId),
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, productionRunId) => [
        { type: 'ProductionRunShipments', id: productionRunId },
        { type: 'ProductionRunShipments', id: 'LIST' },
        { type: 'Shipments', id: 'LIST' },
      ],
    }),
    getEstimateConsumption: builder.query<
      ProductionRunConsumption[],
      ProductionRun['id']
    >({
      query: (productionRunId) => ({
        url: URLS.PRODUCTION_RUN_ESTIMATE_CONSUMPTION(productionRunId),
      }),
      providesTags: (result, error, productionRunId) => [
        { type: 'ProductionRunConsumptionEstimates', id: productionRunId },
      ],
    }),
    executeProductionRun: builder.mutation<
      ProductionRun,
      {
        productionRunId: ProductionRun['id'];
        payload: { consumption: ProductionRunConsumption[] };
      }
    >({
      query: ({ productionRunId, payload }) => ({
        url: URLS.PRODUCTION_RUN_EXECUTE(productionRunId),
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: (result, error, { productionRunId }) => [
        { type: 'ProductionRuns', id: productionRunId },
        { type: 'ProductionRuns', id: 'LIST' },
        { type: 'ConsumeStockEvents', id: productionRunId },
        { type: 'ConsumeStockEvents', id: 'LIST' },
      ],
    }),
    undoExecuteProductionRun: builder.mutation<
      ProductionRun,
      ProductionRun['id']
    >({
      query: (productionRunId) => ({
        url: URLS.PRODUCTION_RUN_UNEXECUTE(productionRunId),
        method: 'POST',
      }),
      invalidatesTags: (result, error, productionRunId) => [
        { type: 'ProductionRuns', id: productionRunId },
        { type: 'ProductionRuns', id: 'LIST' },
        { type: 'ConsumeStockEvents', id: productionRunId },
        { type: 'ConsumeStockEvents', id: 'LIST' },
      ],
    }),
    getProductionRunInventory: builder.query<
      NoPartInventory[],
      ProductionRun['id']
    >({
      query: (productionRunId) => ({
        url: URLS.PRODUCTION_RUN_INVENTORY(productionRunId),
      }),
      providesTags: (result) =>
        result
          ? [
              // NOTE: may want to create a ProductionRunInventory tag for this later
              { type: 'NoPartInventory', id: 'LIST' },
            ]
          : [{ type: 'NoPartInventory', id: 'LIST' }],
    }),
    createPreviewProductionRunAllocationsForParts: builder.mutation<
      ProductionRunAllocationForPart[],
      {
        productionRunId: string;
        payload: ProductionRunAllocationForPartPayload;
      }
    >({
      query: ({ productionRunId, payload }) => ({
        url: URLS.PRODUCTION_RUN_PREVIEW_ALLOCATIONS(productionRunId),
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: () => [
        { type: 'ProductionRunAllocationsForPartPreview', id: 'LIST' },
        {
          type: 'ProductionRunAllocationsForPartPreview',
          id: 'productionRunId',
        },
      ],
    }),
    createProductionRunAllocationsForParts: builder.mutation<
      ProductionRunAllocationForPart[],
      {
        productionRunId: string;
        payload: ProductionRunAllocationForPartPayload;
      }
    >({
      query: ({ productionRunId, payload }) => ({
        url: URLS.PRODUCTION_RUN_ALLOCATIONS_FOR_PARTS(productionRunId),
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: (__, ___, { productionRunId }) => [
        { type: 'ProductionRunAllocationsForPart', id: 'LIST' },
        { type: 'ProductionRunAllocationsForPart', id: productionRunId },
        { type: 'PartInventory', id: 'LIST' },
        { type: 'NoPartInventory', id: 'LIST' },
        { type: 'ConsumeStockEvents', id: 'LIST' },
        { type: 'Allocation', id: 'PARTIAL-LIST' },
        { type: 'NoPartInventory', id: 'LIST' },
      ],
    }),
  }),
});

export const {
  useGetProductionRunsQuery,
  useDeleteProductionRunMutation,
  useGetProductionRunQuery,
  useLazyGetProductionRunQuery,
  useUpdateProductionRunMutation,
  useCreateProductionRunMutation,
  useDuplicateProductionRunMutation,
  useGetProductionRunPartsCoreQuery,
  useGetProductionRunConsumeStockEventsQuery,
  useCreateConsumeStockEventsForRunMutation,
  useGetProductionRunPartOverridesQuery,
  useCreateProductionRunPartOverrideMutation,
  useDeleteProductionRunPartOverrideMutation,
  useUpdateProductionRunPartOverrideMutation,
  useGetProductionRunLinesQuery,
  useGetProductionRunLineQuery,
  useCreateProductionRunLineMutation,
  useUpdateProductionRunLineMutation,
  useDeleteProductionRunLineMutation,
  useGetProductionRunAvailabilitiesWithKittingMutation,
  useCreateProductionRunAvailabilitiesSnapshotMutation,
  useGetProductionRunAllocationsQuery,
  useUpdateProductionRunAllocationsMutation,
  useDeleteProductionRunAllocationsMutation,
  useApproveProductionRunMutation,
  useUnapproveProductionRunMutation,
  useGetProductionRunDatesQuery,
  useGetTheoreticalProductionRunShipmentsQuery,
  useCreateProductionRunShipmentsMutation,
  useGetEstimateConsumptionQuery,
  useExecuteProductionRunMutation,
  useGetProductionRunInventoryQuery,
  useCreatePreviewProductionRunAllocationsForPartsMutation,
  useCreateProductionRunAllocationsForPartsMutation,
} = extendedApiSlice;
