import * as yup from 'yup';
import { ChipColor } from 'ui-component/extended/Chip';
import { TagModelOptions } from 'types/clientV2/tag';

export const createUpdateTagFormConstants = {
  name: {
    id: 'name',
    label: 'Tag Name',
  },
  color: {
    id: 'color',
    label: 'Tag Color',
  },
  modelOptions: {
    id: 'modelOptions',
    label: 'Data Types',
  },
  active: {
    id: 'active',
    label: 'Active',
  },
  team: {
    id: 'team',
    label: 'Team',
  },
};

export const createUpdateTagValidationSchema = yup.object({
  [createUpdateTagFormConstants.name.id]: yup
    .string()
    .required(`${createUpdateTagFormConstants.name.label} is required`),
  [createUpdateTagFormConstants.color.id]: yup
    .mixed<ChipColor>()
    .oneOf(Object.values(ChipColor)),
  [createUpdateTagFormConstants.modelOptions.id]: yup
    .array()
    .of(
      yup
        .mixed<TagModelOptions>()
        .oneOf(
          Object.values(TagModelOptions),
          `${
            createUpdateTagFormConstants.modelOptions.label
          } must be in ${Object.values(TagModelOptions).join(', ')}`
        )
    ),
  [createUpdateTagFormConstants.active.id]: yup
    .boolean()
    .required(`${createUpdateTagFormConstants.active.label} is required`),
  [createUpdateTagFormConstants.team.id]: yup.string().nullable(),
});
