import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  QueryDefinition,
} from '@reduxjs/toolkit/dist/query';
import { QueryActionCreatorResult } from '@reduxjs/toolkit/dist/query/core/buildInitiate';
import { GenericObject } from 'types';
import { UseMutation } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import { MutationDefinition } from '@reduxjs/toolkit/dist/query/react';

export enum QueryFilterOperators {
  '=' = '=',
  '>' = '>',
  '>=' = '>=',
  '<' = '<',
  '<=' = '<=',
  'is' = 'is',
  'equals' = 'equals',
  'after' = 'after',
  'onOrAfter' = 'onOrAfter',
  'before' = 'before',
  'onOrBefore' = 'onOrBefore',
  'contains' = 'contains',
  'startsWith' = 'startsWith',
  'endsWith' = 'endsWith',
  'isEmpty' = 'isEmpty',
  'isNotEmpty' = 'isNotEmpty',
  'isAnyOf' = 'isAnyOf',
  'not' = 'not',
  '!=' = '!=',
}

export const OperatorMap: Record<QueryFilterOperators, string> = {
  [QueryFilterOperators['=']]: '',
  [QueryFilterOperators['>']]: '__gt',
  [QueryFilterOperators['>=']]: '__gte',
  [QueryFilterOperators['<']]: '__lt',
  [QueryFilterOperators['<=']]: '__lte',
  [QueryFilterOperators.is]: '__iexact',
  [QueryFilterOperators.equals]: '__iexact',
  [QueryFilterOperators.after]: '__gt',
  [QueryFilterOperators.onOrAfter]: '__gte',
  [QueryFilterOperators.before]: '__lt',
  [QueryFilterOperators.onOrBefore]: '__lte',
  [QueryFilterOperators.contains]: '__icontains',
  [QueryFilterOperators.startsWith]: '__istartswith',
  [QueryFilterOperators.endsWith]: '__iendswith',
  [QueryFilterOperators.isEmpty]: '__isnull',
  [QueryFilterOperators.isNotEmpty]: '__isnull', // the apparent duplication is intentional, see the NullOperatorValues enum
  [QueryFilterOperators.isAnyOf]: '__in',
  [QueryFilterOperators.not]: '',
  [QueryFilterOperators['!=']]: '',
} as const;

export enum NullOperatorValues {
  'isEmpty' = 'true',
  'isNotEmpty' = 'false',
}

export enum OrderDirectionValues {
  asc = '',
  desc = '-',
}

export enum FilterLinkOperatorsValues {
  and = ',',
  or = '|',
}

export const ALL = '__all__';

/**
 * Use this constant to remove pagination limits entirely.
 * @warning This will fetch ALL records and may cause performance issues with large datasets.
 * Only use when you are certain about the maximum possible size of the result set.
 * With great power comes great responsibility.
 */
export const DISABLE_PAGE_LIMIT = 0;

type commaSeparatedString<T> = keyof T extends infer K
  ? K extends string
    ? K extends keyof T
      ? `${K}${Exclude<commaSeparatedStringExclude<T, K>, ''> extends never
          ? ''
          : ','}${Exclude<commaSeparatedStringExclude<T, K>, ''>}`
      : never
    : never
  : never;

type commaSeparatedStringExclude<T, U> = keyof T extends infer K
  ? K extends string
    ? U extends K
      ? never
      : K
    : never
  : never;

type SubSchema<T> = T extends (infer U)[]
  ? SubSchema<U>[]
  : T extends null | undefined
  ? SubSchema<NonNullable<T>>
  : T extends object
  ? keyof T | { [key in keyof T]?: SubSchema<T[key]>[] }
  : never;

export type NestedPath<T extends GenericObject> = {
  [K in keyof T]: T[K] extends GenericObject ? K | `${string & K}` : K;
}[keyof T];

/**
 * NestedPaths
 * Get all the possible paths of an object
 * @example
 * type Keys = NestedPaths<{ a: { b: { c: string } }>
 * // 'a' | 'a.b' | 'a.b.c'
 */
export type NestedPaths<T extends GenericObject> = {
  [K in keyof T]: T[K] extends (infer U)[]
    ? U extends GenericObject
      ? K | `${string & K}.${string & NestedPath<U>}`
      : K
    : T[K] extends GenericObject
    ? K | `${string & K}.${string & NestedPath<T[K]>}`
    : K;
}[keyof T];

export type NestedTypeField<T extends GenericObject> = keyof T | NestedPaths<T>;

/**
 * FieldFilter
 * @example
 * type FieldFilter = FieldFilter<StockLot>
 * // { field: 'id' | 'org' | 'status' | 'quant' | 'part.id' | 'part.mpn', operator: '=', value: any }
 */
export type FieldFilter<T extends GenericObject> = {
  field: NestedTypeField<T>;
  operator: keyof typeof QueryFilterOperators;
  value: unknown;
};

export interface QueryParams<T extends GenericObject> {
  pageSize?: number | typeof DISABLE_PAGE_LIMIT;
  pageNumber?: number;
  // order by can be any property of T for example QueryParams<StockLot> can be {order_by: 'quantity'}
  sort?: {
    orderBy: keyof T;
    orderDirection: keyof typeof OrderDirectionValues;
  }[];
  // filter is an array of objects with keys of T and any value
  filters?:
    | []
    | [FieldFilter<T>]
    | [
        FieldFilter<T>,
        ...(FieldFilter<T> | keyof typeof FilterLinkOperatorsValues)[],
        FieldFilter<T>
      ];
  excludes?:
    | []
    | [FieldFilter<T>]
    | [
        FieldFilter<T>,
        ...(FieldFilter<T> | keyof typeof FilterLinkOperatorsValues)[],
        FieldFilter<T>
      ];
  // schema is an array of strings or objects with keys of T and values of strings or objects with keys of T and values of strings
  schema?:
    | keyof T
    | (
        | keyof T
        | typeof ALL
        | {
            [key in keyof T]?: SubSchema<T[key]>[];
          }
      )[]
    | commaSeparatedString<T>;
  noCache?: boolean;
  newVersion?: boolean;
  blended?: boolean;
  search?: string;
  searchSchema?:
    | keyof T
    | (
        | keyof T
        | typeof ALL
        | {
            [key in keyof T]?: SubSchema<T[key]>[];
          }
      )[]
    | commaSeparatedString<T>;
  overrideOrgId?: string;
  exportBlob?: boolean;
}

export interface ListResponse<T> {
  count: number;
  pages: number;
  data: T[];
}

/**
 * Generic type for creating POST request schemas from base schemas.
 * Takes a base schema T and optional fields O to create a POST schema with:
 * - Optional fields specified by O (with Lite applied to object fields)
 * - All other fields excluded
 * - ID field always omitted
 * @example
 * // With only optional fields
 * type CreateKitRequest = POSTSchema<
 *   KitRequestSchema,
 *   never,  // no required fields
 *   'internalNotes' | 'externalNotes' | 'shipPartial'  // optional fields
 * >;
 *
 * // With both required and optional fields
 * type CreateKitRequest = POSTSchema<
 *   KitRequestSchema,
 *   'name',  // required fields
 *   'internalNotes' | 'externalNotes' | 'shipPartial'  // optional fields
 * >;
 */
export type POSTSchema<
  T,
  K extends Exclude<keyof T, 'id'> = never,
  O extends Exclude<keyof T, K | 'id'> = never
> = Required<Lite<Pick<T, K>>> & Partial<Lite<Pick<T, O>>>;

export type PATCHSchema<T> = Partial<T> & {
  id: string;
};

export interface GenericResource {
  id: string;
}

export type ClientV2POSTRequest<T> = T | T[];

export type ClientV2PATCHRequest<T extends GenericResource> =
  | Partial<T>
  | Partial<T>[];

export type ClientV2DELETERequest<T extends GenericResource> = {
  ids: T['id'][];
};

export interface ClientV2POSTResponse<T extends GenericResource> {
  createdIds: T['id'][];
}

export interface ClientV2PATCHResponse<T extends GenericResource> {
  updatedIds: T['id'][];
  nonUpdatedIds: T['id'][];
}

export interface ClientV2DELETEResponse<T extends GenericResource> {
  deletedIds: T['id'][];
}

export interface ClientV2ToggleResponse<T extends GenericResource> {
  updatedIds: T['id'][];
  nonUpdatedIds: T['id'][];
}

export interface ClientV2BulkActionRequest<T extends GenericResource> {
  ids: T['id'][];
}

// Used for Approve/Unapprove, Lock/Unlock, Archive/Unarchive request bodies
export interface ClientV2ToggleRequest<T extends GenericResource> {
  ids: T['id'][];
}

export interface AsyncJob {
  id: string;
}

export enum AsyncJobStatus {
  starting = 'starting',
  processing = 'processing',
  done = 'done',
  error = 'error',
}

export interface AsyncJobResponse<T> {
  jobId: string;
  status: AsyncJobStatus;
  data: T;
}

/**
 * Transforms a type by replacing object properties with their string representation, typically used for ID fields.
 * If the property is an array of objects, it replaces the array with an array of strings.
 *
 * The transformation is done as follows:
 * - If a property is an object and not part of the keys specified in `K`, it is replaced with a string.
 * - If a property is an array of objects and not part of the keys specified in `K`, it is replaced with an array of strings.
 * - If a property is an array but not of objects, it remains the same.
 * - All other properties remain the same.
 * - If a property key is part of `K`, it will not be transformed and will retain its original type.
 *
 * This is useful when you want to simplify complex objects or arrays of objects to their ID representations which is often how Flagship will represent its query schema.
 *
 * @example
 * type AllocationQuerySchema = { id: string, org: Org, stockLot: { id: string, name: string }, productionRun: { id: string, name: string }[] };
 * type SimplifiedAllocationQuerySchema = Lite<AllocationQuerySchema, 'stockLot'>; // { id: string, org: string, stockLot: { id: string, name: string }, productionRun: string[] }
 *
 * @example
 * type AllocationQuerySchemaWithArray = { id: string, org: Org, stockLot: { id: string, name: string }[], productionRun: { id: string, name: string }[] };
 * type SimplifiedAllocationQuerySchemaWithArray = Lite<AllocationQuerySchemaWithArray, 'stockLot'>; // { id: string, org: string, stockLot: { id: string, name: string }[], productionRun: string[] }
 */
export type Lite<T, K extends keyof T = never> = {
  [P in keyof T]: P extends K
    ? T[P]
    : T[P] extends Array<infer U>
    ? U extends object
      ? string[]
      : T[P]
    : T[P] extends object
    ? string
    : T[P] extends object | null
    ? string | null
    : T[P] extends object | undefined
    ? string | undefined
    : T[P] extends object | null | undefined
    ? string | null | undefined
    : T[P];
};

/**
 * `PropertySubsetValidator` is a utility type that checks if a given type `T` can be represented as a subset of its own properties specified by `K`.
 *
 * It takes two type parameters:
 * - `T`: The original type.
 * - `K`: The keys of `T` that we want to pick.
 *
 * If `T` can be represented as a `Pick<T, K>`, then `T` is returned; otherwise, `never` is returned.
 *
 * @example
 * type User = { name: string, age: number };
 * type ValidUserProps = PropertySubsetValidator<User, 'name'>; // This will be User if 'name' exists in User, otherwise never.
 *
 * // Usage in function parameters to ensure correct type:
 * function doSomething(
 *   allocations:
 *     | PropertySubsetValidator<AllocationQuerySchema, 'name'>[]
 *     | PropertySubsetValidator<AllocationCRUDSchema, 'name'>[]
 * ) {
 *   return allocations.filter((allocation) => allocation.name === 'test');
 * }
 *
 * const allocation = [{ name: 'hi' }] as AllocationQuerySchema[];
 * const relatedRecord = [{ name: 'hi' }] as RelatedRecord[];
 *
 * doSomething(allocation); // valid
 * doSomething(relatedRecord); // TS2345: Argument of type RelatedRecord[] is not assignable to parameter of type AllocationCRUDSchema[] | AllocationQuerySchema[]
 */
export type PropertySubsetValidator<T, K extends keyof T> = Pick<T, K> &
  Partial<Record<Exclude<keyof T, K>, any>>;

export type RefetchQuery<Request, Response> = QueryActionCreatorResult<
  QueryDefinition<
    Request,
    BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError>,
    string,
    Response,
    string
  >
>;

export type ToggleMutation<T extends GenericResource> = UseMutation<
  MutationDefinition<
    ClientV2ToggleRequest<T>,
    BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError>,
    string,
    ClientV2ToggleResponse<T>
  >
>;

export type DeleteMutation<T extends GenericResource> = UseMutation<
  MutationDefinition<
    ClientV2DELETERequest<T>,
    BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError>,
    string,
    ClientV2DELETEResponse<T>
  >
>;
