import { handleErr } from 'utils/functions';
import { useLazyGetEntitlementsQuery } from 'store/slices/apiV1/accessEntitlements';
import { useSelector } from 'store';
import { useEffect } from 'react';

export function useGetEntitlements() {
  const { activeOrgId } = useSelector((state) => state.org);
  const { blended } = useSelector((state) => state.org);

  const [
    getEntitlements,
    { data: metronomeEntitlements, isLoading: isLoadingMetronomeEntitlements },
  ] = useLazyGetEntitlementsQuery();

  const fetchEntitlements = async () => {
    try {
      await getEntitlements().unwrap();
    } catch (err) {
      handleErr(err, (__: string) => {});
    }
  };

  // If the feature flag is active, request entitlements upon active org change or blended change
  useEffect(() => {
    fetchEntitlements();
  }, [activeOrgId, blended]);

  return {
    metronomeEntitlements,
    isLoadingMetronomeEntitlements,
  };
}
