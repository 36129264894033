import { useEffect, useState } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  useCreatePurchaseRuleMutation,
  useUpdatePurchaseRuleMutation,
} from 'store/slices/apiV1/inventory';
import {
  HookFormInput,
  HookFormToggle,
  HookFormSelect,
  HookQuerySearchSelect,
} from 'ui-component/HookFormComponents';
import { ExtendedDialog } from 'ui-component/extended/ExtendedDialog';
import { PurchaseRule } from 'types/inventory';
import {
  formConstants,
  validationSchema,
  getCopy,
} from 'views/suppliers/CreateEditPurchaseRuleDialog/constants';
import { handleErr } from 'utils/functions';
import useSnackbar from 'hooks/useSnackbar';
import { OrgSupplier } from 'types/suppliers';
import { useGetOrgSuppliersServerSideQuery } from 'store/slices/apiV1/suppliers';

const CreateEditPurchaseRuleDialog = ({
  dialogOpen,
  onClose,
  selectedPurchaseRule,
  onCreateSuccess,
}: {
  dialogOpen: boolean;
  onClose: () => void;
  selectedPurchaseRule?: PurchaseRule | null;
  onCreateSuccess?: (purchaseRule: PurchaseRule) => void;
}) => {
  const copy = getCopy(!!selectedPurchaseRule);
  const [submitError, setSubmitError] = useState<string | null>(null);

  const { dispatchSuccessSnackbar } = useSnackbar();
  const [createPurchaseRule, { isLoading: isCreatingPurchaseRule }] =
    useCreatePurchaseRuleMutation();

  const [updatePurchaseRule, { isLoading: isUpdatingPurchaseRule }] =
    useUpdatePurchaseRuleMutation();

  const initialFormValues = {
    [formConstants.name.id]: selectedPurchaseRule?.name || '',
    [formConstants.authorizedOnly.id]:
      selectedPurchaseRule?.authorizedOnly || false,
    [formConstants.requiredCertifications.id]:
      selectedPurchaseRule?.requiredCertifications || [],
    [formConstants.allowedOrgSuppliers.id]:
      Array.isArray(selectedPurchaseRule?.allowedOrgSuppliers) &&
      typeof selectedPurchaseRule?.allowedOrgSuppliers[0] === 'object'
        ? selectedPurchaseRule?.allowedOrgSuppliers.map((supplier) =>
            typeof supplier === 'string' ? supplier : supplier.id
          )
        : selectedPurchaseRule?.allowedOrgSuppliers || [],
    [formConstants.preferredOrgSuppliers.id]:
      Array.isArray(selectedPurchaseRule?.preferredOrgSuppliers) &&
      typeof selectedPurchaseRule?.preferredOrgSuppliers[0] === 'object'
        ? selectedPurchaseRule?.preferredOrgSuppliers.map((supplier) =>
            typeof supplier === 'string' ? supplier : supplier.id
          )
        : selectedPurchaseRule?.preferredOrgSuppliers || [],
    [formConstants.lastResortOrgSuppliers.id]:
      Array.isArray(selectedPurchaseRule?.lastResortOrgSuppliers) &&
      typeof selectedPurchaseRule?.lastResortOrgSuppliers[0] === 'object'
        ? selectedPurchaseRule?.lastResortOrgSuppliers.map((supplier) =>
            typeof supplier === 'string' ? supplier : supplier.id
          )
        : selectedPurchaseRule?.lastResortOrgSuppliers || [],
    [formConstants.bannedOrgSuppliers.id]:
      Array.isArray(selectedPurchaseRule?.bannedOrgSuppliers) &&
      typeof selectedPurchaseRule?.bannedOrgSuppliers[0] === 'object'
        ? selectedPurchaseRule?.bannedOrgSuppliers.map((supplier) =>
            typeof supplier === 'string' ? supplier : supplier.id
          )
        : selectedPurchaseRule?.bannedOrgSuppliers || [],
    [formConstants.isDefaultPurchaseRule.id]:
      selectedPurchaseRule?.isDefaultPurchaseRule || false,
  };

  const {
    control,
    reset,
    formState: { errors },
    handleSubmit,
  } = useForm<FieldValues>({
    defaultValues: initialFormValues,
    resolver: yupResolver(validationSchema),
    mode: 'all',
  });

  useEffect(() => {
    reset(initialFormValues);
  }, [dialogOpen, selectedPurchaseRule?.id]);

  const handleClose = () => {
    onClose();
    reset();
  };

  const onSubmit = async (payload: FieldValues) => {
    try {
      if (selectedPurchaseRule) {
        await updatePurchaseRule({
          id: selectedPurchaseRule.id,
          payload,
        }).unwrap();
      } else {
        const createdPurchaseRule = await createPurchaseRule(payload).unwrap();
        onCreateSuccess && onCreateSuccess(createdPurchaseRule);
      }
      dispatchSuccessSnackbar(copy.success);

      handleClose();
    } catch (err) {
      handleErr(err, (errMessage: string) => {
        setSubmitError(errMessage);
      });
    }
  };

  return (
    <ExtendedDialog
      open={dialogOpen}
      maxWidth="sm"
      title={copy.title}
      isForm
      onCloseDialog={handleClose}
      onSubmit={handleSubmit(onSubmit)}
      formSubmitError={submitError}
      submitButtonCopy={copy.submit}
      isSubmitting={isCreatingPurchaseRule || isUpdatingPurchaseRule}
    >
      <HookFormInput
        label={formConstants.name.label}
        name={formConstants.name.id}
        errors={errors}
        control={control}
      />
      <HookFormToggle
        label={formConstants.authorizedOnly.label}
        name={formConstants.authorizedOnly.id}
        errors={errors}
        control={control}
        sx={{ mb: 2 }}
      />
      <HookFormToggle
        label={formConstants.isDefaultPurchaseRule.label}
        name={formConstants.isDefaultPurchaseRule.id}
        errors={errors}
        control={control}
        sx={{ mb: 2 }}
      />
      <HookFormSelect
        label={formConstants.requiredCertifications.label}
        name={formConstants.requiredCertifications.id}
        errors={errors}
        control={control}
        multiple
        options={[
          { label: 'AS9120', value: 'AS9120' },
          { label: 'CFR21', value: 'CFR21' },
        ]}
        sx={{ mb: 2 }}
      />
      <HookQuerySearchSelect<OrgSupplier>
        label={formConstants.allowedOrgSuppliers.label}
        name={formConstants.allowedOrgSuppliers.id}
        useGetQuery={useGetOrgSuppliersServerSideQuery}
        errors={errors}
        control={control}
        schema={['name']}
        multiple
        getOptionLabel={(option: OrgSupplier) => option.name}
      />
      <HookQuerySearchSelect<OrgSupplier>
        label={formConstants.preferredOrgSuppliers.label}
        name={formConstants.preferredOrgSuppliers.id}
        useGetQuery={useGetOrgSuppliersServerSideQuery}
        errors={errors}
        control={control}
        schema={['name']}
        multiple
        getOptionLabel={(option: OrgSupplier) => option.name}
      />
      <HookQuerySearchSelect<OrgSupplier>
        label={formConstants.lastResortOrgSuppliers.label}
        name={formConstants.lastResortOrgSuppliers.id}
        useGetQuery={useGetOrgSuppliersServerSideQuery}
        errors={errors}
        control={control}
        schema={['name']}
        multiple
        getOptionLabel={(option: OrgSupplier) => option.name}
      />
      <HookQuerySearchSelect<OrgSupplier>
        label={formConstants.bannedOrgSuppliers.label}
        name={formConstants.bannedOrgSuppliers.id}
        useGetQuery={useGetOrgSuppliersServerSideQuery}
        errors={errors}
        control={control}
        schema={['name']}
        multiple
        getOptionLabel={(option: OrgSupplier) => option.name}
      />
    </ExtendedDialog>
  );
};

export default CreateEditPurchaseRuleDialog;
