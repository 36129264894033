import { useFeature } from '@growthbook/growthbook-react';
import { UseMutation } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import { useDocumentTemplates } from 'hooks/useDocumentTemplates';
import useSnackbar from 'hooks/useSnackbar';
import { useState } from 'react';
import { useGeneratePurchaseOrderPdfMutation } from 'store/slices/apiV1/procurement';
import { FeatureFlags } from 'types';
import { PermissionScope } from 'types/apps';
import {
  DocumentTemplate,
  Shipment,
  StockDocType,
  StockDocument,
  StockLotCRUDSchema,
} from 'types/inventory';
import { handleErr } from 'utils/functions';
import AccordionCard from 'ui-component/cards/AccordionCard';
import { CardContent, Grid, MenuItem, Select, Typography } from '@mui/material';
import { gridSpacing } from 'constants/themeConstants';
import MainCard from 'ui-component/cards/MainCard';
import UploadStockDocsWidget from 'ui-component/UploadStockDocsWidget';
import SegmentLoadingButton from 'ui-component/Segment/SegmentLoadingButton';
import { IconDeviceFloppy } from '@tabler/icons-react';
import { PurchaseLite } from 'types/purchasing';
import { BomLite } from 'types/bom';
import { ProductionRun } from 'types/production';
import { KitRequestSchema } from 'types/kitting';
import _ from 'lodash';
import { SupplierBillSchema } from 'types/procurement';
import { OrgSupplier } from 'types/suppliers';
import { RfqSchema, SupplierQuoteSchema } from 'types/sourcing';
import { PurchaseRequestGroupSchema } from 'types/clientV2/purchaseRequests';

const UploadGenerateDocuments = ({
  stockLotId,
  purchaseId,
  kitRequestId,
  bomId,
  productionRunId,
  shipmentId,
  supplierBillId,
  orgSupplierId,
  rfqId,
  supplierQuoteId,
  purchaseRequestGroupId,
  generateDocumentType,
  defaultDocumentTemplateId,
  useGenerateMutation,
  uploadPermissionScope,
  additionalGenerateProps,
  allowUpload,
  setSelectedDocumentId,
  processDocument = false,
  cardTitle,
}: {
  stockLotId?: StockLotCRUDSchema['id'];
  purchaseId?: PurchaseLite['id'];
  bomId?: BomLite['id'];
  productionRunId?: ProductionRun['id'];
  kitRequestId?: KitRequestSchema['id'];
  shipmentId?: Shipment['id'];
  supplierBillId?: SupplierBillSchema['id'];
  orgSupplierId?: OrgSupplier['id'];
  rfqId?: RfqSchema['id'];
  supplierQuoteId?: SupplierQuoteSchema['id'];
  purchaseRequestGroupId?: PurchaseRequestGroupSchema['id'];
  generateDocumentType?: StockDocType;
  defaultDocumentTemplateId?: DocumentTemplate['id'] | null;
  useGenerateMutation?: UseMutation<any>;
  uploadPermissionScope?: PermissionScope;
  additionalGenerateProps?: object;
  allowUpload?: boolean;
  processDocument?: boolean;
  setSelectedDocumentId?: (id: string) => void;
  cardTitle?: string;
}) => {
  const { dispatchSuccessSnackbar, dispatchErrorSnackbar } = useSnackbar();
  const tempDocumentGeneration = useFeature(
    FeatureFlags.tempDocumentGeneration
  ).on;

  const useMutation =
    useGenerateMutation || useGeneratePurchaseOrderPdfMutation;

  const [generatePdf, { isLoading: isLoadingGeneratePdf }] = useMutation();

  const {
    allDocumentTemplates,
    defaultDocumentTemplates,
    groupedDocumentTemplates,
  } = useDocumentTemplates();

  const defaultDocumentTemplate = defaultDocumentTemplateId
    ? _.find(allDocumentTemplates, { id: defaultDocumentTemplateId }) ||
      _.get(defaultDocumentTemplates, generateDocumentType || '', null)
    : _.get(defaultDocumentTemplates, generateDocumentType || '', null);

  const [selectedDocumentTemplateId, setSelectedDocumentTemplateId] = useState<
    DocumentTemplate['id']
  >(defaultDocumentTemplate?.id || '');

  const onGeneratePdf = async () => {
    if (!selectedDocumentTemplateId) return;
    try {
      const record = (await generatePdf({
        ...(additionalGenerateProps || {}),
        payload: {
          documentTemplate: selectedDocumentTemplateId,
        },
      }).unwrap()) as StockDocument;
      setSelectedDocumentId && setSelectedDocumentId(record?.id);
      dispatchSuccessSnackbar('Document Generated');
    } catch (err) {
      handleErr(err, (errMessage: string) => {
        dispatchErrorSnackbar(errMessage);
      });
    }
  };

  return (
    <>
      {/* Upload or Generate Document */}
      {useGenerateMutation && allowUpload && tempDocumentGeneration && (
        <AccordionCard
          defaultExpanded={false}
          title={cardTitle || 'Upload or Generate Documents'}
        >
          <Grid container spacing={gridSpacing}>
            <Grid item xs={6}>
              <MainCard content={false}>
                <CardContent>
                  <Grid container spacing={gridSpacing}>
                    <Grid item xs={12}>
                      <Typography variant="h4">Upload Documents</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <UploadStockDocsWidget
                        stockLotId={stockLotId}
                        purchaseId={purchaseId}
                        bomId={bomId}
                        productionRunId={productionRunId}
                        shipmentId={shipmentId}
                        processDocument={processDocument}
                        kitRequestId={kitRequestId}
                        supplierBillId={supplierBillId}
                        orgSupplierId={orgSupplierId}
                        rfqId={rfqId}
                        supplierQuoteId={supplierQuoteId}
                        purchaseRequestGroupId={purchaseRequestGroupId}
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </MainCard>
            </Grid>
            <Grid item xs={6}>
              <MainCard content={false}>
                <CardContent>
                  <Grid
                    container
                    spacing={gridSpacing}
                    justifyContent="flex-end"
                  >
                    <Grid item xs={12}>
                      <Typography variant="h4">Generate Document</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Select
                        defaultValue={defaultDocumentTemplate?.id}
                        value={selectedDocumentTemplateId}
                        onChange={(e) => {
                          setSelectedDocumentTemplateId(e.target.value);
                        }}
                        fullWidth
                      >
                        {_.get(
                          groupedDocumentTemplates,
                          generateDocumentType || '',
                          []
                        ).map((option) => (
                          <MenuItem key={option.id} value={option.id}>
                            {option.externalName || option.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </Grid>
                    <Grid item xs="auto">
                      <SegmentLoadingButton
                        loading={isLoadingGeneratePdf}
                        variant="contained"
                        size="medium"
                        color="primary"
                        disabled={!selectedDocumentTemplateId}
                        onClick={onGeneratePdf}
                        permissionScope={uploadPermissionScope}
                        startIcon={<IconDeviceFloppy />}
                      >
                        Generate PDF
                      </SegmentLoadingButton>
                    </Grid>
                  </Grid>
                </CardContent>
              </MainCard>
            </Grid>
          </Grid>
        </AccordionCard>
      )}
      {/* Upload Document */}
      {!(useGenerateMutation && tempDocumentGeneration) && allowUpload && (
        <AccordionCard
          defaultExpanded={false}
          title={cardTitle || 'Upload Documents'}
        >
          <UploadStockDocsWidget
            stockLotId={stockLotId}
            purchaseId={purchaseId}
            bomId={bomId}
            kitRequestId={kitRequestId}
            productionRunId={productionRunId}
            shipmentId={shipmentId}
            processDocument={processDocument}
            supplierBillId={supplierBillId}
            orgSupplierId={orgSupplierId}
            rfqId={rfqId}
            supplierQuoteId={supplierQuoteId}
            purchaseRequestGroupId={purchaseRequestGroupId}
          />
        </AccordionCard>
      )}
    </>
  );
};

export default UploadGenerateDocuments;
