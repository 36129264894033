import {
  apiSlice,
  createClientV2QueryFn,
  createClientV2MutationFn,
} from 'store/slices/api';
import { parseQueryParams } from 'store/slices/utils';
import {
  ClientV2BulkActionRequest,
  ClientV2DELETERequest,
  ClientV2DELETEResponse,
  ClientV2PATCHRequest,
  ClientV2PATCHResponse,
  ClientV2POSTRequest,
  ClientV2POSTResponse,
  ClientV2ToggleRequest,
  ClientV2ToggleResponse,
  ListResponse,
  QueryParams,
} from 'types/api';
import {
  KitRequestLinePOSTSchema,
  KitRequestLineSchema,
  KitRequestPATCHSchema,
  KitRequestPOSTSchema,
  KitRequestSchema,
} from 'types/kitting';
import { PrintLabelRequest } from 'types/inventory';
import { CLIENT_V2_URLS } from 'store/slices/constants/clientV2';

export const extendedApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getKitRequests: builder.query<
      ListResponse<KitRequestSchema>,
      QueryParams<KitRequestSchema>
    >({
      queryFn: createClientV2QueryFn<
        ListResponse<KitRequestSchema>,
        QueryParams<KitRequestSchema>
      >((params) =>
        CLIENT_V2_URLS.KIT_REQUESTS(parseQueryParams<KitRequestSchema>(params))
      ),
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(
                ({ id }: KitRequestSchema) =>
                  ({
                    type: 'KitRequests',
                    id,
                  } as const)
              ),
              { type: 'KitRequests', id: 'LIST' },
            ]
          : [{ type: 'KitRequests', id: 'LIST' }],
    }),
    createKitRequests: builder.mutation<
      ClientV2POSTResponse<KitRequestSchema>,
      ClientV2POSTRequest<KitRequestPOSTSchema>
    >({
      queryFn: createClientV2MutationFn<
        ClientV2POSTResponse<KitRequestSchema>,
        ClientV2POSTRequest<KitRequestPOSTSchema>
      >((payload) => ({
        url: CLIENT_V2_URLS.KIT_REQUESTS(),
        method: 'POST',
        body: payload,
      })),
      invalidatesTags: [{ type: 'KitRequests', id: 'LIST' }, 'PreviewZpl'],
    }),
    updateKitRequests: builder.mutation<
      ClientV2PATCHResponse<KitRequestSchema>,
      ClientV2PATCHRequest<KitRequestPATCHSchema>
    >({
      query: (data) => ({
        url: CLIENT_V2_URLS.KIT_REQUESTS(),
        method: 'PATCH',
        body: data,
      }),
      invalidatesTags: [{ type: 'KitRequests', id: 'LIST' }, 'PreviewZpl'],
    }),
    deleteKitRequests: builder.mutation<
      ClientV2DELETEResponse<KitRequestSchema>,
      ClientV2DELETERequest<KitRequestSchema>
    >({
      query: (data) => ({
        url: CLIENT_V2_URLS.KIT_REQUESTS(),
        method: 'DELETE',
        body: data,
      }),
      invalidatesTags: [{ type: 'KitRequests', id: 'LIST' }],
    }),
    approveKitRequest: builder.mutation<
      ClientV2ToggleResponse<KitRequestSchema>,
      ClientV2ToggleRequest<KitRequestSchema>
    >({
      query: (data) => ({
        url: CLIENT_V2_URLS.KIT_REQUESTS_APPROVE(),
        method: 'POST',
        body: data,
      }),
      invalidatesTags: [
        { type: 'KitRequests', id: 'LIST' },
        { type: 'KitRequestLines', id: 'LIST' },
        { type: 'ClientV2Allocations', id: 'LIST' },
        { type: 'ClientV2HandlingRequests', id: 'LIST' },
      ],
    }),
    unapproveKitRequest: builder.mutation<
      ClientV2ToggleResponse<KitRequestSchema>,
      ClientV2ToggleRequest<KitRequestSchema>
    >({
      query: (data) => ({
        url: CLIENT_V2_URLS.KIT_REQUESTS_UNAPPROVE(),
        method: 'POST',
        body: data,
      }),
      invalidatesTags: [
        { type: 'KitRequests', id: 'LIST' },
        { type: 'KitRequestLines', id: 'LIST' },
        { type: 'ClientV2Allocations', id: 'LIST' },
        { type: 'ClientV2HandlingRequests', id: 'LIST' },
      ],
    }),
    archiveKitRequest: builder.mutation<
      ClientV2ToggleResponse<KitRequestSchema>,
      ClientV2ToggleRequest<KitRequestSchema>
    >({
      query: (data) => ({
        url: CLIENT_V2_URLS.KIT_REQUESTS_ARCHIVE(),
        method: 'POST',
        body: data,
      }),
      invalidatesTags: [{ type: 'KitRequests', id: 'LIST' }],
    }),
    unarchiveKitRequest: builder.mutation<
      ClientV2ToggleResponse<KitRequestSchema>,
      ClientV2ToggleRequest<KitRequestSchema>
    >({
      query: (data) => ({
        url: CLIENT_V2_URLS.KIT_REQUESTS_UNARCHIVE(),
        method: 'POST',
        body: data,
      }),
      invalidatesTags: [{ type: 'KitRequests', id: 'LIST' }],
    }),
    printKitRequestLabel: builder.mutation<
      KitRequestSchema,
      { kitRequestId: KitRequestSchema['id']; payload: PrintLabelRequest }
    >({
      query: ({ kitRequestId, payload }) => ({
        url: CLIENT_V2_URLS.KIT_REQUEST_PRINT_LABEL(kitRequestId),
        method: 'POST',
        body: payload,
      }),
    }),
    getKitRequestLines: builder.query<
      ListResponse<KitRequestLineSchema>,
      QueryParams<KitRequestLineSchema>
    >({
      query: (params) => ({
        url: CLIENT_V2_URLS.KIT_REQUEST_LINES(
          parseQueryParams<KitRequestLineSchema>(params)
        ),
        method: 'GET',
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(
                ({ id }: KitRequestLineSchema) =>
                  ({
                    type: 'KitRequestLines',
                    id,
                  } as const)
              ),
              { type: 'KitRequestLines', id: 'LIST' },
            ]
          : [{ type: 'KitRequestLines', id: 'LIST' }],
    }),
    createKitRequestLines: builder.mutation<
      ClientV2POSTResponse<KitRequestLineSchema>,
      ClientV2POSTRequest<KitRequestLinePOSTSchema>
    >({
      query: (data) => ({
        url: CLIENT_V2_URLS.KIT_REQUEST_LINES(),
        method: 'POST',
        body: data,
      }),
      invalidatesTags: [
        { type: 'KitRequestLines', id: 'LIST' },
        { type: 'BomLines', id: 'LIST' },
        { type: 'ProductionRunLines', id: 'LIST' },
      ],
    }),
    updateKitRequestLines: builder.mutation<
      ClientV2PATCHResponse<KitRequestLineSchema>,
      ClientV2PATCHRequest<KitRequestLineSchema>
    >({
      query: (data) => ({
        url: CLIENT_V2_URLS.KIT_REQUEST_LINES(),
        method: 'PATCH',
        body: data,
      }),
      invalidatesTags: [
        { type: 'KitRequestLines', id: 'LIST' },
        { type: 'BomLines', id: 'LIST' },
        { type: 'ProductionRunLines', id: 'LIST' },
      ],
    }),
    deleteKitRequestLines: builder.mutation<
      ClientV2DELETEResponse<KitRequestLineSchema>,
      ClientV2DELETERequest<KitRequestLineSchema>
    >({
      query: (data) => ({
        url: CLIENT_V2_URLS.KIT_REQUEST_LINES(),
        method: 'DELETE',
        body: data,
      }),
      invalidatesTags: [{ type: 'KitRequestLines', id: 'LIST' }],
    }),
    lockKitRequestLines: builder.mutation<
      ClientV2ToggleResponse<KitRequestLineSchema>,
      ClientV2ToggleRequest<KitRequestLineSchema>
    >({
      query: (data) => ({
        url: CLIENT_V2_URLS.KIT_REQUEST_LINES_LOCK(),
        method: 'POST',
        body: data,
      }),
      invalidatesTags: [{ type: 'KitRequestLines', id: 'LIST' }],
    }),
    unlockKitRequestLines: builder.mutation<
      ClientV2ToggleResponse<KitRequestLineSchema>,
      ClientV2ToggleRequest<KitRequestLineSchema>
    >({
      query: (data) => ({
        url: CLIENT_V2_URLS.KIT_REQUEST_LINES_UNLOCK(),
        method: 'POST',
        body: data,
      }),
      invalidatesTags: [{ type: 'KitRequestLines', id: 'LIST' }],
    }),
    recalculateKitRequestLineAllocations: builder.mutation<
      ClientV2PATCHResponse<KitRequestLineSchema>,
      ClientV2BulkActionRequest<KitRequestLineSchema>
    >({
      query: (data) => ({
        url: CLIENT_V2_URLS.RECALCULATE_KIT_REQUEST_LINE_ALLOCATIONS,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: [
        { type: 'KitRequestLines', id: 'LIST' },
        { type: 'ClientV2Allocations', id: 'LIST' },
        { type: 'ClientV2HandlingRequests', id: 'LIST' },
      ],
    }),
  }),
});

export const {
  useGetKitRequestsQuery,
  useCreateKitRequestsMutation,
  useUpdateKitRequestsMutation,
  useDeleteKitRequestsMutation,
  useApproveKitRequestMutation,
  useUnapproveKitRequestMutation,
  useArchiveKitRequestMutation,
  useUnarchiveKitRequestMutation,
  useGetKitRequestLinesQuery,
  useCreateKitRequestLinesMutation,
  useUpdateKitRequestLinesMutation,
  useDeleteKitRequestLinesMutation,
  useLockKitRequestLinesMutation,
  useUnlockKitRequestLinesMutation,
  useRecalculateKitRequestLineAllocationsMutation,
  usePrintKitRequestLabelMutation,
  usePrefetch,
} = extendedApiSlice;
