import {
  apiSlice,
  createClientV2MutationFn,
  createClientV2QueryFn,
} from 'store/slices/api';
import { parseQueryParams } from 'store/slices/utils';
import {
  ClientV2BulkActionRequest,
  ClientV2PATCHResponse,
  ClientV2POSTRequest,
  ClientV2POSTResponse,
  ListResponse,
  QueryParams,
} from 'types/api';

import {
  HandlingRequestPOSTPickSchema,
  HandlingRequestPOSTSchema,
  HandlingRequestPOSTSpliceCombineSchema,
  HandlingRequestSchema,
} from 'types/clientV2/stock';
import { CLIENT_V2_URLS } from 'store/slices/constants/clientV2';

export const extendedApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getHandlingRequestsV2: builder.query<
      ListResponse<HandlingRequestSchema>,
      QueryParams<HandlingRequestSchema>
    >({
      queryFn: createClientV2QueryFn<
        ListResponse<HandlingRequestSchema>,
        QueryParams<HandlingRequestSchema>
      >((params) =>
        CLIENT_V2_URLS.HANDLING_REQUESTS(
          parseQueryParams<HandlingRequestSchema>(params)
        )
      ),
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(
                ({ id }: HandlingRequestSchema) =>
                  ({
                    type: 'ClientV2HandlingRequests',
                    id,
                  } as const)
              ),
              { type: 'ClientV2HandlingRequests', id: 'LIST' },
            ]
          : [{ type: 'ClientV2HandlingRequests', id: 'LIST' }],
    }),
    createHandlingRequests: builder.mutation<
      ClientV2POSTResponse<HandlingRequestSchema>,
      ClientV2POSTRequest<HandlingRequestPOSTSchema>
    >({
      queryFn: createClientV2MutationFn<
        ClientV2POSTResponse<HandlingRequestSchema>,
        ClientV2POSTRequest<HandlingRequestPOSTSchema>
      >((data) => ({
        url: CLIENT_V2_URLS.HANDLING_REQUESTS(),
        method: 'POST',
        body: data,
      })),
      invalidatesTags: [
        { type: 'ClientV2HandlingRequests', id: 'LIST' },
        { type: 'HandlingRequests', id: 'LIST' }, // Temporarily included for backwards compatibility
        { type: 'HandlingTaskRows', id: 'LIST' }, // Temporarily included for backwards compatibility
        { type: 'StockLot', id: 'LIST' },
        { type: 'StockLot', id: 'PARTIAL-LIST' },
      ],
    }),
    createSpliceCombineHandlingRequests: builder.mutation<
      ClientV2POSTResponse<HandlingRequestSchema>,
      ClientV2POSTRequest<HandlingRequestPOSTSpliceCombineSchema>
    >({
      query: (data) => ({
        url: CLIENT_V2_URLS.CREATE_SPLICE_COMBINE_HANDLING_REQUESTS,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: [
        { type: 'ClientV2HandlingRequests', id: 'LIST' },
        { type: 'HandlingRequests', id: 'LIST' }, // Temporarily included for backwards compatibility
        { type: 'HandlingTaskRows', id: 'LIST' }, // Temporarily included for backwards compatibility
        { type: 'StockLot', id: 'LIST' },
        { type: 'StockLot', id: 'PARTIAL-LIST' },
      ],
    }),
    cancelHandlingRequests: builder.mutation<
      ClientV2POSTResponse<HandlingRequestSchema>,
      ClientV2BulkActionRequest<HandlingRequestSchema>
    >({
      query: (data) => ({
        url: CLIENT_V2_URLS.CANCEL_HANDLING_REQUESTS,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: [
        { type: 'ClientV2HandlingRequests', id: 'LIST' },
        { type: 'HandlingRequests', id: 'LIST' }, // Temporarily included for backwards compatibility
        { type: 'HandlingTaskRows', id: 'LIST' }, // Temporarily included for backwards compatibility
        { type: 'StockLot', id: 'LIST' },
        { type: 'StockLot', id: 'PARTIAL-LIST' },
      ],
    }),
    pickedHandlingRequests: builder.mutation<
      ClientV2PATCHResponse<HandlingRequestSchema>,
      ClientV2POSTRequest<HandlingRequestPOSTPickSchema>
    >({
      query: (data) => ({
        url: CLIENT_V2_URLS.PICKED_HANDLING_REQUESTS,
        method: 'POST',
        body: data,
      }),
    }),
  }),
});

export const {
  useGetHandlingRequestsV2Query,
  useCreateHandlingRequestsMutation,
  useCreateSpliceCombineHandlingRequestsMutation,
  useCancelHandlingRequestsMutation,
  usePickedHandlingRequestsMutation,
} = extendedApiSlice;
