import {
  ListItemIcon,
  MenuItem,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useState } from 'react';
import { Control, FieldValues, UseFormSetValue } from 'react-hook-form';
import { useSelector } from 'store';
import { useGetOrgQuery } from 'store/slices/apiV1/org';
import { GenericObject } from 'types';
import { StockLocation } from 'types/inventory';
import { HookFormComponentProps } from 'ui-component/HookFormComponents/types';
import { HookQuerySearchSelect } from 'ui-component/HookFormComponents/HookQuerySearchSelect';
import { useGetStockLocationsServerSideQuery } from 'store/slices/apiV1/inventory';
import { IconHome } from '@tabler/icons-react';
import ContextualCreateButton from 'ui-component/ContextualCreateButton';
import CreateUpdateFacilityDialog from 'views/inventory/components/CreateUpdateFacilityDialog';

type HookFormAutoCompletePropsDisableRequired<
  T extends GenericObject | string
> = {
  disableRequiredProps: true;
  getOptionLabel?: (option: StockLocation) => string | never;
  handleChange?: (option: T) => void | never;
  disabled?: boolean | never;
  mb?: number;
  fullWidth?: boolean;
  multiple?: boolean;
  contextualCreate?: boolean;
  setValue?: UseFormSetValue<FieldValues>;
};

export type BaseHookFormAutocompleteProps<T extends GenericObject | string> = {
  options: T[];
  getOptionLabel: (option: StockLocation) => string;
  handleChange?: (option: T) => void;
  disabled?: boolean;
  mb?: number;
  fullWidth?: boolean;
  multiple?: boolean;
  contextualCreate?: boolean;
  setValue?: UseFormSetValue<FieldValues>;
};

type HookFormAutocompleteProps<T extends GenericObject | string> =
  HookFormComponentProps &
    (
      | HookFormAutoCompletePropsDisableRequired<T>
      | BaseHookFormAutocompleteProps<T>
    );

export const HookFormAddressSelect = <T extends GenericObject | string>({
  name = '',
  control,
  label,
  errors,
  getOptionLabel,
  disabled,
  multiple,
  setValue,
  contextualCreate,
  handleChange,
}: HookFormAutocompleteProps<T>) => {
  const { activeOrgId } = useSelector((state) => state.org);
  const [contextuallyCreatedOption, setContextuallyCreatedOption] = useState<
    StockLocation | undefined
  >(undefined);
  const [dialogOpen, setDialogOpen] = useState(false);
  const { data: org } = useGetOrgQuery(activeOrgId || skipToken);

  const QuerySearchSelect = (
    <HookQuerySearchSelect<StockLocation>
      sx={{ width: '100%' }}
      name={name}
      label={label}
      useGetQuery={useGetStockLocationsServerSideQuery}
      filters={[
        {
          field: 'locationType',
          operator: '=',
          value: 'facility',
        },
      ]}
      multiple={multiple}
      schema={[
        'externalName',
        'name',
        'locationType',
        'customerLocation',
        'org',
        'address',
      ]}
      errors={errors}
      control={control as Control<any, object>}
      contextuallyCreatedOption={contextuallyCreatedOption}
      getOptionLabel={
        getOptionLabel ||
        ((option: StockLocation) => `${option.externalName || option.name}`)
      }
      renderOption={(props, option: StockLocation) => (
        <MenuItem value={option.id} key={option.id} {...props}>
          <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
          >
            {option.id === org?.defaultShippingDestinationLocation?.id && (
              <Tooltip title="The default shipping destination for your organization">
                <ListItemIcon>
                  <IconHome />
                </ListItemIcon>
              </Tooltip>
            )}
            <Typography>
              {option.externalName ? option.externalName : option.name}
            </Typography>
          </Stack>
        </MenuItem>
      )}
      disabled={disabled}
    />
  );

  return (
    <>
      {contextualCreate ? (
        <>
          <ContextualCreateButton
            onClick={() => setDialogOpen(true)}
            boxAlignItems="flex-start"
            buttonMarginTop={0.5}
          >
            {QuerySearchSelect}
          </ContextualCreateButton>
          <CreateUpdateFacilityDialog
            dialogOpen={dialogOpen}
            onClose={() => setDialogOpen(false)}
            onCreateSuccess={(value) => {
              setContextuallyCreatedOption(value);
              setValue && setValue(name, value.id);
            }}
          />
        </>
      ) : (
        QuerySearchSelect
      )}
    </>
  );
};
