import { useTheme } from '@mui/material/styles';
import { Avatar, Grid, Typography, Stack, Badge, Tooltip } from '@mui/material';
import { App, appPermissionStates } from 'types/apps';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';
import WithSkeleton from 'ui-component/extended/WithSkeleton';
import { useDispatch } from 'store';
import { activeItem } from 'store/slices/menu';
import usePermissionStateAndCopyHook from 'hooks/usePermissionStateAndCopyHook';
import { useIsCofactr } from 'hooks/useIsCofactr';
import { IS_PROD } from 'constants/envConstants';

interface AppCardProps {
  app: App;
  variant?: 'dashboard' | 'title';
  color?: 'error' | 'default' | 'success' | 'warning' | 'primary' | 'secondary';
  size?: 'sm' | 'md' | 'lg';
  ignorePermissions?: boolean;
  clickable?: boolean;
  badge?: string | number | null;
  isLoading?: boolean;
}

export const AppCard = ({
  app,
  variant = 'dashboard',
  color = 'primary',
  size = 'lg',
  ignorePermissions = false,
  clickable = true,
  badge = null,
  isLoading = false,
}: AppCardProps) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const navigate = useNavigate();
  const Icon = app.icon;
  const { isCofactr } = useIsCofactr();
  const overrideGlobalDisabled = isCofactr && !IS_PROD;
  const badgeContent =
    size === 'sm' ? null : badge || (app.globalBeta ? 'BETA' : null);
  const disabled = ignorePermissions
    ? false
    : (app.permissions?.comingSoon && !overrideGlobalDisabled) ||
      app.permissions?.state !== appPermissionStates.enabled;
  const { disabledCopy } = usePermissionStateAndCopyHook({
    permissionScope: { app: app.id },
  });

  return (
    <Grid item xs={variant === 'dashboard' ? true : 'auto'}>
      <WithSkeleton isLoading={isLoading}>
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={1}
          onClick={() => {
            if (!disabled && clickable) {
              navigate(`${app.path}`);
              dispatch(activeItem([app.id]));
            }
          }}
        >
          <Tooltip
            title={`${disabled ? disabledCopy : `${app.name} App`}`}
            placement="top"
          >
            <Badge
              badgeContent={badgeContent}
              color={color}
              sx={
                badge
                  ? {}
                  : {
                      '.MuiBadge-anchorOriginTopRight': {
                        right: '10px',
                      },
                    }
              }
            >
              <Avatar
                variant="rounded"
                sx={{
                  overflow: 'hidden',
                  transition: 'all .3s ease-in-out',
                  background: disabled
                    ? theme.palette.grey[200]
                    : _.get(theme.palette, [color, 'light']),
                  color: disabled
                    ? theme.palette.grey[400]
                    : _.get(theme.palette, [color, 'dark']),
                  '&:hover':
                    disabled || variant === 'title'
                      ? {}
                      : {
                          cursor: 'pointer',
                          background: theme.palette.primary.dark,
                          color: theme.palette.common.white,
                          boxShadow: '0 2px 14px 0 rgb(32 40 45 / 8%)',
                        },
                  width: { lg: '64px', md: '48px', sm: '32px' }[size],
                  height: { lg: '64px', md: '48px', sm: '32px' }[size],
                  borderRadius: { lg: '12px', md: '9px', sm: '6px' }[size],
                }}
                color="inherit"
              >
                <Icon size={{ lg: 40, md: 30, sm: 20 }[size]} />
              </Avatar>
            </Badge>
          </Tooltip>
          {variant === 'dashboard' && (
            <Typography variant="caption" align="center">
              {app.name}
            </Typography>
          )}
        </Stack>
      </WithSkeleton>
    </Grid>
  );
};
