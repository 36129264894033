import { FC, useCallback, useEffect, useState } from 'react';
import {
  LabelPreviewRequest,
  LabelTemplate,
  printerTypeSpecs,
} from 'types/inventory';
import { Paper, Alert, AlertTitle, CircularProgress } from '@mui/material';
import { useGetLabelPreviewZplQuery } from 'store/slices/apiV1/inventory';
import { useTheme } from '@mui/material/styles';
import { skipToken } from '@reduxjs/toolkit/query/react';
import _ from 'lodash';

interface LabelPreviewProps {
  labelTemplate: LabelTemplate | null | undefined;
  additionalData?: Object;
  shipment?: string | null;
  stockLocation?: string | null;
  stockLot?: string | null;
  shipmentLine?: string | null;
  kitRequest?: string | null;
  printer?: string | null;
  loading?: boolean;
  size?: string;
}

const LabelPreview: FC<LabelPreviewProps> = ({
  labelTemplate,
  additionalData = {},
  shipment = null,
  stockLocation = null,
  stockLot = null,
  shipmentLine = null,
  kitRequest = null,
  printer = null,
  loading = false,
  size = 'large',
}) => {
  const theme = useTheme();
  const [labelPreviewRequest, setLabelPreviewRequest] = useState({
    labelTemplateId: labelTemplate?.id || '',
    payload: {
      shipment,
      stockLocation,
      stockLot,
      shipmentLine,
      kitRequest,
      printer,
      additionalData,
    },
  });

  const debouncedLabelPreviewRequest = useCallback(
    _.debounce((options) => setLabelPreviewRequest(options), 500),
    []
  );

  useEffect(() => {
    debouncedLabelPreviewRequest({
      labelTemplateId: labelTemplate?.id || '',
      payload: {
        shipment,
        stockLocation,
        stockLot,
        shipmentLine,
        kitRequest,
        printer,
        additionalData,
      } as LabelPreviewRequest,
    });
  }, [
    labelTemplate,
    shipment,
    stockLocation,
    stockLot,
    shipmentLine,
    kitRequest,
    printer,
    additionalData,
  ]);

  const {
    data: labelPreviewZpl,
    isFetching: isFetchingLabelPreviewZpl,
    isSuccess: isGetLabelPreviewZplSuccess,
    isError: isGetLabelPreviewZplError,
  } = useGetLabelPreviewZplQuery(
    labelPreviewRequest && labelPreviewRequest.labelTemplateId
      ? labelPreviewRequest
      : skipToken
  );

  const paperWidth = size === 'large' ? 450 : size === 'medium' ? 300 : 150;

  let labelPreviewUrl = '';
  let labelWidth = 3;
  let labelHeight = 2;
  if (labelTemplate) {
    labelWidth = printerTypeSpecs[labelTemplate.printerType].width;
    labelHeight = printerTypeSpecs[labelTemplate.printerType].height;
    labelPreviewUrl = `http://api.labelary.com/v1/printers/8dpmm/labels/${labelWidth}x${labelHeight}/0/${encodeURIComponent(
      labelPreviewZpl?.zpl || ''
    )}`;
  }

  return (
    <Paper
      elevation={3}
      sx={{
        my: 2,
        mx: 2,
        p: 0.25,
        width: paperWidth,
        height: (paperWidth / labelWidth) * labelHeight,
        justifyContent: 'center',
        display: 'flex',
        alignItems: 'center',
        textAlign: 'center',
        verticalAlign: 'middle',
      }}
    >
      {(!labelTemplate || loading || isFetchingLabelPreviewZpl) && (
        <CircularProgress
          size={90}
          thickness={2.5}
          sx={{ color: theme.palette.primary[600] }}
        />
      )}
      {labelTemplate &&
        !loading &&
        !isFetchingLabelPreviewZpl &&
        isGetLabelPreviewZplSuccess && (
          <img
            alt="Label Preview"
            src={labelPreviewUrl}
            width="100%"
            height="100%"
          />
        )}
      {labelTemplate &&
        !loading &&
        !isFetchingLabelPreviewZpl &&
        isGetLabelPreviewZplError && (
          <Alert severity="error">
            <AlertTitle>Label Preview Error</AlertTitle>
            The system failed to generate a label preview. Please contact
            Cofactr support.
          </Alert>
        )}
    </Paper>
  );
};

export default LabelPreview;
