import { ALL_APPS, ALL_VIEW_IDS } from 'constants/appConstants';
import MainCardWrapper from 'layout/MainLayout/MainCardWrapper';
import PermissionsRouteWrapper from 'views/pages/components/PermissionsRouteWrapper';
import Loadable from 'ui-component/Loadable';
import { lazy } from 'react';
import { Redirect } from 'react-router6-redirect';
import { Navigate } from 'react-router-dom';

const PurchasingOrdersStatus = Loadable(
  lazy(() => import('views/purchasing/PurchasingOrdersStatus'))
);
const PurchasingOrdersTable = Loadable(
  lazy(() => import('views/purchasing/PurchasingOrders'))
);
const PurchasingOrder = Loadable(lazy(() => import('views/purchasing/Order')));
const PurchasingSnapshotsTable = Loadable(
  lazy(() => import('views/purchasing/PurchasingSnapshots'))
);
const PurchasingSnapshotWrapper = Loadable(
  lazy(
    () =>
      import('views/purchasing/PurchasingSnapshot/PurchasingSnapshotWrapper')
  )
);
const PurchasingOrderPreferences = Loadable(
  lazy(() => import('views/purchasing/OrderPreferences'))
);

export const OrdersRoutes = [
  {
    path: `${ALL_APPS.ORDERS.deprecatedPath}`,
    element: <Redirect to={`${ALL_APPS.ORDERS.path}`} />,
  },
  {
    path: `${ALL_APPS.ORDERS.path}`,
    element: (
      <MainCardWrapper>
        <PermissionsRouteWrapper
          permissionScope={{
            app: ALL_APPS.ORDERS.id,
          }}
        >
          <Navigate
            to={`${ALL_APPS.ORDERS.views?.YOUR_ORDERS?.path}`}
            replace
          />
        </PermissionsRouteWrapper>
      </MainCardWrapper>
    ),
  },
  {
    path: `${ALL_APPS.ORDERS.path}/:orderId`,
    element: (
      <PermissionsRouteWrapper permissionScope={{ app: ALL_APPS.ORDERS.id }}>
        <PurchasingOrder />
      </PermissionsRouteWrapper>
    ),
  },
  {
    path: `${ALL_APPS.ORDERS.views?.ORDERS_STATUS?.path}`,
    element: (
      <MainCardWrapper>
        <PermissionsRouteWrapper permissionScope={{ app: ALL_APPS.ORDERS.id }}>
          <PurchasingOrdersStatus />
        </PermissionsRouteWrapper>
      </MainCardWrapper>
    ),
  },
  {
    path: `${ALL_APPS.ORDERS.views?.ORDERS_TABLE?.path}`,
    element: (
      <MainCardWrapper>
        <PermissionsRouteWrapper permissionScope={{ app: ALL_APPS.ORDERS.id }}>
          <PurchasingOrdersTable />
        </PermissionsRouteWrapper>
      </MainCardWrapper>
    ),
  },
  {
    path: `${ALL_APPS.ORDERS.views?.ORDERS_TABLE?.path}/:orderId`,
    element: (
      <PermissionsRouteWrapper permissionScope={{ app: ALL_APPS.ORDERS.id }}>
        <PurchasingOrder />
      </PermissionsRouteWrapper>
    ),
  },
  {
    path: `${ALL_APPS.ORDERS.views?.YOUR_ORDERS?.path}`,
    element: (
      <MainCardWrapper>
        <PermissionsRouteWrapper permissionScope={{ app: ALL_APPS.ORDERS.id }}>
          <PurchasingSnapshotsTable />
        </PermissionsRouteWrapper>
      </MainCardWrapper>
    ),
  },
  {
    path: `${ALL_APPS.ORDERS.views?.YOUR_ORDERS?.path}/:snapshotId`,
    element: (
      <PermissionsRouteWrapper permissionScope={{ app: ALL_APPS.ORDERS.id }}>
        <PurchasingSnapshotWrapper />
      </PermissionsRouteWrapper>
    ),
  },
  {
    path: `${ALL_APPS.ORDERS.views?.ORDER_PREFERENCES?.path}`,
    element: (
      <PermissionsRouteWrapper
        permissionScope={{
          app: ALL_APPS.ORDERS.id,
          view: ALL_VIEW_IDS.ORDER_PREFERENCES,
        }}
      >
        <PurchasingOrderPreferences />
      </PermissionsRouteWrapper>
    ),
  },
];
