import { skipToken } from '@reduxjs/toolkit/query/react';
import React, { useEffect, useState, JSX } from 'react';
import { GrowthBook, GrowthBookProvider } from '@growthbook/growthbook-react';
import { useSelector } from 'store';
import { useGetUserQuery } from 'store/slices/apiV1/user';
import { useGetOrgQuery } from 'store/slices/apiV1/org';
import _ from 'lodash';
import { FeatureFlags } from 'types';
import useLocalStorage from 'hooks/useLocalStorage';
import { ENV, IS_LOCAL } from 'constants/envConstants';

// https://docs.growthbook.io/

const GROWTHBOOK_URL = process.env.GROWTHBOOK_URL || '';
const growthbook = new GrowthBook({
  attributes: {
    environment: ENV,
    company: null,
    // Other attributes can be added here, such as browser device, ect
  },
  enableDevMode: IS_LOCAL,
});

const FeatureFlagProvider = ({ children }: { children: JSX.Element }) => {
  const [fallbackToDefaults, setFallbackToDefaults] = useState(false);
  const { isLoggedIn } = useSelector(({ session }) => session);
  const [savedGrowthbookValues, setSavedGrowthbookValues] = useLocalStorage(
    'growthbook',
    null
  );

  const { data: userData } = useGetUserQuery(
    isLoggedIn ? undefined : skipToken
  );
  const { activeOrgId } = useSelector((state) => state.org);
  const {
    data: org,
    isFetching: isOrgLoading,
    isSuccess: isOrgSuccess,
  } = useGetOrgQuery(activeOrgId ?? skipToken);

  useEffect(() => {
    const fetchFeatures = async () => {
      try {
        const response = await fetch(GROWTHBOOK_URL);
        if (response.ok) {
          const data = await response.json();
          growthbook.setFeatures(data.features);
          if (IS_LOCAL) {
            setSavedGrowthbookValues(data.features);
          }
        } else {
          throw new Error(response?.statusText);
        }
      } catch (err) {
        setFallbackToDefaults(true);
      }
    };

    fetchFeatures();
    // Set user attributes for targeting (from cookie, auth system, etc.)
    // growthbook.setAttributes();
  }, []);

  useEffect(() => {
    if (fallbackToDefaults) {
      growthbook.setFeatures(
        savedGrowthbookValues ??
          _.reduce(
            Object.keys(FeatureFlags) as (keyof typeof FeatureFlags)[],
            (acc, flag) => {
              acc[flag] = { defaultValue: true };
              return acc;
            },
            {} as Record<keyof typeof FeatureFlags, { defaultValue: boolean }>
          )
      );
    }
  }, [fallbackToDefaults]);

  useEffect(() => {
    growthbook.setAttributes({
      environment: ENV,
      id: userData?.id,
      email: userData?.email,
      org: org?.createdFrom
        ? typeof org?.createdFrom === 'string'
          ? org?.createdFrom
          : org?.createdFrom.id
        : org?.id || activeOrgId,
      owned_org: userData?.org,
    });
  }, [userData, activeOrgId, isOrgLoading, isOrgSuccess]);

  return (
    <GrowthBookProvider growthbook={growthbook}>{children}</GrowthBookProvider>
  );
};

export default FeatureFlagProvider;
