import { Skeleton, Stack, Typography } from '@mui/material';
import { FC, memo } from 'react';
import MainCard from 'ui-component/cards/MainCard';
import { ExtendedGridColDef } from 'types/datagrid';
import Chart, { Props } from 'react-apexcharts';
import _ from 'lodash';
import { useTheme } from '@mui/material/styles';
import { useSelector } from 'store';
import { selectLoadingStateByStatusByIdAndSchemaList } from 'store/slices/apiV1/partStatus';
import SegmentIconButton from 'ui-component/Segment/SegmentIconButton';
import { IconFilterOff } from '@tabler/icons-react';

export interface DataGridChartProps {
  column: ExtendedGridColDef;
  data: string[];
  selectedValue?: string | null;
  loading?: boolean;
  partIds?: string[];
  onFilterClick?: (field: string, value: string) => void;
}

const DataGridChart: FC<DataGridChartProps> = ({
  column,
  data,
  selectedValue,
  loading,
  partIds,
  onFilterClick,
}) => {
  const theme = useTheme();
  const partDataIsLoading = useSelector(
    (state) =>
      selectLoadingStateByStatusByIdAndSchemaList(
        state,
        column.showChart?.schemas || [],
        partIds
      ),
    _.isEqual
  );

  const colorMap: { [key: string]: string } = {
    warning: theme.palette.warning.main,
    success: theme.palette.success.main,
    error: theme.palette.error.main,
    info: theme.palette.info.main,
    primary: theme.palette.primary.main,
    secondary: theme.palette.secondary.main,
    grey: theme.palette.grey[500],
  };

  const dataCounts = _.countBy(data);

  const selectedTooltip = _.chain(column.showChart?.valueOptions)
    ?.find((v) => v.value === selectedValue)
    ?.get('tooltip')
    ?.value();

  const description = selectedTooltip
    ? `${_.chain(column.showChart?.valueOptions)
        ?.find((v) => v.value === selectedValue)
        ?.get('label')
        ?.value()}: ${selectedTooltip}`
    : column.showChart?.description;

  const isLoading =
    loading ||
    ((partIds || []).length > 0 && (column.showChart?.schemas || []).length > 0
      ? partDataIsLoading
      : false);

  const donutChart: Props = {
    height: 200,
    type: 'donut',
    tooltip: 'light',
    plotOptions: {
      pie: {
        expandOnClick: false,
      },
    },
    options: {
      chart: {
        id: `datagridChart-${column.field}`,
        events: {
          // These types are directly from ApexCharts, so we're stuck with `any` here. :(
          dataPointSelection: (__, chartContext: any, config: any) => {
            const clickedValue = (column?.showChart?.valueOptions || [])[
              config.dataPointIndex
            ].value;
            if (onFilterClick && !column.showChart?.disabled) {
              onFilterClick(column.field, clickedValue);
            }
            chartContext.resetSeries();
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      states: {
        active: {
          allowMultipleDataPointsSelection: false,
          filter: {
            type: 'none',
          },
        },
      },
      labels: _.map(column?.showChart?.valueOptions, 'label'),
      colors: _.map(
        column?.showChart?.valueOptions,
        (v) => colorMap[v.color || 'grey']
      ),
      legend: {
        show: false,
      },
    },
    series: _.map(
      column?.showChart?.valueOptions,
      (v) => dataCounts[v.value] || 0
    ),
  };

  return (
    <MainCard sx={{ height: '100%' }} contentSX={{ height: '100%' }}>
      <Stack
        direction="column"
        justifyContent="space-between"
        alignItems="center"
        spacing={1}
        sx={{ height: '100%' }}
      >
        <Stack
          direction="column"
          justifyContent="flex-start"
          alignItems="flex-start"
          spacing={0}
          sx={{ width: '100%' }}
        >
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={1}
            sx={{ width: '100%' }}
          >
            <Typography variant="subtitle1">
              {column.showChart?.title || column.headerName || column.field}
            </Typography>
            <SegmentIconButton
              color="error"
              size="small"
              disabled={!selectedValue}
              onClick={() => {
                if (onFilterClick && selectedValue) {
                  onFilterClick(column.field, selectedValue);
                }
              }}
            >
              <IconFilterOff width={20} height={20} />
            </SegmentIconButton>
          </Stack>
          {!!description && (
            <Typography variant="caption">{description}</Typography>
          )}
        </Stack>
        {isLoading && (
          <Stack
            direction="column"
            justifyContent="flex-start"
            alignItems="center"
          >
            <Skeleton variant="circular" height={200} width={200} />
          </Stack>
        )}
        {!isLoading && <Chart {...donutChart} />}
      </Stack>
    </MainCard>
  );
};

export default memo(DataGridChart);
