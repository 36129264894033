import { UseMutation } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import { FC } from 'react';
import _ from 'lodash';
import { Grid } from '@mui/material';
import { gridSpacing, infoSectionRowSx } from 'constants/themeConstants';
import InfoSectionItem, {
  InfoSectionItemFormatTypes,
} from 'ui-component/InfoSectionItem';
import EditInline from 'ui-component/EditInline';
import {
  HookFormCheckbox,
  HookFormInput,
  HookFormSelect,
} from 'ui-component/HookFormComponents';
import {
  CustomPropertyDataType,
  CustomPropertyModel,
  CustomPropertyVisibility,
} from 'types/customProperty';
import useGetCustomPropertyDefinitions from 'hooks/useGetCustomPropertyDefinitions';
import { HookFormNumberInput } from 'ui-component/HookFormComponents/HookFormNumberInput';
import { ISOStringHookFormDatePicker } from 'ui-component/HookFormComponents/HookFormDatePicker/ISOStringHookFormDatePicker';
import { HookFormUserAutocomplete } from 'ui-component/HookFormComponents/HookFormUserAutocomplete';
import { useGetOrgUsersQuery } from 'store/slices/apiV1/org';
import { skipToken } from '@reduxjs/toolkit/query';
import { useSelector } from 'store';
import { GenericResource } from 'types/api';

interface DetailCustomPropertiesProps<T> {
  // the `any` here seems to be the type used in the RTK Query type definitions
  useMutation: UseMutation<any>;
  record: T | null | undefined;
  model: CustomPropertyModel;
  isLoadingRecord: boolean;
  disableEdit?: boolean;
  idProp?: string;
  otherProps?: object;
  xs?: number | boolean;
  submitAsClientV2Api?: boolean;
}

export const editInlineComponentMap: Record<CustomPropertyDataType, FC<any>> = {
  [CustomPropertyDataType.BOOLEAN]: HookFormCheckbox,
  [CustomPropertyDataType.DATE]: ISOStringHookFormDatePicker,
  [CustomPropertyDataType.DATETIME]: ISOStringHookFormDatePicker,
  [CustomPropertyDataType.USER]: HookFormUserAutocomplete,
  [CustomPropertyDataType.SINGLE_CHOICE]: HookFormSelect,
  [CustomPropertyDataType.MULTI_CHOICE]: HookFormSelect,
  [CustomPropertyDataType.NUMBER]: HookFormNumberInput,
  [CustomPropertyDataType.STRING]: HookFormInput,
};

const DetailCustomProperties = <T extends GenericResource>({
  useMutation,
  record,
  model,
  isLoadingRecord,
  disableEdit,
  idProp = 'id',
  otherProps = {},
  xs,
  submitAsClientV2Api = false,
}: DetailCustomPropertiesProps<T>) => {
  const { activeOrgId } = useSelector((state) => state.org);

  const { customProperties, isFetching } = useGetCustomPropertyDefinitions({
    model,
    visibilityContext: CustomPropertyVisibility.INFO_SECTION,
  });

  const hasUserCustomProp = _.some(
    customProperties,
    (f) => f.dataType === CustomPropertyDataType.USER
  );

  const { data: users = [], isLoading: isLoadingUsers } = useGetOrgUsersQuery(
    hasUserCustomProp && activeOrgId ? activeOrgId : skipToken
  );

  if (customProperties.length < 1) {
    return <></>;
  }

  return (
    <Grid
      container
      item
      columnSpacing={gridSpacing}
      sx={infoSectionRowSx}
      spacing={gridSpacing}
    >
      {customProperties.map((f) => {
        const InlineComponent = editInlineComponentMap[f.dataType];
        const otherInlineComponentProps =
          f.dataType === CustomPropertyDataType.SINGLE_CHOICE ||
          f.dataType === CustomPropertyDataType.MULTI_CHOICE
            ? {
                options: f.values,
                multiple: f.dataType === CustomPropertyDataType.MULTI_CHOICE,
              }
            : {};

        const recordCustomProperties = _.get(record, 'customProperties', {});

        const valueForProp = _.get(
          recordCustomProperties,
          f.key,
          f.defaultValue
        );

        const isUserField = f.dataType === CustomPropertyDataType.USER;

        const getLabelForPropValue = () => {
          if (isUserField) {
            const user = _.find(users, (u) => u.id === valueForProp);
            return user ? `${user.firstName} ${user.lastName}` : valueForProp;
          }
          return _.find(f.values, (option) => option.value === valueForProp)
            ?.label;
        };

        const formatType =
          f.dataType === CustomPropertyDataType.BOOLEAN
            ? InfoSectionItemFormatTypes.boolean
            : undefined;

        return (
          <InfoSectionItem
            key={f.key}
            caption={f.name}
            isLoading={
              isLoadingRecord || isFetching || (isUserField && isLoadingUsers)
            }
            value={getLabelForPropValue() ?? valueForProp}
            disableEdit={disableEdit}
            formatType={formatType}
            xs={xs}
            editInline={
              <EditInline
                useMutation={useMutation}
                name={f.key}
                label={f.name}
                hideLabel
                value={_.get(recordCustomProperties, f.key, f.defaultValue)}
                id={_.get(record, idProp || 'id', '') as string}
                preSubmit={(data) => ({
                  customProperties: {
                    [f.key]: data,
                  },
                })}
                idProp={idProp}
                otherProps={otherProps}
                submitAsClientV2Api={submitAsClientV2Api}
              >
                <InlineComponent
                  disableRequiredProps
                  {...otherInlineComponentProps}
                />
              </EditInline>
            }
          />
        );
      })}
    </Grid>
  );
};
export default DetailCustomProperties;
