import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useGetVersionQuery } from 'store/slices/apiV1/utility';
import { useDispatch } from 'store';
import { IS_LOCAL } from 'constants/envConstants';
import { clearDatagridState } from 'store/slices/datagrid';

const NewVersionContext = createContext({
  showNewVersion: false,
  handleUpdateVersion: () => {},
});

export const useNewVersion = () => useContext(NewVersionContext);

export const NewVersionProvider = ({ children }: { children: ReactNode }) => {
  const dispatch = useDispatch();
  const [showNewVersion, setShowNewVersion] = useState(false);
  const { version, refetch } = useGetVersionQuery(undefined, {
    pollingInterval: 1000 * 60 * 10,
    selectFromResult: ({ data, error }) => {
      if (error || !data?.version) return { version: undefined };
      return { version: data.version, error };
    },
    skip: IS_LOCAL,
  });

  const handleUpdateVersion = () => {
    dispatch(clearDatagridState());
    window.location.reload();
  };

  useEffect(() => {
    if (!IS_LOCAL) {
      refetch();
    }
    if (!IS_LOCAL && version && process.env.GIT_RELEASE_SHA !== version) {
      setShowNewVersion(true);
    }
  }, [version, refetch]);

  return (
    <NewVersionContext.Provider value={{ showNewVersion, handleUpdateVersion }}>
      {children}
    </NewVersionContext.Provider>
  );
};
