import { Grid, Stack, Typography, useTheme } from '@mui/material';
import { IconHome } from '@tabler/icons-react';
import UserAvatar from 'ui-component/extended/UserAvatar';
import { User, UserLite } from 'types/user';
import { FC, memo } from 'react';
import _ from 'lodash';
import { useGetUserQuery } from 'store/slices/apiV1/user';
import { isCofactrUser } from 'utils/userPermissions';
import { useSelector } from 'store';
import { OrgUserFull } from 'types/org';
import CopyClipboard from 'ui-component/extended/CopyClipboard';

export enum UserInfoSize {
  SM = 'sm',
  MD = 'md',
}

interface UserInfoSectionProps {
  user: User | UserLite | OrgUserFull | null | undefined;
  subtitle?: string;
  size?: UserInfoSize;
  automation?: boolean;
  showEmail?: boolean;
}

const UserInfoSection: FC<UserInfoSectionProps> = ({
  user,
  subtitle,
  size,
  automation,
  showEmail = false,
}) => {
  const theme = useTheme();
  const { data: currentUser } = useGetUserQuery();
  const { viewAsStaff } = useSelector((state) => state.user);

  const obscureCofactrUser =
    user?.isStaff && !isCofactrUser({ user: currentUser, viewAsStaff });

  return (
    <Grid
      container
      columnSpacing={1.5}
      justifyContent="flex-start"
      alignItems="center"
    >
      <Grid item xs="auto">
        <UserAvatar
          user={user}
          sx={{
            ...theme.typography.mediumAvatar,
          }}
          size={size}
          automation={automation}
          obscureCofactrUser={obscureCofactrUser}
        />
      </Grid>
      <Grid item xs sx={{ overflow: 'hidden' }}>
        {automation && !user && (
          <Typography align="left" variant="subtitle1" noWrap>
            Cofactr Platform
          </Typography>
        )}
        {!!user && obscureCofactrUser && (
          <Typography align="left" variant="subtitle1" noWrap>
            Cofactr Success
          </Typography>
        )}
        {!!user && !obscureCofactrUser && (
          <Typography align="left" variant="subtitle1" noWrap>
            {user?.firstName || ''} {user?.lastName || ''}
            {'   '}
            {_.get(user, 'isInternal', false) && (
              <IconHome color={theme.palette.primary.dark} width={12} />
            )}
          </Typography>
        )}
        <Typography align="left" variant="subtitle2" noWrap>
          {subtitle || (obscureCofactrUser ? '' : user?.title) || '-'}
        </Typography>
        {showEmail && user?.email && !obscureCofactrUser && (
          <Stack direction="row" alignItems="center">
            <Typography align="left" variant="subtitle2" noWrap>
              {user?.email}
            </Typography>
            <CopyClipboard label="Email" input={user?.email} iconSize={16} />
          </Stack>
        )}
      </Grid>
    </Grid>
  );
};

export default memo(UserInfoSection);
