import { skipToken } from '@reduxjs/toolkit/dist/query';
import useServerSideQuery, {
  ServerSideQueryParams,
} from 'hooks/useServerSideQuery';
import { useMemo } from 'react';
import { useGetAllocationsClientV2Query } from 'store/slices/clientV2/allocations';
import { useGetKitRequestLinesQuery } from 'store/slices/clientV2/kitRequests';
import { useGetHandlingRequestsV2Query } from 'store/slices/clientV2/handlingRequests';
import { useGetShipmentLinesQuery } from 'store/slices/clientV2/shipments';
import { ALL, DISABLE_PAGE_LIMIT, Lite, QueryFilterOperators } from 'types/api';
import { AllocationSchema } from 'types/clientV2/allocations';
import {
  ShipmentLineSchema,
  ShipmentLineSchemaLite,
  ShipmentSchemaLite,
} from 'types/clientV2/shipments';
import { KitRequestLineSchema, KitRequestSchema } from 'types/kitting';
import {
  HandlingRequestSchema,
  HandlingRequestSchemaLite,
} from 'types/clientV2/stock';
import {
  calculateKitRequestStatus,
  combineDecoratedKitRequestLinesWithStatuses,
  createDecoratedKitRequestLines,
  KitRequestAllocationStatus,
  KitRequestLineStats,
} from 'hooks/useGetDecoratedKitRequestLines/utils';
import _ from 'lodash';
import { useGetStockLotsServerSideQuery } from 'store/slices/apiV1/inventory';
import { StockLotQuerySchema, StockLotQuerySchemaLite } from 'types/inventory';

export type KitRequestDataLite = Lite<
  KitRequestSchema,
  'shipTo' | 'sourceLocations' | 'kittedFor' | 'team' | 'org'
>;
export type HandlingRequestDataLite = HandlingRequestSchemaLite;

export type KitRequestLineDataLineLite = Lite<
  KitRequestLineSchema,
  'orgPart' | 'kitRequest' | 'customProperties' | 'orgParts'
>;
export type KitRequestLineDataAllocationLite = Lite<
  AllocationSchema,
  'stockLot'
>;
export type ShipmentLineDataLite = Omit<ShipmentLineSchemaLite, 'shipment'> & {
  shipment: Pick<ShipmentSchemaLite, 'id' | 'shipmentCode' | 'shippedAt'>;
};

export type DecoratedKitRequestLine = Omit<
  KitRequestLineDataLineLite,
  'allocations'
> & {
  allocations: (KitRequestLineDataAllocationLite & {
    calculatedStatus: KitRequestAllocationStatus;
  })[];
  shipmentLines: ShipmentLineDataLite[];
  handlingRequests: HandlingRequestDataLite[];
  stats: KitRequestLineStats;
  stockLots: StockLotQuerySchemaLite[];
};

type UseGetDecoratedKitRequestLinesProps = {
  selectedKitRequestIds: KitRequestSchema['id'][];
};

const getKitRequestLineQueryParams = (
  selectedKitRequestIds: KitRequestSchema['id'][]
): ServerSideQueryParams<KitRequestLineSchema, KitRequestLineDataLineLite> => ({
  schema: [
    ALL,
    {
      orgPart: [],
      kitRequest: ['id', 'approvedBy'],
      customProperties: [],
      orgParts: [],
    },
  ],
  filters: [
    {
      field: 'kitRequest.id',
      operator: QueryFilterOperators.isAnyOf,
      value: selectedKitRequestIds,
    },
  ],
  pageSize: DISABLE_PAGE_LIMIT,
});

const getAllocationQueryParams = (
  selectedKitRequestIds: KitRequestSchema['id'][]
): ServerSideQueryParams<
  AllocationSchema,
  KitRequestLineDataAllocationLite
> => ({
  schema: [
    ALL,
    {
      stockLot: [],
    },
  ],
  filters: [
    {
      field: 'kitRequestLine.kitRequest',
      operator: QueryFilterOperators.isAnyOf,
      value: selectedKitRequestIds,
    },
  ],
  pageSize: DISABLE_PAGE_LIMIT,
});

const getShipmentLineQueryParams = (
  selectedKitRequestIds: KitRequestSchema['id'][]
): ServerSideQueryParams<ShipmentLineSchema, ShipmentLineDataLite> => ({
  schema: [ALL, { shipment: ['id', 'shipmentCode', 'shippedAt'] }],
  filters: [
    {
      field: 'kitRequestLine.kitRequest',
      operator: QueryFilterOperators.isAnyOf,
      value: selectedKitRequestIds,
    },
  ],
  pageSize: DISABLE_PAGE_LIMIT,
});

const getHandlingRequestQueryParams = (
  selectedKitRequestIds: KitRequestSchema['id'][]
): ServerSideQueryParams<HandlingRequestSchema, HandlingRequestDataLite> => ({
  schema: [ALL],
  filters: [
    {
      field: 'kitRequestIdViaLot',
      operator: QueryFilterOperators.isAnyOf,
      value: selectedKitRequestIds,
    },
    {
      field: 'completedAt',
      operator: QueryFilterOperators.isEmpty,
      value: null,
    },
  ],
  pageSize: DISABLE_PAGE_LIMIT,
});

const getStockLotQueryParams = (
  selectedKitRequestIds: KitRequestSchema['id'][]
): ServerSideQueryParams<StockLotQuerySchema, StockLotQuerySchemaLite> => ({
  schema: [ALL],
  filters: [
    {
      // @ts-ignore we have to do this because we are filtering multiple levels deep and that is not supported by the current typing
      field: 'allocations.kitRequestLine.kitRequest',
      operator: QueryFilterOperators.isAnyOf,
      value: selectedKitRequestIds,
    },
  ],
  pageSize: DISABLE_PAGE_LIMIT,
});

export const useGetDecoratedKitRequestLines = ({
  selectedKitRequestIds,
}: UseGetDecoratedKitRequestLinesProps) => {
  const {
    data: kitRequestLinesData,
    isLoading: isLoadingKitRequestLines,
    refetch: refetchKitRequestLines,
  } = useServerSideQuery<KitRequestLineSchema, KitRequestLineDataLineLite>(
    useGetKitRequestLinesQuery,
    selectedKitRequestIds.length > 0
      ? getKitRequestLineQueryParams(selectedKitRequestIds)
      : skipToken
  );

  const {
    data: allocationsData,
    isLoading: isLoadingAllocations,
    refetch: refetchAllocations,
  } = useServerSideQuery<AllocationSchema, KitRequestLineDataAllocationLite>(
    useGetAllocationsClientV2Query,
    selectedKitRequestIds.length > 0
      ? getAllocationQueryParams(selectedKitRequestIds)
      : skipToken
  );

  const {
    data: shipmentLinesData,
    isLoading: isLoadingShipmentLines,
    refetch: refetchShipmentLines,
  } = useServerSideQuery<ShipmentLineSchema, ShipmentLineDataLite>(
    useGetShipmentLinesQuery,
    selectedKitRequestIds.length > 0
      ? getShipmentLineQueryParams(selectedKitRequestIds)
      : skipToken
  );

  const {
    data: handlingRequestsData,
    isLoading: isLoadingHandlingRequests,
    refetch: refetchHandlingRequests,
  } = useServerSideQuery<HandlingRequestSchema, HandlingRequestDataLite>(
    useGetHandlingRequestsV2Query,
    selectedKitRequestIds.length > 0
      ? getHandlingRequestQueryParams(selectedKitRequestIds)
      : skipToken
  );

  const {
    data: stockLotsData,
    isLoading: isLoadingStockLots,
    refetch: refetchStockLots,
  } = useServerSideQuery<StockLotQuerySchema, StockLotQuerySchemaLite>(
    useGetStockLotsServerSideQuery,
    selectedKitRequestIds.length > 0
      ? getStockLotQueryParams(selectedKitRequestIds)
      : skipToken
  );

  const kitRequestLines =
    selectedKitRequestIds.length > 0 ? kitRequestLinesData?.data : undefined;

  const allocations =
    selectedKitRequestIds.length > 0 ? allocationsData?.data : undefined;

  const shipmentLines =
    selectedKitRequestIds.length > 0 ? shipmentLinesData?.data : undefined;

  const handlingRequests =
    selectedKitRequestIds.length > 0
      ? _.uniqBy(handlingRequestsData?.data, 'id')
      : undefined;

  const stockLots =
    selectedKitRequestIds.length > 0 ? stockLotsData?.data : undefined;

  const decoratedKitRequestLines = useMemo(
    () =>
      createDecoratedKitRequestLines({
        kitRequestLines,
        allocations,
        shipmentLines,
        handlingRequests,
        stockLots,
      }),
    [kitRequestLines, allocations, shipmentLines, handlingRequests, stockLots]
  );

  const kitRequestStatuses = useMemo(() => {
    if (!decoratedKitRequestLines) return undefined;
    return calculateKitRequestStatus(decoratedKitRequestLines);
  }, [decoratedKitRequestLines]);

  const decoratedKitRequestLinesWithStatuses = useMemo(
    () =>
      combineDecoratedKitRequestLinesWithStatuses({
        decoratedKitRequestLines,
        kitRequestStatuses,
      }),
    [decoratedKitRequestLines, kitRequestStatuses]
  );

  const refetchDecoratedKitRequestLines = () => {
    if (selectedKitRequestIds.length) {
      refetchKitRequestLines();
      refetchAllocations();
      refetchShipmentLines();
      refetchHandlingRequests();
      refetchStockLots();
    }
  };

  return {
    kitRequestLines,
    kitRequestStatuses,
    allocations,
    stockLots,
    decoratedKitRequestLines: decoratedKitRequestLinesWithStatuses,
    isLoadingKitRequestLines,
    isLoadingAllocations,
    isLoadingShipmentLines,
    isLoadingHandlingRequests,
    isLoadingStockLots,
    refetchDecoratedKitRequestLines,
  };
};
