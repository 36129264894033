import { Autocomplete, Box, FormHelperText, TextField } from '@mui/material';
import { Controller, FieldValues, UseFormSetValue } from 'react-hook-form';
import { HookFormComponentProps } from 'ui-component/HookFormComponents/types';
import { ReactNode, useState } from 'react';
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles';
import { useGetOrgUsersQuery } from 'store/slices/apiV1/org';
import { OrgUserFull } from 'types/org';
import { useSelector } from 'store';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { GenericObject } from 'types';

type HookFormUserAutocompletePropsDisableRequired<
  T extends GenericObject | string
> = {
  disableRequiredProps: true;
  disabled?: boolean;
  mb?: number;
  fullWidth?: boolean;
  multiple?: boolean;
  setValue?: UseFormSetValue<FieldValues>;
  defaultValue?: T;
  sx?: SxProps<Theme>;
  boxSx?: SxProps<Theme>;
  isInlineEdit?: boolean;
  mt?: number;
  helperText?: string;
};

type BaseHookFormUserAutocompleteProps<T extends GenericObject | string> = {
  children?: ReactNode;
  disabled?: boolean;
  multiple?: boolean;
  sx?: SxProps<Theme>;
  helperText?: string;
  mt?: number;
  fullWidth?: boolean;
  setValue: UseFormSetValue<FieldValues>;
  [props: string]: unknown;
} & HookFormComponentProps;

type HookFormUserAutocompleteProps<T extends GenericObject | string> =
  HookFormComponentProps &
    (
      | HookFormUserAutocompletePropsDisableRequired<T>
      | BaseHookFormUserAutocompleteProps<T>
    );

export const HookFormUserAutocomplete = <T extends GenericObject | string>({
  errors,
  control,
  name = '',
  label,
  disabled,
  multiple = false,
  sx,
  mt = 2,
  helperText,
  fullWidth = true,
  setValue,
  isInlineEdit,
  ...rest
}: HookFormUserAutocompleteProps<T>) => {
  const activeOrgId = useSelector((state) => state.org.activeOrgId);
  const [selected, setSelected] = useState<OrgUserFull | null>(null);
  const { data: users = [], isLoading: isLoadingUsers } = useGetOrgUsersQuery(
    activeOrgId ?? skipToken
  );

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, onBlur } }) => (
        <Autocomplete
          loading={isLoadingUsers}
          fullWidth={fullWidth}
          sx={{ mt: isInlineEdit ? 0 : mt, ...sx }}
          options={users}
          onChange={(e, data) => {
            if (data?.id !== selected?.id) {
              setSelected(data);
            }
            onChange(data?.id);
          }}
          value={selected}
          onBlur={onBlur}
          isOptionEqualToValue={(o, v) => o?.id === v?.id}
          getOptionLabel={(option) =>
            `${option?.firstName} ${option?.lastName}${
              option?.title ? `: ${option?.title}` : ''
            }` || ''
          }
          renderInput={(params) => (
            <Box>
              <TextField {...params} label={label} fullWidth />
              {errors?.[name]?.message && (
                <FormHelperText error id={`${name}Error`}>
                  {errors?.[name]?.message}
                </FormHelperText>
              )}
            </Box>
          )}
          disabled={disabled}
        />
      )}
    />
  );
};
