import { apiSlice } from 'store/slices/api';
import { QueryParams } from 'types/api';
import { parseQueryParams } from 'store/slices/utils';
import { KitRequestSchema } from 'types/kitting';
import { SupplierBillLineSchema, SupplierBillSchema } from 'types/procurement';
import {
  SupplierQuoteSchema,
  SupplierQuoteLineSchema,
  RfqSchema,
} from 'types/sourcing';
import { AllocationSchema } from 'types/clientV2/allocations';
import { PurchaseRequestGroupSchema } from 'types/clientV2/purchaseRequests';
import { CLIENT_V2_URLS } from 'store/slices/constants/clientV2';

const extendedApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getExportAllocations: builder.query<Blob, QueryParams<AllocationSchema>>({
      query: (params) => ({
        url: CLIENT_V2_URLS.ALLOCATIONS(
          parseQueryParams<AllocationSchema>({ ...params, exportBlob: true })
        ),
        method: 'GET',
        responseType: 'blob',
        responseHandler: (response) => response.blob(),
      }),
    }),
    getExportSupplierBills: builder.query<
      Blob,
      QueryParams<SupplierBillSchema>
    >({
      query: (params) => ({
        url: CLIENT_V2_URLS.SUPPLIER_BILLS(
          parseQueryParams<SupplierBillSchema>({ ...params, exportBlob: true })
        ),
        method: 'GET',
        responseType: 'blob',
        responseHandler: (response) => response.blob(),
      }),
    }),
    getExportSupplierBillLines: builder.query<
      Blob,
      QueryParams<SupplierBillLineSchema>
    >({
      query: (params) => ({
        url: CLIENT_V2_URLS.SUPPLIER_BILL_LINES(
          parseQueryParams<SupplierBillLineSchema>({
            ...params,
            exportBlob: true,
          })
        ),
        method: 'GET',
        responseType: 'blob',
        responseHandler: (response) => response.blob(),
      }),
    }),
    getExportKitRequests: builder.query<Blob, QueryParams<KitRequestSchema>>({
      query: (params) => ({
        url: CLIENT_V2_URLS.KIT_REQUESTS(
          parseQueryParams<KitRequestSchema>({ ...params, exportBlob: true })
        ),
        method: 'GET',
        responseType: 'blob',
        responseHandler: (response) => response.blob(),
      }),
    }),
    getExportPurchaseRequestGroups: builder.query<
      Blob,
      QueryParams<PurchaseRequestGroupSchema>
    >({
      query: (params) => ({
        url: CLIENT_V2_URLS.PURCHASE_REQUEST_GROUPS(
          parseQueryParams<PurchaseRequestGroupSchema>({
            ...params,
            exportBlob: true,
          })
        ),
        method: 'GET',
        responseType: 'blob',
        responseHandler: (response) => response.blob(),
      }),
    }),
    getExportSupplierQuotes: builder.query<
      Blob,
      QueryParams<SupplierQuoteSchema>
    >({
      query: (params) => ({
        url: CLIENT_V2_URLS.SUPPLIER_QUOTES(
          parseQueryParams<SupplierQuoteSchema>({ ...params, exportBlob: true })
        ),
        method: 'GET',
        responseType: 'blob',
        responseHandler: (response) => response.blob(),
      }),
    }),
    getExportRfqs: builder.query<Blob, QueryParams<RfqSchema>>({
      query: (params) => ({
        url: CLIENT_V2_URLS.RFQS(
          parseQueryParams<RfqSchema>({ ...params, exportBlob: true })
        ),
        method: 'GET',
        responseType: 'blob',
        responseHandler: (response) => response.blob(),
      }),
    }),
    getExportSupplierQuoteLines: builder.query<
      Blob,
      QueryParams<SupplierQuoteLineSchema>
    >({
      query: (params) => ({
        url: CLIENT_V2_URLS.SUPPLIER_QUOTE_LINES(
          parseQueryParams<SupplierQuoteLineSchema>({
            ...params,
            exportBlob: true,
          })
        ),
        method: 'GET',
        responseType: 'blob',
        responseHandler: (response) => response.blob(),
      }),
    }),
  }),
});

export const {
  useLazyGetExportAllocationsQuery,
  useLazyGetExportSupplierBillsQuery,
  useLazyGetExportSupplierBillLinesQuery,
  useLazyGetExportKitRequestsQuery,
  useLazyGetExportPurchaseRequestGroupsQuery,
  useLazyGetExportSupplierQuotesQuery,
  useLazyGetExportSupplierQuoteLinesQuery,
  useLazyGetExportRfqsQuery,
} = extendedApiSlice;
