import {
  useState,
  useEffect,
  useCallback,
  ReactNode,
  MutableRefObject,
} from 'react';
import { Stack, Grid, Divider } from '@mui/material';
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarFilterButton,
} from '@mui/x-data-grid-premium';
import { GridApiPremium } from '@mui/x-data-grid-premium/models/gridApiPremium';
import {
  IconArrowBackUp,
  IconCirclePlus,
  IconDownload,
  IconUpload,
} from '@tabler/icons-react';
import _ from 'lodash';

import { GenerateReportDataType } from 'types/generateReports';
import { actionButtonSpacing, gridSpacing } from 'constants/themeConstants';
import { useDeviceContext } from 'hooks/useDeviceContext';
import {
  RowAction,
  ExtendedGridColDef,
  DatagridNames,
  DatagridRecordButtonProps,
  VisibleColumnGroup,
} from 'types/datagrid';
import { FullOrIconButton } from 'ui-component/DataGrid/components/FullOrIconButton';
import ExtendedQuickFilter from 'ui-component/DataGrid/ExtendedQuickFilter';
import ActionBar from 'ui-component/DataGrid/ExtendedGridToolbar/ActionBar';
import CustomExportButton from 'ui-component/DataGrid/ExtendedGridToolbar/CustomExportButton';

type ExtendedGridToolbarProps<T> = {
  apiRef: MutableRefObject<GridApiPremium>;
  columns: ExtendedGridColDef[];
  exportCsv?: () => void;
  fullCard?: boolean;
  handleResetState: () => void;
  handleSetColumnGroup: (name: string) => void;
  hideToolbar?: boolean;
  isExportLoading?: boolean;
  memoizedVisibleGroups?: VisibleColumnGroup[] | null;
  toolbarColumns?: boolean;
  toolbarExport?: boolean;
  toolbarFilters?: boolean;
  children?: ReactNode;
  exportFields: () => string[];
  exportFileNamePrefix?: string | null;
  gridName: DatagridNames;
  generateReportData?: GenerateReportDataType;
  newRecordButton: DatagridRecordButtonProps;
  uploadRecordButton: DatagridRecordButtonProps;
  rowActions?: RowAction<T>[];
  selectedRowIds: string[];
  selectedRowData: T[];
};

const narrowWindowToolbarButtonSx = {
  width: '34px',
  height: '34px',
  borderRadius: '17px',
  minWidth: 'unset',
  '& .MuiButton-startIcon': { mx: 0 },
};

export default function ExtendedGridToolbar<T>({
  apiRef,
  columns,
  exportCsv,
  fullCard,
  handleResetState,
  handleSetColumnGroup,
  isExportLoading,
  hideToolbar = false,
  memoizedVisibleGroups,
  toolbarColumns,
  toolbarExport,
  toolbarFilters,
  children,
  exportFields,
  exportFileNamePrefix,
  gridName,
  generateReportData,
  newRecordButton,
  uploadRecordButton,
  rowActions,
  selectedRowIds,
  selectedRowData,
}: ExtendedGridToolbarProps<T>) {
  const { narrowWindow } = useDeviceContext();

  const [searchText, setSearchText] = useState('');
  const [quickSearchFocused, setQuickSearchFocused] = useState(false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
  };

  const debouncedFilterUpdate = useCallback(
    _.debounce((value: string) => {
      if (apiRef?.current) {
        apiRef?.current.setQuickFilterValues([value]);
      }
    }, 800),
    []
  );

  useEffect(() => {
    debouncedFilterUpdate(searchText);
  }, [searchText, debouncedFilterUpdate]);

  if (hideToolbar) return null;

  return (
    <GridToolbarContainer>
      <Grid
        container
        sx={{
          px: fullCard ? actionButtonSpacing : undefined,
          pt: fullCard ? gridSpacing : undefined,
        }}
      >
        <Grid container item columnSpacing={1.5} alignItems="center" xs={12}>
          {(toolbarColumns || toolbarFilters) && (
            <Grid item xs="auto">
              <FullOrIconButton
                onClick={handleResetState}
                tooltip={memoizedVisibleGroups ? 'Default' : 'Reset'}
                color="primary"
                startIcon={<IconArrowBackUp />}
                label={memoizedVisibleGroups ? 'Default' : 'Reset'}
                variant={undefined}
              />
            </Grid>
          )}
          {(memoizedVisibleGroups || []).map((g) => (
            <Grid item xs="auto" key={g.name}>
              <FullOrIconButton
                startIcon={g.icon}
                onClick={() => handleSetColumnGroup(g.name)}
                tooltip={g.label}
                color="primary"
                label={g.label}
                variant={undefined}
              />
            </Grid>
          ))}
          {(toolbarColumns || toolbarFilters) && (
            <Divider
              orientation="vertical"
              variant="middle"
              flexItem
              sx={{ ml: 1.5 }}
            />
          )}
          {toolbarColumns && (
            <Grid item xs="auto">
              <GridToolbarColumnsButton
                sx={narrowWindow ? narrowWindowToolbarButtonSx : undefined}
              />
            </Grid>
          )}
          {toolbarFilters && (
            <Grid item xs="auto">
              <GridToolbarFilterButton
                sx={narrowWindow ? narrowWindowToolbarButtonSx : undefined}
              />
            </Grid>
          )}
          {toolbarExport && (
            <Grid item xs="auto">
              {exportCsv ? (
                <FullOrIconButton
                  onClick={exportCsv}
                  tooltip="Export"
                  color="primary"
                  startIcon={<IconDownload />}
                  label="Export"
                  variant={undefined}
                  loadingButton
                  loading={isExportLoading}
                />
              ) : (
                <CustomExportButton
                  apiRef={apiRef}
                  columns={columns}
                  exportFields={exportFields}
                  exportFileNamePrefix={exportFileNamePrefix}
                  generateReportData={generateReportData}
                  gridName={gridName}
                  sx={narrowWindow ? narrowWindowToolbarButtonSx : undefined}
                />
              )}
            </Grid>
          )}
          <Grid item xs="auto">
            <ExtendedQuickFilter
              searchText={searchText}
              setSearchText={setSearchText}
              handleChange={handleChange}
              quickSearchFocused={quickSearchFocused}
              setQuickSearchFocused={setQuickSearchFocused}
            />
          </Grid>
          {(newRecordButton.onClick || uploadRecordButton.onClick) && (
            <Grid
              item
              xs
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              <Stack
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                spacing={actionButtonSpacing}
              >
                {uploadRecordButton.onClick && (
                  <FullOrIconButton
                    onClick={uploadRecordButton.onClick}
                    tooltip={uploadRecordButton.label}
                    color="primary"
                    disabled={uploadRecordButton.disabled}
                    permissionScope={uploadRecordButton.permissionScope}
                    startIcon={<IconUpload />}
                    variant="outlined"
                    label={uploadRecordButton.label}
                    trackingName={uploadRecordButton.trackingName}
                  />
                )}
                {newRecordButton.onClick && (
                  <FullOrIconButton
                    onClick={newRecordButton.onClick}
                    tooltip={newRecordButton.label}
                    color="primary"
                    disabled={newRecordButton.disabled}
                    permissionScope={newRecordButton.permissionScope}
                    startIcon={<IconCirclePlus />}
                    variant="contained"
                    label={newRecordButton.label}
                    trackingName={newRecordButton.trackingName}
                  />
                )}
              </Stack>
            </Grid>
          )}
        </Grid>
        <ActionBar
          rowActions={rowActions}
          selectedRowIds={selectedRowIds}
          selectedRowData={selectedRowData}
        />
        <Grid
          item
          xs={12}
          sx={{
            display: 'flex',
            justifyContent: 'flex-start',
          }}
        >
          {children}
        </Grid>
      </Grid>
    </GridToolbarContainer>
  );
}
