import {
  App,
  AppFeature,
  appPermissionAccessLevels,
  appPermissionStates,
  AppView,
  DISABLED_TYPE,
  DisabledReason,
  noPermissionsReason,
} from 'types/apps';
import { GrowthBook } from '@growthbook/growthbook-react';
import { ALL_APP_IDS, ALL_APPS, ALL_FEATURE_IDS } from 'constants/appConstants';
import { FeatureFlags } from 'types';
import { Entitlement } from 'types/access';

export const doesUserAccessLevelMeetRequiredAccessLevel = ({
  userPermissionLevel,
  requiredPermissionLevel,
}: {
  userPermissionLevel: keyof typeof appPermissionAccessLevels | undefined;
  requiredPermissionLevel: keyof typeof appPermissionAccessLevels | undefined;
}) => {
  // if user doesn't have permissions set for this app, they don't have access
  if (!userPermissionLevel) {
    return false;
  }

  // if user has admin level permission, then the required permission level doesn't matter and user has access
  // if no required permission level is provided, then user has access
  if (
    userPermissionLevel === appPermissionAccessLevels.admin ||
    !requiredPermissionLevel
  ) {
    return true;
  }
  // if app requires edit permission, then user must have edit permission or higher
  if (requiredPermissionLevel === appPermissionAccessLevels.edit) {
    return userPermissionLevel === appPermissionAccessLevels.edit;
  }
  // if required permission is read, user must have at least read permission
  if (requiredPermissionLevel === appPermissionAccessLevels.read) {
    return requiredPermissionLevel === appPermissionAccessLevels.read;
  }
  // if user doesn't have at least read permission, they don't have access
  return false;
};

// Basic, app-level permissions strings
export const appPermissionsCopy = ({ appName }: { appName: App['name'] }) =>
  ({
    [appPermissionStates.comingSoon]: {
      message: 'This app is coming soon.',
      action: 'Please contact Cofactr Success to request early access.',
    },
    [appPermissionStates.disabled]: {
      message: 'This app is disabled on your account.',
      action: `Please contact your account administrator or Cofactr Success to enable the ${appName} app on your account.`,
    },
    [appPermissionStates.unavailable]: {
      message: `You don't have access to this app.`,
      action: `Please contact Cofactr Success to enable the ${appName} app on your account.`,
    },
    [appPermissionStates.noPermission]: {
      message: `You don't have access to this app.`,
      action: `Please contact your account administrator to give you permission to access the ${appName} app.`,
    },
    [appPermissionStates.noBlended]: {
      message: `This app is not currently supported by blended views`,
      action: `Select a specific Active Org to use the ${appName} app.`,
    },
  } as { [key in appPermissionStates]: noPermissionsReason });

// More complex, app, view, and feature specific permissions strings
export const getDisabledPermissionCopy = (disabledReason: DisabledReason) => {
  const DisabledName = {
    [DISABLED_TYPE.APP]: 'app',
    [DISABLED_TYPE.VIEW]: 'view',
    [DISABLED_TYPE.FEATURE]: 'feature',
    [DISABLED_TYPE.PARENT_APP]: 'app',
  };

  const typeString = DisabledName[disabledReason.disabledType];
  const name = disabledReason?.name ?? '';

  switch (disabledReason?.permissionState) {
    case appPermissionStates.comingSoon:
      return {
        message: `This ${typeString} is coming soon.`,
        action: 'Please contact Cofactr Success to request early access.',
      };
    case appPermissionStates.disabled:
      return {
        message: `This ${typeString} is disabled on your account.`,
        action: `Please contact your account administrator or Cofactr Success to enable the ${name} ${typeString} on your account.`,
      };
    case appPermissionStates.unavailable:
      return {
        message: `You don't have access to this ${typeString}.`,
        action: `Please contact Cofactr Success to enable the ${name} ${typeString} on your account.`,
      };
    case appPermissionStates.noBlended:
      return {
        message: `This ${typeString} is not currently supported by blended views.`,
        action: `Select a specific Active Org to use the ${name} ${typeString}.`,
      };
    case appPermissionStates.noPermission:
    default:
      return {
        message: `You don't have access to this ${typeString}.`,
        action: `Please contact Cofactr Success to enable the ${name} ${typeString} on your account.`,
      };
  }
};

export function calculateAppPermissionState({
  app,
  entitlement,
  growthbookEntitlement,
  accessLevel,
  blended,
  vip,
  isCofactr,
}: {
  app: App;
  entitlement?: Entitlement;
  growthbookEntitlement?: boolean | null;
  accessLevel?: keyof typeof appPermissionAccessLevels;
  blended?: boolean;
  vip?: boolean;
  isCofactr?: boolean;
}) {
  if (app.globalComingSoon) {
    if (isCofactr && growthbookEntitlement) {
      return appPermissionStates.enabled;
    }
    return appPermissionStates.comingSoon;
  }
  if (accessLevel === appPermissionAccessLevels.none) {
    return appPermissionStates.disabled;
  }
  if (app.globalEnabled) {
    if (blended) {
      return app.includeInBlended
        ? appPermissionStates.enabled
        : appPermissionStates.noBlended;
    }
    return appPermissionStates.enabled;
  }
  // VIP logic can be ignored if isOverPlanLimit is available and true
  if (
    (vip && entitlement?.isOverPlanLimit !== true) ||
    growthbookEntitlement === true
  ) {
    return appPermissionStates.enabled;
  }
  if (!entitlement || entitlement?.isOverPlanLimit === true) {
    return appPermissionStates.unavailable;
  }
  if (blended) {
    return app.includeInBlended
      ? appPermissionStates.enabled
      : appPermissionStates.noBlended;
  }
  return appPermissionStates.enabled;
}

export function calculateViewFeaturePermissionState({
  viewOrFeature,
  parentAppState,
  entitlement,
  growthbookEntitlement,
  blended,
  vip,
}: {
  viewOrFeature: AppView | AppFeature;
  parentAppState: keyof typeof appPermissionStates;
  entitlement?: Entitlement;
  growthbookEntitlement?: boolean | null;
  blended?: boolean;
  vip?: boolean;
}) {
  if (viewOrFeature.globalComingSoon) {
    return appPermissionStates.unavailable;
  }
  if (parentAppState !== appPermissionStates.enabled) {
    return parentAppState;
  }
  if (blended) {
    return viewOrFeature.excludeInBlended
      ? appPermissionStates.noBlended
      : appPermissionStates.enabled;
  }
  if (viewOrFeature.inheritParentPermissions) {
    return parentAppState;
  }
  // VIP logic can be ignored if isOverPlanLimit is available and true
  if (
    (vip && entitlement?.isOverPlanLimit !== true) ||
    growthbookEntitlement === true
  ) {
    return appPermissionStates.enabled;
  }
  if (!entitlement || entitlement?.isOverPlanLimit === true) {
    return appPermissionStates.unavailable;
  }
  return appPermissionStates.enabled;
}

type GrowthbookValuesType = {
  apps: Record<keyof typeof ALL_APP_IDS, boolean | undefined>;
  features: Record<keyof typeof ALL_FEATURE_IDS, boolean | undefined>;
  vip: boolean;
};

// calculates permissions set via growthbook
export const getGrowthbookValues = ({
  gb,
  appPrefix,
  featurePrefix,
}: {
  gb: GrowthBook;
  appPrefix: string;
  featurePrefix: string;
}) => {
  const result = {
    apps: {},
    features: {},
    vip: gb.evalFeature(FeatureFlags.entitlementVip).value,
  } as GrowthbookValuesType;

  (Object.keys(ALL_APP_IDS) as Array<keyof typeof ALL_APP_IDS>).forEach(
    (appId) => {
      const app = ALL_APPS[appId];
      result.apps[appId] = gb?.evalFeature(
        `${appPrefix}-${app.id.toLowerCase()}`
      ).value;
    }
  );
  (Object.keys(ALL_FEATURE_IDS) as Array<keyof typeof ALL_FEATURE_IDS>).forEach(
    (featureId) => {
      result.features[featureId] = gb?.evalFeature(
        `${featurePrefix}-${featureId.toLowerCase()}`
      ).value;
    }
  );

  return result;
};
