import { useState, MutableRefObject } from 'react';
import { MenuItem, ButtonProps } from '@mui/material';
import {
  GridToolbarExportContainer,
  GridExcelExportMenuItem,
  GridPrintExportMenuItem,
} from '@mui/x-data-grid-premium';
import { GridApiPremium } from '@mui/x-data-grid-premium/models/gridApiPremium';
import { useFeature } from '@growthbook/growthbook-react';
import _ from 'lodash';

import { FeatureFlags } from 'types';
import { DatagridNames, ExtendedGridColDef } from 'types/datagrid';
import { GenerateReportDataType, ReportDataTypes } from 'types/generateReports';
import useGenerateReports from 'hooks/useGenerateReport';
import { nowShortDate } from 'utils/dates';
import { processCsvData, processExcelData } from 'ui-component/DataGrid/utils';
import { downloadFile } from 'utils/functions';

type CustomExportButtonProps = {
  apiRef: MutableRefObject<GridApiPremium>;
  columns: ExtendedGridColDef[];
  exportFields: () => string[];
  exportFileNamePrefix?: string | null;
  generateReportData?: GenerateReportDataType;
  gridName: DatagridNames;
} & ButtonProps;

export default function CustomExportButton({
  apiRef,
  columns,
  exportFields,
  exportFileNamePrefix,
  generateReportData,
  gridName,
  ...rest
}: CustomExportButtonProps) {
  const [generateReportOpen, setGenerateReportOpen] = useState<boolean>(false);
  const generateReportsFeature = useFeature(FeatureFlags.generateReports).on;
  const { GenerateReportDialog } = useGenerateReports(!!generateReportData);

  return (
    <>
      <GridToolbarExportContainer {...rest}>
        <MenuItem
          onClick={() => {
            const fieldsToExport = exportFields();
            const columnsToExport = columns.filter((column) =>
              fieldsToExport.includes(column.field)
            );
            const csvData = apiRef?.current?.getDataAsCsv({
              fields: fieldsToExport,
            });
            if (csvData) {
              const updatedCsvData = processCsvData(csvData, columnsToExport);
              const blob = new Blob([updatedCsvData], {
                type: 'text/csv;charset=utf-8;',
              });
              downloadFile(
                blob,
                `${exportFileNamePrefix || 'CofactrExport'}_${nowShortDate()}`,
                'csv'
              );
            }
          }}
        >
          Download as CSV
        </MenuItem>
        <GridExcelExportMenuItem
          options={{
            exceljsPostProcess: async (processInput) => {
              processExcelData(
                processInput,
                columns.filter((column) =>
                  exportFields().includes(column.field)
                )
              );
              return Promise.resolve();
            },
            fields: exportFields(),
            fileName: `${
              exportFileNamePrefix || 'CofactrExport'
            }_${nowShortDate()}`,
          }}
        />
        <GridPrintExportMenuItem />
        {_.includes(ReportDataTypes, gridName) && generateReportsFeature && (
          <MenuItem
            onClick={() => {
              setGenerateReportOpen(true);
            }}
          >
            Custom Report
          </MenuItem>
        )}
      </GridToolbarExportContainer>
      <GenerateReportDialog
        data={generateReportData}
        open={generateReportOpen}
        onClose={() => {
          setGenerateReportOpen(false);
        }}
        gridName={gridName}
      />
    </>
  );
}
