import {
  Grid,
  ListItemIcon,
  ListSubheader,
  MenuItem,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { gridSpacing, infoSectionRowBorderSx } from 'constants/themeConstants';
import Hero from 'ui-component/Hero';
import { useUpdateKitRequestsMutation } from 'store/slices/clientV2/kitRequests';
import { calculateKitRequestStatus } from 'views/kitting/Kit/utils';
import InfoSectionItem, {
  InfoSectionItemFormatTypes,
} from 'ui-component/InfoSectionItem';
import { LabelFormat, StockLocationType } from 'types/inventory';
import EditInline from 'ui-component/EditInline';
import HookFormStockLocationSelect from 'ui-component/HookFormComponents/HookFormStockLocationSelect';
import { Control, FieldErrors } from 'react-hook-form';
import { createUpdateKitRequestFormConstants } from 'views/kitting/components/constants';
import { skipToken } from '@reduxjs/toolkit/dist/query/react';
import _ from 'lodash';
import AddressCard from 'ui-component/cards/AddressCard';
import LabelPreview from 'ui-component/shared/LabelPreview';
import { useDefaultLabelTemplates } from 'hooks/useDefaultLabelTemplates';
import {
  getDefaultShippingMethod,
  getShipmentCarrierAndService,
} from 'utils/functions';
import { HookFormInput, HookFormSelect } from 'ui-component/HookFormComponents';
import { ShippingMethods } from 'types/purchasing';
import { IconPlaneDeparture } from '@tabler/icons-react';
import { useSelector } from 'store';
import { useGetOrgQuery } from 'store/slices/apiV1/org';
import { useIsCofactr } from 'hooks/useIsCofactr';
import {
  KitRequestSubtype,
  useKitRequestContext,
} from 'views/kitting/Kit/KitRequestContext';
import DetailCustomProperties from 'ui-component/DetailCustomProperties';
import { CustomPropertyModel } from 'types/customProperty';

const KitDetail = ({ kitRequestId }: { kitRequestId: string }) => {
  const { isCofactr } = useIsCofactr();

  const { activeOrgId } = useSelector((state) => state.org);
  const { data: org } = useGetOrgQuery(activeOrgId ?? skipToken);

  const { kitRequest, isLoadingKitRequest } = useKitRequestContext();

  const { fetching: isFetchingLabelTemplates, getLabelTemplate } =
    useDefaultLabelTemplates({ shipToId: kitRequest?.shipTo?.id });

  const disableEdit = Boolean(kitRequest?.approved || kitRequest?.locked);

  return (
    <Grid container>
      <Grid item container xs={12} sx={infoSectionRowBorderSx}>
        <Grid item xs={6}>
          <Hero
            isLoading={isLoadingKitRequest}
            options={{
              top: {
                value: kitRequest?.kitCode ?? '',
                clipboard: 'Kit Code',
                clipboardValue: kitRequest?.kitCode ?? '',
              },
              middle: {
                value: kitRequest?.name ?? '',
                clipboard: 'Kit Name',
                clipboardValue: kitRequest?.name ?? '',
                editInline: (
                  <EditInline
                    useMutation={useUpdateKitRequestsMutation}
                    name={createUpdateKitRequestFormConstants.name.id}
                    label={createUpdateKitRequestFormConstants.name.label}
                    value={kitRequest?.name}
                    id={kitRequestId}
                    submitAsClientV2Api
                  >
                    <HookFormInput disableRequiredProps type="text" />
                  </EditInline>
                ),
              },
              status: kitRequest ? calculateKitRequestStatus(kitRequest) : '',
            }}
          />
        </Grid>
      </Grid>
      <Grid
        item
        container
        sx={infoSectionRowBorderSx}
        justifyContent="space-between"
        spacing={gridSpacing}
      >
        <Grid item xs={4}>
          <InfoSectionItem
            caption={createUpdateKitRequestFormConstants.sourceLocations.label}
            isLoading={isLoadingKitRequest}
            formatType={InfoSectionItemFormatTypes.children}
            value
            disableEdit={disableEdit}
            editInline={
              <EditInline
                useMutation={useUpdateKitRequestsMutation}
                name={createUpdateKitRequestFormConstants.sourceLocations.id}
                label={
                  createUpdateKitRequestFormConstants.sourceLocations.label
                }
                hideLabel
                value={_.map(kitRequest?.sourceLocations, 'id')}
                id={kitRequestId}
                submitAsClientV2Api
              >
                <HookFormStockLocationSelect
                  filters={[
                    {
                      field: 'locationType',
                      operator: '=',
                      value: StockLocationType.FACILITY,
                    },
                    {
                      field: 'warehouseShippingEnabled',
                      operator: '=',
                      value: true,
                    },
                  ]}
                  defaultValue={_.map(kitRequest?.sourceLocations, 'id')}
                  disableRequiredProps
                  errors={{} as FieldErrors}
                  control={{} as Control}
                  multiple
                  name=""
                  label=""
                />
              </EditInline>
            }
          >
            <Stack spacing={1}>
              {_.map(kitRequest?.sourceLocations, (location) => (
                <AddressCard
                  location={location}
                  key={location.id}
                  startCollapsed
                />
              ))}
            </Stack>
          </InfoSectionItem>
        </Grid>
        <Grid item xs={4}>
          <InfoSectionItem
            caption={createUpdateKitRequestFormConstants.shipTo.label}
            isLoading={isLoadingKitRequest}
            formatType={InfoSectionItemFormatTypes.stockLocationAddress}
            value
            stockLocationValue={kitRequest?.shipTo}
            disableEdit={disableEdit}
            editInline={
              <EditInline
                useMutation={useUpdateKitRequestsMutation}
                name={createUpdateKitRequestFormConstants.shipTo.id}
                label={createUpdateKitRequestFormConstants.shipTo.label}
                hideLabel
                value={kitRequest?.shipTo?.id}
                id={kitRequestId}
                submitAsClientV2Api
              >
                <HookFormStockLocationSelect
                  filters={[
                    {
                      field: 'locationType',
                      operator: '=',
                      value: StockLocationType.FACILITY,
                    },
                  ]}
                  defaultValue={kitRequest?.shipTo?.id}
                  disableRequiredProps
                  errors={{} as FieldErrors}
                  control={{} as Control}
                  name=""
                  label=""
                />
              </EditInline>
            }
          />
        </Grid>
        {kitRequest?.shipTo && (
          <Grid item xs={4}>
            <InfoSectionItem
              caption="Kit Label Preview"
              isLoading={isLoadingKitRequest}
              clipboard={false}
              formatType={InfoSectionItemFormatTypes.children}
              value
            >
              <LabelPreview
                labelTemplate={getLabelTemplate(LabelFormat.KitRequestDefault)}
                loading={isFetchingLabelTemplates}
                kitRequest={kitRequestId}
                size="small"
              />
            </InfoSectionItem>
          </Grid>
        )}
      </Grid>
      <Grid
        item
        container
        sx={infoSectionRowBorderSx}
        justifyContent="space-between"
        spacing={gridSpacing}
      >
        {kitRequest?.shipTo && (
          <InfoSectionItem
            xs={4}
            caption="Shipping Method"
            isLoading={isLoadingKitRequest}
            value={
              kitRequest?.carrier && kitRequest?.service
                ? `${kitRequest?.carrier}: ${kitRequest?.service}`
                : '-'
            }
            disableEdit={disableEdit || !kitRequest?.shipTo}
            editInline={
              <EditInline
                useMutation={useUpdateKitRequestsMutation}
                name="method"
                id={kitRequestId}
                label="Shipping Method"
                value={
                  kitRequest?.carrier && kitRequest?.service
                    ? `${kitRequest.carrier}_${kitRequest.service}`
                    : getDefaultShippingMethod({ org })
                }
                preSubmit={(value: string | null) => {
                  if (value) {
                    const { defaultShippingCarrier, defaultShippingService } =
                      getShipmentCarrierAndService(value);
                    return {
                      carrier: defaultShippingCarrier,
                      service: defaultShippingService,
                    } as unknown as {
                      carrier: string;
                      service: string;
                    };
                  }
                  return null;
                }}
                submitAsClientV2Api
              >
                <HookFormSelect disableRequiredProps>
                  {ShippingMethods.map((method) =>
                    method.type === 'option' ? (
                      <MenuItem value={method.value} key={method.value}>
                        <Stack
                          direction="row"
                          justifyContent="flex-start"
                          alignItems="center"
                        >
                          {method.value ===
                            getDefaultShippingMethod({ org }) && (
                            <Tooltip title="The default shipping method for your organization">
                              <ListItemIcon>
                                <IconPlaneDeparture />
                              </ListItemIcon>
                            </Tooltip>
                          )}
                          <Typography>{method.label}</Typography>
                        </Stack>
                      </MenuItem>
                    ) : (
                      <ListSubheader key={method.value}>
                        {method.label}
                      </ListSubheader>
                    )
                  )}
                </HookFormSelect>
              </EditInline>
            }
          />
        )}
        <InfoSectionItem
          xs={4}
          caption={createUpdateKitRequestFormConstants.externalNotes.label}
          isLoading={isLoadingKitRequest}
          value={kitRequest?.externalNotes}
          disableEdit={disableEdit}
          editInline={
            <EditInline
              useMutation={useUpdateKitRequestsMutation}
              name={createUpdateKitRequestFormConstants.externalNotes.id}
              hideLabel
              value={kitRequest?.externalNotes}
              id={kitRequestId}
              submitAsClientV2Api
            >
              <HookFormInput disableRequiredProps />
            </EditInline>
          }
        />
        <InfoSectionItem
          xs={4}
          caption="Printed Notes"
          isLoading={isLoadingKitRequest}
          value={kitRequest?.printedNotes}
          disableEdit={disableEdit}
          editInline={
            <EditInline
              useMutation={useUpdateKitRequestsMutation}
              name="printedNotes"
              label="Printed Notes"
              hideLabel
              value={kitRequest?.printedNotes}
              id={kitRequestId}
              submitAsClientV2Api
            >
              <HookFormInput disableRequiredProps />
            </EditInline>
          }
        />
      </Grid>
      {isCofactr && (
        <Grid
          item
          container
          sx={infoSectionRowBorderSx}
          justifyContent="space-between"
          spacing={gridSpacing}
        >
          <InfoSectionItem
            caption={createUpdateKitRequestFormConstants.internalNotes.label}
            isLoading={isLoadingKitRequest}
            value={kitRequest?.internalNotes}
            disableEdit={disableEdit}
            editInline={
              <EditInline
                useMutation={useUpdateKitRequestsMutation}
                name={createUpdateKitRequestFormConstants.internalNotes.id}
                label={createUpdateKitRequestFormConstants.internalNotes.label}
                hideLabel
                value={kitRequest?.internalNotes}
                id={kitRequestId}
                submitAsClientV2Api
              >
                <HookFormInput disableRequiredProps />
              </EditInline>
            }
          />
        </Grid>
      )}
      {kitRequest && (
        <DetailCustomProperties<KitRequestSubtype>
          useMutation={useUpdateKitRequestsMutation}
          record={kitRequest}
          model={CustomPropertyModel.KIT_REQUEST}
          isLoadingRecord={isLoadingKitRequest}
          disableEdit={disableEdit}
          idProp="id"
          submitAsClientV2Api
        />
      )}
    </Grid>
  );
};

export default KitDetail;
