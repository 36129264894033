import { Org } from 'types/org';
import { Team } from 'types/properties';
import { ChipColor } from 'ui-component/extended/Chip';

export enum TagModelOptions {
  ORG_SUPPLIER = 'orgsupplier',
  PURCHASE_REQUEST = 'purchaserequest',
  PURCHASE_REQUEST_GROUP = 'purchaserequestgroup',
  RFQ = 'rfq',
  RFQ_LINE = 'rfqline',
  SUPPLIER_QUOTE = 'supplierquote',
  SUPPLIER_QUOTE_LINE = 'supplierquoteline',
}

export const TagModelOptionsDisplay = {
  [TagModelOptions.ORG_SUPPLIER]: 'Supplier',
  [TagModelOptions.PURCHASE_REQUEST]: 'Requisition Line',
  [TagModelOptions.PURCHASE_REQUEST_GROUP]: 'Requisition',
  [TagModelOptions.RFQ]: 'RFQ',
  [TagModelOptions.RFQ_LINE]: 'RFQ Line',
  [TagModelOptions.SUPPLIER_QUOTE]: 'Supplier Quote',
  [TagModelOptions.SUPPLIER_QUOTE_LINE]: 'Supplier Quote Line',
};

export const TagColorOptionsDisplay = {
  [ChipColor.primary]: 'Primary',
  [ChipColor.secondary]: 'Secondary',
  [ChipColor.success]: 'Success',
  [ChipColor.error]: 'Error',
  [ChipColor.orange]: 'Orange',
  [ChipColor.grey]: 'Grey',
  [ChipColor.warning]: 'Warning',
  [ChipColor.default]: 'Default',
};

type ModelOptions = {
  model: TagModelOptions;
};

export type TagSchema = {
  id: string;
  name: string;
  color: ChipColor;
  active: boolean;
  modelOptions: ModelOptions[];
  org: Org;
  team: Team;
};

export type Tag = {
  id: string;
  name: string;
  color: ChipColor;
  active: boolean;
};
