import _ from 'lodash';
import {
  GridFilterOperator,
  GridFilterItem,
  GridCellParams,
  DEFAULT_GRID_COL_TYPE_KEY,
  getGridDefaultColumnTypes,
  GridFilterInputSingleSelect,
  GridFilterInputValue,
} from '@mui/x-data-grid';
import { DatagridNumberFilterOperatorsValues } from 'types/datagrid';
import PositiveNumberFilterInput from 'ui-component/DataGrid/FilterInput/PositiveNumberFilterInput';
import PositiveNumberFilterAutoComplete from 'ui-component/DataGrid/FilterInput/PositiveNumberFilterAutoComplete';
import MSLFloorHoursLeftFilterInput from 'ui-component/DataGrid/FilterInput/MSLFloorHoursLeftFilterInput';
import PriceFilterInput from 'ui-component/DataGrid/FilterInput/PriceFilterInput';
import { stockStatus, stockStatusFormats } from 'types/part';
// Elements used in Datagrid column prop filterOperators

// this is used to override the default filter operator for a column
export type ExtendedGridFilterOperator = GridFilterOperator & {
  applyAs?:
    | {
        operatorValue: GridFilterItem['operatorValue'];
        value: GridFilterItem['value'];
      }
    | Array<{
        operatorValue: GridFilterItem['operatorValue'];
        value: GridFilterItem['value'];
      }>;
};

export const booleanFilterOperators: GridFilterOperator[] = [
  {
    label: 'is',
    value: '=',
    getApplyFilterFn: (filterItem: GridFilterItem) => {
      if (!filterItem.value) return null;

      return (params): boolean => params.value === filterItem.value;
    },
    InputComponent: GridFilterInputSingleSelect,
  },
];

export const booleanValueOptions = [
  { value: true, label: 'True' },
  { value: false, label: 'False' },
];

export const priceFilterOperators: GridFilterOperator[] = [
  {
    label: '>',
    value: 'greater',
    getApplyFilterFn: (filterItem: GridFilterItem) => {
      if (!filterItem.value) return null;

      return (params): boolean => {
        if (params.value.max === null) return false;
        return params.value.max > Number(filterItem.value);
      };
    },
    InputComponent: PriceFilterInput,
  },
  {
    label: '<',
    value: 'lesser',
    getApplyFilterFn: (filterItem: GridFilterItem) => {
      if (!filterItem.value) return null;

      return (params): boolean => {
        if (params.value.min === null) return false;
        return params.value.min < Number(filterItem.value);
      };
    },
    InputComponent: PriceFilterInput,
  },
];

export const NumberFilterOperators: GridFilterOperator[] =
  (
    getGridDefaultColumnTypes().number ??
    getGridDefaultColumnTypes()[DEFAULT_GRID_COL_TYPE_KEY] ??
    []
  ).filterOperators ?? [];

const UnlimitedFilterOperator: ExtendedGridFilterOperator = {
  value: 'unlimited',
  label: 'Unlimited',
  applyAs: {
    value: 9999,
    operatorValue: DatagridNumberFilterOperatorsValues['>='],
  },
  getApplyFilterFn:
    () =>
    ({ value }): boolean =>
      value >= 9999,
};

const BakeRequiredFilterOperator: ExtendedGridFilterOperator = {
  value: 'bakeRequired',
  label: 'Bake Required',
  applyAs: {
    value: 24,
    operatorValue: DatagridNumberFilterOperatorsValues['<='],
  },
  getApplyFilterFn:
    () =>
    ({ value }): boolean =>
      value <= 24,
};

export const NumberFilterOperatorsNoEmpty: GridFilterOperator[] =
  NumberFilterOperators?.filter(
    (operator: GridFilterOperator) =>
      operator.value !== DatagridNumberFilterOperatorsValues.isEmpty &&
      operator.value !== DatagridNumberFilterOperatorsValues.isNotEmpty
  );

export const PositiveNumberFilterOperators: GridFilterOperator[] =
  NumberFilterOperatorsNoEmpty?.map((operator: GridFilterOperator) => ({
    ...operator,
    InputComponent:
      operator.value === DatagridNumberFilterOperatorsValues.isAnyOf
        ? PositiveNumberFilterAutoComplete
        : PositiveNumberFilterInput,
  })) ?? [];

export const MSLFloorHoursLeftFilterOperators: GridFilterOperator[] =
  [
    UnlimitedFilterOperator,
    BakeRequiredFilterOperator,
    ...NumberFilterOperatorsNoEmpty?.map((operator: GridFilterOperator) => ({
      ...operator,
      InputComponent:
        operator.value === DatagridNumberFilterOperatorsValues.isAnyOf
          ? PositiveNumberFilterAutoComplete
          : MSLFloorHoursLeftFilterInput,
    })),
  ] ?? [];

const supplyShortageFilterOperator: ExtendedGridFilterOperator = {
  label: stockStatusFormats[stockStatus.SHORTAGE].label,
  value: stockStatus.SHORTAGE,
  applyAs: [
    {
      value: 0,
      operatorValue: DatagridNumberFilterOperatorsValues['>='],
    },
    {
      value: 1000,
      operatorValue: DatagridNumberFilterOperatorsValues['<'],
    },
  ],
  getApplyFilterFn:
    () =>
    ({ value }): boolean =>
      value >= 0 && value < 1000,
};

const supplyLimitedFilterOperator: ExtendedGridFilterOperator = {
  label: stockStatusFormats[stockStatus.LIMITED].label,
  value: stockStatus.LIMITED,
  applyAs: [
    {
      value: 1000,
      operatorValue: DatagridNumberFilterOperatorsValues['>='],
    },
    {
      value: 10000,
      operatorValue: DatagridNumberFilterOperatorsValues['<'],
    },
  ],
  getApplyFilterFn:
    () =>
    ({ value }): boolean =>
      value >= 1000 && value < 10000,
};

const supplyOkayFilterOperator: ExtendedGridFilterOperator = {
  label: stockStatusFormats[stockStatus.OKAY].label,
  value: stockStatus.OKAY,
  applyAs: [
    {
      value: 10000,
      operatorValue: DatagridNumberFilterOperatorsValues['>='],
    },
    {
      value: 50000,
      operatorValue: DatagridNumberFilterOperatorsValues['<'],
    },
  ],
  getApplyFilterFn:
    () =>
    ({ value }): boolean =>
      value >= 10000 && value < 50000,
};

const supplyStrongFilterOperator: ExtendedGridFilterOperator = {
  label: stockStatusFormats[stockStatus.STRONG].label,
  value: stockStatus.STRONG,
  applyAs: [
    {
      value: 50000,
      operatorValue: DatagridNumberFilterOperatorsValues['>='],
    },
  ],
  getApplyFilterFn:
    () =>
    ({ value }): boolean =>
      value >= 50000,
};

export const supplyFilterOperators: GridFilterOperator[] = [
  supplyShortageFilterOperator,
  supplyLimitedFilterOperator,
  supplyOkayFilterOperator,
  supplyStrongFilterOperator,
];

// TODO: this at least allows us to filter related records ideally we should build this as Multi Select
export const relatedRecordsFilterOperators: GridFilterOperator[] = [
  {
    label: 'contains',
    value: 'contains',
    getApplyFilterFn: (filterItem: GridFilterItem) => {
      if (!filterItem.value) return null;
      return (params): boolean =>
        params.value.some((record: { name: string }) =>
          record.name.toLowerCase().includes(filterItem.value.toLowerCase())
        );
    },
    InputComponent: GridFilterInputValue,
  },
  {
    label: 'equals',
    value: 'equals',
    getApplyFilterFn: (filterItem: GridFilterItem) => {
      if (_.isNull(filterItem.value) || _.isUndefined(filterItem.value))
        return null;
      return (params): boolean =>
        params.value.some(
          (record: { name: string }) =>
            record.name.toLowerCase() === filterItem.value.toLowerCase()
        );
    },
    InputComponent: GridFilterInputValue,
  },
];

export const tagsFilterOperators: GridFilterOperator[] = [
  {
    label: 'contains',
    value: 'contains',
    getApplyFilterFn: (filterItem: GridFilterItem) => {
      if (!filterItem.value) return null;
      return (params): boolean =>
        params.value.some((record: { name: string }) =>
          record.name.toLowerCase().includes(filterItem.value.toLowerCase())
        );
    },
    InputComponent: GridFilterInputValue,
  },
];

export const getApplyFilterFnPartInStockLot = (value: string) => {
  if (!value) {
    return null;
  }
  return (params: GridCellParams): boolean =>
    !params.row.stockLot.part
      ? false
      : typeof params.row.stockLot.part === 'string'
      ? _.lowerCase(params.row.stockLot.part).includes(_.lowerCase(value))
      : _.lowerCase(
          _.compact([
            params.row.stockLot.part.id,
            params.row.stockLot.part.mpn,
            params.row.stockLot.part.mfg,
            params.row.stockLot.part.description,
            _.join(params.row.stockLot.part.altMpns || [], ' '),
          ]).join(' ')
        ).includes(_.lowerCase(value));
};

export const getApplyFilterFnPart = (value: string) => {
  if (!value) {
    return null;
  }
  return (params: GridCellParams): boolean =>
    !params.row.part
      ? false
      : typeof params.row.part === 'string'
      ? _.lowerCase(params.row.part).includes(_.lowerCase(value))
      : _.lowerCase(
          _.compact([
            params.row.part.id,
            params.row.part.mpn,
            params.row.part.mfg,
            params.row.part.description,
            params.row.part.customId,
            _.join(params.row.part.altMpns || [], ' '),
          ]).join(' ')
        ).includes(_.lowerCase(value));
};

export const getApplyFilterFnPartAndDescription = (value: string) => {
  if (!value) {
    return null;
  }
  return (params: GridCellParams): boolean =>
    !params.row.part
      ? _.lowerCase(params.row.description || '').includes(_.lowerCase(value))
      : typeof params.row.part === 'string'
      ? _.lowerCase(params.row.part).includes(_.lowerCase(value))
      : _.lowerCase(
          _.compact([
            params.row.part.id,
            params.row.part.mpn,
            params.row.part.mfg,
            params.row.part.description,
            params.row.part.customId,
            _.join(params.row.part.altMpns || [], ' '),
          ]).join(' ')
        ).includes(_.lowerCase(value));
};

export const getApplyFilterFnOrgPart = (value: string) => {
  if (!value) {
    return null;
  }
  return (params: GridCellParams): boolean =>
    !params.row.orgPart
      ? false
      : typeof params.row.orgPart === 'string'
      ? _.lowerCase(params.row.orgPart).includes(_.lowerCase(value))
      : _.lowerCase(
          _.compact([
            params.row.orgPart.id,
            params.row.orgPart.mpn,
            params.row.orgPart.mfg,
            params.row.orgPart.description,
            params.row.orgPart.customId,
            _.join(params.row.orgPart.altMpns || [], ' '),
          ]).join(' ')
        ).includes(_.lowerCase(value));
};

export const getApplyFilterFnOrgPartAndDescription = (value: string) => {
  if (!value) {
    return null;
  }
  return (params: GridCellParams): boolean =>
    !params.row.orgPart
      ? _.lowerCase(params.row.description || '').includes(_.lowerCase(value))
      : typeof params.row.orgPart === 'string'
      ? _.lowerCase(params.row.orgPart).includes(_.lowerCase(value))
      : _.lowerCase(
          _.compact([
            params.row.orgPart.id,
            params.row.orgPart.mpn,
            params.row.orgPart.mfg,
            params.row.orgPart.description,
            params.row.orgPart.customId,
            _.join(params.row.orgPart.altMpns || [], ' '),
          ]).join(' ')
        ).includes(_.lowerCase(value));
};

export const getApplyFilterFnRelatedRecords = (value: string) => {
  if (!value) {
    return null;
  }
  return (params: GridCellParams): boolean =>
    !params.value
      ? false
      : _.lowerCase(_.map(params.value, 'name').join(' ')).includes(
          _.lowerCase(value)
        );
};

export const getApplyFilterFnTags = (value: string) => {
  if (!value) {
    return null;
  }
  return (params: GridCellParams): boolean =>
    !params.value
      ? false
      : _.lowerCase(_.map(params.value, 'name').join(' ')).includes(
          _.lowerCase(value)
        );
};

export const getApplyFilterFnCaseInsensitive = (value: string) => {
  if (!value) {
    return null;
  }
  return (params: GridCellParams): boolean =>
    !params.value
      ? false
      : _.lowerCase(_.toString(params.value)).includes(_.lowerCase(value));
};
