import { ReactNode } from 'react';
import { Typography } from '@mui/material';

type NoRowsOverlayComponentProps = {
  noRows?: string | ReactNode;
};

export default function NoRowsOverlayComponent({
  noRows,
}: NoRowsOverlayComponentProps) {
  return (
    <Typography sx={{ textAlign: 'center', mt: 4 }} variant="subtitle1">
      {noRows}
    </Typography>
  );
}
