import {
  apiSlice,
  createClientV2QueryFn,
  createClientV2MutationFn,
} from 'store/slices/api';
import { parseQueryParams } from 'store/slices/utils';
import { ListResponse, QueryParams } from 'types/api';
import { TagSchema } from 'types/clientV2/tag';
import { CLIENT_V2_URLS } from 'store/slices/constants/clientV2';

export const extendedApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getTags: builder.query<ListResponse<TagSchema>, QueryParams<TagSchema>>({
      queryFn: createClientV2QueryFn((params) =>
        CLIENT_V2_URLS.TAGS(parseQueryParams<TagSchema>(params))
      ),
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(
                ({ id }: TagSchema) =>
                  ({
                    type: 'Tag',
                    id,
                  } as const)
              ),
              { type: 'Tag', id: 'LIST' },
            ]
          : [{ type: 'Tag', id: 'LIST' }],
    }),
    createTag: builder.mutation<TagSchema, Partial<TagSchema[]>>({
      queryFn: createClientV2MutationFn<TagSchema, Partial<TagSchema[]>>(
        (data) => ({
          url: CLIENT_V2_URLS.TAGS(),
          method: 'POST',
          body: data,
        })
      ),
      invalidatesTags: [{ type: 'Tag', id: 'LIST' }],
    }),
    updateTag: builder.mutation<TagSchema, Partial<TagSchema[]>>({
      queryFn: createClientV2MutationFn<TagSchema, Partial<TagSchema[]>>(
        (data) => ({
          url: CLIENT_V2_URLS.TAGS(),
          method: 'PATCH',
          body: data,
        })
      ),
      invalidatesTags: [{ type: 'Tag', id: 'LIST' }],
    }),
    deleteTag: builder.mutation<
      { deleted_ids: TagSchema['id'][] },
      { ids: TagSchema['id'][] }
    >({
      queryFn: createClientV2MutationFn<
        { deleted_ids: TagSchema['id'][] },
        { ids: TagSchema['id'][] }
      >((data) => ({
        url: CLIENT_V2_URLS.TAGS(),
        method: 'DELETE',
        body: data,
      })),
      invalidatesTags: [{ type: 'Tag', id: 'LIST' }],
    }),
  }),
});

export const {
  useGetTagsQuery,
  useCreateTagMutation,
  useUpdateTagMutation,
  useDeleteTagMutation,
} = extendedApiSlice;
