import { useMemo, useCallback, MutableRefObject } from 'react';
import { Grid } from '@mui/material';
import { GridApiPremium } from '@mui/x-data-grid-premium/models/gridApiPremium';
import { DataGridPremiumProps } from '@mui/x-data-grid-premium';
import _ from 'lodash';

import { ExtendedGridColDef } from 'types/datagrid';
import { gridSpacing } from 'constants/themeConstants';
import AccordionCard from 'ui-component/cards/AccordionCard';
import DataGridChart from 'ui-component/DataGrid/DatagridChart';

type DataGridChartsContainerProps = {
  apiRef: MutableRefObject<GridApiPremium>;
  datagridColumnVisibilityModel: { [key: string]: boolean };
  loading: boolean;
  loadingCharts: boolean;
  memoizedColumns: ExtendedGridColDef[];
  rows: DataGridPremiumProps['rows'];
};

export default function DataGridChartsContainer({
  apiRef,
  datagridColumnVisibilityModel,
  loading,
  loadingCharts,
  memoizedColumns,
  rows,
}: DataGridChartsContainerProps) {
  const chartColumns = useMemo(
    () =>
      _.chain(memoizedColumns)
        .filter('showChart')
        .filter(
          (c) =>
            !!c.showChart?.alwaysVisible ||
            !!datagridColumnVisibilityModel[c.field]
        )
        .value(),
    [memoizedColumns, datagridColumnVisibilityModel]
  );

  const chartAccordionCards = useMemo(
    () =>
      _.chain(chartColumns)
        .map((c) => c.showChart?.accordionCardGroupTitle)
        .compact()
        .uniq()
        .value(),
    [chartColumns]
  );

  const handleChartFilterClick = useCallback(
    (field: string, value: string) => {
      const currentFilterModelItems =
        apiRef.current?.state?.filter.filterModel.items;
      const currentFilterModelQuickfilter =
        apiRef?.current?.state?.filter.filterModel.quickFilterValues;
      // check if filter already exists
      if (_.find(currentFilterModelItems, { columnField: field })) {
        // check if filter value is already active and remove filter if it is
        if (
          _.find(currentFilterModelItems, { columnField: field })?.value ===
          value
        ) {
          apiRef?.current?.setFilterModel({
            items: [
              ..._.filter(
                currentFilterModelItems,
                (f) => f.columnField !== field
              ),
            ],
            quickFilterValues: currentFilterModelQuickfilter,
          });
        } else {
          // if filter exists but is a different value, then update the filter value
          apiRef?.current?.setFilterModel({
            items: [
              ..._.filter(
                currentFilterModelItems,
                (f) => f.columnField !== field
              ),
              { columnField: field, operatorValue: 'is', value },
            ],
            quickFilterValues: currentFilterModelQuickfilter,
          });
        }
      } else {
        apiRef?.current?.setFilterModel({
          items: [
            ...currentFilterModelItems,
            { columnField: field, operatorValue: 'is', value },
          ],
          quickFilterValues: currentFilterModelQuickfilter,
        });
      }
    },
    [
      apiRef?.current?.state?.filter.filterModel.items,
      apiRef?.current?.state?.filter.filterModel.quickFilterValues,
      apiRef?.current?.setFilterModel,
    ]
  );

  const charts = useMemo(() => {
    if (!chartColumns.length) {
      return [];
    }
    return _.filter(
      chartColumns,
      (c) => !c.showChart?.accordionCardGroupTitle
    ).map((c) => ({
      column: c,
      data: _.map(apiRef?.current?.state?.rows?.ids || [], (rowId) =>
        apiRef?.current?.getCellValue(rowId, c.field)
      ),
      selectedValue: _.find(apiRef?.current?.state?.filter.filterModel.items, {
        columnField: c.field,
      })?.value,
      partIds: _.chain(rows)
        .map((r) => _.get(r, 'part', null))
        .compact()
        .map((p) => (typeof p === 'string' ? p : p.id))
        .uniq()
        .value(),
      loading: loadingCharts === undefined ? loading : loadingCharts,
    }));
  }, [chartColumns, rows, loadingCharts, loading]);

  if (!chartColumns.length) {
    return null;
  }

  return (
    <Grid container item xs={12} spacing={gridSpacing} alignItems="stretch">
      {charts.map((chart) => (
        <Grid item xs={3} key={chart.column.field}>
          <DataGridChart
            column={chart.column}
            data={chart.data}
            onFilterClick={handleChartFilterClick}
            selectedValue={chart.selectedValue}
            partIds={chart.partIds}
            loading={chart.loading}
          />
        </Grid>
      ))}
      {chartAccordionCards.map((cardTitle) => (
        <Grid item xs={12} key={cardTitle}>
          <AccordionCard title={cardTitle} unmountOnExit>
            <Grid container spacing={gridSpacing} alignItems="stretch">
              {_.filter(
                chartColumns,
                (c) => c.showChart?.accordionCardGroupTitle === cardTitle
              ).map(
                (c) =>
                  !!c.showChart && (
                    <Grid item xs={3} key={c.field}>
                      <DataGridChart
                        column={c}
                        data={_.map(
                          apiRef?.current?.state?.rows?.ids || [],
                          (rowId) =>
                            apiRef?.current?.getCellValue(rowId, c.field)
                        )}
                        onFilterClick={handleChartFilterClick}
                        selectedValue={
                          _.find(
                            apiRef?.current?.state?.filter.filterModel.items,
                            {
                              columnField: c.field,
                            }
                          )?.value
                        }
                        partIds={_.chain(rows)
                          .map((r) => _.get(r, 'part', null))
                          .compact()
                          .map((p) => (typeof p === 'string' ? p : p.id))
                          .uniq()
                          .value()}
                        loading={
                          loadingCharts === undefined ? loading : loadingCharts
                        }
                      />
                    </Grid>
                  )
              )}
            </Grid>
          </AccordionCard>
        </Grid>
      ))}
    </Grid>
  );
}
