import { ExtendedDialog } from 'ui-component/extended/ExtendedDialog';
import { useSelector } from 'store';
import {
  useCreateStockLocationMutation,
  useUpdateStockLocationMutation,
  useGetStockLocationQuery,
} from 'store/slices/apiV1/inventory';
import { skipToken } from '@reduxjs/toolkit/dist/query/react';
import {
  StockLocation,
  StockLocationCreate,
  StockLocationUpdate,
} from 'types/inventory';
import { Org, OrgAddressBase } from 'types/org';
import { useGetPriceModelQuery } from 'store/slices/apiV1/purchasing';
import { useGetOrgQuery } from 'store/slices/apiV1/org';
import { FieldValues, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  addressFieldIds,
  formConstants,
  locationTypes,
  validationSchema,
} from 'views/inventory/components/CreateUpdateFacilityDialog/constants';
import { useEffect, useMemo, useState } from 'react';
import _ from 'lodash';
import {
  manufacturingConstantKeys,
  manufacturingConstants,
} from 'constants/toggleConstants';
import { ErrorType, handleErr } from 'utils/functions';
import { getCopy } from 'views/inventory/components/CreateUpdateFacilityDialog/copy';
import { ALL_APPS } from 'constants/appConstants';
import { appPermissionAccessLevels } from 'types/apps';
import VerticalStepper from 'ui-component/VerticalStepper';
import {
  HookFormToggleCardButton,
  HookFormInput,
} from 'ui-component/HookFormComponents';
import { AddressFields } from 'views/inventory/components/CreateUpdateFacilityDialog/AddressFields';
import { Alert } from '@mui/material';
import useSnackbar from 'hooks/useSnackbar';
import useGetCustomPropertyDefinitions from 'hooks/useGetCustomPropertyDefinitions';
import {
  CustomPropertyModel,
  CustomPropertyVisibility,
} from 'types/customProperty';

type FacilityCreateSubmitError = ErrorType & {
  data: {
    details: {
      address: {
        phone: string[];
      };
    };
  };
};

const CreateUpdateFacilityDialog = ({
  dialogOpen,
  orgId,
  onClose,
  selectedLocation,
  onCreateSuccess,
}: {
  dialogOpen: boolean;
  orgId?: Org['id'];
  onClose: () => void;
  selectedLocation?: StockLocation | StockLocation['id'] | null;
  onCreateSuccess?: (location: StockLocation) => void;
}) => {
  const isEdit = !!selectedLocation;
  const { data: queriedStockLocation } = useGetStockLocationQuery(
    typeof selectedLocation === 'string' ? selectedLocation : skipToken
  );
  const selectedLocationData = useMemo(
    () =>
      typeof selectedLocation === 'string'
        ? queriedStockLocation
        : selectedLocation,
    [selectedLocation, queriedStockLocation]
  );

  const [createStockLocation, { isLoading: isCreatingStockLocation }] =
    useCreateStockLocationMutation();
  const [updateStockLocation, { isLoading: isUpdatingStockLocation }] =
    useUpdateStockLocationMutation();
  const [submitDisabled, setSubmitDisabled] = useState<boolean>(true);
  const [submitError, setSubmitError] = useState<string | null>(null);
  const { activeOrgId } = useSelector((state) => state.org);
  const { data: org } = useGetOrgQuery(activeOrgId ?? skipToken);
  const { data: priceModel } = useGetPriceModelQuery(
    org?.priceModel.id || skipToken
  );

  const {
    customProperties,
    getDefaultValuesForCustomProperties,
    customPropertiesFieldName,
  } = useGetCustomPropertyDefinitions({
    model: CustomPropertyModel.STOCK_LOCATION,
    visibilityContext: selectedLocation
      ? CustomPropertyVisibility.EDIT_RECORD_DIALOG
      : CustomPropertyVisibility.NEW_RECORD_DIALOG,
  });

  const { dispatchSuccessSnackbar } = useSnackbar();
  const copy = getCopy(!selectedLocationData?.id);

  const initialValues = {
    [formConstants.locationType.id]: locationTypes.facility.name,
    [formConstants.locationName.id]: selectedLocationData?.name ?? '',
    [formConstants.company.id]: selectedLocationData?.address?.company ?? '',
    [formConstants.name.id]: selectedLocationData?.address?.name ?? '',
    [formConstants.country.id]: selectedLocationData?.address?.country ?? '',
    [formConstants.state.id]: selectedLocationData?.address?.state ?? '',
    [formConstants.city.id]: selectedLocationData?.address?.city ?? '',
    [formConstants.postalCode.id]:
      selectedLocationData?.address?.postalCode ?? '',
    [formConstants.line1.id]: selectedLocationData?.address?.line1 ?? '',
    [formConstants.line2.id]: selectedLocationData?.address?.line2 ?? '',
    [formConstants.phone.id]: selectedLocationData?.address?.phone ?? '',
    [formConstants.notificationEmail.id]:
      selectedLocationData?.notificationEmail ?? '',
    [manufacturingConstantKeys.consumesStock]:
      selectedLocationData?.consumesStock ?? true,
    [manufacturingConstantKeys.acceptsSmtNoPnp]:
      selectedLocationData?.acceptsSmtNoPnp ?? false,
    [manufacturingConstantKeys.requiresSplits]:
      selectedLocationData?.requiresSplits ?? true,
    [manufacturingConstantKeys.packaging]: {
      [manufacturingConstantKeys.requiresReels]:
        selectedLocationData?.requiresReels ?? false,
      [manufacturingConstantKeys.requiresMerges]:
        selectedLocationData?.requiresMerges ?? false,
    },
    [formConstants.team.id]: selectedLocationData?.team?.id ?? '',
    [customPropertiesFieldName]:
      getDefaultValuesForCustomProperties<StockLocation>(selectedLocationData),
  };

  const {
    control,
    reset,
    setValue,
    watch,
    trigger,
    formState: { errors, touchedFields },
    handleSubmit,
    setError,
  } = useForm<FieldValues>({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
    mode: 'all',
  });

  useEffect(() => {
    reset(initialValues);
  }, [selectedLocationData, customProperties]);

  const watchAllFields = watch();

  const handleClose = () => {
    onClose();
    reset();
  };

  const onSubmit = async (data: FieldValues) => {
    try {
      const payload = _.omitBy(
        _.omit(data, [
          ...addressFieldIds,
          ...(!data[formConstants.notificationEmail.id]
            ? [formConstants.notificationEmail.id]
            : []),
          manufacturingConstantKeys.packaging,
        ]),
        (val) => _.isNil(val) || val === ''
      ) as StockLocationCreate;
      payload.address = _.omitBy(
        _.pick(data, addressFieldIds),
        (key) => !key
      ) as OrgAddressBase;
      payload.name = data.locationName;
      payload[manufacturingConstantKeys.requiresReels] =
        data.packaging.requiresReels;
      payload[manufacturingConstantKeys.requiresMerges] =
        data.packaging.requiresMerges;
      payload.orgId = orgId || activeOrgId;
      if (selectedLocationData?.id) {
        await updateStockLocation({
          stockLocationId: selectedLocationData.id,
          payload: payload as StockLocationUpdate,
        }).unwrap();
      } else {
        await createStockLocation({
          payload,
        })
          .unwrap()
          .then((fulfilled) => {
            onCreateSuccess && onCreateSuccess(fulfilled);
          });
      }
      dispatchSuccessSnackbar(copy.successMessage);
      handleClose();
    } catch (err) {
      if ((err as FacilityCreateSubmitError).data?.details?.address?.phone) {
        setError(formConstants.phone.id, {
          type: 'custom',
          message: (err as FacilityCreateSubmitError).data.details.address
            .phone[0],
        });
      } else {
        handleErr(err, (errMessage: string) => {
          setSubmitError(errMessage);
        });
      }
    }
  };

  return (
    <ExtendedDialog
      open={dialogOpen}
      maxWidth="md"
      title={copy.title}
      isForm
      onCloseDialog={handleClose}
      onSubmit={handleSubmit(onSubmit)}
      formSubmitError={submitError}
      submitButtonCopy={copy.submitButtonCopy}
      submitDisabled={submitDisabled && !isEdit}
      isSubmitting={isCreatingStockLocation || isUpdatingStockLocation}
      trackingNames={copy.trackingNames}
      permissionScope={{
        app: ALL_APPS.KITTING.id,
        accessLevel: appPermissionAccessLevels.edit,
      }}
    >
      {!isEdit ? (
        <VerticalStepper
          onValidate={(isValid) => setSubmitDisabled(!isValid)}
          steps={[
            {
              label: 'Address Name',
              preNext: async () => trigger([formConstants.locationName.id]),
              component: (
                <>
                  <HookFormInput
                    errors={errors}
                    control={control}
                    name={formConstants.locationName.id}
                    label={formConstants.locationName.label}
                    sx={{ mt: 2 }}
                  />
                </>
              ),
            },
            {
              label: 'Address',
              preNext: async () =>
                trigger([
                  formConstants.company.id,
                  formConstants.name.id,
                  formConstants.line1.id,
                  formConstants.line2.id,
                  formConstants.city.id,
                  formConstants.state.id,
                  formConstants.postalCode.id,
                  formConstants.country.id,
                  formConstants.phone.id,
                  formConstants.notificationEmail.id,
                ]),
              component: (
                <>
                  <AddressFields
                    control={control}
                    errors={errors}
                    touchedFields={touchedFields}
                    setValue={setValue}
                    teamId={selectedLocationData?.team?.id ?? ''}
                    customProperties={customProperties}
                  />
                </>
              ),
            },
            ...[
              ...manufacturingConstants(
                priceModel,
                watchAllFields[formConstants.locationName.id]
              ).alwaysShow,
              manufacturingConstants(
                priceModel,
                watchAllFields[formConstants.locationName.id]
              ).packaging,
            ].map((formField) => ({
              label: `${formField.label}`,
              preNext: async () => trigger([formField.name]),
              component: (
                <HookFormToggleCardButton
                  key={formField.name}
                  name={formField.name}
                  control={control}
                  errors={errors}
                  label={formField.description}
                  options={formField.options.map((option) => ({
                    value: option.value,
                    label: option?.label || formField.name,
                    description: option.description,
                  }))}
                  alert={
                    formField.name ===
                      manufacturingConstantKeys.consumesStock &&
                    !watchAllFields[formField.name] && (
                      <Alert severity="warning">
                        Are you sure that you want stock sent to this facility
                        to remain in Cofactr? This stock will still show as On
                        Hand in your inventory until you manually remove it.
                        Most users do not select this setting.
                      </Alert>
                    )
                  }
                />
              ),
            })),
          ]}
        />
      ) : (
        <>
          <HookFormInput
            errors={errors}
            control={control}
            name={formConstants.locationName.id}
            label={formConstants.locationName.label}
            sx={{ mt: 2 }}
          />
          <AddressFields
            control={control}
            errors={errors}
            touchedFields={touchedFields}
            setValue={setValue}
            customProperties={customProperties}
          />
          {[
            ...manufacturingConstants(
              priceModel,
              watchAllFields[formConstants.locationName.id]
            ).alwaysShow,
            manufacturingConstants(
              priceModel,
              watchAllFields[formConstants.locationName.id]
            ).packaging,
          ].map((formField) => (
            <HookFormToggleCardButton
              name={formField.name}
              control={control}
              errors={errors}
              label={formField.description}
              options={formField.options.map((option) => ({
                value: option.value,
                label: option?.label || formField.name,
                description: option.description,
              }))}
              alert={
                formField.name === manufacturingConstantKeys.consumesStock &&
                !watchAllFields[formField.name] && (
                  <Alert severity="warning">
                    Are you sure that you want stock sent to this facility to
                    remain in Cofactr? This stock will still show as On Hand in
                    your inventory until you manually remove it. Most users do
                    not select this setting.
                  </Alert>
                )
              }
            />
          ))}
        </>
      )}
    </ExtendedDialog>
  );
};

export default CreateUpdateFacilityDialog;
