import { useEffect, memo, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { Avatar, Box, Tooltip } from '@mui/material';
import LogoSection from 'layout/MainLayout/LogoSection';
import MobileSection from 'layout/MainLayout/Header/MobileSection';
import ProfileSection from 'layout/MainLayout/Header/ProfileSection';
import { useDispatch, useSelector } from 'store';
import { openDrawer } from 'store/slices/menu';
import ActiveOrgSwitcher from 'layout/MainLayout/Header/ActiveOrgSwitcher';
import { setViewAsStaff, useGetUserQuery } from 'store/slices/apiV1/user';
import { skipToken } from '@reduxjs/toolkit/query/react';
import { useNavigate } from 'react-router-dom';
import { MOBILE_INVENTORY_ROUTES } from 'routes/constants';
import {
  IconMenu2,
  IconBuildingWarehouse,
  IconBug,
  IconUserShield,
} from '@tabler/icons-react';
import KnockNotificationWidget from 'ui-component/third-party/KnockNotificationWidget';
import { ALL_APPS, cofactrName } from 'constants/appConstants';
import { useAppAccessContext } from 'contexts/AppAccessContext';
import { IS_KNOCK_PROD } from 'constants/envConstants';
import BugReportDialog from 'ui-component/BugReportDialog';
import { isCofactrUser } from 'utils/userPermissions';
import _ from 'lodash';
import { init } from 'commandbar';
import { HelpHub } from 'ui-component/Commandbar/HelpHub';

const Header = () => {
  const theme = useTheme();
  const { isLoggedIn } = useSelector(({ session }) => session);
  const { isSuccess: isGetUserSuccess, data: user } = useGetUserQuery(
    isLoggedIn ? undefined : skipToken
  );
  const { viewAsStaff } = useSelector((state) => state.user);
  const isCofactr = isCofactrUser({ user, viewAsStaff });

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { drawerOpen } = useSelector((state) => state.menu);
  const { activeOrgId } = useSelector((state) => state.org);
  const { hasAppPermission } = useAppAccessContext();

  const showHamburger =
    isGetUserSuccess && !window.location.pathname.includes('account');

  const renderStaffUserToggle =
    _.find(user?.orgs, ['id', user?.org])?.name === cofactrName;

  const [bugReportDialogOpen, setBugReportDialogOpen] = useState(false);

  useEffect(() => {
    if (process.env.COMMANDBAR_KEY && !window.CommandBar) {
      init(process.env.COMMANDBAR_KEY);
    }
    if (window.CommandBar) {
      window.CommandBar.addRouter(navigate);
      if (user) {
        window.CommandBar.boot(user?.id);
      }
    }

    return undefined;
  }, [user]);

  return (
    <>
      {bugReportDialogOpen && (
        <BugReportDialog
          open={bugReportDialogOpen}
          onClose={() => setBugReportDialogOpen(false)}
        />
      )}
      <Box
        sx={{
          width: 228,
          display: 'flex',
          [theme.breakpoints.down('md')]: {
            width: 48,
          },
        }}
      >
        <Box
          component="span"
          sx={{ display: { xs: 'none', md: 'block' }, flexGrow: 1 }}
        >
          <LogoSection />
        </Box>
        {showHamburger && (
          <Tooltip title="Collapse/Expand navigation sidebar">
            <Avatar
              variant="rounded"
              sx={{
                ...theme.typography.commonAvatar,
                ...theme.typography.mediumAvatar,
                overflow: 'hidden',
                transition: 'all .2s ease-in-out',
                background: theme.palette.brand.moss,
                color: theme.palette.brand.steel,
                '&:hover': {
                  background: theme.palette.primary.dark,
                  color: theme.palette.common.white,
                },
              }}
              onClick={() => dispatch(openDrawer(!drawerOpen))}
              color="inherit"
            >
              <IconMenu2 stroke={1.5} size="1.3rem" />
            </Avatar>
          </Tooltip>
        )}
      </Box>

      {/* Necessary for padding */}
      <Box sx={{ flexGrow: 1 }}>{activeOrgId && <ActiveOrgSwitcher />}</Box>
      <Box sx={{ flexGrow: 1 }} />

      {isCofactr && (
        <Box sx={{ pr: 3 }}>
          <Tooltip title="Report Bug">
            <Avatar
              variant="rounded"
              sx={{
                ...theme.typography.commonAvatar,
                ...theme.typography.mediumAvatar,
                overflow: 'hidden',
                transition: 'all .2s ease-in-out',
                background: theme.palette.brand.moss,
                color: theme.palette.brand.steel,
                '&:hover': {
                  background: theme.palette.primary.dark,
                  color: theme.palette.common.white,
                },
              }}
              onClick={() => setBugReportDialogOpen(true)}
              color="inherit"
            >
              <IconBug stroke={1.5} size="1.3rem" />
            </Avatar>
          </Tooltip>
        </Box>
      )}

      {renderStaffUserToggle && (
        <Box sx={{ pr: 3 }}>
          <Tooltip
            title={viewAsStaff ? 'Disable staff mode' : 'Enable staff mode'}
          >
            <Avatar
              variant="rounded"
              sx={{
                ...theme.typography.commonAvatar,
                ...theme.typography.mediumAvatar,
                overflow: 'hidden',
                transition: 'all .2s ease-in-out',
                background: viewAsStaff
                  ? theme.palette.success.main
                  : theme.palette.brand.moss,
                color: viewAsStaff
                  ? theme.palette.common.white
                  : theme.palette.brand.steel,
                '&:hover': {
                  background: theme.palette.primary.dark,
                  color: theme.palette.common.white,
                },
              }}
              onClick={() => {
                dispatch(setViewAsStaff(!viewAsStaff));
              }}
              color="inherit"
            >
              <IconUserShield stroke={1.5} size="1.3rem" />
            </Avatar>
          </Tooltip>
        </Box>
      )}

      {activeOrgId && isLoggedIn && (
        <Box sx={{ pr: 3 }}>
          <KnockNotificationWidget authWithKnock={IS_KNOCK_PROD} />
        </Box>
      )}

      {hasAppPermission(ALL_APPS.WAREHOUSE.id) && (
        <Box sx={{ pr: 3 }}>
          <Tooltip title="Warehouse App">
            <Avatar
              variant="rounded"
              sx={{
                ...theme.typography.commonAvatar,
                ...theme.typography.mediumAvatar,
                overflow: 'hidden',
                transition: 'all .2s ease-in-out',
                background: theme.palette.brand.moss,
                color: theme.palette.brand.steel,
                '&:hover': {
                  background: theme.palette.primary.dark,
                  color: theme.palette.common.white,
                },
              }}
              onClick={() => navigate(MOBILE_INVENTORY_ROUTES.DASHBOARD)}
              color="inherit"
            >
              <IconBuildingWarehouse stroke={1.5} size="1.3rem" />
            </Avatar>
          </Tooltip>
        </Box>
      )}

      <ProfileSection />

      {/* mobile header */}
      <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
        <MobileSection />
      </Box>
      <HelpHub />
    </>
  );
};

export default memo(Header);
