import { Fragment, memo, useMemo, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import {
  Avatar,
  Box,
  Divider,
  Drawer,
  Grid,
  IconButton,
  Stack,
  Tooltip,
} from '@mui/material';
import PerfectScrollbar from 'react-perfect-scrollbar';
import LogoSection from 'layout/MainLayout/LogoSection';
import { activeItem, openDrawer } from 'store/slices/menu';
import { useDispatch, useSelector } from 'store';
import cofactrLogo from 'assets/images/cofactr_logo.png';
import cofactrSmallLogo from 'assets/images/Cofactr_logomark_clover_256x256.png';
import {
  drawerWidth,
  collapsedDrawerWidth,
  actionButtonSpacing,
  roundedButtonAvatarSx,
} from 'constants/themeConstants';
import { setViewAsStaff, useGetUserQuery } from 'store/slices/apiV1/user';
import {
  IconBug,
  IconDashboard,
  IconLayoutSidebarLeftCollapse,
  IconLayoutSidebarLeftExpand,
  IconUserShield,
} from '@tabler/icons-react';
import ActiveOrgSwitcher from 'layout/MainLayout/Header/ActiveOrgSwitcher';
import _ from 'lodash';
import { CommandBar } from 'ui-component/Commandbar/Commandbar';
import useMenuItems from 'layout/MainLayout/Sidebar/useMenuItems';
import NavGroup from 'layout/MainLayout/Sidebar/MenuList/NavGroup';
import NavItem from 'layout/MainLayout/Sidebar/MenuList/NavItem';
import { useAppAccessContext } from 'contexts/AppAccessContext';
import { appPermissionStates } from 'types/apps';
import useConfig from 'hooks/useConfig';
import { useNavigate } from 'react-router-dom';
import ProfileSection from 'layout/MainLayout/Header/ProfileSection';
import KnockNotificationWidget from 'ui-component/third-party/KnockNotificationWidget';
import { IS_KNOCK_PROD } from 'constants/envConstants';
import { isCofactrUser } from 'utils/userPermissions';
import BugReportDialog from 'ui-component/BugReportDialog';
import AppButton from 'layout/MainLayout/Sidebar/AppButton';
import { cofactrName } from 'constants/appConstants';
import { useNewVersion } from 'contexts/NewVersionContext';
import { DASHBOARD_ROUTE } from 'routes/constants';
import { HelpHub } from 'ui-component/Commandbar/HelpHub';

interface SidebarProps {
  window?: Window;
}

const Sidebar = ({ window }: SidebarProps) => {
  const theme = useTheme();
  const { borderRadius } = useConfig();
  const { activeOrgId } = useSelector((state) => state.org);
  const dispatch = useDispatch();
  const { drawerOpen, openItem } = useSelector((state) => state.menu);
  const menuItems = useMenuItems();
  const { appsWithPermissions } = useAppAccessContext();
  const { isSuccess: isGetUserSuccess, data: user } = useGetUserQuery();
  const navigate = useNavigate();
  const { isLoggedIn } = useSelector(({ session }) => session);
  const { viewAsStaff } = useSelector((state) => state.user);
  const isCofactr = isCofactrUser({ user, viewAsStaff });
  const { showNoPlanBanner } = useSelector((state) => state.utility);
  const { showNewVersion } = useNewVersion();

  const orgOptions = useMemo(() => {
    if (!user || user?.orgs.length < 2) return [];
    return _.chain(user?.orgs)
      .filter((o) => !o.createdFrom)
      .sortBy('name')
      .map((o) => [
        o,
        ..._.chain(user?.orgs)
          .filter(
            (co) =>
              (typeof co.createdFrom === 'string'
                ? co.createdFrom
                : co.createdFrom?.id) === o.id
          )
          .value(),
      ])
      .flatten()
      .value();
  }, [user?.orgs]);

  const navApps = useMemo(
    () =>
      _.chain(appsWithPermissions)
        .values()
        .filter(
          (a) =>
            (a.includeInSidebar || false) &&
            a.permissions?.state === appPermissionStates.enabled
        )
        .value(),
    [appsWithPermissions]
  );

  const itemHandler = (id: string) => {
    dispatch(activeItem([id]));
  };

  const navSection = useMemo(
    () => (
      <PerfectScrollbar component="div">
        <Stack
          direction="column"
          sx={{
            justifyContent: 'flex-start',
            alignItems: 'stretch',
            p: 2,
          }}
        >
          {drawerOpen &&
            menuItems.items.map((item) => {
              switch (item.type) {
                case 'group':
                  return item?.children && item.children.length > 0 ? (
                    <NavGroup key={item.id} item={item} />
                  ) : null;
                default:
                  return <NavItem key={item.id} item={item} level={1} />;
              }
            })}
          {!drawerOpen && (
            <>
              <Tooltip title="Dashboard">
                <IconButton
                  sx={{
                    borderRadius: `${borderRadius}px`,
                    width: '42px',
                    height: '42px',
                    backgroundColor: _.includes(openItem, 'dashboard')
                      ? theme.palette.brand.moss
                      : 'transparent',
                    color: _.includes(openItem, 'dashboard')
                      ? theme.palette.primary.main
                      : undefined,
                  }}
                  onClick={() => {
                    navigate(DASHBOARD_ROUTE);
                    itemHandler('dashboard');
                  }}
                >
                  <IconDashboard stroke={1.5} size="1.3rem" />
                </IconButton>
              </Tooltip>
              {_.filter(navApps, (a) => !a.appGroup).map((app) => (
                <AppButton key={app.id} app={app} />
              ))}
              {_.chain(navApps)
                .filter((a) => !!a.appGroup)
                .groupBy('appGroup.id')
                .values()
                .value()
                .map((apps) => (
                  <Fragment key={apps[0].appGroup?.id}>
                    <Divider flexItem sx={{ my: 1 }} />
                    {apps.map((app) => (
                      <AppButton key={app.id} app={app} />
                    ))}
                  </Fragment>
                ))}
            </>
          )}
        </Stack>
      </PerfectScrollbar>
    ),
    [drawerOpen, navApps, openItem]
  );

  const renderStaffUserToggle =
    _.find(user?.orgs, ['id', user?.org])?.name === cofactrName;

  const [bugReportDialogOpen, setBugReportDialogOpen] = useState(false);

  const container =
    window !== undefined ? () => window.document.body : undefined;

  const selectedApp = _.find(
    appsWithPermissions,
    (a) =>
      _.includes(openItem, a.id as string) ||
      _.some(_.keys(a.views || {}), (key) => _.includes(openItem, key))
  );

  return (
    <Box
      component="nav"
      sx={{
        flexShrink: { md: 0 },
        width: drawerOpen ? drawerWidth : collapsedDrawerWidth,
      }}
    >
      <Drawer
        container={container}
        variant="permanent"
        anchor="left"
        open={drawerOpen}
        onClose={() => dispatch(openDrawer(!drawerOpen))}
        sx={{
          '& .MuiDrawer-paper': {
            width: drawerOpen ? drawerWidth : collapsedDrawerWidth,
            background: theme.palette.background.default,
            color: theme.palette.text.primary,
            borderRight: 'none',
            height: '100%',
            mt: showNewVersion || showNoPlanBanner ? '30px' : 0,
          },
        }}
        ModalProps={{ keepMounted: true }}
        color="inherit"
      >
        <Box
          sx={{
            position: 'fixed',
            top: '16px',
            left: `${
              (drawerOpen ? drawerWidth : collapsedDrawerWidth) - 34 / 2
            }px`,
            zIndex: 100000,
          }}
        >
          <Avatar
            variant="rounded"
            sx={roundedButtonAvatarSx(theme)}
            onClick={() => dispatch(openDrawer(!drawerOpen))}
            color="inherit"
          >
            {drawerOpen ? (
              <IconLayoutSidebarLeftCollapse stroke={1.5} size="1.3rem" />
            ) : (
              <IconLayoutSidebarLeftExpand stroke={1.5} size="1.3rem" />
            )}
          </Avatar>
        </Box>
        <Grid
          container
          direction="column"
          sx={{
            justifyContent: 'space-between',
            alignItems: 'stretch',
            height: '100%',
          }}
        >
          {drawerOpen && (
            <Grid item xs="auto" sx={{ pb: 2, pl: 2, pt: '12px', pr: 3 }}>
              <Stack
                direction="row"
                sx={{
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  width: '100%',
                }}
              >
                <LogoSection />
                {!_.includes(openItem, 'dashboard') && !!selectedApp && (
                  <AppButton app={selectedApp} currentAppVariant />
                )}
              </Stack>
            </Grid>
          )}
          {activeOrgId && orgOptions.length > 0 && drawerOpen && (
            <Grid item xs="auto" sx={{ p: 2 }}>
              <ActiveOrgSwitcher />
            </Grid>
          )}
          {!drawerOpen &&
            !_.includes(openItem, 'dashboard') &&
            !!selectedApp && (
              <Grid item xs="auto" sx={{ pl: 1, pt: '12px', pb: 0 }}>
                <AppButton app={selectedApp} currentAppVariant />
              </Grid>
            )}
          {!drawerOpen &&
            (_.includes(openItem, 'dashboard') || !selectedApp) && (
              <Grid item xs="auto" sx={{ pl: 1, pt: '12px', pb: 0 }}>
                <Box sx={{ height: '42px' }} />
              </Grid>
            )}
          {isGetUserSuccess && (
            <Grid
              item
              xs="auto"
              sx={{ px: 2, pb: drawerOpen ? 2 : 0, pt: drawerOpen ? 0 : 2 }}
            >
              <CommandBar user={user} />
            </Grid>
          )}
          {drawerOpen && <Divider flexItem />}
          <Grid item xs sx={{ flexGrow: 1, overflowY: 'hidden' }}>
            {navSection}
          </Grid>
          <Divider flexItem />
          <Grid item xs="auto" sx={{ p: 2 }}>
            <Stack
              direction={drawerOpen ? 'row' : 'column-reverse'}
              spacing={actionButtonSpacing}
              sx={
                drawerOpen
                  ? {
                      justifyContent: 'center',
                      alignItems: 'center',
                    }
                  : {
                      justifyContent: 'flex-end',
                      alignItems: 'center',
                    }
              }
            >
              <ProfileSection />
              {activeOrgId && isLoggedIn && (
                <Box>
                  <KnockNotificationWidget authWithKnock={IS_KNOCK_PROD} />
                </Box>
              )}
              {isCofactr && (
                <Tooltip title="Report Bug">
                  <Avatar
                    variant="rounded"
                    sx={roundedButtonAvatarSx(theme)}
                    onClick={() => setBugReportDialogOpen(true)}
                    color="inherit"
                  >
                    <IconBug stroke={1.5} size="1.3rem" />
                  </Avatar>
                </Tooltip>
              )}

              {renderStaffUserToggle && (
                <Tooltip
                  title={
                    viewAsStaff ? 'Disable staff mode' : 'Enable staff mode'
                  }
                >
                  <Avatar
                    variant="rounded"
                    sx={{
                      ...roundedButtonAvatarSx(theme),
                      ...{
                        background: viewAsStaff
                          ? theme.palette.success.main
                          : theme.palette.brand.moss,
                        color: viewAsStaff
                          ? theme.palette.common.white
                          : theme.palette.brand.steel,
                      },
                    }}
                    onClick={() => {
                      dispatch(setViewAsStaff(!viewAsStaff));
                    }}
                    color="inherit"
                  >
                    <IconUserShield stroke={1.5} size="1.3rem" />
                  </Avatar>
                </Tooltip>
              )}
            </Stack>
          </Grid>
          <Grid
            item
            xs="auto"
            sx={{ p: 2, pt: 0, display: 'flex', justifyContent: 'center' }}
          >
            {drawerOpen && (
              <img src={cofactrLogo} alt="Cofactr Logo" width="100" />
            )}
            {!drawerOpen && (
              <img src={cofactrSmallLogo} alt="Cofactr Logo" width="24" />
            )}
          </Grid>
        </Grid>
      </Drawer>
      {bugReportDialogOpen && (
        <BugReportDialog
          open={bugReportDialogOpen}
          onClose={() => setBugReportDialogOpen(false)}
        />
      )}
      <HelpHub />
    </Box>
  );
};

export default memo(Sidebar);
