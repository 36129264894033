import {
  ADD_PARTS_ALTS,
  ADD_PARTS_CORE,
  ADD_PARTS_SPECS,
  ADD_PARTS_MARKET,
  ADD_PARTS_SUPPLY,
  handleOrgSwitch,
  logout,
  softLogout,
} from 'store/actions';
import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import { PartSchemas } from 'types/partStatus';
import _ from 'lodash';
import {
  PartAlts,
  PartCore,
  PartId,
  PartSpecs,
  PartMarket,
  PartSupply,
} from 'types/part';
import { DateString, GenericObject } from 'types';
import { URLS } from 'store/slices/constants/apiV1';
import { camelize } from 'utils/functions';
import { RootState } from 'store';
import createCachedSelector from 're-reselect';
import { fetchWithReauth } from 'store/slices/api';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';

export type PartDataStateProps = {
  [PartSchemas.core]: PartData<PartCore>;
  [PartSchemas.alts]: PartData<PartAlts>;
  [PartSchemas.specs]: PartData<PartSpecs>;
  [PartSchemas.supply]: PartData<PartSupply>;
  [PartSchemas.market]: PartData<PartMarket>;
};

type FetchedAt = { fetchedAt: DateString };

export type PartData<T extends GenericObject> = {
  byId: { [key: string]: T & FetchedAt };
  allIds: string[];
};

export const fetchPartCoreData = createAsyncThunk(
  ADD_PARTS_CORE,
  async (
    args: { parts: string[] },
    { getState, rejectWithValue, dispatch }
  ) => {
    const { parts } = args;
    const response = await fetchWithReauth(
      URLS.DESCRIBE_PARTS({ parts, schemas: [PartSchemas.core] }),
      getState as () => RootState,
      dispatch as ThunkDispatch<RootState, void, AnyAction>
    );
    if (!response.ok) {
      return rejectWithValue(response);
    }
    const data = await response.json();
    return camelize(data as GenericObject);
  }
);

export const fetchPartAltsData = createAsyncThunk(
  ADD_PARTS_ALTS,
  async (
    args: { parts: string[] },
    { getState, rejectWithValue, dispatch }
  ) => {
    const { parts } = args;
    const response = await fetchWithReauth(
      URLS.DESCRIBE_PARTS({ parts, schemas: [PartSchemas.alts] }),
      getState as () => RootState,
      dispatch as ThunkDispatch<RootState, void, AnyAction>
    );
    if (!response.ok) {
      return rejectWithValue(response);
    }
    const data = await response.json();
    return camelize(data as GenericObject);
  }
);

export const fetchPartSpecsData = createAsyncThunk(
  ADD_PARTS_SPECS,
  async (
    args: { parts: string[] },
    { getState, rejectWithValue, dispatch }
  ) => {
    const { parts } = args;

    const response = await fetchWithReauth(
      URLS.DESCRIBE_PARTS({ parts, schemas: [PartSchemas.specs] }),
      getState as () => RootState,
      dispatch as ThunkDispatch<RootState, void, AnyAction>
    );
    if (!response.ok) {
      return rejectWithValue(response);
    }
    const data = await response.json();
    return camelize(data as GenericObject);
  }
);

export const fetchPartSupplyData = createAsyncThunk(
  ADD_PARTS_SUPPLY,
  async (
    args: { parts: string[] },
    { getState, rejectWithValue, dispatch }
  ) => {
    const { parts } = args;

    const response = await fetchWithReauth(
      URLS.DESCRIBE_PARTS({ parts, schemas: [PartSchemas.supply] }),
      getState as () => RootState,
      dispatch as ThunkDispatch<RootState, void, AnyAction>
    );
    if (!response.ok) {
      return rejectWithValue(response);
    }
    const data = await response.json();
    return camelize(data as GenericObject);
  }
);

export const fetchPartMarketData = createAsyncThunk(
  ADD_PARTS_MARKET,
  async (
    args: { parts: string[] },
    { getState, rejectWithValue, dispatch }
  ) => {
    const { parts } = args;

    const response = await fetchWithReauth(
      URLS.DESCRIBE_PARTS({ parts, schemas: [PartSchemas.market] }),
      getState as () => RootState,
      dispatch as ThunkDispatch<RootState, void, AnyAction>
    );
    if (!response.ok) {
      return rejectWithValue(response);
    }
    const data = await response.json();
    return camelize(data as GenericObject);
  }
);

export const initialStatePartData = {
  [PartSchemas.core]: {
    byId: {},
    allIds: [],
  } as PartData<PartCore>,
  [PartSchemas.alts]: {
    byId: {},
    allIds: [],
  } as PartData<PartAlts>,
  [PartSchemas.specs]: {
    byId: {},
    allIds: [],
  } as PartData<PartSpecs>,
  [PartSchemas.supply]: {
    byId: {},
    allIds: [],
  } as PartData<PartSupply>,
  [PartSchemas.market]: {
    byId: {},
    allIds: [],
  } as PartData<PartMarket>,
};

const partData = createSlice({
  name: 'partData',
  initialState: initialStatePartData,
  reducers: {
    updatePartCoreFields(state, action) {
      const { partId, coreData } = action.payload;
      const currentPartCore = state[PartSchemas.core].byId[partId];
      const newPartCore = { ...currentPartCore, ...coreData };
      state[PartSchemas.core].byId[partId] = {
        ...newPartCore,
        fetchedAt: new Date(),
      } as PartCore & FetchedAt;
    },
    removePartDataByIdAndSchema(state, action) {
      const { partIds, schema } = action.payload;
      _.forEach(partIds, (id) => {
        state[schema as PartSchemas].byId = _.omit(
          state[schema as PartSchemas].byId,
          [id]
        );
        state[schema as PartSchemas].allIds = _.without(
          state[schema as PartSchemas].allIds,
          id
        );
      });
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(logout, () => initialStatePartData)
      .addCase(softLogout, () => initialStatePartData)
      .addCase(handleOrgSwitch, (state, action) => {
        const { customPartIds } = action.payload;
        //   remove part status for each supply, market and alt in the old org
        state[PartSchemas.supply] = initialStatePartData[PartSchemas.supply];
        state[PartSchemas.alts] = initialStatePartData[PartSchemas.alts];
        state[PartSchemas.market] = initialStatePartData[PartSchemas.market];

        _.forEach(customPartIds, (partId) => {
          delete state[PartSchemas.core]?.byId[partId];
          delete state[PartSchemas.specs]?.byId[partId];

          _.remove(state[PartSchemas.core].allIds, (id) => id === partId);
          _.remove(state[PartSchemas.specs].allIds, (id) => id === partId);
        });
      })
      .addCase(fetchPartCoreData.fulfilled, (state, action) => {
        _.forEach(action.payload, (partCore) => {
          state[PartSchemas.core].byId[partCore.id] = {
            ...partCore,
            fetchedAt: new Date(),
          } as PartCore & FetchedAt;
          state[PartSchemas.core].allIds = _.uniq([
            ...state[PartSchemas.core].allIds,
            partCore.id,
          ]);
        });
      })
      .addCase(fetchPartAltsData.fulfilled, (state, action) => {
        _.forEach(action.payload, (partAlts) => {
          state[PartSchemas.alts].byId[partAlts.id] = {
            ...partAlts,
            fetchedAt: new Date(),
          } as PartAlts & FetchedAt;
          state[PartSchemas.alts].allIds = _.uniq([
            ...state[PartSchemas.alts].allIds,
            partAlts.id,
          ]);
        });
      })
      .addCase(fetchPartSpecsData.fulfilled, (state, action) => {
        _.forEach(action.payload, (partSpecs) => {
          state[PartSchemas.specs].byId[partSpecs.id] = {
            ...partSpecs,
            fetchedAt: new Date(),
          } as PartSpecs & FetchedAt;
          state[PartSchemas.specs].allIds = _.uniq([
            ...state[PartSchemas.specs].allIds,
            partSpecs.id,
          ]);
        });
      })
      .addCase(fetchPartSupplyData.fulfilled, (state, action) => {
        _.forEach(action.payload, (partSupply) => {
          state[PartSchemas.supply].byId[partSupply.id] = {
            ...partSupply,
            fetchedAt: new Date(),
          } as PartSupply & FetchedAt;
          state[PartSchemas.supply].allIds = _.uniq([
            ...state[PartSchemas.supply].allIds,
            partSupply.id,
          ]);
        });
      })
      .addCase(fetchPartMarketData.fulfilled, (state, action) => {
        _.forEach(action.payload, (partMarket) => {
          state[PartSchemas.market].byId[partMarket.id] = {
            ...partMarket,
            fetchedAt: new Date(),
          } as PartMarket & FetchedAt;
          state[PartSchemas.market].allIds = _.uniq([
            ...state[PartSchemas.market].allIds,
            partMarket.id,
          ]);
        });
      });
  },
});

export default partData.reducer;

export const { updatePartCoreFields, removePartDataByIdAndSchema } =
  partData.actions;

export const partDataSelector = (state: RootState) => state.partData;

// Cached selector for each sub-slice
const selectCoreById = createCachedSelector(
  partDataSelector,
  (__: RootState, id: PartId) => id,
  (partDataState, id) => {
    const coreData = partDataState?.[PartSchemas.core]?.byId?.[id];
    return coreData !== undefined ? coreData : null;
  }
)((__, id) => id);

const selectSupplyById = createCachedSelector(
  partDataSelector,
  (__: RootState, id: PartId) => id,
  (partDataState, id) => {
    const supplyData = partDataState?.[PartSchemas.supply]?.byId?.[id];
    return supplyData !== undefined ? supplyData : null;
  }
)((__, id) => id);

const selectAltsById = createCachedSelector(
  partDataSelector,
  (__: RootState, id: PartId) => id,
  (partDataState, id) => {
    const altData = partDataState?.[PartSchemas.alts]?.byId?.[id];
    return altData !== undefined ? altData : null;
  }
)((__, id) => id);

const selectSpecsById = createCachedSelector(
  partDataSelector,
  (__: RootState, id: PartId) => id,
  (partDataState, id) => {
    const specsData = partDataState?.[PartSchemas.specs]?.byId?.[id];
    return specsData !== undefined ? specsData : null;
  }
)((__, id) => id);

const selectMarketById = createCachedSelector(
  partDataSelector,
  (__: RootState, id: PartId) => id,
  (partDataState, id) => {
    const marketData = partDataState?.[PartSchemas.market]?.byId?.[id];
    return marketData !== undefined ? marketData : null;
  }
)((__, id) => id);

// Selector to get an array of partById values for different sub-slices
export const selectPartsByIds = createCachedSelector(
  (state: RootState) => state,
  (___: RootState, partIds: PartId[]) => partIds,
  (___: RootState, ____, partListId) => partListId,
  // Third argument is partListId
  (state: RootState, partIds: PartId[]) => {
    const data = {} as { [key: string]: any };

    partIds.forEach((partId) => {
      if (partId) {
        const pd = {} as {
          core: PartCore | null;
          supply: PartSupply | null;
          alts: PartAlts | null;
          specs: PartSpecs | null;
          market: PartMarket | null;
        };

        pd.core = selectCoreById(state, partId);
        pd.supply = selectSupplyById(state, partId);
        pd.alts = selectAltsById(state, partId);
        pd.specs = selectSpecsById(state, partId);
        pd.market = selectMarketById(state, partId);

        data[partId] = pd;
      }
    });

    return data;
  }
)((___: RootState, ____: PartId[], partListId: string) => partListId);

export const getCustomizedParts = createSelector(partDataSelector, (pd) => {
  const coreById = pd[PartSchemas.core].byId;
  return _.map(
    _.filter(coreById, (part) => part?.isCustomizedInfo),
    'id'
  );
});
