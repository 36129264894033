import useCreateRecordDialog from 'hooks/useCreateRecordDialog';
import { HookFormInput } from 'ui-component/HookFormComponents';
import {
  createUpdateTeamTypeFormConstants,
  createUpdateTeamTypeValidationSchema,
} from 'views/properties/Teams/components/constants';
import { useCreateTeamTypeMutation } from 'store/slices/apiV1/properties';
import { useCallback, useEffect } from 'react';
import _ from 'lodash';
import useCustomTeamTypeName from 'hooks/useCustomTeamTypeName';
import { Org } from 'types/org';

type CreateTeamTypeDialogProps = {
  dialogOpen: boolean;
  onClose: () => void;
  onCreateSuccess?: (record: object) => void;
  overrideOrgId?: Org['id'];
};

const CreateTeamTypeDialog = ({
  dialogOpen,
  onClose,
  onCreateSuccess,
  overrideOrgId,
}: CreateTeamTypeDialogProps) => {
  const { handleReplaceWithCustomTeamName } = useCustomTeamTypeName();

  const formDefaults = {
    [createUpdateTeamTypeFormConstants.name.id]: '',
    [createUpdateTeamTypeFormConstants.pluralName.id]: '',
  };
  const { methods, control, errors, BaseCreateRecordDialog, dialogProps } =
    useCreateRecordDialog({
      formConstants: createUpdateTeamTypeFormConstants,
      validationSchema: createUpdateTeamTypeValidationSchema,
      newRecordDefaults: formDefaults,
      useCreateMutation: useCreateTeamTypeMutation,
      recordName: handleReplaceWithCustomTeamName('Team Type'),
      submitAsPayload: true,
      onSave: onCreateSuccess,
      otherProps: overrideOrgId
        ? {
            overrideOrgId,
          }
        : undefined,
    });

  const watchName = methods.watch(createUpdateTeamTypeFormConstants.name.id);
  const dirtyFields = methods.formState.dirtyFields;

  const debouncedSetValue = useCallback(
    _.debounce((value) => {
      methods.setValue(createUpdateTeamTypeFormConstants.pluralName.id, value);
    }, 500),
    []
  );

  useEffect(() => {
    if (
      !Object.keys(dirtyFields).includes(
        createUpdateTeamTypeFormConstants.pluralName.id
      )
    ) {
      debouncedSetValue(watchName ? `${watchName}s` : '');
    }
  }, [watchName, dirtyFields, debouncedSetValue]);

  return (
    <BaseCreateRecordDialog
      open={dialogOpen}
      onClose={onClose}
      dialogProps={dialogProps}
    >
      <HookFormInput
        name={createUpdateTeamTypeFormConstants.name.id}
        label={handleReplaceWithCustomTeamName(
          createUpdateTeamTypeFormConstants.name.label
        )}
        control={control}
        errors={errors}
      />
      <HookFormInput
        name={createUpdateTeamTypeFormConstants.pluralName.id}
        label={handleReplaceWithCustomTeamName(
          createUpdateTeamTypeFormConstants.pluralName.label
        )}
        control={control}
        errors={errors}
      />
    </BaseCreateRecordDialog>
  );
};

export default CreateTeamTypeDialog;
