import { memo } from 'react';
import { useNavigate } from 'react-router-dom';
import { apiSlice } from 'store/slices/api';
import { useTheme } from '@mui/material/styles';
import {
  Chip,
  ListItemIcon,
  ListItemText,
  Avatar,
  Tooltip,
  Menu,
  MenuItem,
} from '@mui/material';
import { IconLogout, IconSettings } from '@tabler/icons-react';
import useConfig from 'hooks/useConfig';
import {
  useGetUserQuery,
  useSignoutUserMutation,
} from 'store/slices/apiV1/user';
import { logout } from 'store/actions';
import { skipToken } from '@reduxjs/toolkit/query/react';
import { useDispatch, useSelector } from 'store';
import { useAppAccessContext } from 'contexts/AppAccessContext';
import { handleErr } from 'utils/functions';
import { activeItem } from 'store/slices/menu';
import { appPermissionStates } from 'types/apps';
import _ from 'lodash';
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state';

const ProfileSection = () => {
  const theme = useTheme();
  const { borderRadius } = useConfig();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { appsWithPermissions } = useAppAccessContext();
  const { drawerOpen } = useSelector((state) => state.menu);
  const { isLoggedIn } = useSelector((state) => state.session);
  const { data: user } = useGetUserQuery(isLoggedIn ? undefined : skipToken);
  const [signoutUser] = useSignoutUserMutation();

  const handleLogout = async () => {
    try {
      await signoutUser().unwrap();
    } catch (err) {
      handleErr(err, (__: string) => {});
    } finally {
      dispatch(logout());
      dispatch(apiSlice.util.resetApiState());
    }
  };

  const itemHandler = (id: string) => {
    dispatch(activeItem([id]));
  };

  if (!isLoggedIn) return null;

  return (
    <PopupState variant="popover" popupId="profile-menu">
      {(popupState) => (
        <>
          <Chip
            sx={{
              height: drawerOpen ? '48px' : '84px',
              width: drawerOpen ? '86px' : '42px',
              alignItems: 'center',
              borderRadius: `${borderRadius}px`,
              transition: 'all .2s ease-in-out',
              borderColor:
                theme.palette.mode === 'dark'
                  ? theme.palette.dark.main
                  : theme.palette.brand.moss,
              backgroundColor:
                theme.palette.mode === 'dark'
                  ? theme.palette.dark.main
                  : theme.palette.brand.moss,
              '&[aria-controls="menu-list-grow"], &:hover': {
                borderColor: theme.palette.primary.main,
                background: `${theme.palette.primary.main}!important`,
                color: theme.palette.brand.moss,
                '& svg': {
                  stroke: theme.palette.brand.moss,
                },
              },
              '& .MuiChip-label': {
                lineHeight: 0,
                textOverflow: 'clip',
              },
              flexDirection: drawerOpen ? 'row' : 'column-reverse',
              justifyContent: drawerOpen ? 'flex-start' : 'space-between',
              padding: drawerOpen ? '0' : '8px 0',
            }}
            icon={
              <Tooltip title="Settings">
                <Avatar
                  src={user?.image || ''}
                  sx={{
                    ...theme.typography.mediumAvatar,
                    margin: drawerOpen
                      ? '8px 0 8px 8px !important'
                      : '0 !important',
                    cursor: 'pointer',
                  }}
                  color={theme.palette.primary.main}
                >
                  {user?.firstName[0]}
                  {user?.lastName[0]}
                </Avatar>
              </Tooltip>
            }
            label={
              <IconSettings
                stroke={1.5}
                size="24px"
                color={theme.palette.primary.main}
              />
            }
            variant="outlined"
            {...bindTrigger(popupState)}
            onClick={(e) => {
              // To prevent clickthrough on the collapse action
              e.stopPropagation();
              bindTrigger(popupState).onClick(e);
            }}
            color="primary"
          />
          <Menu
            {...bindMenu(popupState)}
            onClose={(e, reason) => {
              // Necessary because e may not be an HTML event
              if (reason === 'backdropClick') {
                if (
                  'stopPropagation' in e &&
                  e.stopPropagation &&
                  typeof e.stopPropagation === 'function'
                ) {
                  bindMenu(popupState).onClose();
                  e.stopPropagation();
                }
              }
            }}
            variant="selectedMenu"
          >
            {_.chain(appsWithPermissions)
              .values()
              .filter(
                (a) =>
                  !!a.includeInSettingsMenu &&
                  a.permissions?.state === appPermissionStates.enabled
              )
              .value()
              .map((a) => {
                const Icon = a?.icon!;

                return (
                  <MenuItem
                    key={a.id}
                    onClick={(event) => {
                      event.stopPropagation();
                      navigate(a.path as string);
                      itemHandler(a.id);
                      bindMenu(popupState).onClose();
                    }}
                  >
                    <ListItemIcon>
                      <Icon />
                    </ListItemIcon>
                    <ListItemText>{a.name}</ListItemText>
                  </MenuItem>
                );
              })}
            <MenuItem key="logout" onClick={handleLogout}>
              <ListItemIcon>
                <IconLogout />
              </ListItemIcon>
              <ListItemText>Logout</ListItemText>
            </MenuItem>
          </Menu>
        </>
      )}
    </PopupState>
  );
};

export default memo(ProfileSection);
