import { useState } from 'react';
import ConfirmDialog from 'ui-component/shared/ConfirmDialog';
import { ALL_APPS, ALL_FEATURE_IDS } from 'constants/appConstants';
import { appPermissionAccessLevels } from 'types/apps';
import { handleErr } from 'utils/functions';
import useSnackbar from 'hooks/useSnackbar';
import { useDeleteDocumentMutation } from 'store/slices/apiV1/inventory';

const StockLotDocumentDeleteDialog = ({
  documentId,
  dialogOpen,
  onClose,
}: {
  documentId: string;
  dialogOpen: boolean;
  onClose: () => void;
}) => {
  const [deleteError, setDeleteError] = useState<string | null>(null);
  const { dispatchSuccessSnackbar } = useSnackbar();
  const [deleteDocument, { isLoading }] = useDeleteDocumentMutation();

  const handleClose = () => {
    setDeleteError(null);
    onClose();
  };

  const handleDeleteDocument = async () => {
    if (!documentId) {
      return;
    }
    try {
      setDeleteError(null);
      await deleteDocument(documentId).unwrap();
      dispatchSuccessSnackbar('Document successfully deleted');
      handleClose();
    } catch (err) {
      handleErr(err, setDeleteError);
    }
  };

  return (
    <ConfirmDialog
      title="Confirm Delete Stock Document"
      dialogText="Are you sure you want to delete this document?"
      onClose={handleClose}
      onConfirm={handleDeleteDocument}
      dialogOpen={dialogOpen}
      loading={isLoading}
      error={deleteError}
      permissionScope={{
        app: ALL_APPS.STOCK.id,
        accessLevel: appPermissionAccessLevels.edit,
        feature: ALL_FEATURE_IDS.FILE_ATTACHMENTS,
      }}
    />
  );
};

export default StockLotDocumentDeleteDialog;
