import { useForm, FieldValues, FormProvider } from 'react-hook-form';
import { TagModelOptions, TagSchema } from 'types/clientV2/tag';
import { useCreateTagMutation } from 'store/slices/clientV2/tags';
import { ChipColor } from 'ui-component/extended/Chip';
import { useEffect } from 'react';
import {
  createUpdateTagFormConstants,
  createUpdateTagValidationSchema,
} from 'views/properties/Tags/TagsTable/constants';
import { yupResolver } from '@hookform/resolvers/yup';
import useCreateRecordDialog from 'hooks/useCreateRecordDialog';
import CreateUpdateTagForm from 'views/properties/Tags/TagsTable/CreateUpdateTagForm';

interface CreateTagDialogProps {
  tag?: TagSchema;
  open: boolean;
  onClose: () => void;
  setTagId?: (value: TagSchema['id']) => void;
}

const CreateTagDialog = ({
  tag,
  open,
  onClose,
  setTagId,
}: CreateTagDialogProps) => {
  const defaultValues = {
    [createUpdateTagFormConstants.name.id]: tag?.name || '',
    [createUpdateTagFormConstants.color.id]:
      tag?.color || ('default' as ChipColor),
    [createUpdateTagFormConstants.modelOptions.id]:
      tag?.modelOptions || ([] as TagModelOptions[]),
    [createUpdateTagFormConstants.active.id]: tag?.active || true,
    [createUpdateTagFormConstants.team.id]: tag?.team?.id || null,
  };

  const { reset } = useForm<FieldValues>({
    defaultValues,
    resolver: yupResolver(createUpdateTagValidationSchema),
  });

  useEffect(() => {
    reset(defaultValues);
  }, [tag]);

  const { methods, BaseCreateRecordDialog, dialogProps } =
    useCreateRecordDialog({
      formConstants: createUpdateTagFormConstants,
      validationSchema: createUpdateTagValidationSchema,
      newRecordDefaults: defaultValues,
      useCreateMutation: useCreateTagMutation,
      recordName: 'Tag',
      onSaveClientV2: (recordId: TagSchema['id']) => {
        if (setTagId) {
          setTagId(recordId);
        }
      },
    });

  return (
    <BaseCreateRecordDialog
      open={open}
      onClose={onClose}
      dialogProps={dialogProps}
    >
      <FormProvider {...methods}>
        <CreateUpdateTagForm />
      </FormProvider>
    </BaseCreateRecordDialog>
  );
};

export default CreateTagDialog;
