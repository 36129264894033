import {
  apiSlice,
  createClientV2MutationFn,
  createClientV2QueryFn,
} from 'store/slices/api';
import { parseQueryParams } from 'store/slices/utils';
import {
  ClientV2DELETERequest,
  ClientV2DELETEResponse,
  ClientV2PATCHRequest,
  ClientV2PATCHResponse,
  ClientV2POSTRequest,
  ClientV2POSTResponse,
  ListResponse,
  QueryParams,
} from 'types/api';
import {
  AddAndPackShipmentLinePOSTSchema,
  ShipmentLineSchema,
  ShipmentPATCHSchema,
  ShipmentPOSTSchema,
  ShipmentSchema,
} from 'types/clientV2/shipments';
import { CLIENT_V2_URLS } from 'store/slices/constants/clientV2';

// NOTE: Non GET endpoints are implemented but may not be fully fleshed out this is fine and will be updated as needed with flagship
export const extendedApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getShipmentsClientV2: builder.query<
      ListResponse<ShipmentSchema>,
      QueryParams<ShipmentSchema>
    >({
      queryFn: createClientV2QueryFn<
        ListResponse<ShipmentSchema>,
        QueryParams<ShipmentSchema>
      >((params) =>
        CLIENT_V2_URLS.SHIPMENTS(parseQueryParams<ShipmentSchema>(params))
      ),
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(
                ({ id }: ShipmentSchema) =>
                  ({
                    type: 'ClientV2Shipments',
                    id,
                  } as const)
              ),
              { type: 'ClientV2Shipments', id: 'LIST' },
            ]
          : [{ type: 'ClientV2Shipments', id: 'LIST' }],
    }),
    createShipments: builder.mutation<
      ClientV2POSTResponse<ShipmentSchema>,
      ClientV2POSTRequest<ShipmentPOSTSchema>
    >({
      queryFn: createClientV2MutationFn<
        ClientV2POSTResponse<ShipmentSchema>,
        ClientV2POSTRequest<ShipmentPOSTSchema>
      >((data) => ({
        url: CLIENT_V2_URLS.SHIPMENTS(),
        method: 'POST',
        body: data,
      })),
      invalidatesTags: [{ type: 'ClientV2Shipments', id: 'LIST' }],
    }),
    updateShipments: builder.mutation<
      ClientV2PATCHResponse<ShipmentSchema>,
      ClientV2PATCHRequest<ShipmentPATCHSchema>
    >({
      query: (data) => ({
        url: CLIENT_V2_URLS.SHIPMENTS(),
        method: 'PATCH',
        body: data,
      }),
      invalidatesTags: [{ type: 'ClientV2Shipments', id: 'LIST' }],
    }),
    deleteShipments: builder.mutation<
      ClientV2DELETEResponse<ShipmentSchema>,
      ClientV2DELETERequest<ShipmentSchema>
    >({
      query: (data) => ({
        url: CLIENT_V2_URLS.SHIPMENTS(),
        method: 'DELETE',
        body: data,
      }),
      invalidatesTags: [{ type: 'ClientV2Shipments', id: 'LIST' }],
    }),
    getShipmentLines: builder.query<
      ListResponse<ShipmentLineSchema>,
      QueryParams<ShipmentLineSchema>
    >({
      query: (params) =>
        CLIENT_V2_URLS.SHIPMENT_LINES(
          parseQueryParams<ShipmentLineSchema>(params)
        ),
      providesTags: [{ type: 'ClientV2ShipmentLines', id: 'LIST' }],
    }),
    createShipmentLines: builder.mutation<
      ClientV2POSTResponse<ShipmentLineSchema>,
      ClientV2POSTRequest<ShipmentLineSchema>
    >({
      query: (data) => ({
        url: CLIENT_V2_URLS.SHIPMENT_LINES(),
        method: 'POST',
        body: data,
      }),
      invalidatesTags: [{ type: 'ClientV2ShipmentLines', id: 'LIST' }],
    }),
    updateShipmentLines: builder.mutation<
      ClientV2PATCHResponse<ShipmentLineSchema>,
      ClientV2PATCHRequest<ShipmentLineSchema>
    >({
      query: (data) => ({
        url: CLIENT_V2_URLS.SHIPMENT_LINES(),
        method: 'PATCH',
        body: data,
      }),
      invalidatesTags: [{ type: 'ClientV2ShipmentLines', id: 'LIST' }],
    }),
    deleteShipmentLines: builder.mutation<
      ClientV2DELETEResponse<ShipmentLineSchema>,
      ClientV2DELETERequest<ShipmentLineSchema>
    >({
      query: (data) => ({
        url: CLIENT_V2_URLS.SHIPMENT_LINES(),
        method: 'DELETE',
        body: data,
      }),
      invalidatesTags: [{ type: 'ClientV2ShipmentLines', id: 'LIST' }],
    }),
    addAndPackShipmentLine: builder.mutation<
      ClientV2POSTResponse<ShipmentLineSchema>,
      {
        id: ShipmentSchema['id'];
        data: AddAndPackShipmentLinePOSTSchema;
      }
    >({
      query: ({ id, data }) => ({
        url: CLIENT_V2_URLS.ADD_AND_PACK_SHIPMENT_LINE(id),
        method: 'POST',
        body: data,
      }),
      invalidatesTags: [{ type: 'ClientV2ShipmentLines', id: 'LIST' }],
    }),
  }),
});

export const {
  useGetShipmentLinesQuery,
  useLazyGetShipmentLinesQuery,
  useCreateShipmentLinesMutation,
  useUpdateShipmentLinesMutation,
  useDeleteShipmentLinesMutation,
  useGetShipmentsClientV2Query,
  useCreateShipmentsMutation,
  useUpdateShipmentsMutation,
  useDeleteShipmentsMutation,
  useAddAndPackShipmentLineMutation,
} = extendedApiSlice;
