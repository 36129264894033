import { Grid, Stack } from '@mui/material';
import { Icon as TablerIcon } from '@tabler/icons-react';
import _ from 'lodash';

import { COLOR_OPTIONS } from 'types';
import { actionButtonSpacing } from 'constants/themeConstants';
import { useDeviceContext } from 'hooks/useDeviceContext';
import { RowAction } from 'types/datagrid';
import { FullOrIconButton } from 'ui-component/DataGrid/components/FullOrIconButton';
import { MoreActionsButton } from 'ui-component/DataGrid/components/MoreActionsButton';

type ActionBarProps<T> = {
  rowActions?: RowAction<T>[];
  selectedRowIds: string[];
  selectedRowData: T[];
};

export default function ActionBar<T>({
  rowActions = [],
  selectedRowIds,
  selectedRowData,
}: ActionBarProps<T>) {
  const { narrowWindow } = useDeviceContext();
  const batchActions = rowActions.filter(
    (action) => action.onBatchClick && !action.hideBatchAction
  );

  if (!batchActions) return null;

  return (
    <Grid
      item
      xs={12}
      sx={{
        display: 'flex',
        justifyContent: 'flex-start',
      }}
    >
      <Stack
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={actionButtonSpacing}
        sx={{ my: 1 }}
      >
        {_.filter(batchActions, (action) => !action.showInBatchMenu).map(
          (action) => {
            const ActionIcon: TablerIcon = action.getRowIcon
              ? action.getRowIcon(selectedRowData[0])
              : action.Icon;

            return (
              <FullOrIconButton
                key={action.getLabel(selectedRowData[0])}
                onClick={() => {
                  if (action.onBatchClick) {
                    action.onBatchClick(selectedRowData);
                  }
                }}
                tooltip={
                  !selectedRowIds.length
                    ? 'Select at least one row checkbox to enable actions'
                    : action.getBatchTooltip
                    ? action.getBatchTooltip(selectedRowIds.length)
                    : action.getLabel(selectedRowData[0])
                }
                color={
                  action.color ??
                  action.getColor?.(selectedRowData[0]) ??
                  COLOR_OPTIONS.primary
                }
                disabled={
                  action.disabled ||
                  !selectedRowIds.length ||
                  (!!action.isBatchDisabled &&
                    action.isBatchDisabled(selectedRowData))
                }
                permissionScope={action.permissionScope}
                startIcon={<ActionIcon />}
                label={action.getLabel(selectedRowData[0])}
                variant="outlined"
                trackingName={action.trackingName}
              />
            );
          }
        )}
        <MoreActionsButton<T>
          rowActions={batchActions}
          selectedRowData={selectedRowData}
          narrowWindow={narrowWindow}
        />
      </Stack>
    </Grid>
  );
}
