import { useCallback, useState } from 'react';
import { Workbook, ValueType, Worksheet, Row } from 'exceljs';
import { saveAs } from 'file-saver';
import Mustache from 'mustache';
import { ExtendedDialog } from 'ui-component/extended/ExtendedDialog';
import { DialogContentText } from '@mui/material';
import _ from 'lodash';
import { useGetUserQuery } from 'store/slices/apiV1/user';
import { getNowDate, nowShortDate } from 'utils/dates';
import {
  postProcessorScripts,
  preProcessorScripts,
} from 'utils/generateReportProcessors';
import { User } from 'types/user';
import useSnackbar from 'hooks/useSnackbar';

interface generateReportProps {
  data: any;
  preProcessor?: (inputData: unknown, metadata: unknown) => any;
  templateWorkbook: Workbook | undefined | null;
  postProcessor?:
    | ((
        inputWorkbook: Workbook,
        mergeData: unknown,
        metadata: unknown
      ) => Workbook)
    | undefined
    | null;
  filename?: string;
  gridName: string;
}

interface GenerateReportDialogProps {
  data: any;
  filename?: string;
  open: boolean;
  gridName: string;
  onClose: () => void;
}

const parseRangeRegex = /([A-Z]+)([0-9]+):([A-Z]+)([0-9]+)/g;
const columnRangeRegex = /[A-Z]+[0-9]+:[A-Z]+[0-9]+/g;

export default function useGenerateReports(useReportGeneration: boolean) {
  const { dispatchErrorSnackbar } = useSnackbar();
  const { data: user } = useGetUserQuery(undefined);
  const [generatingReport, setGeneratingReport] = useState(false);

  const generateReport = useCallback(
    async (props: generateReportProps) => {
      setGeneratingReport(true);
      let data = props.data;
      // add user and date props to data
      data.user = user as User;
      data.date = getNowDate();
      data.shortDate = nowShortDate();
      // process workbook
      let mergedWorkbook = props.templateWorkbook || new Workbook();
      // extract MASTER__CONFIG sheet from workbook
      let masterConfigSheet = null;
      let filename = '';
      let gridNames: string[] = [];
      let preProcessorScript = props.preProcessor;
      let postProcessorScript = props.postProcessor;
      let preProcessorMetadata: unknown = {};
      let postProcessorMetadata: unknown = {};
      try {
        masterConfigSheet = mergedWorkbook.getWorksheet('MASTER__CONFIG');
        const configFilename = masterConfigSheet.getCell('B1');
        if (configFilename) {
          if (configFilename.type !== ValueType.Formula) {
            if (
              configFilename.value &&
              typeof configFilename.value === 'string'
            ) {
              filename = Mustache.render(configFilename.value, data);
            }
          }
        }
        const configGridName = masterConfigSheet.getCell('B2');
        if (configGridName) {
          if (configGridName.type !== ValueType.Formula) {
            if (
              configGridName.value &&
              typeof configGridName.value === 'string'
            ) {
              gridNames = configGridName.value.split('|');
            }
          }
        }
        // check for grid mismatch
        if (!_.includes(gridNames, props.gridName)) {
          dispatchErrorSnackbar('Template does not match this data grid');
          return;
        }
        // load pre/post processor scripts
        const preProcessorScriptName = masterConfigSheet.getCell('B3');
        if (preProcessorScriptName) {
          if (preProcessorScriptName.type !== ValueType.Formula) {
            if (
              preProcessorScriptName.value &&
              typeof preProcessorScriptName.value === 'string'
            ) {
              preProcessorScript = _.get(
                preProcessorScripts,
                preProcessorScriptName.value as string
              );
            }
          }
        }
        const postProcessorScriptName = masterConfigSheet.getCell('B4');
        if (postProcessorScriptName) {
          if (postProcessorScriptName.type !== ValueType.Formula) {
            if (
              postProcessorScriptName.value &&
              typeof postProcessorScriptName.value === 'string'
            ) {
              postProcessorScript = _.get(
                postProcessorScripts,
                postProcessorScriptName.value as string
              );
            }
          }
        }
        // load pre/post processor metadata
        const preProcessorMetadataCell = masterConfigSheet.getCell('B5');
        if (preProcessorMetadataCell) {
          if (preProcessorMetadataCell.type !== ValueType.Formula) {
            if (
              preProcessorMetadataCell.value &&
              typeof preProcessorMetadataCell.value === 'string'
            ) {
              preProcessorMetadata = JSON.parse(
                preProcessorMetadataCell.value as string
              );
            }
          }
        }
        const postProcessorMetadataCell = masterConfigSheet.getCell('B6');
        if (postProcessorMetadataCell) {
          if (postProcessorMetadataCell.type !== ValueType.Formula) {
            if (
              postProcessorMetadataCell.value &&
              typeof postProcessorMetadataCell.value === 'string'
            ) {
              postProcessorMetadata = JSON.parse(
                postProcessorMetadataCell.value as string
              );
            }
          }
        }

        mergedWorkbook.removeWorksheet(masterConfigSheet.id);
      } catch {
        filename = props.filename || 'CofactrReport';
      }

      // preprocess data
      if (preProcessorScript) {
        data = preProcessorScript(data, preProcessorMetadata);
      }

      // build reference object for __CONFIG sheets
      const sheetsWithConfig: {
        worksheet: Worksheet;
        configWorksheet: Worksheet;
      }[] = [];
      mergedWorkbook.eachSheet((worksheet) => {
        if (!_.includes(worksheet.name, '__CONFIG')) {
          // find matching config sheet
          mergedWorkbook.eachSheet((configWorksheet) => {
            if (configWorksheet.name === `${worksheet.name}__CONFIG`) {
              sheetsWithConfig.push({
                worksheet,
                configWorksheet,
              });
            }
          });
        }
      });

      // store reference to rows that were duplicates for use in updating formula ranges later
      const rangesToUpdate: {
        worksheet: Worksheet;
        repeatRowNum: number;
        dataLength: number;
      }[] = [];

      // duplicate and mustache merge repeating rows for sheets with __CONFIG
      sheetsWithConfig.forEach((sheetPair) => {
        const worksheet = sheetPair.worksheet;
        const configWorksheet = sheetPair.configWorksheet;
        // get configs from configWorksheet
        const repeatRowNum = configWorksheet.getCell('B1');
        let repeatRow: null | Row = null;
        if (repeatRowNum.type !== ValueType.Formula && repeatRowNum.value) {
          repeatRow = worksheet.getRow(repeatRowNum.value as number);
        }
        const repeatProp = configWorksheet.getCell('B2');
        let repeatData: unknown[] = [];
        if (
          repeatProp.type !== ValueType.Formula &&
          repeatProp.value &&
          typeof repeatProp.value === 'string'
        ) {
          repeatData = _.get(data, repeatProp.value);
        }

        // duplicate and merge specified rows
        if (repeatRow && repeatData) {
          rangesToUpdate.push({
            worksheet,
            repeatRowNum: repeatRowNum.value as number,
            dataLength: repeatData.length,
          });
          // duplicate rows
          worksheet.duplicateRow(repeatRow.number, repeatData.length, true);
          // remove original template row
          worksheet.spliceRows(repeatRow.number, 1);
          // create shared formulas in first row
          const firstDuplicatedRow = worksheet.getRow(repeatRow.number);
          firstDuplicatedRow?.eachCell((cell) => {
            if (cell.type === ValueType.Formula) {
              cell.value = {
                formula: cell.formula, // @ts-ignore
                shareType: 'shared', // @ts-ignore
                ref: `${worksheet.getColumn(cell.col).letter}${
                  repeatRow?.number
                }:${worksheet.getColumn(cell.col).letter}${
                  (repeatRow?.number as number) + repeatData.length
                }`,
              };
            }
          });
          // merge all rows
          let rowIndex = repeatRow.number;
          repeatData.forEach((rd: unknown) => {
            const newRow = worksheet.getRow(rowIndex);
            newRow.eachCell((cell) => {
              if (cell.type !== ValueType.Formula) {
                // mustache merge non formula cells
                if (cell.value && typeof cell.value === 'string') {
                  cell.value = Mustache.render(cell.value, rd);
                  if (!isNaN(cell.value as any) && cell.value) {
                    cell.value = _.toNumber(cell.value);
                  }
                }
              } else if (newRow.number !== repeatRow?.number) {
                // handle shared formulas
                cell.value = {
                  sharedFormula: `${worksheet.getColumn(cell.col).letter}${
                    repeatRow?.number || 0
                  }`,
                  date1904: true,
                };
              }
            });
            rowIndex += 1;
          });
        }
        // remove config worksheet
        mergedWorkbook.removeWorksheet(configWorksheet.id);
      });

      // mustache merge all other cells
      mergedWorkbook.eachSheet((worksheet) => {
        worksheet.eachRow((row) => {
          row.eachCell((cell) => {
            const regexTestResult = new RegExp(columnRangeRegex).test(
              cell.formula as string
            );
            if (cell.type !== ValueType.Formula) {
              if (cell.value && typeof cell.value === 'string') {
                cell.value = Mustache.render(cell.value, data);
                if (!isNaN(cell.value as any)) {
                  cell.value = _.toNumber(cell.value);
                }
              }
            } else if (cell.formula && regexTestResult) {
              // update ranges in functions
              Array.from(cell.formula.matchAll(parseRangeRegex)).forEach(
                (cr) => {
                  const originalString = cr[0];
                  const startCol = cr[1];
                  const startRow = cr[2];
                  const endCol = cr[3];
                  const endRow = cr[4];
                  if (
                    startCol === endCol &&
                    _.find(
                      rangesToUpdate,
                      (r) =>
                        r.worksheet.id === worksheet.id &&
                        r.repeatRowNum === parseInt(startRow, 10)
                    )
                  ) {
                    const replacementString = `${startCol}${startRow}:${endCol}${
                      parseInt(endRow, 10) +
                      (_.find(
                        rangesToUpdate,
                        (r) =>
                          r.worksheet.id === worksheet.id &&
                          r.repeatRowNum === parseInt(startRow, 10)
                      )?.dataLength || 0) -
                      1
                    }`;
                    cell.value = {
                      formula: cell.formula.replace(
                        originalString,
                        replacementString
                      ),
                      date1904: true,
                    };
                  }
                }
              );
            }
          });
        });
      });
      // postprocess workbook
      if (postProcessorScript) {
        mergedWorkbook = postProcessorScript(
          mergedWorkbook,
          data,
          postProcessorMetadata
        );
      }
      // save and download workbook
      mergedWorkbook.xlsx.writeBuffer().then((fileData) => {
        const blob = new Blob([fileData], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8',
        });
        saveAs(blob, `${filename}.xlsx`);
      });
      setGeneratingReport(false);
    },
    [user?.id]
  );

  const GenerateReportDialog = ({
    data,
    filename,
    open,
    onClose,
    gridName,
  }: GenerateReportDialogProps) => {
    const [worksheetFile, setWorksheetFile] = useState<File | null | undefined>(
      null
    );

    if (!useReportGeneration) {
      return <></>;
    }

    return (
      <ExtendedDialog
        open={open}
        title="Generate Report from Template"
        onCloseDialog={onClose}
        submitButtonCopy="Generate & Download"
        isSubmitting={generatingReport}
        submitDisabled={!worksheetFile}
        onClickPrimaryButton={() => {
          if (worksheetFile) {
            const reader = new FileReader();
            reader.onloadend = () => {
              const arrayBuffer = reader.result;
              const workbook = new Workbook();
              workbook.xlsx.load(arrayBuffer as Buffer).then(() => {
                generateReport({
                  data,
                  filename,
                  templateWorkbook: workbook,
                  gridName,
                });
              });
            };
            reader.readAsArrayBuffer(worksheetFile);
            onClose();
          }
        }}
      >
        <DialogContentText>
          Select a template XLSX file prepared according to the Cofactr report
          generator standard.
        </DialogContentText>
        <input
          id="outlined-adornment-file"
          type="file"
          name="file"
          onChange={(event) => {
            setWorksheetFile(_.first(event.currentTarget.files));
          }}
        />
      </ExtendedDialog>
    );
  };

  return {
    loading: generatingReport,
    generateReport,
    GenerateReportDialog,
  };
}
