import {
  Box,
  TextField,
  CircularProgress,
  FormHelperText,
  Autocomplete,
} from '@mui/material';
import { useState, useEffect, useMemo } from 'react';
import { Controller, FieldValues, UseFormSetValue } from 'react-hook-form';
import { GenericObject } from 'types';
import { TeamLite, Team } from 'types/properties';
import { HookFormComponentProps } from 'ui-component/HookFormComponents/types';
import {
  useGetTeamsQuery,
  useLazyGetTeamQuery,
} from 'store/slices/apiV1/properties';
import ContextualCreateButton from 'ui-component/ContextualCreateButton';
import _ from 'lodash';
import CreateTeamDialog from 'views/properties/Teams/components/CreateTeamDialog';
import { ALL_APPS } from 'constants/appConstants';
import { appPermissionAccessLevels } from 'types/apps';
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles';
import { Org } from 'types/org';

type HookFormTeamAutocompletePropsDisableRequired<
  T extends GenericObject | string
> = {
  disableRequiredProps: true;
  disabled?: boolean;
  mb?: number;
  fullWidth?: boolean;
  multiple?: boolean;
  setValue?: UseFormSetValue<FieldValues>;
  defaultValue?: T;
  sx?: SxProps<Theme>;
  boxSx?: SxProps<Theme>;
  isInlineEdit?: boolean;
  overrideOrgId?: Org['id'];
};

export type BaseHookFormTeamAutocompleteProps<
  T extends GenericObject | string
> = {
  disabled?: boolean;
  mb?: number;
  fullWidth?: boolean;
  multiple?: boolean;
  setValue?: UseFormSetValue<FieldValues>;
  defaultValue?: T;
  sx?: SxProps<Theme>;
  boxSx?: SxProps<Theme>;
  isInlineEdit?: boolean;
  overrideOrgId?: Org['id'];
};

type HookFormTeamAutocompleteProps<T extends GenericObject | string> =
  HookFormComponentProps &
    (
      | HookFormTeamAutocompletePropsDisableRequired<T>
      | BaseHookFormTeamAutocompleteProps<T>
    );

export const HookFormTeamAutocomplete = <T extends GenericObject | string>({
  errors,
  control,
  name = '',
  label,
  defaultValue,
  disabled,
  sx,
  boxSx,
  fullWidth = true,
  setValue,
  isInlineEdit,
  overrideOrgId,
}: HookFormTeamAutocompleteProps<T>) => {
  const [newTeamDialogOpen, setNewTeamDialogOpen] = useState(false);
  const [selected, setSelected] = useState<Team | TeamLite | null>(null);
  const [contextuallyCreatedOption, setContextuallyCreatedOption] =
    useState<Team | null>(null);
  const {
    data: teams,
    isFetching: isFetchingTeams,
    isSuccess: isSuccessTeams,
  } = useGetTeamsQuery();
  const [getTeam, { data: team, isFetching: isFetchingTeam }] =
    useLazyGetTeamQuery();

  const handleGetTeam = async (teamId: Team['id']) => {
    const fetchedTeam = await getTeam(teamId).unwrap();
    setSelected(fetchedTeam);
  };

  const options: (Team | TeamLite)[] = useMemo(() => {
    const combinedOptions = [
      ...(contextuallyCreatedOption ? [contextuallyCreatedOption] : []),
      ...(teams || []),
      ...([team] || []),
    ].filter((option) => option !== null && option !== undefined);

    return Array.from(new Set(combinedOptions.map((option) => option?.id)))
      .map((id) => combinedOptions.find((option) => option?.id === id))
      .filter(
        (option): option is Team =>
          option !== undefined &&
          !option.archived &&
          (overrideOrgId ? option.org === overrideOrgId : true)
      );
  }, [teams, team, contextuallyCreatedOption?.id, overrideOrgId]);

  useEffect(() => {
    if (defaultValue) {
      const defaultTeam = _.find(teams, (t) => t.id === defaultValue);
      if (!defaultTeam && typeof defaultValue === 'string') {
        handleGetTeam(defaultValue);
      }
      setSelected(defaultTeam || null);
    }
  }, [defaultValue, isSuccessTeams]);

  const AutoCompleteInput = (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, onBlur } }) => (
        <Autocomplete
          loading={isFetchingTeams || isFetchingTeam}
          fullWidth={fullWidth}
          sx={{ ...sx }}
          options={options}
          onChange={(__, data) => {
            if (data?.id !== selected?.id) {
              setSelected(data);
            }
            onChange(data?.id || null);
          }}
          value={selected}
          onBlur={onBlur}
          isOptionEqualToValue={(o, v) => o?.id === v?.id}
          getOptionLabel={(option) => option?.name || ''}
          renderInput={(params) => (
            <Box>
              <TextField
                {...params}
                label={label}
                fullWidth
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {isFetchingTeam || isFetchingTeams ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
              {errors?.[name]?.message && (
                <FormHelperText error id={`${name}Error`}>
                  {errors?.[name]?.message}
                </FormHelperText>
              )}
            </Box>
          )}
          disabled={disabled}
        />
      )}
    />
  );

  return (
    <Box sx={{ ...boxSx }}>
      {disabled ? (
        AutoCompleteInput
      ) : (
        <>
          <ContextualCreateButton
            onClick={() => setNewTeamDialogOpen(true)}
            permissionScope={{
              app: ALL_APPS.PROPERTIES.id,
              accessLevel: appPermissionAccessLevels.edit,
            }}
            sx={
              isInlineEdit
                ? {
                    borderRadius: '0px',
                    borderRight: 'none',
                    height: '42px',
                    p: '5px',
                    minWidth: '0',
                    position: 'relative',
                    left: '-1px',
                    '.MuiButton-startIcon': { p: 0, m: 0 },
                  }
                : undefined
            }
          >
            {AutoCompleteInput}
          </ContextualCreateButton>
          <CreateTeamDialog
            dialogOpen={newTeamDialogOpen}
            onClose={() => setNewTeamDialogOpen(false)}
            onCreateSuccess={(value) => {
              const newTeam = value as Team;
              setContextuallyCreatedOption(newTeam);
              setSelected(newTeam);
              setValue &&
                setValue(name, newTeam.id, {
                  shouldDirty: true,
                });
            }}
            overrideOrgId={overrideOrgId}
          />
        </>
      )}
    </Box>
  );
};
