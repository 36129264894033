import { UseMutation } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import _ from 'lodash';
import EditInline from 'ui-component/EditInline';

import {
  CustomPropertyDataType,
  CustomPropertyModel,
  CustomPropertyVisibility,
} from 'types/customProperty';
import useGetCustomPropertyDefinitions from 'hooks/useGetCustomPropertyDefinitions';
import { editInlineComponentMap } from 'ui-component/DetailCustomProperties';
import SidebarInfoSection from 'ui-component/DetailLayout/SidebarComponents/SidebarInfoSection';
import { useGetOrgUsersQuery } from 'store/slices/apiV1/org';
import { skipToken } from '@reduxjs/toolkit/query';
import { useSelector } from 'store';
import { GenericResource } from 'types/api';

interface SidebarCustomPropertiesProps<T> {
  // the `any` here seems to be the type used in the RTK Query type definitions
  useMutation: UseMutation<any>;
  record: T | null | undefined;
  model: CustomPropertyModel;
  isLoadingRecord: boolean;
  disableEdit?: boolean;
  idProp?: string;
  otherProps?: object;
  submitAsClientV2Api?: boolean;
}

const SidebarCustomProperties = <T extends GenericResource>({
  useMutation,
  record,
  model,
  isLoadingRecord,
  disableEdit,
  idProp = 'id',
  otherProps = {},
  submitAsClientV2Api = false,
}: SidebarCustomPropertiesProps<T>) => {
  const { activeOrgId } = useSelector((state) => state.org);

  const { customProperties, isFetching } = useGetCustomPropertyDefinitions({
    model,
    visibilityContext: CustomPropertyVisibility.DETAIL_SIDEBAR,
  });

  const hasUserCustomProp = _.some(
    customProperties,
    (f) => f.dataType === CustomPropertyDataType.USER
  );

  const { data: users = [], isLoading: isLoadingUsers } = useGetOrgUsersQuery(
    hasUserCustomProp && activeOrgId ? activeOrgId : skipToken
  );

  if (customProperties.length < 1) {
    return <></>;
  }

  return (
    <>
      {customProperties.map((f) => {
        const InlineComponent = editInlineComponentMap[f.dataType];
        const otherInlineComponentProps =
          f.dataType === CustomPropertyDataType.SINGLE_CHOICE ||
          f.dataType === CustomPropertyDataType.MULTI_CHOICE
            ? {
                options: f.values,
                multiple: f.dataType === CustomPropertyDataType.MULTI_CHOICE,
              }
            : {};

        const recordCustomProperties = _.get(record, 'customProperties', {});

        const valueForProp = _.get(
          recordCustomProperties,
          f.key,
          f.defaultValue
        );

        const isUserField = f.dataType === CustomPropertyDataType.USER;

        const getLabelForPropValue = () => {
          if (isUserField) {
            const user = _.find(users, (u) => u.id === valueForProp);
            return user ? `${user.firstName} ${user.lastName}` : valueForProp;
          }
          return _.find(f.values, (option) => option.value === valueForProp)
            ?.label;
        };

        return (
          <SidebarInfoSection
            key={f.key}
            caption={f.name}
            isLoading={
              isLoadingRecord || isFetching || (isUserField && isLoadingUsers)
            }
            value={getLabelForPropValue() ?? valueForProp}
            disableEdit={disableEdit}
            editInline={
              <EditInline
                useMutation={useMutation}
                name={f.key}
                label={f.name}
                hideLabel
                value={_.get(recordCustomProperties, f.key, f.defaultValue)}
                id={_.get(record, idProp || 'id', '') as string}
                preSubmit={(data) => ({
                  customProperties: {
                    [f.key]: data,
                  },
                })}
                idProp={idProp}
                otherProps={otherProps}
                submitAsClientV2Api={submitAsClientV2Api}
              >
                <InlineComponent
                  disableRequiredProps
                  {...otherInlineComponentProps}
                />
              </EditInline>
            }
          />
        );
      })}
    </>
  );
};

export default SidebarCustomProperties;
