import { StockLocationType, StockLocationTypeLabels } from 'types/inventory';
import * as Yup from 'yup';

export const locationTypes = {
  shelf: {
    name: StockLocationType.SHELF,
    label: StockLocationTypeLabels[StockLocationType.SHELF],
  },
  esdBin: {
    name: StockLocationType.ESD_BIN,
    label: StockLocationTypeLabels[StockLocationType.ESD_BIN],
  },
  pallet: {
    name: StockLocationType.PALLET,
    label: StockLocationTypeLabels[StockLocationType.PALLET],
  },
  workstation: {
    name: StockLocationType.WORKSTATION,
    label: StockLocationTypeLabels[StockLocationType.WORKSTATION],
  },
  dryCabinet: {
    name: StockLocationType.DRY_CABINET,
    label: StockLocationTypeLabels[StockLocationType.DRY_CABINET],
  },
  notSpecified: {
    name: StockLocationType.NOT_SPECIFIED,
    label: 'Other',
  },
};

export const formConstants = {
  locationName: { id: 'locationName', label: 'Name' },
  locationType: { id: 'locationType', label: 'Type' },
  parentLocation: { id: 'parentLocation', label: 'Parent Location' },
  team: {
    id: 'team',
    label: 'Team',
  },
};

export const validationSchema = Yup.object().shape({
  [formConstants.parentLocation.id]: Yup.string()
    .max(255, 'Parent Location must be under 255 characters')
    .required('Parent Location is required')
    .typeError('Parent Location is required'),
  [formConstants.locationType.id]: Yup.string()
    .max(255, 'Type must be under 255 characters')
    .required('Type is required'),
  [formConstants.locationName.id]: Yup.string()
    .max(255, 'Location Name must be under 255 characters')
    .required('Location Name is required'),
  [formConstants.team.id]: Yup.string(),
});
