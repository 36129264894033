import React, { ReactElement } from 'react';
import { ChipProps } from '@mui/material';
import { Icon } from '@tabler/icons-react';
import { UserLite, UserStateProps } from 'types/user';
import { OrgStateProps } from 'types/org';
import { BomStateProps } from 'types/bom';
import { SnackbarProps } from 'types/snackbar';
import { PurchasingStateProps } from 'types/purchasing';
import { SessionStateProps } from 'types/session';
import { PartDataStateProps } from 'store/slices/apiV1/partData';
import { PartStatusStateProps } from 'store/slices/apiV1/partStatus';

declare global {
  interface Window {
    analytics: typeof analytics;
  }
}

type Primitive = string | number | symbol;

export type GenericObject = Record<Primitive, unknown>;
export interface GenericCardProps {
  title?: string;
  primary?: string | number | undefined;
  secondary?: string;
  content?: string;
  image?: string;
  dateTime?: string;
  iconPrimary?: Icon;
  color?: string;
  size?: string;
}

export type LinkTarget = '_blank' | '_self' | '_parent' | '_top';

export type NavItemType = {
  id?: string;
  icon?: GenericCardProps['iconPrimary'];
  target?: boolean;
  external?: string;
  url?: string | undefined | null;
  type?: string;
  title?: React.ReactNode | string;
  titleString?: string;
  color?: 'primary' | 'secondary' | 'default' | undefined;
  caption?: React.ReactNode | string;
  breadcrumbs?: boolean;
  disabled?: boolean;
  chip?: ChipProps;
  children?: NavItemType[];
};

export interface DefaultRootStateProps {
  snackbar: SnackbarProps;
  user: UserStateProps;
  org: OrgStateProps;
  bom: BomStateProps;
  purchasing: PurchasingStateProps;
  session: SessionStateProps;
  partData: PartDataStateProps;
  partStatus: PartStatusStateProps;
}

export interface ColorProps {
  readonly [key: string]: string;
}

export type GuardProps = {
  children: ReactElement | null;
};

export interface StringColorProps {
  id?: string;
  label?: string;
  color?: string;
  primary?: string;
  secondary?: string;
}

/** ---- Common Functions types ---- */

export type StringBoolFunc = (s: string) => boolean;
export type StringNumFunc = (s: string) => number;
export type NumbColorFunc = (n: number) => StringColorProps | undefined;

/** ---- Date types ---- */
// This should help readability with what we expect from the type of date
// DateString ~= 'YYYY-MM-DD'
// DateTimeString ~= 'YYYY-MM-DD:HH' ect
// these are here for when we decide to possibly enforce the type further either with something like
// https://stackoverflow.com/questions/51445767/how-to-define-a-regex-matched-string-type-in-typescript
// or when typescript gets regex enforced types
export type DateString = string;
export type DateTimeString = string;

export enum FeatureFlags {
  partWatchlistAddGroup = 'part-watchlist-add-group',
  undoExecuteProductionRun = 'undo-execute-production-run',
  sendToShipstation = 'feature-send-to-shipstation',
  manualBomlineCreation = 'manual-bomline-creation',
  featureWarehouseAudit = 'feature-warehouse-audit',
  featureHideOffers = 'feature-hide-offers',
  generateReports = 'generate-reports',
  entitlementVip = 'entitlement-vip',
  tempAppGrantsUI = 'temp-app-grants-ui',
  tempPartRuleSets = 'temp-part-rule-sets',
  tempAvailabilitiesAsync = 'temp-availabilities-async',
  tempPartMarketData = 'temp-part-market-data',
  tempBomAnalyzer = 'temp-bom-analyzer',
  tempOpenReplay = 'temp-open-replay',
  tempOrderApprovals = 'temp-order-approvals',
  tempBomUploadAsync = 'temp-bom-upload-async',
  tempSentryReplay = 'temp-sentry-replay',
  tempSpectraPredictions = 'temp-spectra-predictions',
  tempOrgShippingAccounts = 'temp-org-shipping-accounts',
  tempBomUploaderV2 = 'temp-bom-uploader-v2',
  tempDeprecateSubtractStockToggles = 'temp-deprecate-subtract-stock-toggles',
  includeProgramDataInStockLotPrinting = 'include-program-data-in-stock-lot-printing',
  tempDocumentGeneration = 'temp-document-generation',
  tempBomScrubbingBuyableStock = 'temp-bomscrubbing-buyable_stock',
  tempBomScrubbingAltList = 'temp-bomscrubbing-alt_list',
  tempBomScrubbingClassification = 'temp-bomscrubbing-classification',
  tempCustomProperties = 'temp-custom-properties',
  tempDisableShipments = 'temp-disable-shipments',
}

export enum ManagementActionTypes {
  bom = 'bom',
  productionRun = 'productionRun',
  supplier = 'supplier',
  purchaseRule = 'purchaseRule',
  purchaseOrder = 'purchaseOrder',
  team = 'team',
}

export enum ProductionActionTypes {
  approveRun = 'approve-run',
  createSourcingRequests = 'create-sourcing-requests',
  purchaseParts = 'purchase-parts',
  createAllocations = 'create-allocations',
  createKits = 'create-kits',
  consumeStock = 'consume-stock',
}

export type GlobalSearchResponse = {
  display: string;
  endpoint: string;
  id: string;
  model: string;
};

export enum COLOR_OPTIONS {
  secondary = 'secondary',
  success = 'success',
  warning = 'warning',
  error = 'error',
  primary = 'primary',
}

export type ColorOptions = keyof typeof COLOR_OPTIONS;

export type CustomPropertiesType = {
  [key: string]:
    | string
    | DateString
    | DateTimeString
    | number
    | boolean
    | UserLite['id'];
};
