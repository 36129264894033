import { MenuItem, Stack } from '@mui/material';
import { ExtendedDialog } from 'ui-component/extended/ExtendedDialog';
import { useSelector } from 'store';
import {
  useCreateStockLocationMutation,
  useUpdateStockLocationMutation,
} from 'store/slices/apiV1/inventory';
import {
  StockLocation,
  StockLocationCreate,
  StockLocationUpdate,
} from 'types/inventory';
import { Org } from 'types/org';
import { FieldValues, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  formConstants,
  locationTypes,
  validationSchema,
} from 'views/inventory/components/CreateUpdateStockLocationDialog/constants';
import {
  HookFormInput,
  HookFormSelect,
  HookFormTeamAutocomplete,
} from 'ui-component/HookFormComponents';
import { useEffect, useState } from 'react';
import _ from 'lodash';
import { handleErr } from 'utils/functions';
import { getCopy } from 'views/inventory/components/CreateUpdateStockLocationDialog/copy';
import { ALL_APP_IDS, ALL_APPS } from 'constants/appConstants';
import { appPermissionAccessLevels } from 'types/apps';
import HookFormStockLocationSelect from 'ui-component/HookFormComponents/HookFormStockLocationSelect';
import useSnackbar from 'hooks/useSnackbar';
import { useAppAccessContext } from 'contexts/AppAccessContext';
import useCustomTeamTypeName from 'hooks/useCustomTeamTypeName';
import useGetCustomPropertyDefinitions from 'hooks/useGetCustomPropertyDefinitions';
import {
  CustomPropertyModel,
  CustomPropertyVisibility,
} from 'types/customProperty';
import { HookFormCustomPropertyInput } from 'ui-component/HookFormComponents/HookFormCustomPropertyInput';

const CreateUpdateStockLocationDialog = ({
  dialogOpen,
  orgId,
  onClose,
  selectedLocation,
  onCreateSuccess,
}: {
  dialogOpen: boolean;
  orgId?: Org['id'];
  onClose: () => void;
  selectedLocation?: StockLocation | null;
  onCreateSuccess?: (location: StockLocation) => void;
}) => {
  const [submitError, setSubmitError] = useState<string | null>(null);

  const { activeOrgId } = useSelector((state) => state.org);
  const copy = getCopy(!selectedLocation?.id);
  const { dispatchSuccessSnackbar } = useSnackbar();
  const [createStockLocation, { isLoading: isCreatingStockLocation }] =
    useCreateStockLocationMutation();
  const [updateStockLocation, { isLoading: isUpdatingStockLocation }] =
    useUpdateStockLocationMutation();

  const { hasAppPermission } = useAppAccessContext();
  const propertiesAppEnabled = hasAppPermission(ALL_APP_IDS.PROPERTIES);
  const { handleReplaceWithCustomTeamName } = useCustomTeamTypeName();

  const {
    customProperties,
    getDefaultValuesForCustomProperties,
    customPropertiesFieldName,
  } = useGetCustomPropertyDefinitions({
    model: CustomPropertyModel.STOCK_LOCATION,
    visibilityContext: selectedLocation
      ? CustomPropertyVisibility.EDIT_RECORD_DIALOG
      : CustomPropertyVisibility.NEW_RECORD_DIALOG,
  });

  const initialFormValues = {
    [formConstants.parentLocation.id]:
      (selectedLocation?.parent as StockLocation)?.id ?? '',
    [formConstants.locationType.id]: selectedLocation?.locationType ?? '',
    [formConstants.locationName.id]: selectedLocation?.name ?? '',
    [formConstants?.team?.id]: selectedLocation?.team?.id ?? '',
    [customPropertiesFieldName]:
      getDefaultValuesForCustomProperties<StockLocation>(selectedLocation),
  };

  const {
    control,
    reset,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm<FieldValues>({
    defaultValues: initialFormValues,
    resolver: yupResolver(validationSchema),
    mode: 'all',
  });

  useEffect(() => {
    reset(initialFormValues);
  }, [selectedLocation, customProperties]);

  const handleClose = () => {
    onClose();
    reset();
  };

  const onSubmit = async (data: FieldValues) => {
    try {
      const payload = _.omitBy(
        data,
        (val) => _.isNil(val) || val === ''
      ) as StockLocationCreate;

      payload.name = data.locationName;
      payload.orgId = orgId || activeOrgId;

      if (data.parentLocation) {
        payload.parentId = data.parentLocation;
      }

      if (selectedLocation?.id) {
        await updateStockLocation({
          stockLocationId: selectedLocation.id,
          payload: payload as StockLocationUpdate,
        }).unwrap();
      } else {
        await createStockLocation({ payload })
          .unwrap()
          .then((fulfilled) => {
            onCreateSuccess && onCreateSuccess(fulfilled);
          });
      }
      dispatchSuccessSnackbar(copy.successMessage);
      handleClose();
    } catch (err) {
      handleErr(err, (errMessage: string) => {
        setSubmitError(errMessage);
      });
    }
  };

  return (
    <ExtendedDialog
      open={dialogOpen}
      maxWidth="sm"
      title={copy.title}
      isForm
      onCloseDialog={handleClose}
      onSubmit={handleSubmit(onSubmit)}
      formSubmitError={submitError}
      submitButtonCopy={copy.submitButtonCopy}
      isSubmitting={isCreatingStockLocation || isUpdatingStockLocation}
      trackingNames={copy.trackingNames}
      permissionScope={{
        app: ALL_APPS.KITTING.id,
        accessLevel: appPermissionAccessLevels.edit,
      }}
    >
      <HookFormInput
        errors={errors}
        control={control}
        name={formConstants.locationName.id}
        label={formConstants.locationName.label}
        sx={{ mt: 2 }}
      />
      <HookFormSelect
        control={control}
        name={formConstants.locationType.id}
        label={formConstants.locationType.label}
        errors={errors}
        sx={{ mt: 0, mb: 2 }}
      >
        {Object.values(locationTypes).map((value) => (
          <MenuItem value={value.name} key={value.name}>
            {value.label}
          </MenuItem>
        ))}
      </HookFormSelect>
      <HookFormStockLocationSelect
        control={control}
        name={formConstants.parentLocation.id}
        label={formConstants.parentLocation.label}
        errors={errors}
        sx={{ mt: 0, mb: 0 }}
      />
      {propertiesAppEnabled && (
        <HookFormTeamAutocomplete
          errors={errors}
          control={control}
          name={formConstants.team.id}
          label={handleReplaceWithCustomTeamName(formConstants.team.label)}
          defaultValue={selectedLocation?.team?.id ?? ''}
          setValue={setValue}
        />
      )}
      <Stack sx={{ mt: 2 }}>
        {_.map(customProperties, (property) => (
          <HookFormCustomPropertyInput
            control={control}
            errors={errors}
            key={property.key}
            customProperty={property}
            setValue={setValue}
          />
        ))}
      </Stack>
    </ExtendedDialog>
  );
};

export default CreateUpdateStockLocationDialog;
