import { Box, MenuItem, Stack } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import {
  TagColorOptionsDisplay,
  TagModelOptionsDisplay,
} from 'types/clientV2/tag';
import {
  HookFormInput,
  HookFormSelect,
  HookFormTeamAutocomplete,
  HookFormToggle,
} from 'ui-component/HookFormComponents';
import { createUpdateTagFormConstants } from 'views/properties/Tags/TagsTable/constants';

const CreateUpdateTagForm = ({
  defaultTeamId,
  editMode,
}: {
  defaultTeamId?: string | null;
  editMode?: boolean;
}) => {
  const {
    control,
    formState: { errors },
    setValue,
  } = useFormContext();

  return (
    <Stack spacing={2}>
      <HookFormInput
        name={createUpdateTagFormConstants.name.id}
        label={createUpdateTagFormConstants.name.label}
        control={control}
        errors={errors}
        boxSx={{ mb: 0 }}
      />

      <HookFormSelect
        name={createUpdateTagFormConstants.modelOptions.id}
        label={createUpdateTagFormConstants.modelOptions.label}
        control={control}
        errors={errors}
        boxSx={{ mb: 0 }}
        multiple
      >
        {Object.entries(TagModelOptionsDisplay).map(([value, label]) => (
          <MenuItem key={value} value={value}>
            {label}
          </MenuItem>
        ))}
      </HookFormSelect>

      <HookFormSelect
        name={createUpdateTagFormConstants.color.id}
        label={createUpdateTagFormConstants.color.label}
        control={control}
        errors={errors}
        boxSx={{ mb: 0 }}
      >
        {Object.entries(TagColorOptionsDisplay).map(([value, label]) => (
          <MenuItem
            key={value}
            value={value}
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              {label}
              <Box
                sx={{
                  width: 16,
                  height: 16,
                  borderRadius: 0.5,
                  bgcolor: (theme) =>
                    theme.palette[
                      value.toLowerCase() as keyof typeof theme.palette
                    ]?.main || value,
                  border: '1px solid',
                  borderColor: 'divider',
                  ml: 'auto',
                }}
              />
            </Box>
          </MenuItem>
        ))}
      </HookFormSelect>

      {editMode && (
        <HookFormToggle
          name={createUpdateTagFormConstants.active.id}
          label={createUpdateTagFormConstants.active.label}
          control={control}
          errors={errors}
        />
      )}

      <HookFormTeamAutocomplete
        name={createUpdateTagFormConstants.team.id}
        label={createUpdateTagFormConstants.team.label}
        control={control}
        errors={errors}
        setValue={setValue}
        defaultValue={defaultTeamId || ''}
      />
    </Stack>
  );
};

export default CreateUpdateTagForm;
