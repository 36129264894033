import useCreateRecordDialog from 'hooks/useCreateRecordDialog';
import { useCreateTeamMutation } from 'store/slices/apiV1/properties';
import { HookFormInput } from 'ui-component/HookFormComponents';
import {
  createUpdateTeamFormConstants,
  createUpdateTeamValidationSchema,
} from 'views/properties/Teams/components/constants';
import { HookFormTeamTypeAutocomplete } from 'ui-component/HookFormComponents/HookFormTeamTypeAutocomplete';
import { useGetOrgQuery } from 'store/slices/apiV1/org';
import { skipToken } from '@reduxjs/toolkit/dist/query/react';
import { useSelector } from 'store';
import useCustomTeamTypeName from 'hooks/useCustomTeamTypeName';
import { Org } from 'types/org';

type CreateTeamDialogProps = {
  dialogOpen: boolean;
  onClose: () => void;
  onCreateSuccess?: (record: object) => void;
  overrideOrgId?: Org['id'];
};

const CreateTeamDialog = ({
  dialogOpen,
  onClose,
  onCreateSuccess,
  overrideOrgId,
}: CreateTeamDialogProps) => {
  const { activeOrgId } = useSelector((state) => state.org);
  const { data: org } = useGetOrgQuery(
    overrideOrgId ?? activeOrgId ?? skipToken
  );
  const { handleReplaceWithCustomTeamName } = useCustomTeamTypeName();

  const formDefaults = {
    [createUpdateTeamFormConstants.name.id]: '',
    [createUpdateTeamFormConstants.teamType.id]: org?.defaultTeamType?.id ?? '',
  };
  const { methods, control, errors, BaseCreateRecordDialog, dialogProps } =
    useCreateRecordDialog({
      formConstants: createUpdateTeamFormConstants,
      validationSchema: createUpdateTeamValidationSchema(
        handleReplaceWithCustomTeamName
      ),
      newRecordDefaults: formDefaults,
      useCreateMutation: useCreateTeamMutation,
      recordName: handleReplaceWithCustomTeamName('Team'),
      submitAsPayload: true,
      onSave: onCreateSuccess,
      otherProps: overrideOrgId
        ? {
            overrideOrgId,
          }
        : undefined,
    });

  return (
    <BaseCreateRecordDialog
      open={dialogOpen}
      onClose={onClose}
      dialogProps={dialogProps}
    >
      <HookFormInput
        name={createUpdateTeamFormConstants.name.id}
        label={handleReplaceWithCustomTeamName(
          createUpdateTeamFormConstants.name.label
        )}
        control={control}
        errors={errors}
      />
      <HookFormTeamTypeAutocomplete
        name={createUpdateTeamFormConstants.teamType.id}
        label={handleReplaceWithCustomTeamName(
          createUpdateTeamFormConstants.teamType.label
        )}
        control={control}
        errors={errors}
        defaultValue={formDefaults[createUpdateTeamFormConstants.teamType.id]}
        setValue={methods.setValue}
        overrideOrgId={overrideOrgId}
      />
    </BaseCreateRecordDialog>
  );
};

export default CreateTeamDialog;
