import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'store';

import { DefaultRootStateProps } from 'types';
import { User, UserUpdate } from 'types/user';
import {
  NotificationWorkflow,
  UserNotificationCategory,
  UserNotificationPreferences,
  UserNotificationPreferencesUpdate,
} from 'types/notifications';
import { URLS } from 'store/slices/constants/apiV1';
import { logout, softLogout } from 'store/actions';

import { apiSlice } from 'store/slices/api';
import { PrintLabelRequest } from 'types/inventory';

export const userExtendedApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getUser: builder.query<User, void>({
      query: () => URLS.USER,
      providesTags: ['User'],
    }),
    updateUser: builder.mutation<User, { payload: UserUpdate }>({
      query: ({ payload }) => ({
        url: URLS.USER,
        method: 'PATCH',
        body: payload,
      }),
      invalidatesTags: () => ['User'],
    }),
    resetPassword: builder.mutation<string, { email: string }>({
      query: (creds) => ({
        url: URLS.RESET_PASSWORD,
        method: 'POST',
        body: creds,
      }),
    }),
    uploadUserPhoto: builder.mutation<string, { payload: FormData }>({
      queryFn: async ({ payload }, { getState }) => {
        const result = await fetch(URLS.USER_IMAGE, {
          method: 'POST',
          body: payload,
          headers: {
            Authorization: `Bearer ${
              (getState() as RootState).session.authToken
            }`,
          },
        });
        const data = await result.json();
        return { data };
      },
      // query: ({ orgId, payload }) => ({
      //   url: URLS.USER_IMAGE,
      //   method: 'POST',
      //   body: payload,
      // }),
      invalidatesTags: () => ['User'],
    }),

    getUserNotificationPreferences: builder.query<
      UserNotificationPreferences,
      void
    >({
      query: () => URLS.USER_NOTIFICATION_PREFERENCES,
      providesTags: ['NotificationPreferences'],
    }),
    getUserNotificationWorkflows: builder.query<NotificationWorkflow[], void>({
      query: () => URLS.NOTIFICATION_WORKFLOW_BREAKDOWN,
    }),
    getUserNotificationCategories: builder.query<
      UserNotificationCategory[],
      void
    >({
      query: () => URLS.USER_NOTIFICATION_CATEGORIES,
    }),
    updateNotificationPreferences: builder.mutation<
      UserNotificationPreferences,
      UserNotificationPreferencesUpdate
    >({
      query: (payload) => ({
        url: URLS.USER_NOTIFICATION_PREFERENCES,
        method: 'PUT',
        body: payload,
      }),
      invalidatesTags: () => ['NotificationPreferences'],
    }),
    signoutUser: builder.mutation<void, void>({
      query: () => ({
        url: URLS.SIGNOUT,
        method: 'POST',
      }),
    }),
    printPrintLabelUser: builder.mutation<
      User,
      { userId: User['id']; payload: PrintLabelRequest }
    >({
      query: ({ userId, payload }) => ({
        url: URLS.USER_PRINT_LABEL(userId),
        method: 'POST',
        body: payload,
      }),
    }),
  }),
});
export const {
  useGetUserQuery,
  useGetUserNotificationPreferencesQuery,
  useGetUserNotificationWorkflowsQuery,
  useGetUserNotificationCategoriesQuery,
  useUpdateUserMutation,
  useResetPasswordMutation,
  useUploadUserPhotoMutation,
  useUpdateNotificationPreferencesMutation,
  useSignoutUserMutation,
  usePrintPrintLabelUserMutation,
} = userExtendedApiSlice;

const initialState: DefaultRootStateProps['user'] = {
  error: null,
  user: null,
  viewAsStaff: null,
};

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    hasError(state, action) {
      state.error = action.payload;
    },
    setViewAsStaff(state, action) {
      state.viewAsStaff = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(logout, (state) => {
        state.user = null;
        state.viewAsStaff = null;
      })
      .addCase(softLogout, (state) => {
        state.user = null;
        state.viewAsStaff = null;
      });
  },
});

export const { setViewAsStaff } = slice.actions;

export default slice.reducer;
