import { Box, Stack } from '@mui/system';
import MainCard from 'ui-component/cards/MainCard';
import { CardContent, Typography } from '@mui/material';
import SegmentButton from 'ui-component/Segment/SegmentButton';
import { setStoredOrg } from 'store/actions';
import { setActiveOrgId } from 'store/slices/apiV1/org';
import { DASHBOARD_ROUTE } from 'routes/constants';
import { useDispatch } from 'react-redux';
import { Org } from 'types/org';
import { useNavigate } from 'react-router-dom';

const NoSubOrgViewCard = ({ createdFrom }: { createdFrom: Org }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  return (
    <Box>
      <MainCard>
        <CardContent>
          <Stack spacing={2} justifyContent="center">
            <Typography variant="h3">Not Available for Sub-Orgs</Typography>
            <Typography variant="subtitle1">
              This account is a sub-org of {createdFrom?.name}. This view is
              only available for the parent org. Please switch to the{' '}
              {createdFrom?.name} org to access this view.
            </Typography>
            <span>
              <SegmentButton
                variant="contained"
                onClick={() => {
                  dispatch(setStoredOrg(createdFrom?.id));
                  dispatch(
                    setActiveOrgId({
                      orgId: createdFrom?.id,
                      org: createdFrom,
                    })
                  );
                  navigate(DASHBOARD_ROUTE);
                }}
              >
                Switch to {createdFrom?.name}
              </SegmentButton>
            </span>
          </Stack>
        </CardContent>
      </MainCard>
    </Box>
  );
};

export default NoSubOrgViewCard;
