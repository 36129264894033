import { ALL_APPS, ALL_VIEWS } from 'constants/appConstants';
import PermissionsRouteWrapper from 'views/pages/components/PermissionsRouteWrapper';
import Loadable from 'ui-component/Loadable';
import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import FullHeightMainCardWrapper from 'layout/MainLayout/FullHeightMainCardWrapper';

const ProcurementPurchaseRequests = Loadable(
  lazy(() => import('views/procurement/PurchaseRequests'))
);
const ProcurementPurchaseRequestGroups = Loadable(
  lazy(
    () =>
      import(
        'views/procurement/PurchaseRequestGroups/PurchaseRequestGroupsTable'
      )
  )
);

const PurchaseRequestGroup = Loadable(
  lazy(() => import('views/procurement/PurchaseRequestGroup'))
);

export const RequisitionsRoutes = [
  {
    path: `${ALL_APPS.REQUISITIONS.path}`,
    element: (
      <PermissionsRouteWrapper
        permissionScope={{
          app: ALL_APPS.REQUISITIONS.id,
        }}
      >
        <Navigate to={`${ALL_VIEWS.REQUISITIONS_TABLE.path}`} replace />
      </PermissionsRouteWrapper>
    ),
  },
  {
    path: `${ALL_VIEWS.REQUISITIONS_TABLE.path}`,
    element: (
      <FullHeightMainCardWrapper>
        <PermissionsRouteWrapper
          permissionScope={{ app: ALL_APPS.REQUISITIONS.id }}
        >
          <ProcurementPurchaseRequestGroups />
        </PermissionsRouteWrapper>
      </FullHeightMainCardWrapper>
    ),
  },
  {
    path: `${ALL_VIEWS.REQUISITIONS_TABLE.path}/:purchaseRequestGroupId`,
    element: (
      <PermissionsRouteWrapper
        permissionScope={{ app: ALL_APPS.REQUISITIONS.id }}
      >
        <PurchaseRequestGroup />
      </PermissionsRouteWrapper>
    ),
  },
  {
    path: `${ALL_VIEWS.ALL_REQUISITIONS.path}`,
    element: (
      <PermissionsRouteWrapper
        permissionScope={{ app: ALL_APPS.REQUISITIONS.id }}
      >
        <ProcurementPurchaseRequests />
      </PermissionsRouteWrapper>
    ),
  },
];
