import { createContext, ReactNode, useContext } from 'react';
import {
  Part,
  PartAlts,
  PartCore,
  PartId,
  PartMarket,
  PartSpecs,
  PartSupply,
} from 'types/part';
import useRequestDecoratedPartData from 'hooks/useRequestDecoratedPartData';
import {
  LoadingStatusTypes,
  PartRequestStateType,
  PartSchemas,
  partStatusRequestTypes,
} from 'types/partStatus';
import _ from 'lodash';
import { Dictionary } from '@reduxjs/toolkit';
import { useGetPartQuery } from 'store/slices/apiV1/part';
import { skipToken } from '@reduxjs/toolkit/query';

type PartDataType = {
  [PartSchemas.core]?: PartCore;
  [PartSchemas.supply]?: PartSupply;
  [PartSchemas.specs]?: PartSpecs;
  [PartSchemas.alts]?: PartAlts;
  [PartSchemas.market]?: PartMarket;
};

type PartDataIsLoadingType = {
  [PartSchemas.core]?: boolean;
  [PartSchemas.supply]?: boolean;
  [PartSchemas.specs]?: boolean;
  [PartSchemas.alts]?: boolean;
  [PartSchemas.market]?: boolean;
};

type PartProvider = {
  partData: { [p: Part['id']]: PartDataType };
  partStatus: Dictionary<PartRequestStateType>;
  isSuccessDescribePart: boolean;
  partDataIsLoading: PartDataIsLoadingType;
  basePart: Part | undefined;
};
export const PartContext = createContext({} as PartProvider);

export const usePartContext = () => useContext(PartContext);

export const PartContextProvider = ({
  partId,
  children,
}: {
  partId: PartId | undefined;
  children: ReactNode;
}) => {
  const {
    data: partData = {},
    status: partStatus = {},
    isSuccess: isSuccessDescribePart,
  } = useRequestDecoratedPartData({
    partIds: partId ? [partId] : [],
    schemas: [
      PartSchemas.core,
      PartSchemas.supply,
      PartSchemas.specs,
      PartSchemas.alts,
      PartSchemas.market,
    ],
    useLazyRequest: !partId,
    cacheKey: `${partId}-part`,
  });

  const partStatusForPartId = partStatus?.[partId ?? ''] || {};
  const partDataIsLoading = _.reduce(
    Object.keys(partStatusForPartId),
    (acc, key) => {
      if (key) {
        const partStatusForKey = partStatusForPartId[key as PartSchemas];
        acc[key] = partStatusForKey
          ? LoadingStatusTypes.includes(
              partStatusForKey as partStatusRequestTypes
            )
          : false;
      }
      return acc;
    },
    {} as { [key: PartId]: boolean }
  );

  const { data: basePart } = useGetPartQuery(!partId ? skipToken : partId);

  return (
    <PartContext.Provider
      value={{
        partData,
        partStatus,
        isSuccessDescribePart,
        partDataIsLoading,
        basePart,
      }}
    >
      {children}
    </PartContext.Provider>
  );
};
