import {
  PurchaseRule,
  RelatedRecord,
  StockLocationLite,
  Supplier,
} from 'types/inventory';
import { Org, OrgLite } from 'types/org';
import { BomCRUDSchema, BomLine } from 'types/bom';
import { Availabilities, ShipToUserKittingOptions } from 'types/purchasing';
import { DateString, DateTimeString } from 'types';
import { User } from 'types/user';
import { OrgSupplier } from 'types/suppliers';
import { Team, TeamLite } from 'types/properties';

export type Document = {
  description?: string;
  documentType: string;
  file: string;
  url: string;
};
export type Specification = {
  id: string;
  label: string;
  value: string;
};
export type PartInfoAvailability = {
  buyableStock: number;
  quotableStock: number;
  possibleStock: number;
  timeToShip?: number;
};
export type ReferencePriceLite = {
  convertedPrice: number;
  quantity: number;
};

export enum lifecycleStatus {
  NEW = 'New',
  PRODUCTION = 'Production',
  EOL = 'EOL',
  NRFND = 'NRFND',
  OBSOLETE = 'Obsolete',
  UNKNOWN = 'Unknown',
}

export const lifeCycleStatusFormats: {
  [key: string]: {
    color: 'warning' | 'primary' | 'error' | 'success' | 'grey' | undefined;
    label: string;
    tooltip: string;
  };
} = {
  [lifecycleStatus.NEW]: {
    color: 'primary',
    label: 'New',
    tooltip:
      'This part has just been released or is pre-release. It may not be widely available initially.',
  },
  [lifecycleStatus.PRODUCTION]: {
    color: 'success',
    label: 'Production',
    tooltip: 'This part is currently in production',
  },
  [lifecycleStatus.EOL]: {
    color: 'error',
    label: 'End-Of-Life',
    tooltip:
      'This part is end of life and availability is limited or will become limited soon. We recommend identifying suitable alternative parts.',
  },
  [lifecycleStatus.NRFND]: {
    color: 'warning',
    label: 'Not Recommended For New Design',
    tooltip:
      'This part will be end-of-life soon and is not recommended for new designs. We recommend identifying suitable alternative parts.',
  },
  [lifecycleStatus.OBSOLETE]: {
    color: 'error',
    label: 'Obsolete',
    tooltip:
      'This part is no longer in production and availability is limited or will become limited soon. We recommend identifying suitable alternative parts.',
  },
  [lifecycleStatus.UNKNOWN]: {
    color: 'grey',
    label: 'Unknown',
    tooltip: 'The lifecycle status of this part is not known',
  },
};

export enum reachStatus {
  UNKNOWN = 'REACH Unknown',
  UNAFFECTED = 'REACH Unaffected',
  AFFECTED = 'REACH Affected',
  COMPLIANT = 'REACH Compliant',
  NONCOMPLIANT = 'REACH Non-Compliant',
}

export const reachStatusFormats: {
  [key: string]: {
    color: 'warning' | 'primary' | 'error' | 'success' | 'grey' | undefined;
    label: string;
    tooltip: string;
  };
} = {
  [reachStatus.UNKNOWN]: {
    color: 'grey',
    label: 'Unknown',
    tooltip: 'The REACH status of this part is not known',
  },
  [reachStatus.UNAFFECTED]: {
    color: 'success',
    label: 'Unaffected',
    tooltip: 'This part is unaffected by REACH regulations',
  },
  [reachStatus.AFFECTED]: {
    color: 'warning',
    label: 'Unaffected',
    tooltip: 'This part is affected by REACH regulations',
  },
  [reachStatus.COMPLIANT]: {
    color: 'success',
    label: 'Unaffected',
    tooltip: 'This part is compliant with REACH regulations',
  },
  [reachStatus.NONCOMPLIANT]: {
    color: 'error',
    label: 'Unaffected',
    tooltip: 'This part is not compliant with REACH regulations',
  },
};

export enum rohsStatus {
  COMPLIANT3 = 'RoHS3 Compliant',
  COMPLIANT = 'RoHS Compliant',
  COMPLIANT3_EXEMPTION = 'RoHS3 Compliant By Exemption',
  COMPLIANT_EXEMPTION = 'RoHS Compliant By Exemption',
  NONCOMPLIANT = 'RoHS Non-Compliant',
  UNKNOWN = 'RoHS Unknown',
}

export const rohsStatusFormats: {
  [key: string]: {
    color: 'warning' | 'primary' | 'error' | 'success' | 'grey' | undefined;
    label: string;
    tooltip: string;
  };
} = {
  [rohsStatus.NONCOMPLIANT]: {
    color: 'error',
    label: 'Non-Compliant',
    tooltip: 'This part is not RoHS compliant',
  },
  [rohsStatus.COMPLIANT]: {
    color: 'success',
    label: 'RoHS Compliant',
    tooltip: 'This part complies with RoHS',
  },
  [rohsStatus.COMPLIANT3]: {
    color: 'success',
    label: 'RoHS3 Compliant',
    tooltip: 'This part complies with RoHS3',
  },
  [rohsStatus.COMPLIANT_EXEMPTION]: {
    color: 'warning',
    label: 'RoHS Compliant By Exemption',
    tooltip: 'This part complies with RoHS via an exemption',
  },
  [rohsStatus.COMPLIANT3_EXEMPTION]: {
    color: 'warning',
    label: 'RoHS3 Compliant By Exemption',
    tooltip: 'This part complies with RoHS3 via an exemption',
  },
  [rohsStatus.UNKNOWN]: {
    color: 'grey',
    label: 'Unknown',
    tooltip: 'The RoHS status of this part is not known',
  },
};

export enum aecStatus {
  AECQ100 = 'AEC-Q100',
  AECQ101 = 'AEC-Q101',
  AECQ102 = 'AEC-Q102',
  AECQ103 = 'AEC-Q103',
  AECQ104 = 'AEC-Q104',
  AECQ200 = 'AEC-Q200',
  AECQ001 = 'AEC-Q001',
  AECQ002 = 'AEC-Q002',
  AECQ003 = 'AEC-Q003',
  AECQ004 = 'AEC-Q004',
  AECQ005 = 'AEC-Q005',
  AECQ006 = 'AEC-Q006',
  UNKNOWN = 'AEC-Q Unknown',
}

export const aecStatusFormats: {
  [key: string]: {
    color: 'warning' | 'primary' | 'error' | 'success' | 'grey' | undefined;
    label: string;
    tooltip: string;
  };
} = {
  [aecStatus.AECQ100]: {
    color: 'success',
    label: 'AEC-Q100',
    tooltip: 'This part complies with AEC-Q100',
  },
  [aecStatus.AECQ101]: {
    color: 'success',
    label: 'AEC-Q101',
    tooltip: 'This part complies with AEC-Q101',
  },
  [aecStatus.AECQ102]: {
    color: 'success',
    label: 'AEC-Q102',
    tooltip: 'This part complies with AEC-Q102',
  },
  [aecStatus.AECQ103]: {
    color: 'success',
    label: 'AEC-Q103',
    tooltip: 'This part complies with AEC-Q103',
  },
  [aecStatus.AECQ104]: {
    color: 'success',
    label: 'AEC-Q104',
    tooltip: 'This part complies with AEC-Q104',
  },
  [aecStatus.AECQ200]: {
    color: 'success',
    label: 'AEC-Q200',
    tooltip: 'This part complies with AEC-Q200',
  },
  [aecStatus.AECQ001]: {
    color: 'success',
    label: 'AEC-Q001',
    tooltip: 'This part complies with AEC-Q001',
  },
  [aecStatus.AECQ002]: {
    color: 'success',
    label: 'AEC-Q002',
    tooltip: 'This part complies with AEC-Q002',
  },
  [aecStatus.AECQ003]: {
    color: 'success',
    label: 'AEC-Q003',
    tooltip: 'This part complies with AEC-Q003',
  },
  [aecStatus.AECQ004]: {
    color: 'success',
    label: 'AEC-Q004',
    tooltip: 'This part complies with AEC-Q004',
  },
  [aecStatus.AECQ005]: {
    color: 'success',
    label: 'AEC-Q005',
    tooltip: 'This part complies with AEC-Q005',
  },
  [aecStatus.AECQ006]: {
    color: 'success',
    label: 'AEC-Q006',
    tooltip: 'This part complies with AEC-Q006',
  },
  [aecStatus.UNKNOWN]: {
    color: 'grey',
    label: '-',
    tooltip: 'The AEC-Q status of this part is not known',
  },
};

export enum factoryLeadDayStatus {
  THREE_MONTHS = '<3 Months',
  SIX_MONTHS = '3-6 Months',
  ONE_YEAR = '6-12 Months',
  MORE = '>12 Months',
}

export const factoryLeadDayStatusFormats: {
  [key: string]: {
    color: 'warning' | 'primary' | 'error' | 'success';
    label: string;
    tooltip: string;
  };
} = {
  [factoryLeadDayStatus.THREE_MONTHS]: {
    color: 'success',
    label: '<3 Months',
    tooltip:
      'The median distributor-reported factory lead time for this part is <3 months',
  },
  [factoryLeadDayStatus.SIX_MONTHS]: {
    color: 'primary',
    label: '3-6 Months',
    tooltip:
      'The median distributor-reported factory lead time of this part is 3-6 months',
  },
  [factoryLeadDayStatus.ONE_YEAR]: {
    color: 'warning',
    label: '6-12 Months',
    tooltip:
      'The median distributor-reported factory lead time of this part is 6-12 months. We recommend identifying suitable alternative parts with current availability or a shorter lead time.',
  },
  [factoryLeadDayStatus.MORE]: {
    color: 'error',
    label: '>12 Months',
    tooltip:
      'The median distributor-reported factory lead time of this part is >12 months. We recommend identifying suitable alternative parts with current availability or a shorter lead time.',
  },
};

export enum minLeadStatus {
  THREE_DAYS = '<=3 Days',
  ONE_WEEK = '< 1 Week',
  ONE_MONTH = '< 1 Month',
  MORE = '>1 Month',
}

export const minLeadStatusFormats: {
  [key: string]: {
    color: 'warning' | 'primary' | 'error' | 'success';
    label: string;
    tooltip: string;
  };
} = {
  [minLeadStatus.THREE_DAYS]: {
    color: 'success',
    label: '<=3 Days',
    tooltip: 'The shortest distributor lead time for this part is <=3 days',
  },
  [minLeadStatus.ONE_WEEK]: {
    color: 'primary',
    label: '< 1 Week',
    tooltip: 'The shortest distributor lead time for this part is <1 week',
  },
  [minLeadStatus.ONE_MONTH]: {
    color: 'warning',
    label: '< 1 Month',
    tooltip: 'The shortest distributor lead time for this part is <1 month',
  },
  [minLeadStatus.MORE]: {
    color: 'error',
    label: '>1 Month',
    tooltip:
      'The shortest distributor lead time for this part is >1 month. We recommend identifying suitable alternative parts with current availability or a shorter lead time.',
  },
};

export enum multiSourcingStatus {
  TWO = '<2',
  FIVE = '2-4',
  TEN = '5-9',
  MORE = '>10',
}

export const multiSourcingStatusFormats: {
  [key: string]: {
    color: 'warning' | 'primary' | 'error' | 'success';
    label: string;
    tooltip: string;
  };
} = {
  [multiSourcingStatus.TWO]: {
    color: 'error',
    label: '<2',
    tooltip:
      'Fewer than 2 authorized distributors report stocking this part, so mid-to-long term supply chain stability is very much in question. We recommend proactively finding alternatives for this part or, if none can be found, selecting a comparable part with more sourcing flexibility.',
  },
  [multiSourcingStatus.FIVE]: {
    color: 'warning',
    label: '2-4',
    tooltip:
      'Only 2-4 authorized distributors report stocking this part, so mid-to-long term supply chain risk may be elevated. We recommend proactively finding alternatives for this part or, if none can be found, selecting a comparable part with more sourcing flexibility.',
  },
  [multiSourcingStatus.TEN]: {
    color: 'primary',
    label: '5-9',
    tooltip: '5-9 authorized distributors report stocking this part.',
  },
  [multiSourcingStatus.MORE]: {
    color: 'success',
    label: '>10',
    tooltip: '10 or more authorized distributors report stocking this part.',
  },
};

export const multiSourcingStatusCurrentFormats: {
  [key: string]: {
    color: 'warning' | 'primary' | 'error' | 'success';
    label: string;
    tooltip: string;
  };
} = {
  [multiSourcingStatus.TWO]: {
    color: 'error',
    label: '<2',
    tooltip:
      'Fewer than 2 authorized distributors report current stock of this part so there is a significantly elevated risk of shortages.',
  },
  [multiSourcingStatus.FIVE]: {
    color: 'warning',
    label: '2-4',
    tooltip:
      'Only 2-4 authorized distributors report current stock of this part so there is an elevated risk of shortages.',
  },
  [multiSourcingStatus.TEN]: {
    color: 'primary',
    label: '5-9',
    tooltip: '5-9 authorized distributors report current stock of this part.',
  },
  [multiSourcingStatus.MORE]: {
    color: 'success',
    label: '>10',
    tooltip:
      '10 or more authorized distributors report current stock of this part.',
  },
};

export enum stockStatus {
  STRONG = 'Strong',
  OKAY = 'Okay',
  LIMITED = 'Limited',
  SHORTAGE = 'Shortage',
}

export const stockStatusFormats: {
  [key: string]: {
    color: 'warning' | 'primary' | 'error' | 'success';
    label: string;
    tooltip: string;
  };
} = {
  [stockStatus.STRONG]: {
    color: 'success',
    label: 'High',
    tooltip:
      '50,000 or more of this part are currently buyable from authorized distributors.',
  },
  [stockStatus.OKAY]: {
    color: 'primary',
    label: 'Stable',
    tooltip:
      '10,000-50,000 of this part are currently buyable from authorized distributors. We recommend using the Watchlist feature to actively monitor availability so you can purchase before stock levels drop too much.',
  },
  [stockStatus.LIMITED]: {
    color: 'warning',
    label: 'Limited',
    tooltip:
      '1,000-10,000 of this part are currently buyable from authorized distributors. We recommend purchasing enough stock to cover your near-to-mid-term requirements as soon as possible.',
  },
  [stockStatus.SHORTAGE]: {
    color: 'error',
    label: 'Shortage',
    tooltip:
      'Fewer than 1,000 of this part are currently buyable from authorized distributors. We recommend identifying alternative parts and/or suppliers.',
  },
};

export enum historicalProcurabilityStatus {
  HIGH = 'High',
  MEDIUM = 'Medium',
  LOW = 'Low',
}

export const historicalProcurabilityStatusFormats: {
  [key: string]: {
    color: 'warning' | 'error' | 'success';
    tooltip: string;
  };
} = {
  [historicalProcurabilityStatus.HIGH]: {
    color: 'success',
    tooltip:
      'This parts stock level has consistently met or exceeded the Estimated Annual Usage (EAU) over the past year.',
  },
  [historicalProcurabilityStatus.MEDIUM]: {
    color: 'warning',
    tooltip:
      'This parts stock level has often met the Estimated Annual Usage (EAU) over the past year.',
  },
  [historicalProcurabilityStatus.LOW]: {
    color: 'error',
    tooltip:
      'This parts stock level has been below the Estimated Annual Usage (EAU) over the past year.',
  },
};

export enum estimatedDaysOfSupplyStatus {
  STRONG = 'Strong',
  OKAY = 'Okay',
  LIMITED = 'Limited',
  SHORTAGE = 'Shortage',
}

export const estimatedDaysOfSupplyFormats: {
  [key: string]: {
    color: 'warning' | 'primary' | 'error' | 'success';
    tooltip: (daysOrWeeksValue: string) => string;
  };
} = {
  [estimatedDaysOfSupplyStatus.STRONG]: {
    color: 'success',
    tooltip: (daysOrWeeksValue) =>
      `This part is estimated to be available for ${daysOrWeeksValue}`,
  },
  [estimatedDaysOfSupplyStatus.OKAY]: {
    color: 'primary',
    tooltip: (daysOrWeeksValue) =>
      `This part is estimated to be available for ${daysOrWeeksValue}`,
  },
  [estimatedDaysOfSupplyStatus.LIMITED]: {
    color: 'warning',
    tooltip: (daysOrWeeksValue) =>
      `This part is estimated to be available for ${daysOrWeeksValue}`,
  },
  [estimatedDaysOfSupplyStatus.SHORTAGE]: {
    color: 'error',
    tooltip: (daysOrWeeksValue) =>
      `This part is estimated to be available for ${daysOrWeeksValue}`,
  },
};

export enum probableAvailabilityWindowStatus {
  STRONG = 'Strong',
  OKAY = 'Okay',
  LIMITED = 'Limited',
  SHORTAGE = 'Shortage',
}

export const probableAvailabilityWindowStatusFormats: {
  [key: string]: {
    color: 'warning' | 'primary' | 'error' | 'success';
    tooltip: (daysOrWeeksValue: string) => string;
  };
} = {
  [probableAvailabilityWindowStatus.STRONG]: {
    color: 'success',
    tooltip: (daysOrWeeksValue) =>
      `The total global stock for this part is predicted to remain above the EAU value for ${daysOrWeeksValue} days, including predicted restock events.`,
  },
  [probableAvailabilityWindowStatus.OKAY]: {
    color: 'primary',
    tooltip: (daysOrWeeksValue) =>
      `The total global stock for this part is predicted to remain above the EAU value for ${daysOrWeeksValue} days, including predicted restock events.`,
  },
  [probableAvailabilityWindowStatus.LIMITED]: {
    color: 'warning',
    tooltip: (daysOrWeeksValue) =>
      `The total global stock for this part is predicted to remain above the EAU value for ${daysOrWeeksValue} days, including predicted restock events.`,
  },
  [probableAvailabilityWindowStatus.SHORTAGE]: {
    color: 'error',
    tooltip: (daysOrWeeksValue) =>
      `The total global stock for this part is predicted to remain above the EAU value for ${daysOrWeeksValue} days, including predicted restock events.`,
  },
};

export type Part = {
  id: string;
  internalId: string;
  customId?: string;
  mpn: string;
  mfg: string;
  classification: string;
  heroImage: string;
  description: string;
  totalAvailability: PartInfoAvailability;
  availability: PartInfoAvailability;
  documents?: Document[];
  altMpns?: string[];
  specifications?: Specification[];
  dataAge: DateString;
  expected?: number;
  processing?: number;
  onHand?: number;
  total?: number;
  refPrice10?: number;
  refPrice100?: number;
  refPrice1000?: number;
  refPrice10000?: number;
  buyableRefPrice10?: number;
  buyableRefPrice100?: number;
  buyableRefPrice1000?: number;
  buyableRefPrice10000?: number;
  minLead?: number;
  buyableReferencePrices?: ReferencePriceLite[];
  referencePrices?: ReferencePriceLite[];
  package?: string | null;
  terminations?: number;
  terminationType?: string | null;
  deprecatedIds?: string[];
  msl?: string;
  lifecycleStatus?: lifecycleStatus | null;
  lastSyncedAt?: DateTimeString;
  deprecatedBy?: string | null;
  relatedBomCount?: number;
  relatedPurchaseCount?: number;
  relatedStockLotCount?: number;
  relatedWatchlistCount?: number;
  relatedQuoteCount?: number;
  medianFactoryLeadDays?: number;
  numAuthedDistributorsWithOffers?: number;
  numAuthedDistributorsWithOffersCurrentStock?: number;
  numDistributorsWithOffersCurrentStock?: number;
  isCustomizedInfo?: boolean;
};

export const specialCpids: { [key: string]: string } = {
  XX000000000P: 'Unknown MPN - Electronic Component',
  XX000000000H: 'Unknown MPN - Hardware',
  XX000000000M: 'Unknown MPN - Mixed Parts',
  XX000000FIXT: 'Programming/Test Fixture',
  XX000000PACK: 'Packaging',
  XX000000CUST: 'Misc Customer Item',
  XX000000STNC: 'SMT Stencil',
  XX000000BARE: 'Bare PCB',
  XX000000PCBA: 'PCB Assembly',
  XX000000DUMP: 'Pick & Place Dump Bag',
};

export type PartInventoryQuerySchema = {
  part: Part;
  orgPart: OrgPart;
  expected: number;
  processing: number;
  onHand: number;
  inTransit: number;
  atRest: number;
  allocated: number;
  unallocated: number;
  allocatedToRun: number;
  total: number;
};

// Type returned from API
export type NoPartInventory = {
  part: string;
  // 11/16/23 --> Not currently being returned by flagship, but should be
  // in practice, should not be undefined, but setting it as a potential for now
  orgPart?: string;
  expected: number;
  processing: number;
  allocated: number;
  allocatedToRun?: number;
  onHand: number;
  unallocated?: number;
  total: number;
  facility?: StockLocationLite;
};

export type NoPartInventoryWithLocation = NoPartInventory & {
  locationDetail: NoPartInventory[];
};

export type PartPrice = {
  price: number;
  priceBreak: number;
};

export type PartQuote = {
  id: string;
  part: Part;
  status: string;
  method: string;
  quant: number;
  submittedAt: string;
  expiresAt: string;
  moq: number;
  multiple: number;
  maximum: number;
  lead: number;
  additionalPercentage: string;
  additionalFlat: string;
  shipping: string;
  prices: PartPrice[];
  org: Org['id'];
  solicitation: Solicitation;
  supplier: Supplier;
  orgSupplier: OrgSupplier;
  supplierConversationId: string;
  link: string;
  shipsFromCountry: string;
  externalNotes?: string;
  internalNotes?: string;
  authorized?: boolean;
  team?: TeamLite;
};

export type PartQuoteCreateUpdate = {
  part: Part['id'];
  quant?: number;
  method?: string;
  submittedAt?: string;
  expiresAt?: string;
  additionalPercentage?: string;
  additionalFlat?: string;
  shipping?: string;
  externalNotes?: string;
  internalNotes?: string;
  prices?: PartPrice[];
  moq?: number;
  multiple?: number;
  maximum?: number;
  lead?: number;
  solicitation?: Solicitation['id'];
  orgSupplier?: OrgSupplier['id'];
  supplierConversationId?: string;
  link?: string;
  requestStatus?: string;
  receivedAt?: string;
  authorized?: boolean;
  team?: Team['id'];
};

export type PriceBreak = {
  priceBreak: number;
  price: number;
};

export type PartOfferCorrection = {
  correctValue: number | string | PriceBreak[];
  originalValue: number | string | PriceBreak[];
  detail: string | null;
  created_at: DateTimeString;
};

export enum OfferType {
  QUOTE = 'quote',
  OFFER = 'offer',
}

export type PartOffer = {
  id?: string;
  part: string;
  reportedStock: number;
  lead: number;
  moq: number;
  multiple: number;
  country: string | null;
  authorized: boolean;
  foreignCurrency: boolean;
  prices: PriceBreak[];
  supplier: Supplier;
  factoryLeadDays?: number;
  corrections?: { [key: string]: PartOfferCorrection };
  isBackordered?: boolean;
  isUncertainLead?: boolean;
  sku?: string | null;
  customReelSku?: string | null;
  package?: string | null;
  link?: string | null;
  offerType?: OfferType;
};

export type SolicitationCreate = {
  id?: string; // only used for updating
  orgId: string;
  part: string;
  targetQuant: number;
  targetPrice?: string | undefined;
  // notes from customer to cofactr
  notes?: string;
  needBy: string | undefined;
  productionRun?: string | null;
  // for solicitations only: notes related to the request for Cofactr logistics
  // but not for the customer to view
  externalNotes?: string;
  team?: Team['id'];
};

export type Solicitation = {
  id: string;
  status: string;
  targetQuant: number;
  targetPrice: string;
  needBy: string;
  submittedAt: string;
  processedAt: string;
  completedQuoteCount: string;
  totalQuoteCount: string;
  requestNotes: string;
  notes: string;
  part: Part['id'];
  quotes: PartQuote[] | null;
  lowestQuotePrice: number | null;
  relatedRecords?: RelatedRecord[];
  team: TeamLite;
};

export type PartRequest = {
  requestLineId?: string;
  part: string;
  quant: number;
  alts?: string[];
  alwaysAllowSources?: string[];
  limitToSources?: string[];
};

export type ExpandedAlternativePart = {
  originalPartId?: string;
  part: Part;
  sourceType: string;
  matchType: string;
};

export const sourceTypeOptions = [
  'Used in BOM',
  'Same Custom PN',
  'From Supplier',
  'MPN Search',
  'Parametric Search',
  'Community',
  'AltAI',
];

export type ExpandedAlternativePartDescribeParts = {
  originalPartId?: string;
  part: PartId;
  sourceType: string;
  matchType: string;
};

export type AvailabilitiesRequest = ShipToUserKittingOptions & {
  orgId?: string;
  partRequests: PartRequest[];
  authorizedOnly?: boolean;
  maxLead?: number;
  addOverage?: boolean;
  subtractExpectedStock?: boolean;
  subtractProcessingStock?: boolean;
  subtractOnhandStock?: boolean;
  supplierIds?: Supplier['id'][];
  requiredCertifications?: string[];
  backorderOnly?: boolean;
  internationalDestination?: boolean;
  purchaseRule?: PurchaseRule['id'];
  bom?: BomCRUDSchema['id'];
};

export type AvailabilitiesReturnV2 = {
  buyableAvailability: Availabilities;
  partialBackorderAvailability: Availabilities;
  fullBackorderAvailability: Availabilities;
};

export type CreateAvailabilitiesSnapshotRequest = AvailabilitiesRequest & {
  selectedLead: number;
  productionRun?: string | null;
  dropShip?: boolean;
  forceDropShip?: boolean;
};

export enum PartOptionStatus {
  INVENTORY = 'inventory',
  QUOTABLE = 'quotable',
  MAYBE = 'maybe',
  NEED_TO_FIND = 'need_to_find',
  BUYABLE = 'buyable',
}

export type PartOption = {
  requestLineId: string | null | BomLine;
  status: PartOptionStatus;
  id: string;
  quant: number;
  totalQuant: number;
  purchaseQuant: number;
  unitPrice: number;
  totalPrice: number;
  landedPrice: number;
  minimumLead: number;
  breakdown: PartOptionBreakdown[];
  part: Part;
};

export type PartOptionBreakdown = {
  sourceId: string;
  sourceName: string;
  id: string;
  purchaseQuant: number;
  unitPrice: number;
  totalPrice: number;
  landedPrice: number;
  isBackordered?: boolean;
  isUncertainLead?: boolean;
  expectedShipsIn?: number;
  expectedTransitTime?: number;
  bestCaseLead?: number;
  allowDropship?: boolean;
};

export type WatchlistSubscription = {
  id: string;
  name: string;
  status: string;
  part: Part['id'];
  query: string;
  lastEvaluatedAt: string | null;
  lastTriggeredAt: string | null;
  active: boolean;
  triggered: boolean;
  createdBy: User;
  org: OrgLite;
  targetQuant?: number;
};

export type WatchlistSubscriptionCreate = {
  name: string;
  part: Part['id'];
  query: string;
  targetQuant?: number;
};

export type WatchlistSubscriptionUpdate = {
  name?: string;
  part?: Part['id'];
  query?: string;
  targetQuant?: number;
  active?: boolean;
};

export type WatchlistSubscriptionsRequest = {
  partId?: Part['id'];
  expand?: boolean;
};

export type WatchlistSubscriptionRequest = {
  watchlistSubscriptionId: WatchlistSubscription['id'];
  expand?: boolean;
};

export type CreateCustomPartRequest = {
  mpn: string;
  customId?: string;
  mfg?: string;
  customMfg?: string;
  classification?: string;
  customClassification?: string;
  description?: string;
  // TODO: add specific typing for value of packageValueOptions
  package?: string;
  terminations?: number;
  // TODO: add specific typing for value of terminationTypeValues
  terminationType?: string;
};

export type UpdatePartRequest = {
  customId?: string;
  description?: string;
  package?: string;
  terminationType?: string;
  // todo: make typeof lifecycleStatus options
  lifecycleStatus?: string;
};

export type Manufacturer = {
  id: string;
  label: string;
};

export type Classification = { id: string; label: string };

export type PartRaw = {
  altMpns?: string | string[];
  description?: string;
  lineNumber?: string;
  mfg?: string;
  mpn?: string;
  quant?: number;
  refdes?: string;
  value?: string;
  linenum?: string;
  link?: string;
};

export enum ActionTypes {
  pause = 'pause',
  start = 'start',
  delete = 'delete',
}

export enum WatchlistStatusOptions {
  paused = 'Paused',
  watching = 'Watching',
  conditionMet = 'Condition Met',
  'condition_met' = 'Condition Met',
}

export type TerminationTypeOption = {
  value: string;
  label: string;
};
export const terminationTypeOptionsExpanded: TerminationTypeOption[] = [
  { label: 'Other', value: 'other' },
  { label: 'SMT', value: 'SMT' },
  { label: 'THT', value: 'THT' },
  { label: 'Pressed fit', value: 'pressed fit' },
  { label: 'Hybrid of SMT and THT', value: 'hybrid of SMT and THT' },
  {
    label: 'Hybrid of pressed fit and SMT',
    value: 'hybrid of pressed fit and SMT',
  },
  {
    label: 'Hybrid of pressed fit and THT',
    value: 'hybrid of pressed fit and THT',
  },
];

export type PartId = string;

export type PartCore = {
  id: PartId;
  customId: string | null;
  mpn: string | null;
  classification: string | null;
  description: string | null;
  lifecycleStatus: lifecycleStatus | null;
  heroImage: string | null;
  mfg: string | null;
  package: string | null;
  terminations: string | null;
  terminationType: string;
  deprecatedBy: string | null;
  msl: string | null;
  isCustomizedInfo: boolean;
  altMpns: string[];
  mfgAliases?: string[];
  buyable?: number;
  multisourcing?: number;
  currentMultisourcing?: number;
  minLead?: number;
  factoryLead?: number;
  reach?: string;
  rohs?: string;
  aec?: string;
};

export type PartAlts = {
  id: PartId;
  alts: ExpandedAlternativePartDescribeParts[];
};

export type PartSpecs = {
  id: PartId;
  specifications: Specification[];
  documents: Document[];
};

export enum MarketRating {
  RED = 'RED',
  AMBER = 'AMBER',
  GREEN = 'GREEN',
}

export type MarketAvailability = {
  inventoryLevel: number;
  observedAt: DateTimeString;
};

export type PartMarket = {
  id: PartId;
  mfr: string;
  historicalProcurability: number;
  marketAvailabilities: MarketAvailability[];
  estimatedDaysOfSupply: number;
  probableAvailabilityWindow: number;
  rating: keyof typeof MarketRating;
};

export enum RetryDirective {
  pass = 'pass',
  retry_now = 'retry_now',
  no_retry = 'no_retry',
}

export type PartSupply = {
  id: PartId;
  buyable: number;
  quotable: number;
  maybe: number;
  minLead: number;
  medianFactoryLeadDays: number;
  numAuthedDistributorsWithOffers: number;
  numAuthedDistributorsWithOffersCurrentStock: number;
  numDistributorsWithOffersCurrentStock: number;
  refPrice10: number;
  refPrice100: number;
  refPrice1000: number;
  refPrice10000: number;
  buyableRefPrice10: number;
  buyableRefPrice100: number;
  buyableRefPrice1000: number;
  buyableRefPrice10000: number;
  referencePrices: ReferencePrice[];
  buyableReferencePrices: ReferencePrice[];
  offers: Offer[];
  dataAge: DateString;
  retryDirective?: RetryDirective;
};

export type Offer = {
  id?: string | null;
  reportedStock: number;
  lead: number;
  moq: number;
  multiple: number;
  country: string | null;
  authorized: boolean;
  foreignCurrency: boolean;
  factoryLeadDays: number;
  isBackordered: boolean;
  isUncertainLead: boolean;
  supplier: PartOfferSupplier;
  corrections: { [key: string]: PartOfferCorrection };
  prices: PartPrice[];
  sku?: string | null;
  customReelSku?: string | null;
  package?: string | null;
  offerType?: OfferType;
};

export type OfferWithPartId = Offer & {
  partId: PartId;
};

type PartOfferSupplier = {
  id: string;
  name: string;
  authenticity: number;
  accuracy: number;
  certifications: string[];
  buyable: boolean;
  shippingOptions: ShippingLead[];
  freeShip: string | null;
  bestCaseLead: number | null;
  lead: number | null;
};

export type ReferencePrice = {
  quantity: number;
  price: number;
  currency: string;
  convertedCurrency: string;
  convertedPrice: number;
  conversionRate: number;
};

type ShippingLead = {
  price: number;
  shippingLead: number;
};

export type OrgPart = PartCore & {
  part: PartCore;
  customId?: string;
  // NOTE: omitting this for the time being due to this FF https://cofactr.slack.com/archives/C054R6HNSTA/p1729098693828559
  // bomCount?: number;
  // purchaseCount?: number;
  // stockLotCount?: number;
  // watchlistCount?: number;
  // quoteCount?: number;
  customProperties?: object;
};
