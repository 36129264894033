import { useState } from 'react';
import { copyClipboard } from 'utils/functions';
import { Tooltip } from '@mui/material';
import { IconCopy, IconCheck } from '@tabler/icons-react';
import { useTheme } from '@mui/material/styles';

import SegmentIconButton from 'ui-component/Segment/SegmentIconButton';

type CopyClipboardProps = {
  input: string;
  label: string;
  iconSize?: number;
};

const CopyClipboard = ({ input, label, iconSize = 20 }: CopyClipboardProps) => {
  const theme = useTheme();
  const [open, setOpen] = useState<boolean>(false);
  const [message, setMessage] = useState<string>('');

  const handleTooltipClose = () => {
    setTimeout(() => {
      setOpen(false);
    }, 1000);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  const handleClick = async () => {
    try {
      await copyClipboard(input);
      setMessage(`Copied ${label} to clipboard`);
      handleTooltipOpen();
    } catch (err) {
      setMessage(`error copying ${label}`);
      handleTooltipOpen();
    }
  };

  return (
    <Tooltip
      PopperProps={{
        disablePortal: true,
      }}
      open={open}
      onMouseLeave={handleTooltipClose}
      disableFocusListener
      disableHoverListener
      disableTouchListener
      title={message}
    >
      <SegmentIconButton onClick={handleClick} size="small">
        {open ? (
          <IconCheck
            color={theme.palette.primary.dark}
            width={iconSize}
            height={iconSize}
          />
        ) : (
          <IconCopy width={iconSize} height={iconSize} />
        )}
      </SegmentIconButton>
    </Tooltip>
  );
};

export default CopyClipboard;
