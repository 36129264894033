import { useCreateStockDocs } from 'hooks/useCreateStockDocs';
import _ from 'lodash';
import {
  Shipment,
  StockDocType,
  StockDocTypeLabels,
  StockLotCRUDSchema,
  StockLotLite,
} from 'types/inventory';
import useSnackbar from 'hooks/useSnackbar';
import { PurchaseLite } from 'types/purchasing';
import { BomLite } from 'types/bom';
import { ProductionRun } from 'types/production';
import { actionButtonSpacing, gridSpacing } from 'constants/themeConstants';
import {
  Divider,
  Grid,
  IconButton,
  MenuItem,
  Stack,
  Typography,
} from '@mui/material';
import { useDeviceContext } from 'hooks/useDeviceContext';
import FileUploadInput from 'ui-component/FileUploadInput';
import { FieldValues, useFieldArray, useForm } from 'react-hook-form';
import { IconUpload, IconX } from '@tabler/icons-react';
import { useTheme } from '@mui/material/styles';
import { HookFormInput, HookFormSelect } from 'ui-component/HookFormComponents';
import SegmentLoadingButton from 'ui-component/Segment/SegmentLoadingButton';
import { KitRequestSchema } from 'types/kitting';
import { SupplierBillSchema } from 'types/procurement';
import { OrgSupplier } from 'types/suppliers';
import { RfqSchema, SupplierQuoteSchema } from 'types/sourcing';
import { PurchaseRequestGroupSchema } from 'types/clientV2/purchaseRequests';

interface UploadStockDocsWidgetProps {
  stockLotId?: (StockLotCRUDSchema | StockLotLite)['id'];
  purchaseId?: PurchaseLite['id'];
  bomId?: BomLite['id'];
  productionRunId?: ProductionRun['id'];
  shipmentId?: Shipment['id'];
  kitRequestId?: KitRequestSchema['id'];
  supplierBillId?: SupplierBillSchema['id'];
  orgSupplierId?: OrgSupplier['id'];
  rfqId?: RfqSchema['id'];
  supplierQuoteId?: SupplierQuoteSchema['id'];
  purchaseRequestGroupId?: PurchaseRequestGroupSchema['id'];
  processDocument?: boolean;
}

export interface FormValues {
  stockDocuments: {
    file: File;
    documentType: StockDocType;
    notes: string;
  }[];
}

const UploadStockDocsWidget = ({
  stockLotId,
  purchaseId,
  bomId,
  productionRunId,
  shipmentId,
  kitRequestId,
  supplierBillId,
  orgSupplierId,
  processDocument,
  rfqId,
  supplierQuoteId,
  purchaseRequestGroupId,
}: UploadStockDocsWidgetProps) => {
  const theme = useTheme();
  const { dispatchSuccessSnackbar, dispatchErrorSnackbar } = useSnackbar();
  const { desktopScreen } = useDeviceContext();
  const squareSize = desktopScreen ? 300 : 200;

  const { isLoading: isLoadingCreateStockDoc, createStockDocuments } =
    useCreateStockDocs();

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
    watch,
  } = useForm<FormValues>({ defaultValues: { stockDocuments: [] } });
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'stockDocuments',
  });

  const stockDocuments = watch('stockDocuments');

  const onBatchChange = (newFiles?: File[]) => {
    if (newFiles) {
      append(
        _.map(newFiles, (f) => ({
          file: f,
          documentType: StockDocType.FILE,
          notes: '',
        }))
      );
    }
  };

  const onSubmit = async (data: FieldValues) => {
    try {
      await createStockDocuments(
        _.map(data.stockDocuments, (i) => ({
          file: i.file,
          documentType: i.documentType,
          nonBillable: true,
          notes: i.notes,
          stockLotId,
          purchaseId,
          bomId,
          productionRunId,
          kitRequestId,
          shipmentId,
          supplierBillId,
          orgSupplierId,
          filename: i.file.name,
          processDocument,
          rfqId,
          supplierQuoteId,
          purchaseRequestGroupId,
        }))
      );
      dispatchSuccessSnackbar('Documents created');
      reset();
    } catch (err) {
      dispatchErrorSnackbar('Request failed');
      throw err;
    }
  };

  return (
    <Grid container spacing={gridSpacing}>
      <Grid item sx={{ height: squareSize, width: squareSize }}>
        <FileUploadInput
          batchFileMode
          onBatchChange={onBatchChange}
          allowAllExtensions
          defaultText="Upload Files"
        />
      </Grid>

      <Grid item xs>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack
            direction="column"
            justifyContent="flex-start"
            alignItems="stretch"
            spacing={actionButtonSpacing}
            divider={<Divider flexItem />}
          >
            {fields.map((field, index) => (
              <Stack
                key={field.id}
                direction="column"
                justifyContent="flex-start"
                alignItems="stretch"
              >
                <Grid container alignItems="center">
                  <Grid item xs>
                    <Typography variant="h5">{field.file.name}</Typography>
                  </Grid>
                  <Grid item xs="auto">
                    <IconButton
                      aria-label="delete"
                      onClick={() => remove(index)}
                    >
                      <IconX color={theme.palette.error.main} />
                    </IconButton>
                  </Grid>
                </Grid>
                <Grid
                  container
                  alignItems="center"
                  spacing={actionButtonSpacing}
                >
                  <Grid item xs={6}>
                    <HookFormSelect
                      name={`stockDocuments.${index}.documentType`}
                      label="Document Type"
                      control={control}
                      errors={errors}
                      mt={0}
                    >
                      {_.toPairs(StockDocTypeLabels).map((option) => (
                        <MenuItem key={option[0]} value={option[0]}>
                          {option[1]}
                        </MenuItem>
                      ))}
                    </HookFormSelect>
                  </Grid>
                  <Grid item xs={6}>
                    <HookFormInput
                      name={`stockDocuments.${index}.notes`}
                      label="Notes"
                      control={control}
                      errors={errors}
                      boxSx={{ mb: 0 }}
                    />
                  </Grid>
                </Grid>
              </Stack>
            ))}
            <Stack
              direction="row"
              justifyContent="flex-end"
              alignItems="flex-start"
            >
              <SegmentLoadingButton
                loading={isLoadingCreateStockDoc}
                variant="contained"
                size="medium"
                color="primary"
                disabled={(stockDocuments || []).length < 1}
                type="submit"
                startIcon={<IconUpload />}
              >
                Save & Upload
              </SegmentLoadingButton>
            </Stack>
          </Stack>
        </form>
      </Grid>
    </Grid>
  );
};

export default UploadStockDocsWidget;
