import { useSelector } from 'store';
import { useGetOrgQuery } from 'store/slices/apiV1/org';
import { skipToken } from '@reduxjs/toolkit/dist/query/react';
import { useCallback } from 'react';

const TEAM = 'Team';
const TEAMS = 'Teams';

export default function useCustomTeamTypeNames() {
  const { activeOrgId } = useSelector((state) => state.org);
  const { data: org } = useGetOrgQuery(activeOrgId ?? skipToken);

  const defaultTeamTypeName = org?.defaultTeamType?.name ?? TEAM;
  const defaultTeamTypeNamePlural = org?.defaultTeamType?.pluralName ?? TEAMS;
  const defaultTeamTypeId = org?.defaultTeamType?.id ?? '';

  const handleReplaceWithCustomTeamName = useCallback(
    (inputString: string) => {
      if (org?.defaultTeamType) {
        const replacements: {
          searchValue: string;
          replaceValue: string;
        }[] = [
          { searchValue: TEAM, replaceValue: defaultTeamTypeName },
          { searchValue: TEAMS, replaceValue: defaultTeamTypeNamePlural },
        ];
        replacements.forEach(({ searchValue, replaceValue }) => {
          const regex = new RegExp(searchValue, 'gi');
          inputString = inputString.replace(regex, (match) =>
            match === match.toLowerCase()
              ? replaceValue.toLowerCase()
              : replaceValue
          );
        });
      }
      return inputString;
    },
    [defaultTeamTypeName, defaultTeamTypeNamePlural]
  );

  return {
    activeOrgId,
    org,
    defaultTeamTypeName,
    defaultTeamTypeNamePlural,
    defaultTeamTypeId,
    handleReplaceWithCustomTeamName,
  };
}
