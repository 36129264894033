import { useSelector } from 'store';
import { selectLoadingStateByStatusByIdAndSchemaList } from 'store/slices/apiV1/partStatus';
import _ from 'lodash';
import { CircularProgress, Typography } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import { RenderWithLoadingWrapperProps } from 'ui-component/DataGrid/Render';
import KbFailureAlert, {
  KbFailureAlertVariant,
} from 'ui-component/KBFailureAlert';

import { useGetRetryDirectiveStatus } from 'hooks/useGetRetryDirectiveStatus';
import { PartSchemas } from 'types/partStatus';

const CellLoadingWrapper = ({
  partIds = [],
  schemas = [],
  params,
  renderMethod,
  loadingVariant,
  isLoading,
}: RenderWithLoadingWrapperProps) => {
  const { retryStatus } = useGetRetryDirectiveStatus(partIds);

  const partDataIsLoading = useSelector(
    (state) =>
      selectLoadingStateByStatusByIdAndSchemaList(state, schemas, partIds),
    _.isEqual
  );

  const value = params?.formattedValue ?? params?.value;
  const loading =
    partIds.length > 0 && schemas.length > 0
      ? partDataIsLoading
      : Boolean(isLoading);

  // If there's a failure in fetching data from KB, alert the user
  if (!loading && retryStatus && schemas.includes(PartSchemas.supply)) {
    return (
      <KbFailureAlert
        variant={KbFailureAlertVariant.SUPPLY_COLUMN}
        retryStatus={retryStatus}
      />
    );
  }

  switch (loadingVariant) {
    case 'progress':
      return loading ? (
        <CircularProgress color="inherit" />
      ) : (
        <>{renderMethod ? renderMethod(params) : value}</>
      );
    case 'skeleton':
    default:
      return (
        <>
          {loading ? (
            <Skeleton variant="rounded" width={50} />
          ) : renderMethod ? (
            <>{renderMethod(params)}</>
          ) : (
            <Typography>{value}</Typography>
          )}
        </>
      );
  }
};

export default CellLoadingWrapper;
