import { GridValueGetterParams } from '@mui/x-data-grid-premium';
import _ from 'lodash';
import { PartPrice } from 'types/part';
import { Price } from 'types/sourcing';
import { convertDate } from 'utils/dates';
import { Supplier } from 'types/inventory';
import { OrgSupplier } from 'types/suppliers';

export const dateValueGetter = ({ value }: GridValueGetterParams) =>
  convertDate(value);

export function pricesValueGetter(params: GridValueGetterParams) {
  let min: number | null = null;
  let max: number | null = null;
  params.row.prices.forEach((price: PartPrice) => {
    const numPrice = Number(price.price);
    if (min === null) min = numPrice;
    if (max === null) max = numPrice;
    min = min < numPrice ? min : numPrice;
    max = max > numPrice ? max : numPrice;
  });
  return {
    min,
    max,
  };
}

export function priceBreaksValueGetter(params: GridValueGetterParams) {
  let min: number | null = null;
  let max: number | null = null;

  if (!params.row.prices) {
    return {
      min: 0,
      max: 0,
    };
  }

  params.row.prices.forEach((price: Price) => {
    const numPrice = Number(price.unitPrice);
    if (min === null) min = numPrice;
    if (max === null) max = numPrice;
    min = min < numPrice ? min : numPrice;
    max = max > numPrice ? max : numPrice;
  });

  return {
    min,
    max,
  };
}

// in bomlines: supply or inventory
export const sumChildrenSupplyOrInventory = (params: GridValueGetterParams) => {
  const varName = _.includes(params.field, 'inventory')
    ? _.lowerFirst(_.replace(params.field, 'inventory', ''))
    : `${params.field === 'maybe' ? 'possible' : params.field}Stock`;
  const childrenSum =
    params.rowNode.children?.reduce(
      (acc, child) => acc + (params.getValue(child, params.field) || 0),
      0
    ) || 0;
  if (!params.row.part || typeof params.row.part === 'string') return null;
  if (_.includes(params.field, 'inventory')) {
    if (params.row.part[varName] === undefined) return null;
    if (
      !params.rowNode.childrenExpanded &&
      childrenSum &&
      _.isNumber(params.row.part[varName])
    )
      return childrenSum + (params.row.part[varName] || 0);
    return params.row.part[varName] || 0;
  }
  if (
    !params.rowNode.childrenExpanded &&
    childrenSum &&
    _.isNumber(params.row.part.totalAvailability?.[varName])
  )
    return childrenSum + (params.row.part.totalAvailability?.[varName] || 0);
  return params.row.part.totalAvailability?.[varName] || 0;
};

export const nestedValueGetter = ({ field, row }: GridValueGetterParams) =>
  field.split('.').reduce((acc, fieldPart) => acc?.[fieldPart], row) ?? null;

export const supplierValueGetter = (params: GridValueGetterParams) =>
  params.value.map((supplier: Supplier | OrgSupplier) => supplier.name);
