import { DatagridNames, RowAction } from 'types/datagrid';
import {
  ALL_APP_IDS,
  ALL_APPS,
  ALL_VIEW_IDS,
  ALL_VIEWS,
} from 'constants/appConstants';
import { appPermissionAccessLevels } from 'types/apps';
import { KitRequestSchema } from 'types/kitting';
import ServerSideDatagrid from 'ui-component/DataGrid/ServerSideDatagrid';
import useServerSideDatagrid from 'hooks/useServerSideDatagrid';
import { ALL, Lite, QueryFilterOperators, QueryParams } from 'types/api';
import { useIsCofactr } from 'hooks/useIsCofactr';
import { useMemo, useState } from 'react';
import { makeColumns } from 'views/kitting/Kits/columns';
import {
  IconArchive,
  IconArchiveOff,
  IconEdit,
  IconThumbUp,
  IconThumbUpOff,
  IconTrash,
} from '@tabler/icons-react';
import { useDialogManager } from 'hooks/useDialogManager';
import { COLOR_OPTIONS } from 'types';
import { useNavigate } from 'react-router-dom';
import { useLazyGetExportKitRequestsQuery } from 'store/slices/clientV2/download';
import {
  useGetKitRequestsQuery,
  useApproveKitRequestMutation,
  useUnapproveKitRequestMutation,
  useArchiveKitRequestMutation,
  useUnarchiveKitRequestMutation,
  usePrefetch,
  useDeleteKitRequestsMutation,
} from 'store/slices/clientV2/kitRequests';
import CreateKitRequestDialog from 'views/kitting/components/CreateKitRequestDialog';
import UpdateKitRequestDialog from 'views/kitting/components/UpdateKitRequestDialog';
import { ProductionRun } from 'types/production';
import { Snapshot } from 'types/purchasing';
import { StockLocation } from 'types/inventory';
import ToggleRecordDialog, {
  ToggleRecordActionTypes,
} from 'ui-component/clientV2/ToggleRecordDialog';
import DeleteRecordDialog from 'ui-component/clientV2/DeleteRecordDialog';
import useGetCustomPropertyColumns from 'hooks/useGetCustomPropertyColumns';
import {
  CustomPropertyModel,
  CustomPropertyVisibility,
} from 'types/customProperty';
import { createUpdateKitRequestFormConstants } from 'views/kitting/components/constants';

export type KitRequestSelectedSubtype = Lite<KitRequestSchema> & {
  productionRun: Pick<ProductionRun, 'id' | 'name' | 'org'>;
  kitedFor: Pick<Snapshot, 'id' | 'org'>;
  shipTo: Pick<StockLocation, 'id' | 'externalName' | 'name' | 'address'>;
  sourceLocations: Pick<StockLocation, 'id' | 'externalName' | 'name'>[];
};

enum KIT_REQUEST_DIALOGS {
  APPROVE = 'APPROVE',
  EDIT = 'EDIT',
  ARCHIVE = 'ARCHIVE',
  DELETE = 'DELETE',
  CREATE = 'CREATE',
}

const Kits = ({ programId }: { programId?: ProductionRun['id'] }) => {
  const { isCofactr } = useIsCofactr();
  const navigate = useNavigate();

  const { closeDialog, toggleDialog, isDialogOpen, currentDialog } =
    useDialogManager(Object.values(KIT_REQUEST_DIALOGS));

  const [selectedKitRequest, setSelectedKitRequest] =
    useState<KitRequestSelectedSubtype | null>(null);

  const { customPropertyColumns } = useGetCustomPropertyColumns({
    model: CustomPropertyModel.KIT_REQUEST,
    visibilityContext: CustomPropertyVisibility.DATAGRID_READ_ONLY,
  });

  const {
    data: { data: kitRequests, count: rowCount } = {
      data: [],
      count: 0,
    },
    isFetching: isFetchingKitRequests,
    setters,
    queryParams,
  } = useServerSideDatagrid<KitRequestSchema, KitRequestSelectedSubtype>({
    useResourceHook: useGetKitRequestsQuery,
    usePrefetch,
    prefetchName: 'getKitRequests',
    params: {
      schema: [
        ALL,
        {
          kittedFor: ['id', 'org'],
          productionRun: ['id', 'name', 'org'],
          shipTo: ['id', 'externalName', 'name', 'address'],
          // @ts-ignore Ignoring until we can figure out the correct typing for this, UI-3848
          sourceLocations: ['id', 'externalName', 'name'],
          customProperties: [],
        },
      ],
      filters: [
        ...(programId
          ? [
              {
                field: 'productionRun',
                operator: QueryFilterOperators['='],
                value: programId,
              },
            ]
          : []),
      ] as QueryParams<KitRequestSchema>['filters'],
      noCache: true,
    },
  });

  const memoizedColumns = useMemo(() => {
    const columns = makeColumns(isCofactr);
    return [...columns, ...customPropertyColumns];
  }, [isCofactr, customPropertyColumns]);

  const rowActions: RowAction<KitRequestSelectedSubtype>[] = [
    {
      onRowClick: (row) => {
        if (row) {
          setSelectedKitRequest(row);
          toggleDialog(KIT_REQUEST_DIALOGS.APPROVE);
        }
      },
      getLabel: (row) => `${row?.approved ? 'Unapprove' : 'Approve'} Kit`,
      getColor: (row) =>
        row?.approved ? COLOR_OPTIONS.error : COLOR_OPTIONS.primary,
      getRowIcon: (row) => (row?.approved ? IconThumbUpOff : IconThumbUp),
      showInRowMenu: true,
      permissionScope: {
        app: ALL_APPS.KITTING.id,
        accessLevel: appPermissionAccessLevels.edit,
      },
    },
    {
      getLabel: () => 'Edit Kit',
      onRowClick: (row) => {
        if (row) {
          setSelectedKitRequest(row);
          toggleDialog(KIT_REQUEST_DIALOGS.EDIT);
        }
      },
      Icon: IconEdit,
      showInRowMenu: true,
      isRowDisabled: (row) => !!row?.approved,
      permissionScope: {
        app: ALL_APPS.KITTING.id,
        accessLevel: appPermissionAccessLevels.edit,
      },
    },
    {
      onRowClick: (row) => {
        if (row) {
          setSelectedKitRequest(row);
          toggleDialog(KIT_REQUEST_DIALOGS.ARCHIVE);
        }
      },
      getLabel: (row) => `${row?.archived ? 'Unarchive' : 'Archive'} Kit`,
      getRowIcon: (row) => (row?.archived ? IconArchiveOff : IconArchive),
      showInRowMenu: true,
      permissionScope: {
        app: ALL_APPS.KITTING.id,
        accessLevel: appPermissionAccessLevels.edit,
      },
    },
    {
      onRowClick: (row) => {
        if (row) {
          setSelectedKitRequest(row);
          toggleDialog(KIT_REQUEST_DIALOGS.DELETE);
        }
      },
      getLabel: () => 'Delete Kit',
      Icon: IconTrash,
      color: 'error',
      showInRowMenu: true,
      isRowDisabled: (row) =>
        Boolean(
          row?.approved || row?.voidedAt || row?.fulfilledAt || row?.archived
        ),
      permissionScope: {
        app: ALL_APPS.KITTING.id,
        accessLevel: appPermissionAccessLevels.edit,
      },
    },
  ];

  const dialogProps = useMemo(() => {
    switch (currentDialog) {
      case KIT_REQUEST_DIALOGS.APPROVE:
        return {
          isCurrentlyTrue: !!selectedKitRequest?.approved,
          dialogOpen: isDialogOpen(KIT_REQUEST_DIALOGS.APPROVE),
          useToggleTrueMutation: useApproveKitRequestMutation,
          useToggleFalseMutation: useUnapproveKitRequestMutation,
          toggleActionType: ToggleRecordActionTypes.APPROVE,
        };
      case KIT_REQUEST_DIALOGS.ARCHIVE:
        return {
          isCurrentlyTrue: !!selectedKitRequest?.archived,
          dialogOpen: isDialogOpen(KIT_REQUEST_DIALOGS.ARCHIVE),
          useToggleTrueMutation: useArchiveKitRequestMutation,
          useToggleFalseMutation: useUnarchiveKitRequestMutation,
          toggleActionType: ToggleRecordActionTypes.ARCHIVE,
        };
      default:
        return null;
    }
  }, [
    currentDialog,
    selectedKitRequest?.approved,
    selectedKitRequest?.archived,
    isDialogOpen,
  ]);

  return (
    <>
      <ServerSideDatagrid<KitRequestSchema, KitRequestSelectedSubtype>
        {...setters}
        queryParams={queryParams}
        gridName={DatagridNames.kits}
        loading={isFetchingKitRequests}
        useExportHook={useLazyGetExportKitRequestsQuery}
        rowCount={rowCount}
        rows={kitRequests}
        columns={memoizedColumns}
        rowActions={rowActions}
        noRows="No kits found"
        onRowClick={(params) => {
          navigate(`${ALL_VIEWS[ALL_VIEW_IDS.KITS].path}/${params.row.id}`);
        }}
        newRecordButton={{
          label: 'Create New Kit',
          onClick: () => {
            toggleDialog(KIT_REQUEST_DIALOGS.CREATE);
          },
          permissionScope: {
            app: ALL_APP_IDS.KITTING,
            accessLevel: appPermissionAccessLevels.edit,
          },
        }}
        fullCard={!programId}
        initialVisibleColumns={[
          'kitCode',
          'name',
          'approved',
          'method',
          'shipTo',
          'kittedFor',
          'productionRun',
          'shipPartial',
          'notes',
          ...(isCofactr ? ['internalNotes'] : []),
        ]}
        initialFilter={{
          filterModel: {
            items: [
              {
                columnField: 'archived',
                operatorValue: '=',
                value: 'false',
              },
            ],
          },
        }}
      />

      <CreateKitRequestDialog
        dialogOpen={isDialogOpen(KIT_REQUEST_DIALOGS.CREATE)}
        onClose={closeDialog}
        overrideDefaults={
          programId
            ? {
                [createUpdateKitRequestFormConstants.productionRun.id]:
                  programId,
              }
            : undefined
        }
      />
      {selectedKitRequest && (
        <UpdateKitRequestDialog
          dialogOpen={isDialogOpen(KIT_REQUEST_DIALOGS.EDIT)}
          onClose={closeDialog}
          kitRequestToUpdate={selectedKitRequest}
        />
      )}
      {dialogProps && (
        <ToggleRecordDialog<KitRequestSchema>
          {...dialogProps}
          onClose={closeDialog}
          recordName="Kit"
          id={selectedKitRequest?.id ?? ''}
        />
      )}
      <DeleteRecordDialog
        id={selectedKitRequest?.id ?? ''}
        dialogOpen={isDialogOpen(KIT_REQUEST_DIALOGS.DELETE)}
        onClose={closeDialog}
        recordName="Kit"
        useClientDeleteMutation={useDeleteKitRequestsMutation}
      />
    </>
  );
};

export default Kits;
