import { Control, FieldValues, UseFormSetValue } from 'react-hook-form';
import { HookFormComponentProps } from 'ui-component/HookFormComponents/types';
import { HTMLAttributes, useState } from 'react';
import ContextualCreateButton from 'ui-component/ContextualCreateButton';
import { ALL_APPS } from 'constants/appConstants';
import { appPermissionAccessLevels } from 'types/apps';
import { HookQuerySearchSelect } from 'ui-component/HookFormComponents/HookQuerySearchSelect';
import useServerSideQuery from 'hooks/useServerSideQuery';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { TagModelOptions, TagSchema } from 'types/clientV2/tag';
import { useGetTagsQuery } from 'store/slices/clientV2/tags';
import CreateTagDialog from 'views/properties/Tags/TagsTable/CreateTagDialog';
import { AutocompleteGetTagProps, MenuItem } from '@mui/material';
import Chip from 'ui-component/extended/Chip';
import _ from 'lodash';
import { ALL } from 'types/api';
import { GenericObject } from 'types';

export const renderTagOption = (
  props: HTMLAttributes<HTMLLIElement>,
  option: TagSchema
) => (
  <MenuItem value={option.id} key={option.id} {...props}>
    <Chip
      key={option.id}
      id={option.id}
      label={option.name}
      type="tag"
      chipcolor={option.color}
      sx={{ m: 0 }}
    />
  </MenuItem>
);

export const renderSelectedTags = (
  tagValue: TagSchema[],
  getTagProps: AutocompleteGetTagProps
) =>
  tagValue.map((option: TagSchema, index: number) => {
    const { key, ...tagProps } = getTagProps({ index });
    return (
      <Chip
        {..._.omit(tagProps, 'className')}
        label={option.name}
        key={key}
        onDelete={tagProps.onDelete as () => void}
        chipcolor={option.color}
        type="tag"
        sx={{ mx: 0.5, my: 0.5 }}
      />
    );
  });

type HookFormTagsAutocompletePropsDisableRequired<
  T extends GenericObject | string
> = {
  disableRequiredProps: true;
  disabled?: boolean;
  mb?: number;
  fullWidth?: boolean;
  multiple?: boolean;
  setValue?: UseFormSetValue<FieldValues>;
  defaultValue?: T;
  isInlineEdit?: boolean;
  tagModel: TagModelOptions;
};

export type BaseHookFormTagsAutocompleteProps<
  T extends GenericObject | string
> = {
  disabled?: boolean;
  mb?: number;
  fullWidth?: boolean;
  multiple?: boolean;
  setValue?: UseFormSetValue<FieldValues>;
  defaultValue?: T;
  isInlineEdit?: boolean;
  tagModel: TagModelOptions;
};

type HookFormTagsAutocompleteProps<T extends GenericObject | string> =
  HookFormComponentProps &
    (
      | HookFormTagsAutocompletePropsDisableRequired<T>
      | BaseHookFormTagsAutocompleteProps<T>
    );

export const HookFormTagsAutocomplete = <T extends GenericObject | string>({
  errors,
  control,
  name = '',
  label,
  disabled,
  setValue,
  tagModel,
  isInlineEdit,
  disableRequiredProps,
}: HookFormTagsAutocompleteProps<T>) => {
  const [newTagDialogOpen, setNewTagDialogOpen] = useState(false);
  const [contextuallyCreatedOptionId, setContextuallyCreatedOptionId] =
    useState<TagSchema['id'] | null>(null);

  const { data: { data: [contextuallyCreatedOption] } = { data: [null] } } =
    useServerSideQuery<TagSchema, TagSchema>(
      useGetTagsQuery,
      contextuallyCreatedOptionId
        ? {
            pageSize: 1,
            filters: [
              {
                field: 'id',
                operator: 'is',
                value: contextuallyCreatedOptionId,
              },
            ],
            schema: [ALL],
          }
        : skipToken
    );

  return (
    <>
      <ContextualCreateButton
        onClick={() => setNewTagDialogOpen(true)}
        permissionScope={{
          app: ALL_APPS.PROPERTIES.id,
          accessLevel: appPermissionAccessLevels.edit,
        }}
        sx={
          isInlineEdit
            ? {
                borderRadius: '0px',
                borderRight: 'none',
                height: '42px',
                p: '5px',
                minWidth: '0',
                position: 'relative',
                left: '-1px',
                '.MuiButton-startIcon': { p: 0, m: 0 },
              }
            : undefined
        }
      >
        <HookQuerySearchSelect<TagSchema>
          disableRequiredProps={disableRequiredProps}
          name={name}
          label={label}
          useGetQuery={useGetTagsQuery}
          errors={errors}
          control={control as Control}
          schema={[ALL]}
          filters={[
            {
              // Adding a ts-ignore because the field we're filtering on is an array of objects,
              // and our typing does not currently support that.
              // The query itself is accepted by Flagship.
              // @ts-ignore
              field: 'modelOptions.model',
              operator: 'is',
              value: tagModel,
            },
          ]}
          getOptionLabel={(option: TagSchema) => option.name}
          fullWidth
          mb={0}
          contextuallyCreatedOption={contextuallyCreatedOption || undefined}
          multiple
          disabled={disabled}
          renderOption={renderTagOption}
          renderTags={renderSelectedTags}
        />
      </ContextualCreateButton>
      <CreateTagDialog
        open={newTagDialogOpen}
        onClose={() => setNewTagDialogOpen(false)}
        setTagId={(value) => {
          setContextuallyCreatedOptionId(value);
          setValue && setValue(name, value);
        }}
      />
    </>
  );
};

export default HookFormTagsAutocomplete;
