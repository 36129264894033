import {
  HookFormAutocomplete,
  HookFormDatePicker,
  HookFormCheckbox,
  HookFormInput,
  HookFormSelect,
  HookFormTeamTypeAutocomplete,
  HookFormTeamAutocomplete,
} from 'ui-component/HookFormComponents/index';
import React from 'react';
import { BasePackageAutocompleteInlineEdit } from 'ui-component/HookFormComponents/InlineEditInputComponents/components/BasePackageAutocompleteInlineEdit';
import { HookFormAddressSelect } from 'ui-component/HookFormComponents/HookFormAddressSelect';
import { HookFormUserAutocomplete } from 'ui-component/HookFormComponents/HookFormUserAutocomplete';
import HookFormTagsAutocomplete from 'ui-component/HookFormComponents/HookFormTagsAutocomplete';
import { TagModelOptions } from 'types/clientV2/tag';

// To be used with renderEditCellWithInput, which is used for inline editing cells in a Datagrid

export const EditInlineStringInput = (
  <HookFormInput
    onKeyDown={(event) => {
      if (event.key === 'Enter') {
        event.stopPropagation();
      }
    }}
    disableRequiredProps
  />
);

export const EditInlineNumberInput = (
  <HookFormInput
    onKeyDown={(event) => {
      if (event.key === 'Enter') {
        event.stopPropagation();
      }
    }}
    inputProps={{
      inputMode: 'numeric',
      pattern: '[0-9]*',
      max: 1000000000,
      min: 1,
    }}
    type="number"
    disableRequiredProps
  />
);

export const EditInlineDatePicker = (
  <HookFormDatePicker
    onKeyDown={(event) => {
      if (event.key === 'Enter') {
        event.stopPropagation();
      }
    }}
    disableRequiredProps
    clearable
  />
);

/**
 * To use EditInlineSelect or EditInlineMultipleSelect, you'll need to also pass in additionalInputElementProps with options
 * Example:
 * additionalInputElementProps: {
 *  options: [
 *      { value: 'option1', label: 'Option 1' },
 *      { value: 'option2', label: 'Option 2' },
 *  ],
 * },
 */
export const EditInlineSelect = <HookFormSelect disableRequiredProps />;

export const EditInlineMultipleSelect = (
  <HookFormSelect disableRequiredProps multiple />
);

export const EditInlineAutocomplete = (
  <HookFormAutocomplete disableRequiredProps />
);

export const EditInlinePackageAutocomplete = (
  <BasePackageAutocompleteInlineEdit disableRequiredProps />
);

export const EditInlineAddressSelect = (
  <HookFormAddressSelect disableRequiredProps />
);

// TODO: Fix button saving styling for this or add a border or something
export const EditInlineCheckbox = <HookFormCheckbox disableRequiredProps />;

export const EditInlineTeamTypeAutocomplete = (
  <HookFormTeamTypeAutocomplete disableRequiredProps isInlineEdit />
);

export const EditInlineTeamAutocomplete = (
  <HookFormTeamAutocomplete disableRequiredProps isInlineEdit />
);

export const EditInlineUserAutocomplete = (
  <HookFormUserAutocomplete disableRequiredProps isInlineEdit />
);

export const EditInlinePurchaseRequestTagsAutocomplete = (
  <HookFormTagsAutocomplete
    tagModel={TagModelOptions.PURCHASE_REQUEST}
    disableRequiredProps
    isInlineEdit
  />
);
