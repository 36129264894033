import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
} from 'react';
import { useGetUserQuery } from 'store/slices/apiV1/user';
import { useSelector } from 'store';
import { useGrowthBook } from '@growthbook/growthbook-react';
import {
  App,
  AppPermission,
  appPermissionAccessLevels,
  appPermissionStates,
  DISABLED_TYPE,
  DisabledReason,
  PermissionScope,
} from 'types/apps';
import _ from 'lodash';
import {
  ALL_APP_IDS,
  ALL_APPS,
  ALL_FEATURE_IDS,
  ALL_VIEW_IDS,
} from 'constants/appConstants';
import {
  calculateAppPermissionState,
  calculateViewFeaturePermissionState,
  doesUserAccessLevelMeetRequiredAccessLevel,
  getGrowthbookValues,
} from 'utils/permissions';
import { useGetUserAccessControlsQuery } from 'store/slices/apiV1/accessEntitlements';
import { useGetEntitlements } from 'hooks/useGetEntitlements';
import useCustomTeamTypeName from 'hooks/useCustomTeamTypeName';
import { useIsCofactr } from 'hooks/useIsCofactr';

type AppAccessContextType = {
  appPermissions: { [key in ALL_APP_IDS]?: AppPermission };
  appsWithPermissions: { [key: string]: App };
  isLoading: boolean;
  hasAppPermission: (app: keyof typeof ALL_APP_IDS) => boolean;
  hasFeaturePermission: (feature: keyof typeof ALL_FEATURE_IDS) => boolean;
  isFeatureDisabled: ({
    feature,
    requiredPermissionLevel,
  }: {
    feature: keyof typeof ALL_FEATURE_IDS;
    requiredPermissionLevel?: keyof typeof appPermissionAccessLevels;
  }) => boolean;
  isPermissionDisabled: (scope?: PermissionScope) => boolean;
  getDisabledReason: (scope: PermissionScope) => DisabledReason;
};

export const AppAccessContext = createContext({} as AppAccessContextType);

export const useAppAccessContext = () => useContext(AppAccessContext);

export const AppAccessContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const { data: user, isLoading: isLoadingUser } = useGetUserQuery();
  const { isCofactr } = useIsCofactr();
  const { data: userAccessControls, isLoading: isLoadingUserAccessControls } =
    useGetUserAccessControlsQuery(undefined);
  const { activeOrgId, blended } = useSelector((state) => state.org);
  const gb = useGrowthBook();
  const { metronomeEntitlements, isLoadingMetronomeEntitlements } =
    useGetEntitlements();

  const growthbookEntitlements = getGrowthbookValues({
    gb: gb!,
    appPrefix: 'appentitlement',
    featurePrefix: 'featureentitlement',
  });

  const { defaultTeamTypeName, defaultTeamTypeNamePlural } =
    useCustomTeamTypeName();

  const appPermissions = useMemo(() => {
    const permissions: { [key in ALL_APP_IDS]?: AppPermission } = {};

    // calculate permissions for all other apps
    Object.values(ALL_APPS).forEach((app) => {
      if (!(app.id in permissions)) {
        const accessLevel = _.get(
          userAccessControls,
          _.camelCase(app.id.toLowerCase()),
          appPermissionAccessLevels.none
        ) as appPermissionAccessLevels;
        const foundEntitlement = metronomeEntitlements?.find(
          (e) => e.id?.toLowerCase() === app.id?.toLowerCase()
        ); // app ids in entitlements are lowercase
        const permissionStateWithEntitlements = calculateAppPermissionState({
          app,
          entitlement: foundEntitlement,
          growthbookEntitlement: growthbookEntitlements.apps[app.id],
          accessLevel,
          blended,
          vip: growthbookEntitlements.vip,
          isCofactr,
        });
        permissions[app.id as keyof typeof ALL_APP_IDS] = {
          id: app.id,
          state: permissionStateWithEntitlements,
          comingSoon: app.globalComingSoon || false,
          accessLevel,
        };
      }
    });

    return permissions;
  }, [
    user?.id,
    activeOrgId,
    isLoadingUserAccessControls,
    metronomeEntitlements,
    growthbookEntitlements,
  ]);

  const appsWithPermissions = useMemo(() => {
    const apps: { [key: string]: App } = {};

    Object.values(ALL_APPS).forEach((app) => {
      apps[app.id] = { ...app, permissions: appPermissions[app.id] };
    });

    // calculate permissions for views + features
    Object.values(apps).forEach((app) => {
      if (app.views) {
        Object.values(app.views).forEach((view) => {
          const accessLevel = _.get(
            userAccessControls,
            _.camelCase(app.id.toLowerCase())
          );
          const foundEntitlement = metronomeEntitlements?.find(
            (e) =>
              e.id?.toLowerCase() ===
              view?.inheritedFeatureEntitlement?.toLowerCase()
          );
          const permissionStateWithEntitlements =
            calculateViewFeaturePermissionState({
              viewOrFeature: view,
              parentAppState: _.get(apps, [app.id, 'permissions', 'state']),
              entitlement: foundEntitlement,
              growthbookEntitlement: view?.inheritedFeatureEntitlement
                ? growthbookEntitlements.features[
                    view?.inheritedFeatureEntitlement
                  ]
                : undefined,
              blended,
              vip: growthbookEntitlements.vip,
            });
          _.set(apps, [app.id, 'views', view.id, 'permissions'], {
            id: view.id,
            state: permissionStateWithEntitlements,
            comingSoon: view.globalComingSoon || false,
            accessLevel,
          });

          // Override 'Teams' naming if the user has changed defaultTeamType
          if (view.id === ALL_VIEW_IDS.TEAMS_TABLE) {
            _.set(
              apps,
              [app.id, 'views', view.id, 'name'],
              defaultTeamTypeNamePlural
            );
          }
          if (view.id === ALL_VIEW_IDS.TEAMS_PREFERENCES) {
            _.set(
              apps,
              [app.id, 'views', view.id, 'name'],
              `${defaultTeamTypeName} Preferences`
            );
          }
        });
      }

      if (app.features) {
        Object.values(app.features).forEach((feature) => {
          const accessLevel = _.get(
            userAccessControls,
            _.camelCase(app.id.toLowerCase())
          );
          const foundEntitlement = metronomeEntitlements?.find(
            (e) => e.id?.toLowerCase() === feature.id?.toLowerCase() // feature ids in entitlements are lowercase
          );
          const permissionStateWithEntitlements =
            calculateViewFeaturePermissionState({
              viewOrFeature: feature,
              parentAppState: _.get(apps, [app.id, 'permissions', 'state']),
              entitlement: foundEntitlement,
              growthbookEntitlement:
                growthbookEntitlements.features[feature.id],
            });
          _.set(apps, [app.id, 'features', feature.id, 'permissions'], {
            id: feature.id,
            state: permissionStateWithEntitlements,
            comingSoon: feature.globalComingSoon || false,
            accessLevel,
          });
        });
      }
    });

    return apps;
  }, [
    appPermissions,
    user?.id,
    isLoadingUserAccessControls,
    metronomeEntitlements,
    growthbookEntitlements,
    defaultTeamTypeName,
    defaultTeamTypeNamePlural,
  ]);

  const hasAppPermission = useCallback(
    (app: keyof typeof ALL_APP_IDS) =>
      Boolean(
        appPermissions[app] &&
          appPermissions[app]?.state === appPermissionStates.enabled
      ),
    [appPermissions]
  );

  enum VIEW_OR_FEATURE {
    VIEW,
    FEATURE,
  }
  const getParentApp = ({
    id,
    type,
  }: {
    id: keyof typeof ALL_FEATURE_IDS | keyof typeof ALL_VIEW_IDS;
    type: VIEW_OR_FEATURE;
  }) => {
    const appKeys = {
      [VIEW_OR_FEATURE.VIEW]: 'views',
      [VIEW_OR_FEATURE.FEATURE]: 'features',
    };
    return Object.values(ALL_APPS).find(
      (curApp) =>
        Boolean(curApp?.[appKeys[type] as keyof App]) &&
        Object.keys(curApp?.[appKeys[type] as keyof App] || {}).includes(id)
    );
  };

  const hasFeaturePermission = useCallback(
    (feature: keyof typeof ALL_FEATURE_IDS) => {
      const parentApp = getParentApp({
        id: feature,
        type: VIEW_OR_FEATURE.FEATURE,
      });
      return parentApp
        ? Boolean(
            appsWithPermissions[parentApp.id]?.features?.[feature]?.permissions
              ?.state === appPermissionStates.enabled
          )
        : false;
    },
    [appsWithPermissions]
  );

  const hasViewPermission = useCallback(
    (view: keyof typeof ALL_VIEW_IDS) => {
      const parentApp = getParentApp({ id: view, type: VIEW_OR_FEATURE.VIEW });
      return parentApp
        ? Boolean(
            appsWithPermissions[parentApp.id]?.views?.[view]?.permissions
              ?.state === appPermissionStates.enabled
          )
        : false;
    },
    [appsWithPermissions]
  );

  // can probably be combined with isFeatureDisabled but leaving separate for now
  const isViewDisabled = useCallback(
    ({
      view,
      requiredPermissionLevel,
    }: {
      view: keyof typeof ALL_VIEW_IDS;
      requiredPermissionLevel?: keyof typeof appPermissionAccessLevels;
    }) => {
      const parentApp = getParentApp({ id: view, type: VIEW_OR_FEATURE.VIEW });

      // if parent app is disabled, feature is disabled
      if (parentApp && !hasAppPermission(parentApp?.id)) {
        return true;
      }

      // if view doesn't have sufficient permissions, view is disabled
      if (!hasViewPermission(view)) {
        return true;
      }

      // if required permission provided and parent app doesn't have sufficient permission, view is disabled
      // if required permission provided and view doesn't have sufficient permission, view is disabled
      if (requiredPermissionLevel) {
        const parentAppHasPermissionLevel =
          parentApp &&
          doesUserAccessLevelMeetRequiredAccessLevel({
            userPermissionLevel:
              appsWithPermissions[parentApp?.id]?.permissions?.accessLevel,
            requiredPermissionLevel,
          });

        const viewHasPermissionLevel =
          parentApp &&
          doesUserAccessLevelMeetRequiredAccessLevel({
            userPermissionLevel:
              appsWithPermissions[parentApp?.id]?.views?.[view]?.permissions
                ?.accessLevel,
            requiredPermissionLevel,
          });

        return !(parentAppHasPermissionLevel && viewHasPermissionLevel);
      }

      // otherwise, view has sufficient permission, view isn't disabled
      return false;
    },
    [appsWithPermissions]
  );

  const isFeatureDisabled = useCallback(
    ({
      feature,
      requiredPermissionLevel,
    }: {
      feature: keyof typeof ALL_FEATURE_IDS;
      requiredPermissionLevel?: keyof typeof appPermissionAccessLevels;
    }) => {
      const parentApp = getParentApp({
        id: feature,
        type: VIEW_OR_FEATURE.FEATURE,
      });

      // if parent app is disabled, feature is disabled
      if (parentApp && !hasAppPermission(parentApp?.id)) {
        return true;
      }

      // if feature doesn't have sufficient permissions, feature is disabled
      if (!hasFeaturePermission(feature)) {
        return true;
      }

      // if required permission provided and parent app doesn't have sufficient permission, feature is disabled
      // if required permission provided and feature doesn't have sufficient permission, feature is disabled
      if (requiredPermissionLevel) {
        const parentAppHasPermissionLevel =
          parentApp &&
          doesUserAccessLevelMeetRequiredAccessLevel({
            userPermissionLevel:
              appsWithPermissions[parentApp?.id]?.permissions?.accessLevel,
            requiredPermissionLevel,
          });

        const featureHasPermissionLevel =
          parentApp &&
          doesUserAccessLevelMeetRequiredAccessLevel({
            userPermissionLevel:
              appsWithPermissions[parentApp?.id]?.features?.[feature]
                ?.permissions?.accessLevel,
            requiredPermissionLevel,
          });

        return !(parentAppHasPermissionLevel && featureHasPermissionLevel);
      }

      // otherwise, feature has sufficient permission, feature isn't disabled
      return false;
    },
    [appsWithPermissions]
  );

  // provided a PermissionScope (app and optionally feature) determines if permissions are disabled
  // if no scope is provided, permissions implicitly are not disabled
  const isPermissionDisabled = useCallback(
    (scope?: PermissionScope) => {
      //  if no app is scoped, permissions cannot be disabled
      if (!scope) {
        return false;
      }
      const appIsEnabled = hasAppPermission(scope.app);
      // if app isn't enabled, permissions are disabled
      if (!appIsEnabled) {
        return true;
      }

      // if access level is provided, and user doesn't have correct permissions, feature is disabled
      if (
        !doesUserAccessLevelMeetRequiredAccessLevel({
          userPermissionLevel:
            appsWithPermissions[scope.app]?.permissions?.accessLevel,
          requiredPermissionLevel: scope?.accessLevel,
        })
      ) {
        return true;
      }

      // if view is provided and view is disabled, permissions are disabled
      if (
        scope.view &&
        isViewDisabled({
          view: scope.view,
          requiredPermissionLevel: scope?.accessLevel,
        })
      ) {
        return true;
      }

      // if there's a feature within the scope of the app, return its permissions.
      // otherwise, permissions are not disabled
      return scope.feature
        ? isFeatureDisabled({
            feature: scope.feature,
            requiredPermissionLevel: scope?.accessLevel,
          })
        : false;
    },
    [appsWithPermissions]
  );

  const getDisabledReason = useCallback(
    (permissionScope: PermissionScope) => {
      if (!hasAppPermission(permissionScope.app)) {
        return {
          disabledType: DISABLED_TYPE.APP,
          name: ALL_APPS[permissionScope.app].name,
          permissionState:
            appsWithPermissions[permissionScope.app]?.permissions?.state,
        };
      }

      const viewParentApp =
        permissionScope?.view &&
        getParentApp({ id: permissionScope?.view, type: VIEW_OR_FEATURE.VIEW });

      // if there's a view within the scope of the app, and its parent app is disabled
      // return that as the disabled reason
      if (viewParentApp && !hasAppPermission(viewParentApp.id)) {
        return {
          disabledType: DISABLED_TYPE.PARENT_APP,
          name: viewParentApp.name,
          permissionState:
            appsWithPermissions[viewParentApp.id]?.permissions?.state,
        };
      }

      // if there's a view within the scope of the app, and it's disabled
      // return that as the disabled reason
      if (
        permissionScope?.view &&
        isViewDisabled({
          view: permissionScope?.view,
          requiredPermissionLevel: permissionScope?.accessLevel,
        })
      ) {
        return {
          disabledType: DISABLED_TYPE.VIEW,
          name: viewParentApp?.views?.[permissionScope.view]?.name,
          permissionState: viewParentApp
            ? appsWithPermissions[viewParentApp?.id]?.views?.[
                permissionScope.view
              ]?.permissions?.state
            : appPermissionStates.unavailable,
        };
      }

      const featureParentApp =
        permissionScope?.feature &&
        getParentApp({
          id: permissionScope.feature,
          type: VIEW_OR_FEATURE.FEATURE,
        });

      // if there's a feature within the scope of the app, and its parent app is disabled
      // return that as the disabled reason
      if (
        permissionScope?.feature &&
        featureParentApp &&
        !hasAppPermission(featureParentApp.id)
      ) {
        return {
          disabledType: DISABLED_TYPE.PARENT_APP,
          name: featureParentApp.name,
          permissionState:
            appsWithPermissions[featureParentApp.id]?.permissions?.state,
        };
      }

      // if there's a feature within the scope of the app, and it's disabled
      // return that as the disabled reason
      if (
        permissionScope?.feature &&
        !hasFeaturePermission(permissionScope.feature)
      ) {
        return {
          disabledType: DISABLED_TYPE.FEATURE,
          name: featureParentApp?.features?.[permissionScope.feature]?.name,
          permissionState: featureParentApp
            ? appsWithPermissions[featureParentApp?.id]?.features?.[
                permissionScope.feature
              ]?.permissions?.state
            : appPermissionStates.unavailable,
        };
      }

      // as a default/fallback, return 'app' as the disabled reason
      return { disabledType: DISABLED_TYPE.APP };
    },
    [appsWithPermissions]
  );

  return (
    <AppAccessContext.Provider
      value={{
        appPermissions,
        appsWithPermissions,
        isLoading:
          isLoadingUser ||
          isLoadingMetronomeEntitlements ||
          isLoadingUserAccessControls ||
          !userAccessControls,
        hasAppPermission,
        hasFeaturePermission,
        isFeatureDisabled,
        isPermissionDisabled,
        getDisabledReason,
      }}
    >
      {children}
    </AppAccessContext.Provider>
  );
};
