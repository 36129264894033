// Import the RTK Query methods from the React-specific entry point
// Allows automatic creation of react hooks
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { Mutex } from 'async-mutex';
import type {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/query';
import { URLS } from 'store/slices/constants/apiV1';
import { setToken, softLogout } from 'store/actions';
import { RootState } from 'store';
import { camelize, removeUndefined, snakeCase } from 'utils/functions';
import jwtDecode from 'jwt-decode';
import { DecodedToken } from 'types/session';
import { GenericObject, GlobalSearchResponse } from 'types';
import { API_URL } from 'constants/envConstants';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { QueryFilterOperators, QueryParams } from 'types/api';
import { mobileInventoryStorageName } from 'types/mobileInventorySettings';

type ErrorData = {
  status: number;
  data: {
    code: string;
  };
};

// Add other content types if needed
type Headers = {
  'Content-Type'?: 'application/json';
  Authorization?: string;
  'X-ORG-ID'?: string;
};

function isErrorData(error: FetchBaseQueryError): error is ErrorData {
  return (
    typeof (error as ErrorData).status === 'number' &&
    (error as ErrorData).data !== undefined &&
    (error as ErrorData).data.code !== undefined
  );
}

const mutex = new Mutex();

export const baseQuery = fetchBaseQuery({
  baseUrl: API_URL,
  prepareHeaders: (headers, { getState }) => {
    const state = getState() as RootState;
    const token = state.session.authToken;
    const activeOrgId = state.org.activeOrgId;
    if (token) {
      headers.set('Authorization', `Bearer ${token}`);
      if (activeOrgId) {
        if (!headers.has('X-ORG-ID')) headers.set('X-ORG-ID', activeOrgId);
      }
    }

    return headers;
  },
});

const baseQueryWithReauth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  // wait until the mutex is available without locking it
  // Should only be locked if someone is trying to refresh token
  await mutex.waitForUnlock();
  // API returns and expects snake_case properties on resources, but frontend deals in camelCase properties
  // So, when there's an args.body present, convert any keys on it to snakeCase. This is true for POST or PATCH
  // requests mostly. Otherwise, args is usually just a string.
  // Also, some PATCH endpoints will have unexpected behavior if given a nullish value, like an empty string,
  // so remove such values entirely from these objects
  if (typeof args !== 'string' && args.body) {
    args.body = snakeCase(removeUndefined(args.body));
  }
  // Segment Notification
  // Don't blanket track on events that manipulate passwords, both to prevent leaking passwords
  // but also because those events are handled with special Segment semantics
  if (
    typeof args !== 'string' &&
    args.method !== 'GET' &&
    !args.url.includes('token') &&
    !args.url.includes('signup') &&
    !args.url.includes('password')
  ) {
    // Disable for now, not sure this is actually useful
    // analyticsTrack('api:mutation', {
    //   args,
    // });
  }

  const {
    session: { authToken: token },
    org: { blended },
  } = api.getState() as RootState;
  let decodedToken;
  // Check if we should refresh token and do so
  try {
    if (blended) {
      const url = new URL(typeof args === 'string' ? args : args.url);
      const params = url.search ? `${url.search}&blended` : '?blended';
      const newUrl = `${url.origin}${url.pathname}${params}`;
      typeof args === 'string' ? (args = newUrl) : (args.url = newUrl);
    }
    if (token) {
      decodedToken = jwtDecode(token) as DecodedToken;
      // Python gives the expiry in seconds, not milliseconds
      // so, add 3 zeros
      if (decodedToken.exp && decodedToken.exp * 1000 <= new Date().getTime()) {
        if (!mutex.isLocked()) {
          const release = await mutex.acquire();
          try {
            const refreshResult = (await baseQuery(
              {
                url: URLS.REFRESH_TOKEN,
                method: 'POST',
                body: {
                  refresh: (api.getState() as RootState).session.refreshToken,
                },
              },
              api,
              extraOptions
            )) as { data: { access: string } };

            if (refreshResult?.data?.access) {
              api.dispatch(setToken(refreshResult.data.access));

              // Both Access Token and Refresh Token Expired
              // Or, unknown issues with API or communication between frontend and API
            } else {
              api.dispatch(softLogout());
              //  Normally done like:
              //  dispatch(apiSlice.util.resetApiState());
              api.dispatch({ type: 'api/resetApiState' });
            }
          } finally {
            // release must be called once the mutex should be released again.
            release();
          }
        }
      }
      // Check if someone already trying to refresh token
    }
  } catch (e) {
    // A falsy or malformed token will throw an `InvalidTokenError` error.
    // I'm not sure what would cause this case so just logout if it happens
    api.dispatch(softLogout());
  }

  // This ensures that if JWT detected expired token, any other requests that came afterwards
  // will wait until a new token is acquired before attempting to initiate (see release() above)
  // For example, loading stock lots view will cause request to stock_lots/ , shipments/, and
  // stock_locations/. JWT decode will detect expired token on stock_lots and lock mutex. shipments
  // and stock locations request will asynchronously hit this line and wait until stock_locations
  // finally{ release() } happens, then continue on as below
  await mutex.waitForUnlock();

  let result = await baseQuery(args, api, extraOptions);
  // Access Token Expired
  // Refresh Token Possibly Expired
  if (
    result.error &&
    result.error.status === 401 &&
    isErrorData(result.error) &&
    result.error.data?.code !== 'no_active_account' &&
    result.error.data?.code !== 'not_authenticated'
  ) {
    if (!mutex.isLocked()) {
      const release = await mutex.acquire();
      try {
        const refreshResult = (await baseQuery(
          {
            url: URLS.REFRESH_TOKEN,
            method: 'POST',
            body: {
              refresh: (api.getState() as RootState).session.refreshToken,
            },
          },
          api,
          extraOptions
        )) as { data: { access: string } };

        if (refreshResult?.data?.access) {
          api.dispatch(setToken(refreshResult.data.access));

          // retry the initial query
          result = await baseQuery(
            args as string | FetchArgs,
            api,
            extraOptions
          );
          // Both Access Token and Refresh Token Expired
          // Or, unknown issues with API or communication between frontend and API
        } else {
          api.dispatch(softLogout());
          //  Normally done like:
          //  dispatch(apiSlice.util.resetApiState());
          api.dispatch({ type: 'api/resetApiState' });
        }
      } finally {
        // release must be called once the mutex should be released again.
        release();
      }
    } else {
      // wait until the mutex is available without locking it
      await mutex.waitForUnlock();
      result = await baseQuery(args as string | FetchArgs, api, extraOptions);
    }
  }
  // if result data is a blob, return it as is
  if (result.data && result.data instanceof Blob) {
    return result;
  }
  if (result.data) {
    result.data = camelize(result.data as GenericObject);
  }
  if (result.error) {
    // @ts-ignore
    result.error = camelize(result.error);
  }
  return result;
};
// Define our single API slice object

export const apiSlice = createApi({
  // The cache reducer expects to be added at `state.api` (already default - this is optional)
  reducerPath: 'api',
  baseQuery: baseQueryWithReauth,
  // The "endpoints" represent operations and requests for this server
  // We mostly just inject these from the other slices
  // I completely disagree with the docs that say ALL endpoints should plop into here, that seems insane
  endpoints: (builder) => ({
    globalSearch: builder.query<GlobalSearchResponse[], string>({
      query: (search) => URLS.GLOBAL_SEARCH(search),
    }),
  }),
  // NECESSARY to list here any tag types on other endpoints
  // You will get typescript errors if you try to use a tag that isn't listed here
  tagTypes: [
    'Boms',
    'BomLines',
    'BomLinesByPart',
    'BomLineAvailabilities',
    'User',
    'UserLookup',
    'Org',
    'PartInventory',
    'NoPartInventory',
    'StockLocations',
    'StockLocation',
    'Shipments',
    'HandlingTaskRows',
    'HandlingRequests',
    'Ledgers',
    'SupplierAutocomplete',
    'Suppliers',
    'PurchaseRules',
    'PriceModel',
    'Availabilities',
    'AvailabilitiesSnapshot',
    'Part',
    'PartQuote',
    'PartOffer',
    'Solicitation',
    'StockLot',
    'Document',
    'ShipmentSolver',
    'Order',
    'OrderLines',
    'OrderUpdates',
    'WatchlistSubscriptions',
    'WatchlistSubscription',
    'NotificationPreferences',
    'Version',
    'Allocation',
    'ProductionRuns',
    'ProductionRunParts',
    'ProductionRunPartOverrides',
    'ProductionRunLines',
    'ProductionRunAllocations',
    'ProductionRunDates',
    'ProductionRunShipments',
    'ProductionRunConsumptionEstimates',
    'PickListTree',
    'Printer',
    'LabelTemplate',
    'PreviewZpl',
    'LabelPreviewImage',
    'ExpandedPurchaseLines',
    'ConsumeStockEvents',
    'AlternativePart',
    'OrderRemediations',
    'OrderLineAlts',
    'Orgs',
    'Facilities',
    'CustomPart',
    'ManufacturerAutocomplete',
    'ClassificationsAutocomplete',
    'UserAccessControl',
    'Invoice',
    'Apps',
    'Grant',
    'Grants',
    'OrgIssuedGrants',
    'OrgGrants',
    'UserGrants',
    'KittingOptions',
    'ProductionRunAllocationsForPartPreview',
    'ProductionRunAllocationsForPart',
    'OrgPart',
    'PartRuleSet',
    'OrderSnapshot',
    'Entitlements',
    'OrderSnapshotAvailabilities',
    'DocumentExtractors',
    'BomSheet',
    'BomValidations',
    'PurchaseRequests',
    'PurchaseOrders',
    'PurchaseOrderLines',
    'PurchaseOrderLinesWithPurchase',
    'PurchaseOrderNoPartLines',
    'PurchaseOrderCofactrCharges',
    'PurchaseOrderEvents',
    'PublicSuppliers',
    'OrgSuppliers',
    'ReportingSchemas',
    'Teams',
    'TeamTypes',
    'DocumentTemplate',
    'OutboundEmailTemplate',
    'OutboundEmail',
    'CustomProperty',
    'Tag',
    'ConfirmedPurchaseLines',
    // Client V2 Below this line
    'SupplierBills',
    'SupplierBillLines',
    'KitRequests',
    'KitRequestLines',
    'ClientV2HandlingRequests',
    'ClientV2StockEvents',
    'ClientV2Allocations',
    'ClientV2Shipments',
    'ClientV2ShipmentLines',
    'ClientV2PurchaseOrders',
    'ClientV2PurchaseOrderLines',
    'ClientV2PurchaseOrderNoPartLines',
    'PurchaseRequestGroups',
    'ClientV2PurchaseRequests',
    'ClientV2Rfqs',
    'ClientV2RfqLines',
    'SupplierQuotes',
    'SupplierQuoteLines',
    'ClientV2RfqsSupplierJoins',
  ],
});

export const { useGlobalSearchQuery, useLazyGlobalSearchQuery } = apiSlice;

export async function fetchWithReauth(
  url: string,
  getState: () => RootState,
  dispatch: ThunkDispatch<RootState, void, AnyAction>
) {
  const { authToken, refreshToken } = getState().session;
  const { activeOrgId } = getState().org;
  await mutex.waitForUnlock();

  let decodedToken;
  try {
    if (authToken) {
      decodedToken = jwtDecode(authToken) as DecodedToken;
      // Python gives the expiry in seconds, not milliseconds
      // so, add 3 zeros
      if (decodedToken.exp && decodedToken.exp * 1000 <= new Date().getTime()) {
        if (!mutex.isLocked()) {
          const release = await mutex.acquire();
          try {
            const body = JSON.stringify({
              refresh: refreshToken,
            });
            const refreshResult = await fetch(URLS.REFRESH_TOKEN, {
              method: 'POST',
              body,
              headers: {
                'Content-Type': 'application/json',
              },
            });
            const data = (await refreshResult.json()) as {
              access: string;
            };

            if (data?.access) {
              const headers: Headers = {
                Authorization: `Bearer ${data.access}`,
              };
              if (activeOrgId) {
                headers['X-ORG-ID'] = activeOrgId;
              }
              dispatch(setToken(data.access));
              return await fetch(url, {
                method: 'GET',
                headers,
              });

              // Both Access Token and Refresh Token Expired
              // Or, unknown issues with API or communication between frontend and API
            }
            dispatch(softLogout());
            //  Normally done like:
            //  dispatch(apiSlice.util.resetApiState());
            dispatch({ type: 'api/resetApiState' });
            return await Promise.reject(
              new Error(
                'Both Access and Refresh Token are expired. Request impossible. Logging out.'
              )
            );
          } finally {
            // release must be called once the mutex should be released again.
            release();
          }
        }
      } else {
        const headers: Headers = {
          Authorization: `Bearer ${authToken}`,
        };
        if (activeOrgId) {
          headers['X-ORG-ID'] = activeOrgId;
        }
        return await fetch(url, {
          method: 'GET',
          headers,
        });
      }
      // Check if someone already trying to refresh token
    }
  } catch (e) {
    // A falsy or malformed token will throw an `InvalidTokenError` error.
    // I'm not sure what would cause this case so just logout if it happens
    dispatch(softLogout());
    return Promise.reject(
      new Error('There was a falsy or malformed token. Logging out.')
    );
  }
  return Promise.reject(
    new Error('There is no auth token on store right now.')
  );
}

export const createClientV2QueryFn =
  <T, P extends QueryParams<any>>(
    urlFn: (params: P) => string | FetchArgs
  ): BaseQueryFn<P, T, FetchBaseQueryError> =>
  async (params, api) => {
    try {
      const activeOrgId = (api.getState() as RootState).org.activeOrgId;
      const blended = (api.getState() as RootState).org.blended;

      const storedValue = localStorage.getItem(mobileInventoryStorageName);
      const sandbox =
        storedValue === null ? false : JSON.parse(storedValue).sandboxMode;

      const paramsWithOrg = {
        ...params,
        filters: [
          ...(params.filters || []),
          ...(blended || params.blended
            ? []
            : [
                {
                  field: 'org',
                  operator: QueryFilterOperators['='],
                  value: activeOrgId,
                },
              ]),
          // This section basically means if the user in warehouse lets look at the sandbox setting used in local storage used for warehouse
          ...(blended
            ? [
                {
                  field: 'org.sandbox',
                  operator: QueryFilterOperators['='],
                  value: sandbox,
                },
              ]
            : []),
        ],
      };

      const baseArgs = urlFn(paramsWithOrg);
      const fetchArgs =
        typeof baseArgs === 'string' ? { url: baseArgs } : baseArgs;

      const result = await baseQueryWithReauth(fetchArgs, api, {});

      if (result.error) {
        return { error: result.error };
      }

      return { data: result.data as T };
    } catch (error) {
      return {
        error: {
          status: 'CUSTOM_ERROR',
          error: String(error),
        } as FetchBaseQueryError,
      };
    }
  };

export const createClientV2MutationFn =
  <T, P>(
    urlFn: (params: P) => string | FetchArgs
  ): BaseQueryFn<P, T, FetchBaseQueryError> =>
  async (params, api) => {
    try {
      const activeOrgId = (api.getState() as RootState).org.activeOrgId;
      const blended = (api.getState() as RootState).org.blended;

      const baseArgs = urlFn(params);
      const fetchArgs =
        typeof baseArgs === 'string' ? { url: baseArgs } : baseArgs;

      // Add org to the body if not in blended mode
      const modifiedFetchArgs = {
        ...fetchArgs,
        body: (() => {
          const body = (fetchArgs as FetchArgs).body;
          if (!blended) {
            if (Array.isArray(body)) {
              return body.map((item) => ({
                ...item,
                org: activeOrgId,
              }));
            }
            return {
              ...body,
              org: activeOrgId,
            };
          }
          return body;
        })(),
      };

      const result = await baseQueryWithReauth(modifiedFetchArgs, api, {});

      if (result.error) {
        return { error: result.error };
      }

      return { data: result.data as T };
    } catch (error) {
      return {
        error: {
          status: 'CUSTOM_ERROR',
          error: String(error),
        } as FetchBaseQueryError,
      };
    }
  };
